import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Message {
  id: number;
  title: string;
  content: string;
  sendDate: string;
  avatarPath: string;
  isClosed: boolean;
}

interface MessagesState extends Array<Message> {}

interface AddMessagesAction extends PayloadAction<Message[]> {}

const initialState: MessagesState = [];

const messagesSlice = createSlice({
  name: "messagesThread",
  initialState,
  reducers: {
    addMessagesThread: (state, action: AddMessagesAction) => {
      return [...action.payload];
    },
  },
});

export const { addMessagesThread } = messagesSlice.actions;

export default messagesSlice.reducer;
