import { useContext } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../contexts/offerProvider/types";
import AttributesChips from "./AttributesChips";

function Chips() {
  const {
    filters,
    handleChangeCity,
    handleChangeProvince,
    handleChangeMinPrice,
    handleChangeMaxPrice,
  } = useContext(OfferContext) as IOfferContext;

  return (
    <Stack direction="row" sx={{ mb: 2, flexWrap: "wrap" }}>
      {filters.province && (
        <Chip
          sx={{ mb: 1, ml: 1 }}
          label={filters.province}
          variant="outlined"
          onDelete={() => handleChangeProvince(null)}
        />
      )}
      {filters.city && (
        <Chip
          sx={{ mb: 1, ml: 1 }}
          label={filters.city}
          variant="outlined"
          onDelete={() => handleChangeCity(null)}
        />
      )}
      {!!filters.minPrice && (
        <Chip
          sx={{ mb: 1, ml: 1 }}
          label={`od ${filters.minPrice} zł`}
          variant="outlined"
          onDelete={() => handleChangeMinPrice(null)}
        />
      )}
      {filters.maxPrice && (
        <Chip
          sx={{ mb: 1, ml: 1 }}
          label={`do ${filters.maxPrice} zł`}
          variant="outlined"
          onDelete={() => handleChangeMaxPrice(null)}
        />
      )}
      <AttributesChips filterAttributes={filters.attributes} />
    </Stack>
  );
}

export default Chips;
