export const FROM_THE_CHEAPEST = "Od najtańszych" as const;
export const FROM_THE_MOST_EXPENSIVE = "Od najdroższych" as const;
export const FROM_THE_OLDEST = "Od najstarszych" as const;
export const FROM_THE_LATEST = "Od najnowszych" as const;

export type SortType =
  | typeof FROM_THE_CHEAPEST
  | typeof FROM_THE_MOST_EXPENSIVE
  | typeof FROM_THE_OLDEST
  | typeof FROM_THE_LATEST;

export const sortOptions = [
  { value: "od-najtanszych", name: FROM_THE_CHEAPEST },
  { value: "od-najdrozszych", name: FROM_THE_MOST_EXPENSIVE },
  { value: "od-najstarszych", name: FROM_THE_OLDEST },
  { value: "od-najnowszych", name: FROM_THE_LATEST },
];
