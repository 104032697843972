import { Box } from "@mui/material";
import { useOwnedSoldOffersList } from "../../hooks/fetchHooks/transactions/useOwnedSoldOffersList";
import OffersContentLoader from "./OffersContentLoader";
import UserOfferList from "../userOfferList/UserOfferList";
import { useHandlePagination } from "../../hooks/useHandlePagination";
import OffersPagination from "../molecules/OffersPagination";
import { getPagesCount } from "../../utils/paginationUtils";

function SoldTransactions() {
  const { page, handleChangePage } = useHandlePagination();
  const { offers, isLoading } = useOwnedSoldOffersList(page);

  return (
    <Box sx={{ backgroundColor: "#fff", p: 2 }}>
      {isLoading ? (
        <OffersContentLoader />
      ) : (
        <>
          <UserOfferList
            offers={offers.items}
            count={offers.count}
            disableAddToFavorites
          />
          <Box sx={{ mt: 2.25 }}>
            {offers.count > 20 && (
              <OffersPagination
                currentPage={page}
                pagesCount={getPagesCount(offers?.count)}
                handleClickPage={handleChangePage}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default SoldTransactions;
