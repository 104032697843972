import { Box, Tabs } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { MainTab } from "./MainTabs";
import BoughtPaidTransactions from "./BoughtPaidTransactions";
import BoughtUnpaidTransactions from "./BoughtUnpaidTransactions";

function BuyingTransactions() {
  const [tabsValue, setTabsValue] = useState("paid");

  const handleChangeTab = (_event: SyntheticEvent, newValue: string) => {
    setTabsValue(newValue);
  };

  return (
    <Box
      sx={{ backgroundColor: "#fff", flexGrow: 1, overflow: "hidden", p: 2 }}
    >
      <Tabs value={tabsValue} onChange={handleChangeTab} variant="fullWidth">
        <MainTab value="paid" label="Opłacone" sx={{ flexGrow: 1 }} />
        <MainTab value="unpaid" label="Nieopłacone" sx={{ flexGrow: 1 }} />
      </Tabs>
      {tabsValue === "paid" && <BoughtPaidTransactions />}
      {tabsValue === "unpaid" && <BoughtUnpaidTransactions />}
    </Box>
  );
}

export default BuyingTransactions;
