import { AvailableChargeInterface } from "../../models/AvailableChargeInterface";

export const getChargesTotalPrice = (
  selectedCharges: number[],
  charges: AvailableChargeInterface[],
) => {
  return selectedCharges.reduce(
    (totalPriceAcc: number, selectedChargeId: number) => {
      const currentCharge = charges.find(
        (charge) => charge.id === selectedChargeId,
      );

      if (totalPriceAcc && currentCharge?.price) {
        return totalPriceAcc + currentCharge.price;
      }

      return currentCharge?.price || 0;
    },
    0,
  );
};
