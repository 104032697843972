import { useState } from "react";
import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import PriceInput from "../inputs/PriceInput";
import AutocompleteInput from "../inputs/AutocompleteInput";
import SelectInput from "../inputs/SelectInput";
import { sortOptions } from "../offerCatalog/filterSort/sortTypes";
import FiltersChips from "./FiltersChips";
import { BasicOfferFiltersInterface } from "../../models/OffersFiltersInterface";
import { City, Province } from "../../client/Requests";

type Props = {
  filters: BasicOfferFiltersInterface;
  changeFilters: (name: string, value?: string) => void;
  handleClearAllFilters: () => void;
  cities: City[];
  provinces: Province[];
};

function Filters({
  filters,
  cities,
  provinces,
  changeFilters,
  handleClearAllFilters,
}: Props) {
  const filtersCount =
    +!!filters.province +
    +!!filters?.city +
    +!!filters?.minPrice +
    +!!filters?.maxPrice;
  const [openFilters, setOpenFilters] = useState<Boolean>(!!filtersCount);

  return (
    <Box mb={2}>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "baseline",
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="outlined"
          sx={{ mb: 2 }}
          onClick={() => {
            setOpenFilters(!openFilters);
          }}
        >
          Filtry{!!filtersCount && ` (${filtersCount})`}
        </Button>
        <SelectInput
          name="sort"
          label="Sortuj"
          options={sortOptions}
          initialValue={filters.sort}
          onSelectChange={changeFilters}
        />
      </Stack>
      <Divider sx={{ mb: 3 }} />
      {openFilters && (
        <>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AutocompleteInput
                label="Wybierz województwo"
                name="province"
                key="province"
                value={filters?.province || ""}
                onChange={changeFilters}
                options={provinces.map((province) => province.name)}
              />
            </Grid>
            {filters.province && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <AutocompleteInput
                  label="Wybierz miasto"
                  name="city"
                  key="city"
                  value={filters?.city || ""}
                  onChange={changeFilters}
                  options={cities.map((city) => city.name)}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={3} md={2}>
              <PriceInput
                label="Cena od"
                name="minPrice"
                onChange={changeFilters}
                relatedValue={filters.minPrice}
                changeOnBlur
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <PriceInput
                label="Cena do"
                name="maxPrice"
                onChange={changeFilters}
                relatedValue={filters.maxPrice}
                changeOnBlur
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="space-between" alignItems="start">
            <FiltersChips filters={filters} onChange={changeFilters} />
            <Button
              sx={{ minWidth: 130 }}
              onClick={() => handleClearAllFilters()}
              disabled={!filtersCount}
            >
              Wyczyść filtry
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Filters;
