/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  City,
  Province,
  getCities,
  getProvinces,
} from "../../../client/Requests";
import { inputRequired } from "../../../utils/inputValidate";
import { CustomTextField } from "../../atoms/Inputs/InputMUI";
import { errorAdornment } from "./const";
import PostalCodeField from "./PostalCodeField";

function AddressData() {
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [cityName, setCityName] = useState("");
  const [resetAutocomplete, setResetAutocomplete] = useState(0);
  const [openCityField, setOpenCityField] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { control, watch, setValue, resetField } = useFormContext();
  const province = watch("province");

  useEffect(() => {
    getProvinces().then((value: React.SetStateAction<Province[]>) => {
      setProvinces(value);
      if (province) {
        getCities(province).then((cityValue: any) => {
          setCities(cityValue);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (province !== "Województwo" && province) {
      getCities(province).then((value: any) => {
        setCities(value);
        setResetAutocomplete(resetAutocomplete + 1);
      });
    }
  }, [province]);

  useEffect(() => {
    if (cityName.length > 2) {
      setOpenCityField(true);
    } else {
      setOpenCityField(false);
    }
  }, [cityName]);

  return (
    <>
      <Typography
        variant="h5"
        component="h4"
        sx={{ fontWeight: 700, lineHeight: 1.4 }}
      >
        Dane adresowe
      </Typography>
      <Typography sx={{ mb: 3 }}>
        Potrzebne, aby sprzedający wiedział gdzie wysłać zakupione przez Ciebie
        przedmioty.
      </Typography>
      <Grid container spacing={{ xs: 2, md: 3 }} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="street"
            render={({
              field: { ref, value, onChange },
              fieldState: { invalid, error },
            }) => (
              <CustomTextField
                sx={{ width: 1 }}
                label="Ulica i numer"
                placeholder="np. Wspólna 1A/3"
                variant="standard"
                inputRef={ref}
                value={value}
                onChange={onChange}
                error={invalid}
                helperText={error?.message}
                InputProps={invalid ? { endAdornment: errorAdornment } : {}}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="province"
            rules={{ required: inputRequired }}
            render={({
              field: { value: values, onChange },
              fieldState: { invalid, error },
            }) => (
              <FormControl error={invalid} sx={{ width: 1 }}>
                <InputLabel variant="standard">Województwo</InputLabel>
                <Select
                  variant="standard"
                  onChange={(value) => {
                    onChange(value);
                    setValue("city", null);
                  }}
                  sx={{ width: 1, textTransform: "capitalize" }}
                  value={provinces.length ? values : 0}
                  placeholder="Wybierz"
                >
                  <MenuItem disabled value={0}>
                    Wybierz
                  </MenuItem>
                  {provinces.map((value) => (
                    <MenuItem
                      key={value.id}
                      value={value.id}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
                {typeof error?.message === "string" ? (
                  <FormHelperText sx={{ ml: 0 }}>
                    {error.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="postCode"
            render={({
              field: { ref, value, onChange },
              fieldState: { invalid, error },
            }) => (
              <FormControl error={invalid} variant="standard" sx={{ width: 1 }}>
                <InputLabel htmlFor="postCode">Kod pocztowy</InputLabel>
                <Input
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  id="postCode"
                  inputComponent={PostalCodeField as any}
                  endAdornment={invalid ? errorAdornment : null}
                />
                {typeof error?.message === "string" ? (
                  <FormHelperText sx={{ ml: 0 }}>
                    {error.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="city"
            render={({
              field: { value, onChange },
              fieldState: { invalid, error },
            }) => (
              <Autocomplete
                sx={{ width: 1 }}
                disablePortal
                options={cities}
                key={resetAutocomplete}
                disabled={cities.length < 1}
                getOptionLabel={(options) => options.name}
                isOptionEqualToValue={(options) => options.name}
                onChange={(_, valueInput) => onChange(valueInput)}
                onClose={() => setOpenCityField(false)}
                value={value?.name ? value : null}
                noOptionsText="Nie znaleziono takiej lokalizacji"
                renderInput={(params) => (
                  <TextField
                    error={invalid}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    onChange={(e) => setCityName(e.target.value)}
                    variant="standard"
                    label="Miasto"
                    placeholder="Wybierz"
                    helperText={error?.message}
                  />
                )}
                open={openCityField}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AddressData;
