import { useEffect, useState } from "react";
import { OffersFiltersInterface } from "../models/OffersFiltersInterface";
import { City, Province, getCities, getProvinces } from "../client/Requests";

const useFiltersLocations = (provinceSelected?: string) => {
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [cities, setCities] = useState<City[]>([]);

  const handleFetchProvinces = async () => {
    try {
      const provincesFetched = await getProvinces();
      setProvinces(provincesFetched);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFetchCities = async () => {
    const selectedProvinceId = provinces.length
      ? provinces.find((province) => province?.name === provinceSelected)?.id
      : null;
    if (selectedProvinceId) {
      try {
        const citiesFetched = await getCities(selectedProvinceId);
        setCities(citiesFetched);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    handleFetchProvinces();
  }, []);

  useEffect(() => {
    if (provinceSelected && provinces?.length) {
      handleFetchCities();
    }
  }, [provinceSelected, provinces]);

  return {
    provinces,
    cities,
  };
};

export default useFiltersLocations;
