import { useState } from "react";

export const useHandlePagination = () => {
  const [page, setPage] = useState<number>(1);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  return {
    page,
    handleChangePage,
  };
};
