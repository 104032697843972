import axios from "axios";

type MethodType = "get" | "post" | "delete" | "put";

const sendRequest = async (
  methodType: MethodType,
  endpoint: string,
  requestData?: any,
  requestHeaders?: any,
  contentType: string = "application/json",
) => {
  const headers = {
    ...requestHeaders,
    "Content-Type": contentType,
  };

  const requestConfig = {
    method: methodType,
    baseURL: process.env.REACT_APP_API_URL,
    url: endpoint,
    data: requestData,
    headers,
  };

  return axios.request(requestConfig);
};

export default sendRequest;
