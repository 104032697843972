import React, { useState } from "react";
import { FormControl, Input, InputLabel } from "@mui/material";
import PriceField from "../organisms/createOffer/PriceField";

type Props = {
  label: string;
  name?: string;
  onChange: (name: string, value: string) => void;
  relatedValue?: number | string;
  changeOnBlur?: boolean;
};

function PriceInput({
  name = "",
  relatedValue,
  label = "cena",
  onChange,
  changeOnBlur,
}: Props) {
  const [inputValue, setInputValue] = useState<string>(
    relatedValue?.toString() ?? "",
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (changeOnBlur) {
      setInputValue(value);
    } else {
      onChange(name, value);
    }
  };

  const handleOnBlur = () => {
    if (changeOnBlur && inputValue !== relatedValue) {
      onChange(name, inputValue);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onChange(name, inputValue);
    }
  };

  return (
    <FormControl variant="standard">
      <InputLabel>{label}</InputLabel>
      <Input
        value={inputValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyPress}
        placeholder="np. 50"
        inputComponent={PriceField as any}
      />
    </FormControl>
  );
}

export default PriceInput;
