import { useContext, useEffect, useState } from "react";
import InputWithMask from "../form/InputWithMask";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../contexts/offerProvider/types";
import { PRICE_MASK } from "../../utils/masks";
import PriceInput from "../inputs/PriceInput";

function PriceTo() {
  const { handleChangeMaxPrice, filters } = useContext(
    OfferContext,
  ) as IOfferContext;
  const [amount, setAmout] = useState<string>(
    filters.maxPrice?.toString() ?? "",
  );

  useEffect(() => {
    if (amount && !filters.maxPrice) {
      setAmout("");
    }
  }, [filters.maxPrice]);

  const handleChangePrice = (_name: string, value: string) => {
    if (value !== amount) {
      handleChangeMaxPrice(value);
      setAmout(value);
    }
  };

  return (
    <PriceInput
      label="Cena do"
      relatedValue={filters.minPrice?.toString() ?? ""}
      onChange={handleChangePrice}
      changeOnBlur
    />
  );
}

export default PriceTo;
