import { Stack } from "@mui/material";

export interface DefaultLayoutInterface {
  mobilePadding?: boolean;
  children?: JSX.Element | (JSX.Element | null)[];
  width?: 500 | 1200;
}

function DefaultLayout({
  mobilePadding,
  children,
  width = 1200,
}: DefaultLayoutInterface) {
  return (
    <Stack
      sx={{
        width: 1,
        maxWidth: width,
        justifyContent: "center",
        mx: "auto",
        mt: { md: 4 },
        px: { xs: mobilePadding ? 2 : 0, lg: 0 },
      }}
    >
      {children}
    </Stack>
  );
}

export default DefaultLayout;
