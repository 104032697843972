import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { MouseEvent, useContext, useState } from "react";
import { TransactionsOffersInterface } from "../../models/ShortOffersInterface";
import { getFormattedPrice } from "../../utils/PriceUtils";
import EntryUserDialog from "../molecules/dialog/EntryUserDialog";
import {
  addOfferToFavorites,
  removeOfferFromFavorites,
} from "../../client/Requests";
import useUserData from "../../hooks/useUserData";
import { CarouselContext, CarouselContextProvider } from "../carousel/Carousel";

type Props = {
  offer: TransactionsOffersInterface;
  disableAddToFavorites?: boolean;
};

function OfferCard({ offer, disableAddToFavorites }: Props) {
  const carouselContext = useContext(
    CarouselContext,
  ) as CarouselContextProvider;

  const isDragging = carouselContext?.isDragging;

  const { userData } = useUserData();
  const theme = useTheme();
  const [openEntryUserDialog, setOpenEntryUserDialog] = useState(false);
  const {
    imagePath,
    name,
    price,
    isFavorite,
    id: offerId,
    isPromoted,
    slug,
    transactionUsername,
  } = offer;
  const [productIsFavorite, setProductIsFavorite] = useState(isFavorite);

  const handleClickFavoriteIcon = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!userData) {
      setOpenEntryUserDialog(true);
      return;
    }

    setProductIsFavorite(!productIsFavorite);

    if (productIsFavorite) {
      await removeOfferFromFavorites(userData?.userToken, offerId);
    } else {
      await addOfferToFavorites(userData.userToken, offerId);
    }
  };

  return (
    <Card
      sx={{
        height: 310,
        ...(isPromoted && {
          border: 1,
          borderColor: theme.palette.primary.main,
        }),
      }}
    >
      <CardActionArea
        LinkComponent="a"
        href={`/oferta/${slug}`}
        sx={{ height: 1, pointerEvents: isDragging ? "none" : "auto" }}
        disableRipple
      >
        {isPromoted && (
          <Box
            sx={{
              bgcolor: theme.palette.primary.main,
              position: "absolute",
              width: 1,
              top: "0px",
              left: "0px",
              height: "22px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              sx={{ color: "white", lineHeight: 2, fontWeight: 600 }}
            >
              Oferta promowana
            </Typography>
          </Box>
        )}
        {transactionUsername && (
          <Box
            sx={{
              bgcolor: theme.palette.secondary.main,
              position: "absolute",
              width: 1,
              top: "0px",
              left: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              sx={{ color: "white", lineHeight: 2, fontWeight: 600, p: 0.4 }}
            >
              {transactionUsername}
            </Typography>
          </Box>
        )}
        <CardMedia
          component="img"
          image={imagePath}
          alt={name}
          sx={{ height: "56%" }}
        />
        <Stack sx={{ justifyContent: "space-between", height: "44%" }}>
          <Typography
            sx={{ p: 1.5, minHeight: 60 }}
            variant="body2"
            color="text.secondary"
          >
            {name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              p: 1.5,
              pt: 0,
            }}
          >
            <Typography fontWeight={700} variant="h6">
              {getFormattedPrice(price)}
            </Typography>
            {!disableAddToFavorites && (
              <IconButton color="primary" onClick={handleClickFavoriteIcon}>
                {productIsFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
            )}
          </Box>
        </Stack>

        <EntryUserDialog
          initialOpen={openEntryUserDialog}
          handleClose={() => {
            setOpenEntryUserDialog(false);
          }}
        />
      </CardActionArea>
    </Card>
  );
}

export default OfferCard;
