import { configureStore } from "@reduxjs/toolkit";
import messagesThreadReducer from "./messagesSlice";
import userDataReducer from "./userSlice";
import messageThreadDetailsReducer from "./productOfferDataSlice";

export interface RootState {
  messagesThread: ReturnType<typeof messagesThreadReducer>;
  userData: ReturnType<typeof userDataReducer>;
  messageThreadDetails: ReturnType<typeof messageThreadDetailsReducer>;
}

export default configureStore({
  reducer: {
    messagesThread: messagesThreadReducer,
    userData: userDataReducer,
    messageThreadDetails: messageThreadDetailsReducer,
  },
});
