import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ButtonOutlined from "../../../atoms/Buttons/ButtonOutlined";
import ButtonContained from "../../../atoms/Buttons/ButtonContained";
import {
  inputRequired,
  minPrice,
  patternPrice,
} from "../../../../utils/inputValidate";
import { RootState } from "../../../../Redux/messagesStore/store";
import { sendPriceProposal } from "../../../../client/Requests";
import { swapToPennies } from "../../../../utils/PriceUtils";

function MessagePanelItemOwner() {
  const [open, setOpen] = useState(false);
  const userData = useSelector((state: RootState) => state.userData);
  const messageThreadDetails = useSelector(
    (state: RootState) => state.messageThreadDetails,
  );
  const { productOffer } = messageThreadDetails;
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setError,
    watch,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      price: "",
    },
    mode: "onSubmit",
  });

  const onSubmit = async (data: any) => {
    if (userData.userToken && productOffer?.id) {
      try {
        const { price } = data;
        await sendPriceProposal(
          productOffer.id,
          { price: swapToPennies(price.replace(/,/g, ".")) },
          userData.userToken,
        );
      } catch (error: any) {
        setError("price", {
          type: "api",
          message: error.response.data
            ? error.response.data
            : "Błąd podczas wysyłania propozycji Ceny",
        });
      }
      setOpen(false);
    }
  };
  const price = watch("price");
  useEffect(() => {
    if (price.includes(",")) {
      setValue("price", price.replace(/,/g, "."));
    }
  }, [price]);
  return (
    <div className="flex flex-row space-x-2 items-center justify-center	">
      <Button
        color="secondary"
        size="small"
        variant="outlined"
        sx={{
          fontWeight: 600,
          borderWidth: "2px",
          borderColor: "text.secondary",
          textTransform: "none",
        }}
        onClick={() => setOpen(true)}
      >
        Zaproponuj cenę
      </Button>
      <Button
        color="secondary"
        size="small"
        variant="contained"
        sx={{ width: "136.5px", textTransform: "none" }}
        onClick={() => {
          navigate(`/kup-teraz/${productOffer?.slug}`);
        }}
      >
        Kup teraz
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText
              id="dialog-description"
              sx={{ fontSize: "1.375rem", fontWeight: 700, mb: 2 }}
            >
              Zaproponuj cenę
            </DialogContentText>
            <Controller
              rules={{
                required: inputRequired,
                pattern: patternPrice,
                min: minPrice,
              }}
              control={control}
              name="price"
              render={({ field }: any) => (
                <TextField
                  {...field}
                  color="secondary"
                  variant="standard"
                  name="price"
                  type="text"
                  error={!!errors.price}
                  helperText={errors.price?.message}
                  label="Zaproponuj swoją cenę"
                  placeholder="np. 50"
                  sx={{ width: 1 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">PLN</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Anuluj</Button>
            <Button
              type="submit"
              variant="contained"
              disabled={!!price !== true}
            >
              Zaproponuj
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default MessagePanelItemOwner;
