import { useQuery } from "@tanstack/react-query";
import { getOwnedPublishedOffersListInactive } from "../../../client/Requests";
import useUserData from "../../useUserData";

export const useOwnedPublishedOffersListInactive = (page: number = 1) => {
  const { userData } = useUserData();
  const userToken = userData?.userToken;

  const { data, isLoading } = useQuery<any>({
    queryKey: ["owned-published-offer-list-inactive", page],
    queryFn: () =>
      userToken
        ? getOwnedPublishedOffersListInactive(userToken, page - 1).then(
            (res) => res.data,
          )
        : null,
    enabled: !!userToken,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  return {
    offers: data,
    isLoading,
  };
};
