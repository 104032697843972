import { useParentAssistantOffers } from "../../hooks/useParentAssistantOffers";
import ParentAssistantOffersPlaceholder from "./ParentAssistantOffersPlaceholder";
import ParentAssistantShortOffer from "./ParentAssistantShortOffer";

function ParentAssistantOffers() {
  const { parentAssistantOffers } = useParentAssistantOffers();

  return parentAssistantOffers?.length ? (
    <ParentAssistantShortOffer parentAssistantOffers={parentAssistantOffers} />
  ) : (
    <ParentAssistantOffersPlaceholder />
  );
}

export default ParentAssistantOffers;
