import React from "react";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import MUILink from "@mui/material/Link";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BreadcrumbsData } from "./types";

export type BreadcrumbsProps = {
  breadcrumbs: BreadcrumbsData;
};

function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  return (
    <MUIBreadcrumbs
      separator={
        <NavigateNextIcon fontSize="small" sx={{ color: "secondary.light" }} />
      }
    >
      {breadcrumbs.map((breadcrumb, index) => {
        if (breadcrumb.link) {
          return (
            <MUILink
              key={breadcrumb.text}
              component={Link}
              to={breadcrumb.link}
              color="secondary.light"
              underline="none"
            >
              {breadcrumb.text}
            </MUILink>
          );
        }

        return (
          <Typography key={breadcrumb.text} color="secondary.main">
            {breadcrumb.text}
          </Typography>
        );
      })}
    </MUIBreadcrumbs>
  );
}

export default Breadcrumbs;
