import { CircularProgress, Stack } from "@mui/material";
import React from "react";
import Typography from "../atoms/Typography";

function OffersLoading() {
  return (
    <div className="my-10">
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        spacing={5}
      >
        <Typography type="h3" text="Trwa wyszukiwanie ofert..." />
        <CircularProgress color="primary" />
      </Stack>
    </div>
  );
}

export default OffersLoading;
