import React, { useEffect, useMemo, useState } from "react";
import useUserData from "../../hooks/useUserData";
import { getMessages, sendGetMessageThreadList } from "../../client/Requests";
import {
  IMessageContext,
  Message,
  MessageBasketShipping,
  MessageThreadList,
  PriceProposal,
  ProductOffer,
} from "./types";

export const MessagesContext = React.createContext<IMessageContext | null>(
  null,
);

function MessagesProvider({ children }: React.PropsWithChildren<{}>) {
  const { userData: userLocalStorage } = useUserData();
  const [messageThreadList, setMessageThreadList] = useState<
    MessageThreadList[] | null
  >(null);
  const [currentMessageThreadId, setCurrentMessageThreadId] = useState<
    null | number
  >(null);
  const [messages, setMessages] = useState<Message[] | null>(null);
  const [messageBasketShipping, setMessageBasketShipping] =
    useState<MessageBasketShipping | null>(null);
  const [priceProposal, setPriceProposal] = useState<PriceProposal | null>(
    null,
  );
  const [productOffer, setProductOffer] = useState<ProductOffer | null>(null);
  const currentMessageThreadData = (() => {
    if (currentMessageThreadId && messageThreadList) {
      return messageThreadList.find(
        (messageThread) => messageThread.id === currentMessageThreadId,
      );
    }
    return null;
  })();

  const getMessagesThreadList = async () => {
    if (userLocalStorage) {
      const messageThreadListData = await sendGetMessageThreadList(
        userLocalStorage.userToken,
      );
      const messageThreadListArray = messageThreadListData.data;
      if (messageThreadListArray.length) {
        setMessageThreadList(messageThreadListArray);
        setCurrentMessageThreadId(
          messageThreadListArray[messageThreadListArray.length - 1].id,
        );
      }
    }
  };

  const getMessagesForThread = async () => {
    if (userLocalStorage && currentMessageThreadId) {
      const messagesList = await getMessages(
        currentMessageThreadId,
        userLocalStorage.userToken,
      );
      setMessages(messagesList.data.messages);
      setMessageBasketShipping(messagesList.data.messageBasketShipping);
      setPriceProposal(messagesList.data.priceProposal);
      setProductOffer(messagesList.data.productOffer);
    }
  };

  const refreshMessagesForThread = async () => {
    const response = await getMessagesForThread();
    return response;
  };

  const addNewMessage = (newMessage: Message) => {
    if (messages) {
      setMessages([...messages, newMessage]);
    }
  };

  const handleSetCurrentMessageThread = (id: number) => {
    setCurrentMessageThreadId(id);
  };
  useEffect(() => {
    getMessagesThreadList();
  }, [userLocalStorage]);

  useEffect(() => {
    if (currentMessageThreadId) {
      getMessagesForThread();
    }
  }, [currentMessageThreadId]);

  const providerData = useMemo(
    () => ({
      messageThreadList,
      messages,
      messageBasketShipping,
      priceProposal,
      productOffer,
      currentMessageThreadId,
      addNewMessage,
      handleSetCurrentMessageThread,
      refreshMessagesForThread,
      currentMessageThreadData,
    }),
    [
      messageThreadList,
      messages,
      currentMessageThreadId,
      currentMessageThreadData,
    ],
  );

  return (
    <MessagesContext.Provider value={providerData}>
      {children}
    </MessagesContext.Provider>
  );
}

export default MessagesProvider;
