import { useQuery } from "@tanstack/react-query";
import { getUserPublishedOffersBySlug } from "../../client/Requests";
import { UserProfileOfferInterface } from "../../models/UserProfileOfferInterface";

export const useUserProfileOfferBySlug = (
  page: number,
  userProfileSlug?: string,
) => {
  const { data, isLoading, isError } =
    useQuery<UserProfileOfferInterface | null>({
      queryKey: ["user-profile-offer", userProfileSlug || "", page],
      queryFn: () =>
        userProfileSlug
          ? getUserPublishedOffersBySlug(userProfileSlug, page - 1)
          : null,
      refetchOnWindowFocus: false,
      enabled: !!userProfileSlug,
      retry: 3,
    });

  return {
    userProfileOffer: data,
    isLoading,
    isError,
  };
};
