import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";

function MarketingConsent() {
  const { control } = useFormContext();

  return (
    <>
      <Typography
        variant="h5"
        component="h4"
        sx={{ fontWeight: 700, lineHeight: 1.4, mb: 3 }}
      >
        Marketing
        <Typography component="span" sx={{ ml: 1 }}>
          (opcjonalnie)
        </Typography>
      </Typography>
      <FormControlLabel
        sx={{ pl: "11px" }}
        control={
          <Controller
            control={control}
            name="agreeMarketing"
            render={({ field: { ref, value, onChange } }) => (
              <Checkbox inputRef={ref} checked={value} onChange={onChange} />
            )}
          />
        }
        label={
          <Typography fontWeight={600}>
            Zgadzam się na{" "}
            <Typography
              component="span"
              sx={{ textDecoration: "underline" }}
              fontWeight={600}
            >
              <Link to="/page/zgoda-marketingowa" target="_blank">
                marketing
              </Link>
            </Typography>
          </Typography>
        }
      />
    </>
  );
}

export default MarketingConsent;
