import { useState } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import CategoryList from "../components/molecules/CategoryList";
import MobileNav from "../components/organisms/MobileNav";
import Offers from "../components/organisms/Offers";
import DefaultLayout from "../components/layout/DefaultLayout";
import SearchForm from "../components/molecules/SearchForm";
import Hero from "../components/molecules/Hero";
import ParentAssistantOffers from "../components/parentAssistantOffers/ParentAssistantOffers";
import HotOfferCarousel from "../components/onTimeOffer/HotOfferCarousel";

function Home() {
  const [visibleMobileCategories, setVisibleMobileCategories] = useState(false);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <DefaultLayout mobilePadding>
      <MobileNav
        visibleMobileCategories={visibleMobileCategories}
        setVisibleMobileCategories={setVisibleMobileCategories}
      />
      <SearchForm />
      <Hero />
      {isDesktop ? (
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 5.5 }}>
          <Box sx={{ width: "324px" }}>
            <CategoryList visibleMobileCategories={visibleMobileCategories} />
          </Box>
          <Box sx={{ width: "856px" }}>
            <Offers />
          </Box>
        </Box>
      ) : (
        <Box sx={{ mb: 3 }}>
          <Offers />
        </Box>
      )}
      <ParentAssistantOffers />
      <HotOfferCarousel />
    </DefaultLayout>
  );
}

export default Home;
