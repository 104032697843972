import React, { useEffect, useMemo, useState } from "react";
import { IUserProfile, UserContextType } from "../types/UserContextType";
import useUserData from "../hooks/useUserData";
import { getUserProfilDetails } from "../client/Requests";
import { useUserAddress } from "../hooks/useUserAddress";
import { useNewUserData } from "../hooks/fetchHooks/user/useNewUserData";

export const UserContext = React.createContext<UserContextType | null>(null);

function UserProvider({ children }: React.PropsWithChildren<{}>) {
  const { userData: userLocalStorage, updateUserData } = useUserData();
  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);
  const { newUserData } = useNewUserData();
  const { userCity, userProvince } = useUserAddress(newUserData);

  const getProfileData = async () => {
    if (userLocalStorage) {
      const profile = await getUserProfilDetails(userLocalStorage.userToken);
      if (profile) {
        if (profile.data.result.user.avatar !== "https://test-api.bubaa.pl/") {
          setUserProfile(profile.data.result);
        } else {
          const newDataProfile = {
            ...profile.data.result,
            user: {
              ...profile.data.result.user,
              avatar: `https://ui-avatars.com/api/?background=B9BCC3&color=FFF&size=20&font-size=0.6&name=${profile.data.result.user.username}`,
            },
          };
          setUserProfile(newDataProfile);
        }
      }
    }
  };

  useEffect(() => {
    if (userProfile) {
      updateUserData(userProfile);
    }
  }, [userProfile]);

  const updateUserProfileData = (newData: IUserProfile) => {
    setUserProfile(newData);
  };

  const updateUserAvatar = (avatar: Blob | MediaSource) => {
    const avatarObjectUrl = URL.createObjectURL(avatar);
    if (userProfile) {
      const newData = {
        ...userProfile,
        profile: {
          ...userProfile.profile,
          avatarPath: avatarObjectUrl,
        },
        user: {
          ...userProfile.user,
          avatar: avatarObjectUrl,
        },
      };
      setUserProfile(newData);
    }
  };

  useEffect(() => {
    getProfileData();
  }, [userLocalStorage]);

  const providerData = useMemo(
    () => ({
      userProfile,
      updateUserProfileData,
      updateUserAvatar,
      newUserData,
      userAddres: {
        userCity,
        userProvince,
      },
    }),
    [userProfile, newUserData, userCity, userProvince],
  );

  return (
    <UserContext.Provider value={providerData}>{children}</UserContext.Provider>
  );
}

export default UserProvider;
