import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { getAccountStatistics } from "../../client/Requests";
import useUserData from "../../hooks/useUserData";
import { UserStatsInterface } from "../../models/UserStatsInterface";
import { getFormattedPrice } from "../../utils/PriceUtils";

function Stats() {
  const { userData } = useUserData();
  const [stats, setStats] = useState<UserStatsInterface>();

  useEffect(() => {
    if (userData) {
      const fetchStatisticsData = async () => {
        const response = await getAccountStatistics(userData.userToken);
        setStats(response.data.result);
        console.log(stats);
      };
      fetchStatisticsData().then((response) => {
        console.log(response);
      });
    }
  }, [userData]);
  return (
    <div className="bg-white flex flex-col md:mb-8 mb-4 justify-center p-4">
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white ">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <Typography sx={{ fontWeight: 600 }}>Sprzedałem</Typography>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full border-collapse text-blueGray-700  ">
            <tbody>
              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  <Typography variant="caption" sx={{ fontWeight: 700 }}>
                    Zarobiłem na sprzedaży
                  </Typography>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                  <Typography variant="caption">
                    {getFormattedPrice(stats?.soldProductOffersRevenue ?? 0)}
                  </Typography>
                </td>
              </tr>
              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  <Typography variant="caption" sx={{ fontWeight: 700 }}>
                    Liczba przedmiotów
                  </Typography>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                  <Typography variant="caption">
                    {stats?.soldProductOffersCount}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white ">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <Typography sx={{ fontWeight: 600 }}>Kupiłem</Typography>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full border-collapse text-blueGray-700  ">
            <tbody>
              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    Wydałem na zakupy
                  </Typography>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                  <Typography variant="caption">
                    {getFormattedPrice(stats?.boughtProductOffersCost ?? 0)}
                  </Typography>
                </td>
              </tr>
              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    Liczba przedmiotów
                  </Typography>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                  <Typography variant="caption">
                    {stats?.boughtProductOffersCount}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white ">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <Typography sx={{ fontWeight: 600 }}>
                Jak długo jesteś z nami
              </Typography>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full border-collapse text-blueGray-700  ">
            <tbody>
              <tr>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                  <Typography variant="caption">
                    {stats?.accountDaysCount}{" "}
                    {stats?.accountDaysCount === 1 ? "dzień" : "dni"}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Stats;
