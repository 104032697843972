import { Dispatch, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Theme, useMediaQuery } from "@mui/material";
import MobileNavItem from "../atoms/MobileNavItem";
import useUserData from "../../hooks/useUserData";
import { UserCountersInterface } from "../../models/UserCountersInterface";
import { getUserCounters } from "../../client/Requests";
import EntryUserDialog from "../molecules/dialog/EntryUserDialog";
import EmptyUserProfileDialog from "../molecules/dialog/EmptyUserProfileDialog";
import OfferProvider from "../../contexts/offerProvider/OfferProvider";
import CategoriesMenuMobile from "../categoriesMenu/CategoriesMenuMobile";

interface MobileNavInterface {
  visibleMobileCategories: boolean;
  setVisibleMobileCategories: Dispatch<boolean>;
}

function MobileNav({
  visibleMobileCategories,
  setVisibleMobileCategories,
}: MobileNavInterface) {
  const { userData } = useUserData();
  const navigate = useNavigate();
  const [openEntryUserDialog, setOpenEntryUserDialog] =
    useState<boolean>(false);
  const [openEmptyUserProfileDialog, setOpenEmptyUserProfileDialog] =
    useState<boolean>(false);
  const [userCounters, setUserCounters] =
    useState<UserCountersInterface | null>(null);
  const [openCategories, setOpenCategories] = useState<boolean>(false);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const checkCounters = () => {
    if (!userData) {
      return;
    }
    getUserCounters(userData.userToken).then((response) => {
      setUserCounters(response.data);
    });
  };

  useEffect(() => {
    if (isDesktop) {
      return;
    }

    if (!userData) {
      setVisibleMobileCategories(visibleMobileCategories);
      return;
    }

    if (userCounters === null) {
      setInterval(() => {
        checkCounters();
      }, 10000);
    }
  }, [userData]);

  if (isDesktop) {
    return null;
  }

  return (
    <OfferProvider>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: 1,
          zIndex: 400,
          bgcolor: "white",
        }}
      >
        {openCategories && (
          <CategoriesMenuMobile onClose={() => setOpenCategories(false)} />
        )}
        <EntryUserDialog
          initialOpen={openEntryUserDialog}
          handleClose={() => {
            setOpenEntryUserDialog(false);
          }}
        />
        <EmptyUserProfileDialog
          initialOpen={openEmptyUserProfileDialog}
          handleClose={() => {
            setOpenEmptyUserProfileDialog(false);
          }}
        />
        <Stack
          direction="row"
          spacing={4}
          sx={{ width: 1, justifyContent: "center" }}
        >
          <MobileNavItem
            icon="home"
            text="Nowości"
            badgeCount={0}
            key={Math.random()}
            className="text-primary-main"
            onClick={() => {
              navigate("/");
            }}
          />
          <MobileNavItem
            icon="search"
            text="Kupuj"
            badgeCount={0}
            key={Math.random()}
            onClick={() => {
              setOpenCategories(true);
            }}
          />
          <MobileNavItem
            icon="add_circle_outline"
            text="Sprzedaj"
            key={Math.random()}
            onClick={() => {
              if (!userData) {
                navigate("/login");
                return;
              }
              if (!userData.isUserProfileComplete) {
                setOpenEmptyUserProfileDialog(true);
                return;
              }

              navigate("/offers/create");
            }}
          />

          <MobileNavItem
            icon="mail"
            text="Wiadomości"
            badgeCount={0}
            key={Math.random()}
            onClick={() => {
              if (!userData) {
                setOpenEntryUserDialog(true);
                return;
              }

              navigate("/messages");
            }}
          />
        </Stack>
      </Box>
    </OfferProvider>
  );
}

export default MobileNav;
