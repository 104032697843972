import { Typography as MUITypography } from "@mui/material";
import Typography from "./Typography";

interface OfferDetailsInformationInterface {
  title: string;
  description?: string;
  element?: JSX.Element | JSX.Element[];
  bottomBorder?: boolean;
}

function OfferDetailsInformation({
  title,
  description,
  element,
  bottomBorder,
}: OfferDetailsInformationInterface) {
  return (
    <div
      className={`${
        bottomBorder && "border-b border-gray-300"
      } w-full flex justify-between items-center py-8`}
    >
      <Typography
        type="menu"
        text={title.toUpperCase()}
        className="text-text-primary/50 pb-2"
      />
      {description && (
        <MUITypography sx={{ fontWeight: 500 }} variant="caption">
          {description}
        </MUITypography>
      )}
      {element && element}
    </div>
  );
}

export default OfferDetailsInformation;
