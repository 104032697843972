import { Box } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function SearchForm() {
  const [searchPhrase, setSearchPhrase] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const isOfferCatalog =
    location.pathname.includes("/najnowsze-oferty") ||
    location.pathname.includes("/kategoria/");
  const newPathname = isOfferCatalog ? location.pathname : "/najnowsze-oferty";

  const handleSearch = () => {
    if (searchPhrase.length <= 0) {
      return;
    }

    searchParams.set("szukaj", searchPhrase);
    navigate(`${newPathname}?${searchParams.toString()}`);
  };

  return (
    <Box sx={{ w: 1, my: 4 }}>
      <form id="search" onSubmit={handleSearch}>
        <label htmlFor="search">
          <div className="relative">
            <input
              className="w-full h-12 rounded-lg pl-3 pr-12 bg-white text-[16px] font-normal text-text-primary/50 tracking-wider leading-[24px] outline-none focus:outline-primary-main"
              placeholder="Czego szukasz?"
              name="szukaj"
              onChange={(e) => setSearchPhrase(e.target.value)}
            />
            <button type="button" className="absolute top-1 right-3 h-full">
              <span className="material-icons-outlined">search</span>
            </button>
          </div>
        </label>
      </form>
    </Box>
  );
}

export default SearchForm;
