import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Stack, Typography, Link } from "@mui/material";
import { getProductOfferAddressSummary } from "../../client/Requests";
import useUserData from "../../hooks/useUserData";
import { AddressSummaryInterface } from "../../models/AddressSummaryInterface";
import SectionLayout from "../../components/layout/SectionLayout";
import SectionLayoutContent from "../../components/layout/SectionLayoutContent";
import { getFormattedPrice } from "../../utils/PriceUtils";

function PaymentSummary() {
  const { userData } = useUserData();
  const { id } = useParams();
  const offerId = id;
  const navigate = useNavigate();
  const [paymentSummary, setPaymentSummary] =
    useState<AddressSummaryInterface | null>(null);

  useEffect(() => {
    if (typeof offerId !== "string") {
      navigate("/");
      return;
    }

    if (userData) {
      getProductOfferAddressSummary(
        userData.userToken,
        parseInt(offerId, 10),
      ).then((response) => {
        setPaymentSummary(response.data);
      });
    }
  }, [userData, offerId]);

  return (
    <SectionLayout
      defaultLayoutProps={{ width: 500 }}
      sectionTitle="Dane do przelewu"
      breadcrumbs={[
        { text: "Strona główna", link: "/" },
        { text: "Oferta", link: `/oferta/${paymentSummary?.slug}` },
        { text: "Dane do przelewu" },
      ]}
    >
      <SectionLayoutContent styles={{ p: 3 }}>
        <Box>
          {paymentSummary && (
            <Stack spacing={1}>
              <Typography fontWeight={500}>
                {paymentSummary.ownerFirstName}
                {` `}
                {paymentSummary.ownerLastName}
              </Typography>
              <Typography fontWeight={500}>{paymentSummary.address}</Typography>
              <Typography fontWeight={500}>
                Numer konta: {paymentSummary.bankAccount}
              </Typography>

              <Typography fontWeight={500}>
                Do zapłaty: {getFormattedPrice(paymentSummary.paymentValue)}
              </Typography>
              <Typography fontWeight={500}>
                Tytuł przelewu: {paymentSummary.paymentTitle}
              </Typography>
              {paymentSummary?.trackUrl && (
                <Link href={paymentSummary?.trackUrl} target="_blank">
                  Śledź paczkę
                </Link>
              )}
            </Stack>
          )}
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: 1, mt: 3 }}
            onClick={() => navigate(-1)}
          >
            Wróć
          </Button>
        </Box>
      </SectionLayoutContent>
    </SectionLayout>
  );
}

export default PaymentSummary;
