import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

interface IInpostMethods {
  disabled: boolean;
  getChoicePrice: any;
}

function InpostMethodsV2({ disabled, getChoicePrice }: IInpostMethods) {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  const StyledFormControlLabelField2 = styled(FormControlLabel)({
    "& .MuiFormControlLabel-label": {
      fontWeight: 600,
      fontSize: "1rem",
      maxWidth: "230px",
      width: "100%",
    },
    "&": {
      paddingTop: "20px",
      paddingBottom: "20px",
      borderTopWidth: "0px",
    },
  });

  return (
    <Controller
      control={control}
      name="shippingInpost"
      render={({ field }: any) => (
        <FormControl
          error={errors.shipping !== undefined}
          variant="standard"
          color="secondary"
          disabled={disabled}
          sx={{
            ".MuiFormControlLabel-labelPlacementStart": {
              justifyContent: "flex-end",
            },
          }}
        >
          <RadioGroup {...field}>
            <StyledFormControlLabelField2
              value="small"
              control={<Radio sx={{ ml: 2 }} color="secondary" />}
              labelPlacement="start"
              label={
                <Box sx={{ ml: 2 }}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Mała paczka (Gabaryt A)
                  </Typography>
                  <Typography sx={{ fontSize: "0.75rem" }}>
                    max: 8 x 38 x 64 cm; do 25 kg
                  </Typography>
                  <Chip
                    label={getChoicePrice("small")}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                </Box>
              }
              sx={{ ml: 0 }}
            />
            <StyledFormControlLabelField2
              value="medium"
              control={<Radio sx={{ ml: 2 }} color="secondary" />}
              labelPlacement="start"
              label={
                <Box sx={{ ml: 2 }}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Średnia paczka (Gabaryt B)
                  </Typography>
                  <Typography sx={{ fontSize: "0.75rem" }}>
                    max: 19 x 38 x 64 cm; do 25 kg
                  </Typography>
                  <Chip
                    label={getChoicePrice("medium")}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                </Box>
              }
              sx={{ ml: 0 }}
            />
            <StyledFormControlLabelField2
              value="large"
              control={<Radio sx={{ ml: 2 }} color="secondary" />}
              labelPlacement="start"
              label={
                <Box sx={{ ml: 2 }}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Duża paczka (Gabaryt C)
                  </Typography>
                  <Typography sx={{ fontSize: "0.75rem" }}>
                    max: 41 x 38 x 64 cm; do 25 kg{" "}
                  </Typography>
                  <Chip
                    label={getChoicePrice("large")}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                </Box>
              }
              sx={{ ml: 0 }}
            />
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}

export default InpostMethodsV2;
