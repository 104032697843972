import { SvgIconComponent } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { UserData } from "../../Redux/messagesStore/userSlice";

const iconStyles = (theme: Theme) => ({
  color: theme.palette.secondary.main,
});

const textStyles = (theme: Theme) => ({
  color: alpha(theme.palette.secondary.main, 0.64),
});

type Props = {
  Icon?: SvgIconComponent;
  name?: string;
  badgeCount?: number;
  onClick: () => void;
  userData?: UserData | null;
  avatarPath?: string;
};

function Item({
  Icon,
  name,
  badgeCount,
  onClick,
  userData,
  avatarPath,
}: Props) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const userName = userData?.username || "Konto";

  return (
    <Button
      aria-label={name || userName}
      sx={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        textTransform: "none",
        minWidth: { xs: 50, sm: 100 },
      }}
      onClick={onClick}
    >
      {badgeCount && Icon ? (
        <Badge badgeContent={badgeCount} color="error" sx={{ margin: 1.5 }}>
          <Icon sx={iconStyles} />
        </Badge>
      ) : null}

      {!badgeCount && Icon ? (
        <Box sx={{ m: 1.5 }}>
          <Icon sx={iconStyles} />
        </Box>
      ) : null}

      {!badgeCount && !Icon && avatarPath ? (
        <Box sx={{ m: 0.7 }}>
          <Avatar
            sx={{ width: 38, height: 38 }}
            alt="user avatar"
            src={avatarPath}
          />
        </Box>
      ) : null}

      {!badgeCount && !Icon && !avatarPath ? (
        <Box sx={{ m: 1.5 }}>
          <PersonOutlinedIcon sx={iconStyles} />
        </Box>
      ) : null}

      {isDesktop && (
        <Typography sx={textStyles} variant="caption">
          {name || userName}
        </Typography>
      )}
    </Button>
  );
}

export default Item;
