import { Chip, Stack } from "@mui/material";
import { useContext } from "react";
import { ProductTemplateAttributeValueInterface } from "../../models/ProductTemplateAttributeInterface";
import { IOfferContext } from "../../contexts/offerProvider/types";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";

type Props = {
  filterAttributes: ProductTemplateAttributeValueInterface[];
};

function AttributesChips({ filterAttributes }: Props) {
  const { handleChangeAttributes } = useContext(OfferContext) as IOfferContext;

  const handleAttributeDelete = (
    attribute: ProductTemplateAttributeValueInterface,
  ) => {
    const familtyAttributes = filterAttributes.filter(
      (filterAttribute) =>
        filterAttribute.attributeFamilyId === attribute.attributeFamilyId,
    );
    const newFamilyAttributes = familtyAttributes.filter(
      (famillyAttribute) => famillyAttribute.id !== attribute.id,
    );

    handleChangeAttributes(
      newFamilyAttributes?.length ? newFamilyAttributes : [],
      attribute.attributeFamilyId,
    );
  };

  return (
    <>
      {filterAttributes.map((attribute) => (
        <Chip
          sx={{ mb: 1, ml: 1 }}
          key={attribute.id}
          label={attribute.name}
          variant="outlined"
          onDelete={() => handleAttributeDelete(attribute)}
        />
      ))}
    </>
  );
}

export default AttributesChips;
