import { useMediaQuery } from "react-responsive";
import { useState, useEffect, useContext } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import { Box, Stack } from "@mui/material";
import useUserData from "../../hooks/useUserData";
import { sendGetForbiddenWordsList } from "../../client/Requests";
import MessagePanelInputSend from "../molecules/messagePanel/message/MessageInputSend";
import MessagePanelItem from "../molecules/messagePanel/MessagesPanel/MessagePanelItem";
import Messages from "../molecules/messagePanel/message/Messages";
import UsersMessagesList from "../molecules/messagePanel/messagePreview/UsersMessagesList";
import GoBack from "../molecules/messagePanel/GoBack";
import { MessagesContext } from "../../contexts/MessagesProvider/MessagesProvider";
import { IMessageContext } from "../../contexts/MessagesProvider/types";

function MessagePanel() {
  const [forbiddenWordsList, setForbiddenWordsList] = useState(false);
  const [idMessageThread, setIdMessageThread] = useState<null | number>(null);
  const [messageListSection, setMessageListSection] = useState(true);
  const { userData } = useUserData();
  const isDesktop = useMediaQuery({ query: "(min-width: 940px)" });
  const { currentMessageThreadId } = useContext(
    MessagesContext,
  ) as IMessageContext;

  const getMessagePanelData = async () => {
    if (userData) {
      const getForbiddenWordsList = await sendGetForbiddenWordsList(
        userData.userToken,
      );
      setForbiddenWordsList(getForbiddenWordsList.data);
    }
  };

  useEffect(() => {
    getMessagePanelData();
  }, [userData]);

  useEffect(() => {
    setIdMessageThread(currentMessageThreadId);
  }, [currentMessageThreadId]);

  const handleOpenMessages = () => {
    setMessageListSection(false);
  };

  const handleOpenMessagesList = () => {
    setMessageListSection(true);
  };

  if (isDesktop) {
    return (
      <>
        <Stack sx={{ width: 1, maxWidth: 369, overflow: "auto" }}>
          <UsersMessagesList />
        </Stack>
        <div className="flex-1 flex flex-col border-l fixed md:static bg-white	z-10 top-0 right-0 h-screen w-screen md:h-auto md:w-auto">
          <Box sx={{ borderBottomWidth: "1px" }}>
            <MessagePanelItem />
          </Box>
          <Messages />
          <MessagePanelInputSend
            forbiddenWordsList={forbiddenWordsList}
            idMessageThread={idMessageThread}
          />
        </div>
      </>
    );
  }

  return (
    <Box>
      {messageListSection ? (
        <Stack sx={{ width: 1, overflow: "auto" }}>
          <UsersMessagesList handleOpenMessages={handleOpenMessages} />
        </Stack>
      ) : (
        <div className="flex-1 flex flex-col border-l fixed md:static bg-white	z-10 top-0 right-0 h-screen w-screen md:h-auto md:w-auto">
          <Box sx={{ borderBottomWidth: "1px" }}>
            <GoBack handlerGoBack={handleOpenMessagesList} />
            <ScrollToBottom
              followButtonClassName="followButtonOff"
              className="flex-1 overflow-auto max-h-44"
            >
              <MessagePanelItem />
            </ScrollToBottom>
          </Box>
          <ScrollToBottom
            followButtonClassName="followButtonOff"
            className="flex-1 overflow-auto"
          >
            <Messages />
          </ScrollToBottom>
          <MessagePanelInputSend
            forbiddenWordsList={forbiddenWordsList}
            idMessageThread={idMessageThread}
          />
        </div>
      )}
    </Box>
  );
}

export default MessagePanel;
