import { Avatar } from "@mui/material";
import Typography from "../../../atoms/Typography";

function UserMessagePreview({
  messageThread,
  handlerMessageThread,
}: {
  messageThread: any;
  handlerMessageThread: any;
}) {
  return (
    <button
      type="button"
      onClick={() => handlerMessageThread(messageThread.id)}
      className="p-4 flex flex-row hover:bg-light-message-hover cursor-pointer w-full text-left flex-initial"
    >
      <Avatar alt="avatar" src={messageThread.avatarPath} />
      <div className="ml-2">
        <Typography
          type="body2"
          text={messageThread.sendDate.substring(0, 10)}
          className="text-action-placeholderText mb-1"
        />
        <Typography
          type="profilName"
          text={messageThread.title}
          className="text-primary"
        />
        <Typography
          type="captions"
          text={messageThread.content}
          className="text-secondary oneLineText"
        />
      </div>
    </button>
  );
}

export default UserMessagePreview;
