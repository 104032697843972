import { useContext, useEffect, useState } from "react";
import BreadCrumbs from "../molecules/BreadCrumbs";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../contexts/offerProvider/types";
import { createBreadcrumbs, getCategoryFamily } from "./utils";
import { BreadCrumbStepInterface } from "../../models/BreadCrumbStepInterface";

function CatalogBreadCrumbs() {
  const { expandedCategories, category, handleChangeExapandedCategory } =
    useContext(OfferContext) as IOfferContext;
  const [categoryBredcrumbs, setCategoryBreadCrumbs] = useState<
    BreadCrumbStepInterface[]
  >([]);

  useEffect(() => {
    const family = getCategoryFamily(
      expandedCategories,
      category,
      category ? [category] : [],
    );

    setCategoryBreadCrumbs(createBreadcrumbs(family?.reverse() ?? null));
  }, [category]);

  return (
    <BreadCrumbs
      steps={categoryBredcrumbs}
      onCategoryChange={handleChangeExapandedCategory}
    />
  );
}

export default CatalogBreadCrumbs;
