import { useQuery } from "@tanstack/react-query";
import { getUserProfileBySlug } from "../../client/Requests";
import { UserProfileInterface } from "../../models/UserProfileInterface";

export const useUserProfileBySlug = (userProfileSlug?: string) => {
  const { data, isLoading, isError } = useQuery<UserProfileInterface | null>({
    queryKey: ["user-profile", userProfileSlug || ""],
    queryFn: () =>
      userProfileSlug ? getUserProfileBySlug(userProfileSlug) : null,
    refetchOnWindowFocus: false,
    enabled: !!userProfileSlug,
    retry: 3,
  });

  return {
    userProfile: data,
    isLoading,
    isError,
  };
};
