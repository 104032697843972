import { BasicOfferFiltersInterface } from "../models/OffersFiltersInterface";

export const getOfferBasicFilters = (
  filters: BasicOfferFiltersInterface | null,
): string => {
  if (filters === null) {
    return "";
  }

  const urlParams = new URLSearchParams();

  if (filters.currentPage > 1) {
    urlParams.append("strona", filters.currentPage.toString());
  }

  if (filters?.province) {
    urlParams.append("wojewodztwo", filters.province);
  }

  if (filters?.city) {
    urlParams.append("miasto", filters.city);
  }

  if (filters?.minPrice) {
    urlParams.append("cenaOd", filters.minPrice.toString());
  }

  if (filters?.maxPrice) {
    urlParams.append("cenaDo", filters.maxPrice.toString());
  }

  if (filters?.sort) {
    urlParams.append("sortuj", filters.sort);
  }

  return urlParams.toString();
};
