import { CircularProgress, Stack } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "../components/layout/DefaultLayout";
import Typography from "../components/atoms/Typography";
import useUserData from "../hooks/useUserData";
import useLastOrder from "../hooks/useLastOrder";
import { getOrderDetails } from "../client/Requests";
import CustomButton from "../components/atoms/Buttons/CustomButton";

function ThankYouPage() {
  const { lastOrder, clearLastOrder } = useLastOrder();
  const [isFinished, setIsFinished] = useState<Boolean>(false);
  const { userData } = useUserData();
  const navigate = useNavigate();

  let intervalCheck: any = null;

  const checkOrderStatus = () => {
    if (!userData || !lastOrder) {
      return;
    }

    getOrderDetails(userData.userToken, lastOrder.id).then((response) => {
      if (response.data.status === "finished") {
        setIsFinished(true);
        clearInterval(intervalCheck);
        clearLastOrder();
      }
    });
  };

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (!lastOrder) {
      setIsFinished(true);
    }

    if (userData && !isFinished) {
      intervalCheck = setInterval(() => {
        checkOrderStatus();
      }, 1000);
    }
  }, [userData]);

  return (
    <DefaultLayout>
      <div className="bg-white flex flex-col justify-center items-center py-10">
        <Typography type="h1" text="Podsumowanie zakupu" />
        {!isFinished ? (
          <div className="my-10">
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
              className="mb-10"
            >
              <CircularProgress color="primary" size="72px" />
            </Stack>
            <Typography type="h3" text="Przetwarzamy Twoje zamówienie..." />
          </div>
        ) : (
          <div className="my-10 flex flex-col justify-center items-center">
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
              className="mb-10"
            >
              <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 144 }} />
            </Stack>
            <Typography
              type="h3"
              text="Dziękujemy za zakupy i opłacenie prowizji."
            />
            <Typography
              type="h3"
              text="Dzięki temu możemy rozwijać naszą aplikację!"
            />
            <br />
            <br />
            <Typography
              type="body1"
              text="Wszystkie dane do rozliczenia ze sprzedającym(i) otrzymasz na podany adres e-mail."
            />
            <Typography
              type="body1"
              text="Są one również dostępne w aplikacji w sekcji Konto, w zakładce Transakcje."
            />
            <CustomButton
              text="Wróć"
              className="mt-10"
              onClick={() => {
                navigate("/najnowsze-oferty");
              }}
            />
          </div>
        )}
      </div>
    </DefaultLayout>
  );
}

export default ThankYouPage;
