import { useContext, useState } from "react";
import { List, ListItemButton, ListItemText } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ProductCategoryInterface } from "../../models/ProductCategoryInterface";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../contexts/offerProvider/types";
import Navigation from "./Navigation";

type Props = {
  parentCategory: ProductCategoryInterface;
  onClose: () => void;
  onSelectParent: () => void;
};

function CategoryList({ parentCategory, onClose, onSelectParent }: Props) {
  const [selectedCategory, setSelectedCategory] = useState(parentCategory);
  const { categories, handleChangeCategory } = useContext(
    OfferContext,
  ) as IOfferContext;

  const childrens = categories.filter(
    (c) => c.parentId === selectedCategory.id,
  );

  const categoryHaveChildrens = (category: ProductCategoryInterface) =>
    categories.some((c) => c.parentId === category.id);

  const onCategoryChange = (newSelectedCategory: ProductCategoryInterface) => {
    const selectedCategoryHaveChildrens =
      categoryHaveChildrens(newSelectedCategory);

    if (selectedCategoryHaveChildrens) {
      setSelectedCategory(newSelectedCategory);
    } else {
      handleChangeCategory(newSelectedCategory);
      onClose();
    }
  };

  const onCategoryShow = (newSelectedCategory: ProductCategoryInterface) => {
    handleChangeCategory(newSelectedCategory);
    onClose();
  };

  const handleBackToCategory = () => {
    if (selectedCategory.parentId === 1) {
      onSelectParent();
    }

    setSelectedCategory(parentCategory);
  };

  return (
    <>
      <Navigation
        parentCategory={selectedCategory}
        onReturnToCategory={handleBackToCategory}
        onClose={() => onClose()}
      />
      <List component="div" disablePadding>
        <ListItemButton
          sx={{ py: 1.5 }}
          onClick={() => onCategoryShow(selectedCategory)}
          divider
        >
          <ListItemText
            primary={`Zobacz wszystko z ${selectedCategory.name}`}
          />
        </ListItemButton>
        {childrens.map((childCategory) => (
          <ListItemButton
            sx={{ py: 1.5 }}
            onClick={() => onCategoryChange(childCategory)}
            key={childCategory.id}
            divider
          >
            <ListItemText primary={childCategory.name} />
            {categoryHaveChildrens(childCategory) && <ArrowForwardIosIcon />}
          </ListItemButton>
        ))}
      </List>
    </>
  );
}

export default CategoryList;
