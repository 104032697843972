import { Container } from "@mui/material";
import SearchForm from "../../components/molecules/SearchForm";
import OfferProvider from "../../contexts/offerProvider/OfferProvider";
import OfferCatalogList from "../../components/offerCatalog/OfferCatalogList";

function OffersCatalog() {
  return (
    <OfferProvider>
      <Container maxWidth="lg">
        <>
          <SearchForm />
          <OfferCatalogList />
        </>
      </Container>
    </OfferProvider>
  );
}

export default OffersCatalog;
