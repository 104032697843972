import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";
import Typography from "../components/atoms/Typography";
import {
  inputRequired,
  inputUsernameMinLength,
  inputUsernameRegex,
} from "../utils/inputValidate";
import InputMUI from "../components/atoms/Inputs/InputMUI";
import { sendRegisterSocialUserRequest } from "../client/Requests";
import SectionLayoutContent from "../components/layout/SectionLayoutContent";
import SectionLayout from "../components/layout/SectionLayout";
import useUserData from "../hooks/useUserData";

function RegisterSocial() {
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { email } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      agreeRegulations: false,
      agreeMarketing: false,
    },
  });
  const navigate = useNavigate();
  const { getUserData, setUserData } = useUserData();

  const onSubmit = async (data: any) => {
    await setError(null);
    await setMessage(null);
    await sendRegisterSocialUserRequest(JSON.stringify({ ...data, email }))
      .then((response) => {
        if (response.status === 201) {
          const loginData = response.data;
          setUserData({
            userId: loginData.userId,
            username: loginData.username,
            userToken: loginData.userToken,
            isUserProfileComplete: false,
          });
          navigate("/");
          navigate(0);
        } else {
          setError(
            "Nie udało się zarejestrować użytkownika dla podanych danych",
          );
        }
      })
      .catch((errorResponse) => {
        const { response } = errorResponse;
        if (response.status === 400 && response.data.type === "InvalidData") {
          setError(response.data.message);
        } else {
          setError(
            "Nie udało się zarejestrować użytkownika dla podanych danych",
          );
        }
      });
  };

  return (
    <SectionLayout
      defaultLayoutProps={{ width: 500 }}
      sectionTitle="Rejestracja"
    >
      <SectionLayoutContent styles={{ p: 3 }}>
        <form
          className="space-y-6 ng-untouched ng-pristine ng-valid"
          onSubmit={handleSubmit(onSubmit)}
        >
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          {message && (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{message}</span>
            </div>
          )}
          <div>
            <InputMUI
              control={control}
              name="username"
              placeholder="Nazwa użytkownika"
              rules={{
                required: inputRequired,
                minLength: inputUsernameMinLength,
                pattern: inputUsernameRegex,
              }}
            />
          </div>
          <div className="bg-white">
            <FormControlLabel
              control={
                <>
                  <Controller
                    name="agreeRegulations"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />

                  <Typography type="text" text="Akceptuję" className="mr-1" />
                  <Link
                    className="inline"
                    to="/page/regulamin-i-polityka-prywatnosci"
                  >
                    <Typography
                      type="text"
                      text="regulamin i politykę prywatności"
                      className="underline cursor-pointer text-primary-dark"
                    />
                  </Link>
                </>
              }
              label=""
            />
            {errors.agreeRegulations && (
              <p
                className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-1fgqtmb-MuiFormHelperText-root"
                id=":rj:-helper-text"
              >
                Akceptacja regulaminu i polityki prywatności jest wymagana
              </p>
            )}
          </div>
          <div className="bg-white">
            <FormControlLabel
              control={
                <>
                  <Controller
                    name="agreeMarketing"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />

                  <Typography
                    type="text"
                    text="Zgadzam się na"
                    className="mr-1"
                  />
                  <Link className="inline" to="/page/zgoda-marketingowa">
                    <Typography
                      type="text"
                      text="marketing"
                      className="underline cursor-pointer text-primary-dark"
                    />
                  </Link>
                </>
              }
              label=""
            />
          </div>
          <div>
            Masz już konto?{" "}
            <Link to="/login">
              <Typography
                type="text"
                text="Zaloguj się"
                className="underline cursor-pointer text-primary-dark"
              />
            </Link>
          </div>
          <div className="flex">
            <button
              type="submit"
              className="`items-end row-end space-x-[7px] min-w-[122px] h-[42px] rounded-lg bg-button-secondary transition ease-in-out duration-300 hover:bg-button-primary text-white font-semibold text-base py-2 px-4"
            >
              Zarejestruj
            </button>
          </div>
        </form>
      </SectionLayoutContent>
    </SectionLayout>
  );
}

export default RegisterSocial;
