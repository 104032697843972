import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import { ProductCategoryInterface } from "../../models/ProductCategoryInterface";
import { getCategories, getCatalogOffers } from "../../client/Requests";
import { ShortOffersInterface } from "../../models/ShortOffersInterface";
import Typography from "../../components/atoms/Typography";
import OfferPreview from "../../components/atoms/OfferPreview";
import { getFormattedPrice } from "../../utils/PriceUtils";
// import CategoryFilterList from "../../components/organisms/CategoryFilterList";

function Category() {
  const { id } = useParams();
  const [categories, setCategories] = useState<ProductCategoryInterface[]>([]);
  const [offers, setOffers] = useState<ShortOffersInterface[]>([]);
  const navigate = useNavigate();
  const categoryId = id;
  const [categoryToDisplay, setCategoryToDisplay] =
    useState<ProductCategoryInterface | null>(null);

  useEffect(() => {
    if (typeof categoryId !== "string") {
      navigate("/");
      return;
    }

    getCategories().then((data) => {
      setCategories(data);

      const categoryCandidate = data.find(
        (category) => category.id === parseInt(categoryId, 10),
      );
      setCategoryToDisplay(categoryCandidate ?? null);
    });

    getCatalogOffers("").then((data) => {
      setOffers(data.data);
    });
  }, [navigate, categoryId]);

  return (
    <DefaultLayout>
      <div className="my-8 flex flex-col space-y-2">
        <Typography
          type="h1"
          text={categoryToDisplay ? categoryToDisplay.name : ""}
        />
      </div>
      <div className="w-full md:flex justify-between h-auto">
        <div className="md:w-[387px]">
          <div className="w-full bg-white p-6 md:rounded-md md:sticky md:top-3">
            {/* <CategoryFilterList
              categories={categories}
              pathCategories={[]}
              categoryId={1}
              level={0}
              handleClickCategory={() => {
                console.log("test");
              }}
            /> */}
          </div>
        </div>
        <div className="md:w-[793px]">
          <div className="bg-white md:p-6 md:rounded-md">
            <Typography type="h2" text="Oferty" />
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mt-3 mb-5">
              {offers.map((offer) => (
                <OfferPreview
                  offerId={offer.id}
                  isPromoted={Boolean(offer.isPromoted)}
                  text={offer.name}
                  slug="slug"
                  price={getFormattedPrice(offer.price)}
                  imageUrl={offer.imagePath}
                  key={Math.random()}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Category;
