import React, { useEffect, useState } from "react";

interface IOffersPagination {
  currentPage: number;
  pagesCount: number;
  handleClickPage: any;
}

export const PAGE_SIZE = 20;

function OffersPagination({
  currentPage,
  pagesCount,
  handleClickPage,
}: IOffersPagination) {
  const [pages, setPages] = useState<number[]>([]);
  const pagesRange = 1;

  const generatePages = () => {
    const newPages = [];
    for (
      let i = currentPage - pagesRange;
      i <= currentPage + pagesRange;
      i += 1
    ) {
      newPages.push(i);
    }

    setPages(newPages);
  };

  useEffect(() => {
    generatePages();
  }, [currentPage]);

  return (
    <nav>
      <ul className="flex justify-center items-center">
        {currentPage - 1 > 1 && (
          <li>
            <a
              className="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
              href="/"
              aria-label="Previous"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleClickPage(currentPage - 1);
              }}
            >
              <span className="material-icons text-sm">
                keyboard_arrow_left
              </span>
            </a>
          </li>
        )}
        {pages.map(
          (page) =>
            page >= 1 &&
            page <= pagesCount && (
              <li key={Math.random()}>
                <a
                  className={
                    page === currentPage
                      ? "bg-primary-dark mx-1 flex h-9 w-9 items-center justify-center rounded-full p-0 text-sm text-white shadow-md transition duration-150 ease-in-out"
                      : "mx-1 flex h-9 w-9 items-center justify-center rounded-full p-0 text-sm text-secondary shadow-md transition duration-150 ease-in-out"
                  }
                  href="/"
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClickPage(page);
                  }}
                >
                  {page}
                </a>
              </li>
            ),
        )}
        {currentPage + 1 < pagesCount && (
          <li>
            <a
              className="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
              href="/"
              aria-label="Next"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleClickPage(currentPage + 1);
              }}
            >
              <span className="material-icons text-sm">
                keyboard_arrow_right
              </span>
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default OffersPagination;
