import { useQuery } from "@tanstack/react-query";
import { getParentAssistantOffers } from "../client/Requests";
import { ParentAssistantOffersInterface } from "../models/ParentAssistantOffersInterface";
import useUserData from "./useUserData";

export const useParentAssistantOffers = () => {
  const { userData } = useUserData();
  const userToken = userData?.userToken;

  const { data, isLoading } = useQuery<ParentAssistantOffersInterface[]>({
    queryKey: ["parent-assistant-short-offers"],
    queryFn: userToken
      ? () => getParentAssistantOffers(userToken).then((res) => res.data)
      : undefined,
    refetchOnWindowFocus: false,
    enabled: !!userToken,
    retry: 3,
  });

  return {
    parentAssistantOffers: data,
    isLoading,
  };
};
