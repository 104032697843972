import {
  Person,
  CheckCircleOutline,
  Favorite,
  QueryStats,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { CircularProgress, Paper } from "@mui/material";
import { useContext } from "react";
import { Stack } from "@mui/system";
import Typography from "../atoms/Typography";
import { UserContext } from "../../contexts/UserProvider";
import { UserContextType } from "../../types/UserContextType";
import UserProfileSection from "../userProfile/UserProfileSection";

function AccountInfo() {
  const location = useLocation();
  const isActiveProfile = location.pathname === "/account/profile";
  const isActiveTransactions = location.pathname === "/account/transactions";
  const isActiveFovourite = location.pathname === "/account/favourite";
  const isActiveStats = location.pathname === "/account/stats";

  const { userProfile } = useContext(UserContext) as UserContextType;

  if (userProfile) {
    return (
      <Paper
        elevation={0}
        sx={{ display: "flex", flexDirection: "column", width: 1 }}
      >
        <UserProfileSection userProfile={userProfile} />
        <Stack
          direction="row"
          spacing={{ xs: 2, sm: 3 }}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Link to="/account/profile">
            <div className="flex flex-col items-center w-[58px] space-y-1 cursor-pointer">
              <Person
                className={`${isActiveProfile && "text-light-primary-main"}`}
              />
              <Typography
                type="menuMobile"
                text="Profil"
                className={`${
                  isActiveProfile && "text-light-primary-main"
                } font-semibold`}
              />
            </div>
          </Link>

          <Link to="/account/transactions">
            <div className="flex flex-col items-center w-[58px] space-y-1 cursor-pointer">
              <CheckCircleOutline
                className={`${
                  isActiveTransactions && "text-light-primary-main"
                }`}
              />
              <Typography
                type="menuMobile"
                text="Transakcje"
                className={`${
                  isActiveTransactions && "text-light-primary-main"
                } font-semibold`}
              />
            </div>
          </Link>

          <Link to="/account/favourite">
            <div className="flex flex-col items-center w-[58px] space-y-1 cursor-pointer">
              <Favorite
                className={`${isActiveFovourite && "text-light-primary-main"}`}
              />
              <Typography
                type="menuMobile"
                text="Ulubione"
                className={`${
                  isActiveFovourite && "text-light-primary-main"
                } font-semibold`}
              />
            </div>
          </Link>

          <Link to="/account/stats">
            <div className="flex flex-col items-center w-[58px] space-y-1 cursor-pointer">
              <QueryStats
                className={`${isActiveStats && "text-light-primary-main"}`}
              />
              <Typography
                type="menuMobile"
                text="Statystyki"
                className={`${
                  isActiveStats && "text-light-primary-main"
                } font-semibold`}
              />
            </div>
          </Link>
        </Stack>
      </Paper>
    );
  }

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "176px",
        width: "100%",
        backgroundColor: "#FFFFFF",
      }}
    >
      <CircularProgress />
    </Stack>
  );
}

export default AccountInfo;
