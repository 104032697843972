import { useQuery } from "@tanstack/react-query";
import { NewUserDataInterface } from "../../../models/NewUserDataInterface";
import { getNewUserProfilDetails } from "../../../client/Requests";
import useUserData from "../../useUserData";

export const useNewUserData = () => {
  const { userData: userLocalStorage } = useUserData();
  const authToken = userLocalStorage?.userToken;

  const { data: newUserData } = useQuery<NewUserDataInterface | null>({
    queryKey: ["new-user-data-object"],
    queryFn: () => (authToken ? getNewUserProfilDetails(authToken) : null),
    enabled: !!authToken,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  return { newUserData };
};
