import {
  Avatar,
  Box,
  Button,
  Typography as MUITypography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
import Typography from "../atoms/Typography";
import { ShortOffersInterface } from "../../models/ShortOffersInterface";
import { isAvatar, isOffer, isShowMoreLink } from "../../types/typeGuards";
import OfferCard from "../offerCatalog/OfferCard";
import Carousel from "../carousel/Carousel";

interface IOffersCarousel {
  title: string;
  offers: ShortOffersInterface[];
  avatar?: string | null;
  showMoreLink?: string;
}

function OffersCarousel({
  title,
  offers,
  avatar,
  showMoreLink,
}: IOffersCarousel) {
  const carouselOffers = [
    ...(avatar ? [{ avatar }] : []),
    ...offers,
    ...(showMoreLink ? [{ showMoreLink }] : []),
  ];

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {carouselOffers.length && (
        <Box>
          <div className="flex justify-between">
            <Typography type="h3" text={title} className="text-text-primary" />
          </div>
          <Box sx={{ mt: 2 }}>
            <Carousel>
              {carouselOffers.map((offer) => {
                if (isAvatar(offer)) {
                  return (
                    <Box sx={{ height: 1 }} key={avatar}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 1,
                          height: { xs: 224, sm: 254 },
                        }}
                      >
                        <Avatar
                          sx={{
                            width: { xs: 160, sm: 170, md: 190 },
                            height: { xs: 160, sm: 170, md: 190 },
                          }}
                          alt={offer.avatar}
                          src={offer.avatar}
                        />
                      </Box>
                    </Box>
                  );
                }

                if (isOffer(offer)) {
                  return (
                    <Box sx={{ p: 1, minWidth: "208px" }} key={offer.id}>
                      <OfferCard offer={offer} />
                    </Box>
                  );
                }

                if (isShowMoreLink(offer)) {
                  return (
                    <Box sx={{ height: 1 }} key="show-more-link">
                      <Box
                        component={Link}
                        to={offer.showMoreLink}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          p: 1,
                          height: 326,
                        }}
                      >
                        <Button
                          sx={{
                            width: 64,
                            height: 64,
                            borderRadius: "50%",
                            mb: 2,
                          }}
                          color="inherit"
                          variant="outlined"
                        >
                          <KeyboardArrowRightIcon />
                        </Button>
                        <MUITypography sx={{ textAlign: "center" }}>
                          Pokaż więcej
                        </MUITypography>
                      </Box>
                    </Box>
                  );
                }
                return null;
              })}
            </Carousel>
          </Box>
          {/* <div className="flex items-center justify-center w-full space-x-4 my-5">
            <span className="material-icons-outlined text-text-primary/50">
              arrow_back_ios
            </span>
            {offers.map((offer) => (
              <OfferPreview
                offerId={offer.id}
                isPromoted={Boolean(offer.isPromoted)}
                text={offer.name}
                slug={offer.slug}
                price={getFormattedPrice(offer.price)}
                imageUrl={offer.imagePath}
                key={Math.random()}
              />
            ))}
            <span className="material-icons-outlined text-text-primary/50">
              arrow_forward_ios
            </span>
          </div>
          <div className="w-full flex items-center justify-center space-x-2">
            <span className="w-[10px] h-[10px] bg-primary-main rounded-full" />
            <span className="w-[10px] h-[10px] bg-primary-main/50 rounded-full" />
            <span className="w-[10px] h-[10px] bg-primary-main/50 rounded-full" />
            <span className="w-[10px] h-[10px] bg-primary-main/50 rounded-full" />
            <span className="w-[10px] h-[10px] bg-primary-main/50 rounded-full" />
            <span className="w-[10px] h-[10px] bg-primary-main/50 rounded-full" />
            <span className="w-[10px] h-[10px] bg-primary-main/50 rounded-full" />
          </div> */}
        </Box>
      )}
    </>
  );
}

export default OffersCarousel;
