/* eslint-disable import/order */
import { Controller, useFormContext } from "react-hook-form";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { inputRequired } from "../../../utils/inputValidate";
import { useEffect, useState } from "react";
import {
  City,
  Province,
  getCities,
  getProvinces,
} from "../../../client/Requests";
import useFiltersLocations from "../../../hooks/useFiltersLocations";

function LocalizationOffer() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [cityName, setCityName] = useState("");
  const [resetAutocomplete, setResetAutocomplete] = useState(0);
  const [openCityField, setOpenCityField] = useState(false);
  const { control, watch, setValue, resetField } = useFormContext();
  const provinceName = watch("province")?.name ?? "";
  const { provinces, cities } = useFiltersLocations(provinceName);

  useEffect(() => {
    if (cityName.length > 2) {
      setOpenCityField(true);
    } else {
      setOpenCityField(false);
    }
  }, [cityName]);

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={isMobile ? 0 : 3}
      sx={{
        p: { xs: 2, md: 3 },
        mb: { xs: 2, md: 0 },
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      {!isMobile && <Typography sx={{ flex: "1" }}>Lokalizacja</Typography>}
      <Stack sx={{ flex: "1" }} spacing={3}>
        <Controller
          control={control}
          name="province"
          rules={{ required: inputRequired }}
          render={({
            field: { value: fieldValue, onChange },
            fieldState: { invalid, error },
          }) => (
            <FormControl error={invalid} sx={{ width: 1 }}>
              <InputLabel variant="standard">Województwo</InputLabel>
              <Select
                variant="standard"
                onChange={(value) => {
                  const selectedProvince = provinces.find(
                    (province) => province.id === value.target.value,
                  );
                  onChange(selectedProvince);
                  setValue("city", null);
                }}
                sx={{ width: 1, textTransform: "capitalize" }}
                value={fieldValue?.id || 0}
                placeholder="Wybierz"
              >
                <MenuItem disabled value={0}>
                  Wybierz
                </MenuItem>
                {provinces.map((value) => (
                  <MenuItem
                    key={value.id}
                    value={value.id}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
              {typeof error?.message === "string" ? (
                <FormHelperText sx={{ ml: 0 }}>{error.message}</FormHelperText>
              ) : null}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="city"
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => (
            <Autocomplete
              sx={{ width: 1 }}
              disablePortal
              options={cities}
              key={resetAutocomplete}
              disabled={!cities.length}
              getOptionLabel={(options) => options.name}
              isOptionEqualToValue={(options) => options.name}
              onChange={(_, valueInput) => onChange(valueInput)}
              onClose={() => setOpenCityField(false)}
              value={value?.name ? value : null}
              noOptionsText="Nie znaleziono takiej lokalizacji"
              renderInput={(params) => (
                <TextField
                  error={invalid}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  onChange={(e) => setCityName(e.target.value)}
                  variant="standard"
                  label="Miasto"
                  placeholder="Wybierz"
                  helperText={error?.message}
                />
              )}
              open={openCityField}
            />
          )}
        />
      </Stack>
    </Stack>
  );
}

export default LocalizationOffer;
