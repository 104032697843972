/* eslint-disable import/order */
/* eslint-disable import/no-cycle */
import { useEffect, useState } from "react";
import { getCategories } from "../../../../client/Requests";
import {
  Box,
  Dialog,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CustomTextField } from "../../../atoms/Inputs/InputMUI";
import CategoryOfferDialog from "./CategoryOfferDialog";
import { ProductTemplateAttributeInterface } from "../../../../models/ProductTemplateAttributeInterface";
import OfferAttributes from "../../../offer/OfferAttributes";
import { ProductCategoryInterface } from "../../../../models/ProductCategoryInterface";

export interface CategoryOfferProps {
  productAttributes: ProductTemplateAttributeInterface[] | null;
  initialValues?: {
    category: ProductCategoryInterface;
    attributesDefaultValuesIds?: number[];
  };
}

function CategoryOffer({
  productAttributes,
  initialValues,
}: CategoryOfferProps) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [allCategories, setAllCategories] = useState<
    ProductCategoryInterface[] | null
  >(null);
  const [open, setOpen] = useState(false);
  const { control, clearErrors } = useFormContext();
  const [selectedCategory, setSelectedCategory] =
    useState<ProductCategoryInterface | null>(initialValues?.category ?? null);

  useEffect(() => {
    if (!allCategories) {
      getCategories().then((value) => {
        setAllCategories(value);
      });
    }
  }, []);

  const handleOpenCategoryDialog = () => {
    setOpen(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpen(false);
  };

  const handleSelectCategory = (category: ProductCategoryInterface) => {
    clearErrors("category");
    setSelectedCategory(category);
  };

  return (
    <Box
      sx={{
        p: { xs: 2, md: 3 },
        mb: { xs: 2, md: 0 },
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? 0 : 3}>
        <Typography
          sx={{
            flex: 1,
          }}
        >
          Kategoria
        </Typography>

        <Box sx={{ flex: 1 }}>
          <Controller
            control={control}
            name="category"
            render={({
              field: { value, ref },
              fieldState: { error, invalid },
            }) => (
              <CustomTextField
                variant="standard"
                onClick={handleOpenCategoryDialog}
                value={value?.name || ""}
                ref={ref}
                error={invalid}
                helperText={error?.message}
                placeholder="Wybierz kategorię"
                sx={{ mb: 3, width: 1 }}
              />
            )}
          />
        </Box>
        <Dialog open={open} maxWidth="xs" fullWidth>
          {!!allCategories && (
            <CategoryOfferDialog
              allCategories={allCategories}
              handleCloseCategoryDialog={handleCloseCategoryDialog}
              onSelectCategory={handleSelectCategory}
            />
          )}
        </Dialog>
      </Stack>
      {productAttributes?.length ? (
        <Divider orientation="horizontal" flexItem sx={{ mb: 2 }} />
      ) : null}
      {selectedCategory && (
        <OfferAttributes
          productAttributes={productAttributes}
          category={selectedCategory.name}
          defaultValuesIds={initialValues?.attributesDefaultValuesIds}
        />
      )}
    </Box>
  );
}

export default CategoryOffer;
