import { Box, Tabs } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { MainTab } from "./MainTabs";
import ActiveTransactions from "./ActiveTransactions";
import WithdrawnTransactions from "./WithdrawnTransactions";

function SellingTransactions() {
  const [tabsValue, setTabsValue] = useState("active");

  const handleChangeTab = (event: SyntheticEvent, newValue: string) => {
    setTabsValue(newValue);
  };

  return (
    <Box
      sx={{ backgroundColor: "#fff", flexGrow: 1, overflow: "hidden", p: 2 }}
    >
      <Tabs value={tabsValue} onChange={handleChangeTab} variant="fullWidth">
        <MainTab value="active" label="Aktywne" sx={{ flexGrow: 1 }} />
        <MainTab value="withdrawn" label="Wycofane" sx={{ flexGrow: 1 }} />
      </Tabs>
      {tabsValue === "active" && <ActiveTransactions />}
      {tabsValue === "withdrawn" && <WithdrawnTransactions />}
    </Box>
  );
}

export default SellingTransactions;
