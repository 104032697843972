import { Link as RouterLink } from "react-router-dom";
import { Box, IconButton, Link, Paper, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useEffect, useRef, useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EntryUserDialog from "../molecules/dialog/EntryUserDialog";
import useUserData from "../../hooks/useUserData";
import {
  addOfferToFavorites,
  removeOfferFromFavorites,
} from "../../client/Requests";

interface IOfferPreview {
  offerId: number;
  isPromoted: boolean;
  text: string;
  slug: string;
  price: string;
  imageUrl?: string;
  favorite?: boolean | null;
  reloadOffers?: any;
}

function OfferPreview({
  isPromoted = false,
  text = "test",
  slug = "slug",
  price = "20",
  imageUrl = "https://test-api.bubaa.pl/uploads/product-offers/616aceb6b0f87441587890.webp",
  offerId = 2,
  favorite = false,
  reloadOffers = null,
}: IOfferPreview) {
  const { userData } = useUserData();
  const theme = useTheme();
  const [productIsFavorite, setProductIsFavorite] = useState(favorite);
  const [openEntryUserDialog, setOpenEntryUserDialog] = useState(false);

  const favoriteClickHandler = async () => {
    if (userData === null) {
      return;
    }

    if (productIsFavorite) {
      await removeOfferFromFavorites(userData?.userToken, offerId);
    } else {
      await addOfferToFavorites(userData.userToken, offerId);
    }
    await setProductIsFavorite(!productIsFavorite);

    if (reloadOffers) {
      await reloadOffers();
    }
  };

  return (
    <>
      <EntryUserDialog
        initialOpen={openEntryUserDialog}
        handleClose={() => {
          setOpenEntryUserDialog(false);
        }}
      />
      <Link component={RouterLink} to={`/oferta/${slug}`}>
        <Paper elevation={3} sx={{ position: "relative" }}>
          <Stack
            sx={{ bgcolor: "white", overflow: "hidden", borderRadius: "4px" }}
            direction="column"
          >
            <Box
              sx={{
                height: { xs: "102px", sm: "134px" },
                width: "100%",
                position: "relative",
              }}
            >
              {isPromoted && (
                <Box
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    right: "0px",
                    height: "22px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ color: "white", lineHeight: 2, fontWeight: 600 }}
                  >
                    Oferta promowana
                  </Typography>
                </Box>
              )}

              <Box
                component="img"
                src={imageUrl}
                alt="Bubaa.pl"
                sx={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
            <Box sx={{ p: 1.5, lineHeight: "1rem" }}>
              <Typography
                variant="caption"
                sx={{
                  lineHeight: "1rem",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "hidden",
                  whiteSpace: "normal",
                  height: "32px",
                }}
              >
                {text}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.1rem", fontWeight: 700, mt: 3 }}
              >
                {price}
              </Typography>
            </Box>
          </Stack>
          {favorite !== null && (
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!userData) {
                  setOpenEntryUserDialog(true);
                  return;
                }
                favoriteClickHandler();
              }}
              aria-label="add to favourite"
              sx={{ position: "absolute", bottom: "12px", right: "12px" }}
            >
              {productIsFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
          )}
        </Paper>
      </Link>
    </>
  );
}

export default OfferPreview;
