/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Carousel } from "flowbite-react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  useMediaQuery,
  Typography as MUITypography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import MuiCarousel from "react-material-ui-carousel";
import { Helmet } from "react-helmet";
import DefaultLayout from "../../components/layout/DefaultLayout";
import Typography from "../../components/atoms/Typography";
import OfferDetailsInformation from "../../components/atoms/OfferDetailsInformation";
import AtomAlert from "../../components/atoms/Alert";
import {
  getProductOfferBySlug,
  sendAskForProductOffer,
  sendGetForbiddenWordsList,
  sendPriceProposal,
} from "../../client/Requests";
import { getFormattedPrice, swapToPennies } from "../../utils/PriceUtils";
import useUserData from "../../hooks/useUserData";
import { ProductOfferInterface } from "../../models/ProductOfferInterface";
import {
  forbiddenWords,
  inputRequired,
  minPrice,
  patternPrice,
} from "../../utils/inputValidate";
import AskForOfferDialog from "../../components/molecules/dialog/AskForOfferDialog";
import BackButton from "../../components/molecules/BackButton";
import BreadCrumbs from "../../components/molecules/BreadCrumbs";
import OfferSummary from "../../components/offerPreview/OfferSummary";
import OfferDetailsListElements from "../../components/offerPreview/OfferDetailsListElements";
import ImageGallery from "../../components/gallery/ImageGallery";

function Offer() {
  const { userData } = useUserData();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [offer, setOffer] = useState<ProductOfferInterface>();
  const offerSlug = slug;
  const [openAskForPricePropose, setOpenAskForPricePropose] = useState(false);
  const [openAskForOfferModal, setOpenAskForOfferModal] = useState(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [forbiddenWordsRegex, setForbiddenWordsRegex] = useState<any | null>(
    null,
  );
  const [askErrorMessage, setAskErrorMessage] = useState<string | null>(null);
  const [groupedAttributes, setGroupedAttributes] = useState<any | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const offerId = offer?.id || 0;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("md"));
  const [viewImageIndex, setViewImageIndex] = useState<number | null>(null);

  const loadForbiddenWords = async () => {
    if (userData) {
      const getForbiddenWordsList = await sendGetForbiddenWordsList(
        userData.userToken,
      );
      setForbiddenWordsRegex(forbiddenWords(getForbiddenWordsList.data));
    }
  };

  useEffect(() => {
    if (typeof offerSlug !== "string") {
      navigate("/");
      return;
    }

    const fetchOffer = async () =>
      getProductOfferBySlug(offerSlug, userData?.userToken);

    fetchOffer()
      .then((response) => {
        setOffer(response.data);
        setIsFavorite(response.data.isFavorite ?? true);
        const grpAttrs: { [key: string]: string[] } =
          response.data.attributes.reduce((acc: any, current: any) => {
            const { attribute, value } = current;
            if (!acc[attribute]) {
              acc[attribute] = [];
            }
            acc[attribute].push(value);
            return acc;
          }, {});
        setGroupedAttributes(grpAttrs);
      })
      .catch(() => {
        navigate("/not-found");
      });

    if (userData) {
      loadForbiddenWords();
    }
  }, [navigate, offerId, userData]);

  const {
    handleSubmit,
    control,
    setError,
    watch,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      price: "",
    },
    mode: "onSubmit",
  });

  const handleSendAskMessage = (message: string) => {
    if (!userData) {
      return;
    }

    if (forbiddenWordsRegex) {
      const isValid = forbiddenWordsRegex.value.test(message);

      if (!isValid) {
        setAskErrorMessage(forbiddenWordsRegex.message);
        return;
      }
    }

    sendAskForProductOffer(
      offerId,
      {
        content: message,
      },
      userData?.userToken,
    );
    setOpenAskForOfferModal(false);
  };

  const handleCloseDialog = () => {
    resetField("price");
    setOpenAskForPricePropose(false);
  };

  const pricePropose = async (data: any) => {
    if (userData?.userToken && offerId) {
      try {
        const { price } = data;
        await sendPriceProposal(
          offerId,
          { price: swapToPennies(price.replace(/,/g, ".")) },
          userData.userToken,
        );
        handleCloseDialog();
      } catch (error: any) {
        setError("price", {
          type: "api",
          message: error.response.data
            ? error.response.data
            : "Błąd podczas wysyłania propozycji Ceny",
        });
      }
    }
  };
  const price = watch("price");

  useEffect(() => {
    if (price.includes(",")) {
      setValue("price", price.replace(/,/g, "."));
    }
  }, [price]);

  const handleToggleFavoriteState = () => {
    setIsFavorite((prev) => !prev);
  };

  const handleSetSuccessMessage = (newSuccessMessage: string) => {
    setSuccessMessage(newSuccessMessage);
  };

  const handleAskForOffer = () => {
    setOpenAskForOfferModal(true);
  };

  const handleAskForPricePropose = () => {
    setOpenAskForOfferModal(true);
  };

  const handleImageClick = (index: number) => {
    setViewImageIndex(index);
  };

  return (
    <DefaultLayout>
      <Helmet>
        <link href={`/oferta/${offerSlug}`} rel="canonical" />
      </Helmet>
      <ImageGallery
        images={offer?.images}
        selectedImageIndex={viewImageIndex}
        onClose={() => setViewImageIndex(null)}
      />
      <AskForOfferDialog
        initialOpen={openAskForOfferModal}
        handleSend={handleSendAskMessage}
        handleClose={() => {
          setOpenAskForOfferModal(false);
        }}
        errorMessage={askErrorMessage}
      />
      <Box sx={{ ml: 2 }}>
        <BreadCrumbs
          steps={[
            {
              url: `/oferta/${offerSlug}`,
              name: offer ? offer.name : "",
            },
          ]}
        />
      </Box>
      <BackButton />
      <Box
        sx={{
          width: 1,
          display: { md: "flex" },
          justifyContent: "space-between",
          height: "auto",
        }}
      >
        <div className="md:w-[793px]">
          <div className="bg-white md:p-6 md:rounded-md">
            <MuiCarousel
              autoPlay={false}
              navButtonsAlwaysVisible
              navButtonsAlwaysInvisible={offer?.images.length === 1}
              animation="slide"
            >
              {offer?.images.map((value, index) => (
                <Box
                  sx={{
                    width: 1,
                    height: { xs: 232, md: 400 },
                    objectFit: "cover",
                  }}
                  component="img"
                  key={value}
                  src={value}
                  onClick={() => handleImageClick(index + 1)}
                />
              ))}
            </MuiCarousel>
            {!isDesktop ? (
              <OfferSummary
                toggleFavoriteState={handleToggleFavoriteState}
                setSuccessMessage={handleSetSuccessMessage}
                isFavorite={isFavorite}
                offer={offer}
                askForOffer={handleAskForOffer}
                askForPricePropose={handleAskForPricePropose}
              />
            ) : null}
            {offer?.packages?.promotionDate && (
              <div className="mt-10">
                <div className="w-full px-4 md:px-0">
                  <Typography
                    type="menu"
                    text="Promowanie na górze listy w zakładce nowości"
                    className="text-text-primary/50 pb-2"
                  />
                  <Typography
                    type="body1"
                    text={offer?.packages.promotionDate ?? ""}
                  />
                </div>
              </div>
            )}
            {offer?.packages?.highlightDate && (
              <div className="mt-10">
                <div className="w-full px-4 md:px-0">
                  <Typography
                    type="menu"
                    text="Promowanie na górze listy w zakładce Kup"
                    className="text-text-primary/50 pb-2"
                  />
                  <Typography
                    type="body1"
                    text={offer?.packages.highlightDate ?? ""}
                  />
                </div>
              </div>
            )}
            <div className="mt-10">
              <div className="w-full px-4 md:px-0">
                <Typography
                  type="menu"
                  text="OPIS"
                  className="text-text-primary/50 pb-2"
                />
                <MUITypography>
                  {offer?.description ?? "undefined"}
                </MUITypography>
              </div>
            </div>
            <Stack
              sx={{ mt: 3, mb: 1, p: { xs: 2, md: 0 } }}
              divider={<Divider />}
            >
              {offer?.categoryPath && (
                <OfferDetailsListElements
                  title="Kategoria"
                  textRows={offer.categoryPath.map((path) => path)}
                />
              )}
              <OfferDetailsInformation
                title="Lokalizacja"
                description={offer?.location ?? "undefined"}
                key="Lokalizacja"
              />
              {offer?.shipping && (
                <OfferDetailsInformation
                  title="dostawa"
                  element={
                    <div className="text-right">
                      <MUITypography sx={{ fontWeight: 500 }} variant="caption">
                        {offer.shipping.methodName === "other"
                          ? offer.shipping.shippingName
                          : offer.shipping.methodLabel}
                      </MUITypography>
                      {offer.shipping.methodDescription !== null && (
                        <>
                          <br />
                          <MUITypography
                            sx={{ fontWeight: 500 }}
                            variant="caption"
                          >
                            {offer.shipping.methodDescription}
                          </MUITypography>
                        </>
                      )}
                      {offer.shipping.methodName !== "personalOnly" && (
                        <>
                          <br />
                          <MUITypography
                            sx={{ fontWeight: 500 }}
                            variant="caption"
                          >
                            {getFormattedPrice(offer.shipping.price)}
                          </MUITypography>
                        </>
                      )}
                    </div>
                  }
                  key={Math.random()}
                />
              )}
              {groupedAttributes &&
                Object.entries(groupedAttributes).map(([name, values]) => (
                  <OfferDetailsInformation
                    title={name}
                    element={
                      <div className="text-right">
                        {(values as string[]).map((value) => (
                          <Box component="span" key={value}>
                            <MUITypography
                              sx={{ fontWeight: 500 }}
                              variant="caption"
                            >
                              {value}
                            </MUITypography>
                            <br />
                          </Box>
                        ))}
                      </div>
                    }
                    key={Math.random()}
                  />
                ))}
            </Stack>
            <AtomAlert
              type="info"
              icon="info"
              text="Sprzedawca nie jest przedsiębiorcą w rozumieniu przepisów o prawach konsumenta."
            />
          </div>
        </div>
        {isDesktop ? (
          <OfferSummary
            toggleFavoriteState={handleToggleFavoriteState}
            setSuccessMessage={handleSetSuccessMessage}
            isFavorite={isFavorite}
            offer={offer}
            askForOffer={handleAskForOffer}
            askForPricePropose={handleAskForPricePropose}
          />
        ) : null}
      </Box>
      <Dialog
        open={openAskForPricePropose}
        onClose={() => handleCloseDialog()}
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <Box component="form" onSubmit={handleSubmit(pricePropose)}>
          <DialogContent>
            <DialogContentText
              id="dialog-description"
              sx={{ fontSize: "1.375rem", fontWeight: 700, mb: 2 }}
            >
              Zaproponuj cenę
            </DialogContentText>
            <Controller
              rules={{
                required: inputRequired,
                pattern: patternPrice,
                min: minPrice,
              }}
              control={control}
              name="price"
              render={({ field }: any) => (
                <TextField
                  {...field}
                  color="secondary"
                  variant="standard"
                  name="price"
                  type="text"
                  error={!!errors.price}
                  helperText={errors.price?.message}
                  label="Zaproponuj swoją cenę"
                  placeholder="np. 50"
                  sx={{ width: 1 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">PLN</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCloseDialog()}>Anuluj</Button>
            <Button
              type="submit"
              variant="contained"
              disabled={!!price !== true}
            >
              Zaproponuj
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={successMessage !== null && successMessage.length > 0}
        onClose={() => setSuccessMessage(null)}
      >
        <Alert
          onClose={() => setSuccessMessage(null)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </DefaultLayout>
  );
}

export default Offer;
