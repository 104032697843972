import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
} from "@mui/material";
import Typography from "../components/atoms/Typography";
import {
  inputEmailRegex,
  inputPasswordRegex,
  inputRequired,
  inputUsernameMinLength,
  inputUsernameRegex,
} from "../utils/inputValidate";
import InputMUI from "../components/atoms/Inputs/InputMUI";
import { sendRegisterUserRequest } from "../client/Requests";
import SocialButtons from "../components/organisms/SocialButtons";
import SectionLayoutContent from "../components/layout/SectionLayoutContent";
import SectionLayout from "../components/layout/SectionLayout";

function Register() {
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      agreeRegulations: false,
      agreeMarketing: false,
      username: "",
      email: "",
      password: "",
    },
  });
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    // eslint-disable-next-line no-param-reassign
    delete data.agreeRegulations;
    await setError(null);
    await setMessage(null);
    await sendRegisterUserRequest(JSON.stringify(data))
      .then((response) => {
        if (response.status === 201) {
          navigate("/login?registered=1");
          setMessage(
            "Na Twojego maila wysłaliśmy link aktywacyjny. Dokończ rejestrację aktywując konto.",
          );
        } else {
          setError(
            "Nie udało się zarejestrować użytkownika dla podanych danych",
          );
        }
      })
      .catch((errorResponse) => {
        const { response } = errorResponse;
        if (response.status === 400 && response.data.type === "InvalidData") {
          setError(response.data.message);
        } else {
          setError(
            "Nie udało się zarejestrować użytkownika dla podanych danych",
          );
        }
      });
  };

  return (
    <SectionLayout
      defaultLayoutProps={{ width: 500 }}
      sectionTitle="Rejestracja"
    >
      <SectionLayoutContent styles={{ p: 3 }}>
        <>
          <SocialButtons />
          <div>
            <div className="text-center my-10">lub</div>
            <form
              className="space-y-6 ng-untouched ng-pristine ng-valid"
              onSubmit={handleSubmit(onSubmit)}
            >
              {error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              {message && (
                <div
                  className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{message}</span>
                </div>
              )}
              <div>
                <InputMUI
                  control={control}
                  name="username"
                  placeholder="Nazwa użytkownika"
                  rules={{
                    required: inputRequired,
                    minLength: inputUsernameMinLength,
                    pattern: inputUsernameRegex,
                  }}
                />
              </div>
              <div>
                <InputMUI
                  control={control}
                  name="email"
                  placeholder="Email użytkownika"
                  rules={{
                    required: inputRequired,
                    pattern: inputEmailRegex,
                  }}
                />
              </div>
              <div>
                <InputMUI
                  control={control}
                  name="password"
                  placeholder="Hasło (minimum 6 znaków, duża litera, znak specjalny)"
                  rules={{
                    required: inputRequired,
                    pattern: inputPasswordRegex,
                  }}
                  type="password"
                />
              </div>
              <Stack spacing={1}>
                <Box>
                  <Controller
                    name="agreeRegulations"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid },
                    }) => (
                      <>
                        <FormControlLabel
                          name="agreeRegulations"
                          label={
                            <>
                              <Typography
                                type="text"
                                text="Akceptuję"
                                className="mr-1"
                              />
                              <Link
                                className="inline"
                                to="/page/regulamin-i-polityka-prywatnosci"
                              >
                                <Typography
                                  type="text"
                                  text="regulamin i politykę prywatności"
                                  className="underline cursor-pointer text-primary-dark"
                                />
                              </Link>
                            </>
                          }
                          control={
                            <Checkbox
                              {...field}
                              inputRef={ref}
                              checked={field.value}
                            />
                          }
                        />
                        {invalid && (
                          <FormHelperText error={invalid} sx={{ mb: 1 }}>
                            Akceptacja regulaminu i polityki prywatności jest
                            wymagana
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <>
                        <Controller
                          name="agreeMarketing"
                          control={control}
                          render={({ field }) => (
                            <Checkbox {...field} checked={field.value} />
                          )}
                        />

                        <Typography
                          type="text"
                          text="Zgadzam się na"
                          className="mr-1"
                        />
                        <Link className="inline" to="/page/zgoda-marketingowa">
                          <Typography
                            type="text"
                            text="marketing"
                            className="underline cursor-pointer text-primary-dark"
                          />
                        </Link>
                      </>
                    }
                    label=""
                  />
                </Box>
              </Stack>
              <div>
                Masz już konto?{" "}
                <Link to="/login">
                  <Typography
                    type="text"
                    text="Zaloguj się"
                    className="underline cursor-pointer text-primary-dark"
                  />
                </Link>
              </div>
              <div className="flex">
                <button
                  type="submit"
                  className="`items-end row-end space-x-[7px] min-w-[122px] h-[42px] rounded-lg bg-button-secondary transition ease-in-out duration-300 hover:bg-button-primary text-white font-semibold text-base py-2 px-4"
                >
                  Zarejestruj
                </button>
              </div>
            </form>
          </div>
        </>
      </SectionLayoutContent>
    </SectionLayout>
  );
}

export default Register;
