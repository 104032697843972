import MUIButton from "@mui/material/Button";
import MuiTypography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CheckCircle, ThumbUp } from "@mui/icons-material";
import OfferProfileButton from "../atoms/OfferProfileButton";
import CustomButton from "../atoms/Buttons/CustomButton";
import UserAvatar from "../atoms/UserAvatar";
import {
  addOfferToFavorites,
  deleteProductOffer,
  removeOfferFromFavorites,
  sendAddToBasketRequest,
  sendRepublishOfferRequest,
  withdrawProductOffer,
} from "../../client/Requests";
import ButtonOutlined from "../atoms/Buttons/ButtonOutlined";
import { getIsElementInBasket } from "../../pages/offers/utils";
import { BasketContext } from "../../contexts/basketProvider/BasketProvider";
import { IBasketProvider } from "../../contexts/basketProvider/types";
import useUserData from "../../hooks/useUserData";
import { ProductOfferInterface } from "../../models/ProductOfferInterface";
import AtomAlert from "../atoms/Alert";
import { getFormattedPrice } from "../../utils/PriceUtils";
import Typography from "../atoms/Typography";
import EntryUserDialog from "../molecules/dialog/EntryUserDialog";
import EmptyUserProfileDialog from "../molecules/dialog/EmptyUserProfileDialog";

type Props = {
  toggleFavoriteState: () => void;
  isFavorite: boolean;
  setSuccessMessage: (successMessage: string) => void;
  offer?: ProductOfferInterface;
  askForOffer: () => void;
  askForPricePropose: () => void;
};

function OfferSummary({
  toggleFavoriteState,
  isFavorite,
  setSuccessMessage,
  offer,
  askForOffer,
  askForPricePropose,
}: Props) {
  const navigate = useNavigate();
  const { userData, getUserData } = useUserData();
  const [isWithdrawn, setIsWithdrawn] = useState(offer?.status === "withdrawn");
  const [openEntryUserDialog, setOpenEntryUserDialog] =
    useState<boolean>(false);
  const [openEmptyUserProfileDialog, setOpenEmptyUserProfileDialog] =
    useState<boolean>(false);
  const { refetch, basket } = useContext(BasketContext) as IBasketProvider;
  const offerId = offer?.id || 0;

  const isElementInBasket = getIsElementInBasket(basket, offer?.id);

  useEffect(() => {
    setIsWithdrawn(offer?.status === "withdrawn");
  }, [offer]);

  const handleDelete = async () => {
    if (userData) {
      deleteProductOffer(offerId, userData.userToken).then(() => {
        setSuccessMessage("Oferta usunięta");
      });
    }
  };

  const handleWithdraw = async () => {
    if (userData) {
      withdrawProductOffer(offerId, userData.userToken).then(() => {
        setSuccessMessage("Oferta wycofana");
        setIsWithdrawn(true);
      });
    }
  };

  const handleRepublish = async () => {
    if (userData) {
      sendRepublishOfferRequest(userData.userToken, offerId).then(() => {
        setSuccessMessage("Oferta przywrócona do sprzedaży");
        setIsWithdrawn(false);
      });
    }
  };

  const isUserValid = () => {
    const newUserData = getUserData();

    if (!newUserData) {
      setOpenEntryUserDialog(true);
      return false;
    }
    if (!newUserData.isUserProfileComplete) {
      setOpenEmptyUserProfileDialog(true);
      return false;
    }

    return true;
  };

  const handleAddToBasket = async () => {
    if (!isUserValid()) {
      return;
    }

    try {
      await sendAddToBasketRequest(offerId, userData?.userToken);
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleBuyNowSummary = async () => {
    if (!isUserValid()) {
      return;
    }

    await handleAddToBasket();
    await navigate("/basket");
  };

  const handleAddToFavorites = async () => {
    if (!userData) {
      setOpenEntryUserDialog(true);
      return;
    }

    if (isFavorite) {
      removeOfferFromFavorites(userData?.userToken, offerId);
      toggleFavoriteState();
    } else {
      addOfferToFavorites(userData.userToken, offerId);
      toggleFavoriteState();
    }
  };

  const handleAskForOffer = async () => {
    if (!userData) {
      setOpenEntryUserDialog(true);
    } else {
      askForOffer();
    }
  };

  const showAddressButton = () => {
    if (!userData || !offer?.buyer) {
      return false;
    }

    if (userData?.userId === offer?.owner.id) {
      return true;
    }

    return false;
  };

  const showPaymentDataButton = () => {
    if (!userData || !offer?.buyer) {
      return false;
    }

    if (userData?.userId === offer?.buyer.id) {
      return true;
    }

    return false;
  };

  const buttonTriggerPricePropose = async () => {
    if (!userData) {
      setOpenEntryUserDialog(true);
    } else {
      askForPricePropose();
    }
  };

  return (
    <>
      <EntryUserDialog
        initialOpen={openEntryUserDialog}
        handleClose={() => {
          setOpenEntryUserDialog(false);
        }}
      />
      <EmptyUserProfileDialog
        initialOpen={openEmptyUserProfileDialog}
        handleClose={() => {
          setOpenEmptyUserProfileDialog(false);
        }}
      />
      <Box sx={{ width: { xs: 1, md: 387 } }}>
        <div className="w-full bg-white p-2 mt-4 md:mt-0 sm:p-6 md:rounded-md md:sticky md:top-3">
          <Typography type="body1" text={offer?.name ?? "###"} />
          <Typography type="h2" text={getFormattedPrice(offer?.price ?? 0)} />
          {offer?.oldPrice !== offer?.price && (
            <Typography
              type="helper"
              text={getFormattedPrice(offer?.oldPrice ?? 0)}
              className="line-through"
            />
          )}
          <Box
            sx={{
              display: "flex",
              mt: 3,
              p: 1,
              pr: 0,
              pl: 0,
              width: 1,
            }}
          >
            <Link to={`/user-profile/${offer?.owner.id}`}>
              <UserAvatar
                avatarPath={offer ? offer.owner.avatarPath : ""}
                username={offer ? offer.owner.username : ""}
              />
            </Link>
            <Box sx={{ p: 1, pr: 0, ml: -2 }}>
              <Link to={`/user-profile/${offer?.owner?.id}`}>
                <MuiTypography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
                  {offer?.owner.username ?? "Konto"}
                </MuiTypography>
              </Link>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mr: { xs: 1, md: 2 },
                    mb: 0.5,
                  }}
                >
                  <CheckCircle sx={{ fontSize: "16px" }} />
                  <MuiTypography
                    component="span"
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      ml: 1,
                      textAlign: "center",
                    }}
                  >
                    Sprzedane:&nbsp;
                    <MuiTypography
                      component="span"
                      variant="inherit"
                      sx={{ color: "primary.main", fontWeight: 600 }}
                    >
                      {offer?.owner.soldCount ?? 0}
                    </MuiTypography>
                  </MuiTypography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                  <ThumbUp sx={{ fontSize: "16px" }} />
                  <MuiTypography
                    component="span"
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      ml: 1,
                      textAlign: "center",
                    }}
                  >
                    Polecenia:&nbsp;
                    <MuiTypography
                      component="span"
                      variant="inherit"
                      sx={{ color: "primary.main", fontWeight: 600 }}
                    >
                      {offer?.owner.recommendationsCount ?? 0}
                    </MuiTypography>
                  </MuiTypography>
                </Box>
              </Box>
            </Box>
          </Box>
          {offer?.status === "published" &&
            offer.owner.id !== userData?.userId && (
              <div className="grid grid-cols-3 mt-8">
                <OfferProfileButton
                  icon={isFavorite ? "favorite" : "favorite_border"}
                  text="Dodaj do ulubionych"
                  onClick={handleAddToFavorites}
                />
                <OfferProfileButton
                  icon="mail_outline"
                  text="Zadaj pytanie"
                  onClick={handleAskForOffer}
                />
                <OfferProfileButton
                  icon="local_offer"
                  text="Zaproponuj cenę"
                  onClick={buttonTriggerPricePropose}
                />
              </div>
            )}
          {offer?.status === "published" &&
            offer.owner.id !== userData?.userId && (
              <Stack spacing={1} sx={{ my: 1 }}>
                {offer?.status === "published" &&
                  offer.owner.id !== userData?.userId && (
                    <div className="space-y-2 mt-28">
                      {!isElementInBasket && (
                        <MUIButton
                          sx={{ width: 1 }}
                          size="large"
                          variant="contained"
                          color="secondary"
                          onClick={handleBuyNowSummary}
                        >
                          Kup teraz
                        </MUIButton>
                      )}
                    </div>
                  )}
                {isElementInBasket ? (
                  <MUIButton
                    size="large"
                    variant="contained"
                    onClick={() => navigate("/basket")}
                  >
                    Przejdź do koszyka
                  </MUIButton>
                ) : (
                  <MUIButton
                    size="large"
                    variant="contained"
                    onClick={handleAddToBasket}
                  >
                    Dodaj do koszyka
                  </MUIButton>
                )}
              </Stack>
            )}
          {offer?.status === "sold" && showAddressButton() && (
            <div className="space-y-2 mt-28">
              <CustomButton
                text="Dane do wysyłki"
                className="w-full"
                onClick={() => {
                  navigate(`/adres-dostawy/${offerId}`);
                }}
              />
            </div>
          )}
          {offer?.status === "sold" && showPaymentDataButton() && (
            <div className="space-y-2 mt-28">
              <CustomButton
                text="Dane do przelewu"
                className="w-full"
                onClick={() => {
                  navigate(`/dane-do-przelewu/${offerId}`);
                }}
              />
            </div>
          )}
          {offer?.owner.id !== userData?.userId && (
            <Button
              size="large"
              variant="outlined"
              sx={{
                width: 1,
                borderColor: "primary.main",
                mt: 2,
                borderRadius: 5,
                fontWeight: 500,
              }}
              color="secondary"
              onClick={() => navigate(`/user-profile/${offer?.owner.id}`)}
            >
              Sprawdź inne przedmioty sprzedającego
            </Button>
          )}
          {offer?.owner.id === userData?.userId && offer?.status !== "sold" && (
            <div className="space-y-2 mt-28">
              {!isWithdrawn && (
                <Button
                  size="large"
                  variant="outlined"
                  color="secondary"
                  sx={{ width: 1 }}
                  onClick={handleWithdraw}
                >
                  Wycofaj ze sprzedaży
                </Button>
              )}
              {isWithdrawn && (
                <Button
                  size="large"
                  variant="outlined"
                  color="secondary"
                  sx={{ width: 1 }}
                  onClick={handleRepublish}
                >
                  Przywróć do sprzedaży
                </Button>
              )}

              <Button
                size="large"
                variant="contained"
                color="secondary"
                sx={{ width: 1 }}
                onClick={() => {
                  navigate(`/offers/edit/${offerId}`);
                }}
              >
                Edytuj
              </Button>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                sx={{ width: 1 }}
                onClick={() => {
                  navigate(`/promote/${offerId}`, {
                    state: { isEdit: true },
                  });
                }}
              >
                Opcje promowania
              </Button>
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                sx={{ width: 1 }}
                onClick={handleDelete}
              >
                Usuń
              </Button>
            </div>
          )}
          {offer?.status === "sold" && (
            <div className="mt-12">
              <AtomAlert
                type="error"
                icon="error"
                text="Oferta nie jest już aktualna. Przedmiot został sprzedany"
              />
            </div>
          )}
        </div>
      </Box>
    </>
  );
}

export default OfferSummary;
