/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/system";
import { Alert, FormHelperText, Snackbar } from "@mui/material";
import Typography from "../atoms/Typography";
import { deleteImg } from "../../client/Requests";
import useUserData from "../../hooks/useUserData";

export type ImgData = { id: number; path: string };

interface EditOfferUploaderInterface {
  uploadedFiles: File[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  uploadErrorMessage: string | null;
  setUploadErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setedImg: any;
  setSetedImgs: any;
  isSubmitting: boolean;
}

function EditOfferUploader({
  uploadedFiles,
  setUploadedFiles,
  uploadErrorMessage,
  setUploadErrorMessage,
  setedImg,
  setSetedImgs,
  isSubmitting,
}: EditOfferUploaderInterface) {
  const [images, setImages] = useState<JSX.Element[]>([]);
  const [dragActive, setDragActive] = useState<Boolean>(false);
  const { userData } = useUserData();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (isSubmitting && !images?.length) {
      setError("Dodaj chociaż jedno zdjęcie!");
    } else if (error && images) {
      setError("");
    }
  }, [isSubmitting, images]);

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      if (files[0]?.size > 5 * 1024 * 1024) {
        setOpenSnackBar(true);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < files.length; i++) {
          const file = files.item(i);

          if (file) {
            setUploadedFiles((old) => [...old, file]);
          }
        }
      }
    }
    setUploadErrorMessage(null);
  };

  const deleteHandler = (index: number) => {
    const file = uploadedFiles.at(index);
    if (file) {
      setUploadedFiles(uploadedFiles.filter((value) => value !== file));
    }
  };

  const deletesetedImgHandler = async (index: number) => {
    if (userData?.userToken) {
      await deleteImg(userData.userToken, index);
    }
    const newArray = setedImg.filter((value: any) => value.id !== index);
    setSetedImgs(newArray);
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (e.dataTransfer.files) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          const file = e.dataTransfer.files.item(i);

          if (file) {
            setUploadedFiles((old) => [...old, file]);
          }
        }
      }
    }
  };

  useEffect(() => {
    const elements: JSX.Element[] = [];
    // eslint-disable-next-line array-callback-return
    uploadedFiles.map((value, index) => {
      const preview = URL.createObjectURL(value);
      const img = (
        <div className="relative" key={index}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="absolute top-4 right-4 bg-white rounded-full w-10 h-10 flex items-center justify-center cursor-pointer"
            onClick={() => deleteHandler(index)}
          >
            <span className="material-icons-outlined text-text-primary">
              close
            </span>
          </div>
          <Box
            component="img"
            src={preview}
            alt="Uploaded file"
            sx={{
              width: { xs: "140px", md: "180px" },
              height: { xs: "140px", md: "180px" },
              borderRadius: "5px",
              objectFit: "cover",
              m: 1,
            }}
            key={Math.random()}
          />
        </div>
      );

      elements.push(img);
    });

    setImages(elements);
  }, [uploadedFiles]);
  const loadSetedImg = () => {
    if (setedImg) {
      setSetedImgs(setedImg);
    }
  };

  useEffect(() => {
    loadSetedImg();
  }, [setedImg]);

  return (
    <div className="w-full bg-white min-h-[283px] p-6">
      <Typography
        type="captions"
        text="Dodaj przynajmniej jedno zdjęcie, maksymalnie 10 zdjęć. "
      />
      <label
        htmlFor="imgs"
        className="flex flex-col items-center justify-center rounded-lg cursor-pointer w-full h-[186px] border border-dashed border-button-primary/50 mt-8"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6 text-center">
          {dragActive && (
            <div className="space-x-2">
              <Typography
                type="menu"
                text="Upuść tutaj"
                className="text-light-info-main underline underline-offset-4"
              />
            </div>
          )}
          {!dragActive && (
            <div className="space-x-2">
              <Typography
                type="menu"
                text="Kliknij tutaj, aby dodać zdjęcie"
                className="text-light-info-main underline underline-offset-4"
              />
              <Typography
                type="menu"
                text="albo przeciągnij i upuść"
                className="hidden md:inline-flex"
              />
            </div>
          )}
          {!dragActive && (
            <div className="mt-2">
              <Typography
                type="body2"
                text="JGP lub PNG (max. 5MB)"
                className="text-text-primary/50"
              />
            </div>
          )}
        </div>
        <input
          className="w-full h-full text-center hidden"
          type="file"
          name="imgs"
          id="imgs"
          accept="image/jpeg, image/png"
          multiple
          onChange={fileChangeHandler}
        />
      </label>
      {uploadErrorMessage && (
        <div className="mt-2 w-full text-red-500">{uploadErrorMessage}</div>
      )}
      <Stack
        direction="row"
        sx={{ flexWrap: "wrap", justifyContent: { xs: "center", md: "left" } }}
      >
        {setedImg
          ? setedImg.map((img: { id: number; path: string }) => (
              <div className="relative" key={img.id}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className="absolute top-4 right-4 bg-white rounded-full w-10 h-10 flex items-center justify-center cursor-pointer"
                  onClick={() => deletesetedImgHandler(img.id)}
                >
                  <span className="material-icons-outlined text-text-primary">
                    close
                  </span>
                </div>
                <Box
                  component="img"
                  src={img.path}
                  alt="Uploaded file"
                  sx={{
                    width: { xs: "140px", md: "180px" },
                    height: { xs: "140px", md: "180px" },
                    borderRadius: "5px",
                    objectFit: "cover",
                    m: 1,
                  }}
                  key={Math.random()}
                />
              </div>
            ))
          : null}
        {images}
      </Stack>
      {error && (
        <FormHelperText error component="span">
          {error}
        </FormHelperText>
      )}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="error">Maksymalny rozmiar zdięcia to 5MB</Alert>
      </Snackbar>
    </div>
  );
}

export default EditOfferUploader;
