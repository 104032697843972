import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getFormattedPrice } from "../../utils/PriceUtils";

interface Props {
  chargesAreSelected: boolean;
  handleBuyCharges: () => void;
  totalPrice: number;
  isEdit: boolean;
}

function PromoteActionsButtons({
  chargesAreSelected,
  handleBuyCharges,
  totalPrice,
  isEdit,
}: Props) {
  const navigate = useNavigate();

  const handleWithoutPromoteClick = () => {
    navigate("/");
  };

  return (
    <Stack spacing={1}>
      <Button
        color="secondary"
        variant="contained"
        disabled={!chargesAreSelected}
        onClick={handleBuyCharges}
      >
        {isEdit
          ? `Przejdź do płatności (${getFormattedPrice(totalPrice)})`
          : `Zapłać i bądź widoczny (${getFormattedPrice(totalPrice)})`}
      </Button>
      <Button
        color="secondary"
        variant={chargesAreSelected ? "outlined" : "contained"}
        onClick={handleWithoutPromoteClick}
      >
        {isEdit ? `Anuluj` : `Wystaw bez promowania`}
      </Button>
    </Stack>
  );
}

export default PromoteActionsButtons;
