import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import useWebInstallPrompt from "../../hooks/pwaHooks/useWebInstallPrompt";
import useIosInstallPrompt from "../../hooks/pwaHooks/useIosInstallPrompt";

function PwaPrompt() {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  if ((!iosInstallPrompt && !webInstallPrompt) || isDesktop) {
    return null;
  }

  return (
    <Dialog open={iosInstallPrompt || webInstallPrompt}>
      <DialogTitle sx={{ fontWeight: 500 }}>
        Zainstaluj aplikację Bubaa
      </DialogTitle>
      <DialogContent>
        {iosInstallPrompt && (
          <Box>
            <Typography>
              Kliknij <IosShareIcon /> i wybierz opcję "Do ekranu początkowego"
            </Typography>
            <Box sx={{ width: 1, display: "flex", justifyContent: "end" }}>
              <Button onClick={handleIOSInstallDeclined}>Zamknij</Button>
            </Box>
          </Box>
        )}
        {webInstallPrompt && (
          <Stack
            direction="row"
            spacing={1}
            sx={{ width: 1, justifyContent: "flex-end", mt: 1 }}
          >
            <Button
              variant="text"
              color="secondary"
              onClick={handleWebInstallDeclined}
            >
              Zamknij
            </Button>
            <Button variant="contained" onClick={handleWebInstallAccepted}>
              Zainstaluj
            </Button>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default PwaPrompt;
