import { BasicOfferFiltersInterface } from "../models/OffersFiltersInterface";
import { ShortOffersInterface } from "../models/ShortOffersInterface";

export const isBasicFilters = (
  filters: object | null,
): filters is BasicOfferFiltersInterface => {
  const basicFilters = filters as BasicOfferFiltersInterface;

  return (
    !!basicFilters &&
    (!!basicFilters?.city ||
      !!basicFilters?.currentPage ||
      !!basicFilters?.maxPrice ||
      !!basicFilters?.minPrice ||
      !!basicFilters?.pageSize ||
      !!basicFilters?.province ||
      !!basicFilters?.sort)
  );
};

export const isAvatar = (
  offer?: ShortOffersInterface | { avatar: string } | { showMoreLink: string },
): offer is { avatar: string } => {
  const avatar = offer as { avatar: string };

  return !!offer && !!avatar?.avatar;
};

export const isShowMoreLink = (
  offer?: ShortOffersInterface | { avatar: string } | { showMoreLink: string },
): offer is { showMoreLink: string } => {
  const showMoreLink = offer as { showMoreLink: string };

  return !!showMoreLink && !!showMoreLink?.showMoreLink;
};

export const isOffer = (
  offer?: ShortOffersInterface | { avatar: string } | { showMoreLink: string },
): offer is ShortOffersInterface => {
  const validOffer = offer as ShortOffersInterface;

  return !!validOffer && !!validOffer.id;
};
