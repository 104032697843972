import { Divider, Stack, Typography } from "@mui/material";

interface IHeader {
  text: string;
}

function Header({ text }: IHeader) {
  return (
    <Stack>
      <Typography sx={{ width: "215px" }}>{text}</Typography>
      <Divider sx={{ mt: 2, mb: 1 }} />
    </Stack>
  );
}

export default Header;
