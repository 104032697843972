import { Breakpoint, Container } from "@mui/material";

interface Props {
  children: JSX.Element;
  maxSize: Breakpoint;
}

function PromotePageLayout({ children, maxSize }: Props) {
  return <Container maxWidth={maxSize}>{children}</Container>;
}

export default PromotePageLayout;
