import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import router from "./Routes/routes";
import theme from "./theme";
import BasketProvider from "./contexts/basketProvider/BasketProvider";
import CookiesPolicy from "./components/cookiesPolicy/CookiesPolicy";
import PwaPrompt from "./components/pwaPrompt/PwaPrompt";

const queryClient = new QueryClient();

const routes = createBrowserRouter([...router]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BasketProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <RouterProvider router={routes} />
            <CookiesPolicy />
            <PwaPrompt />
          </LocalizationProvider>
        </BasketProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
