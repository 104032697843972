import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Stack, Theme, useMediaQuery } from "@mui/material";
import CustomButton from "../atoms/Buttons/CustomButton";
import useUserData from "../../hooks/useUserData";
import logoLight from "../../images/logo_light.svg";
import { getUserCounters } from "../../client/Requests";
import EntryUserDialog from "../molecules/dialog/EntryUserDialog";
import { UserCountersInterface } from "../../models/UserCountersInterface";
import EmptyUserProfileDialog from "../molecules/dialog/EmptyUserProfileDialog";
import { UserContext } from "../../contexts/UserProvider";
import { UserContextType } from "../../types/UserContextType";
import Item from "./Item";
import { BasketContext } from "../../contexts/basketProvider/BasketProvider";
import { IBasketProvider } from "../../contexts/basketProvider/types";

function TopNav() {
  const { userData, getUserData } = useUserData();
  const navigate = useNavigate();
  const [openEntryUserDialog, setOpenEntryUserDialog] =
    useState<boolean>(false);
  const [openEmptyUserProfileDialog, setOpenEmptyUserProfileDialog] =
    useState<boolean>(false);
  const [userCounters, setUserCounters] =
    useState<UserCountersInterface | null>(null);
  const { basket } = useContext(BasketContext) as IBasketProvider;

  const { userProfile } = useContext(UserContext) as UserContextType;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );

  const checkCounters = () => {
    if (!userData) {
      return;
    }
    getUserCounters(userData.userToken).then((response) => {
      setUserCounters(response.data);
    });
  };

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (userCounters === null) {
      setInterval(() => {
        checkCounters();
      }, 10000);
    }
  }, [userData]);

  return (
    <nav className="bg-white w-full h-20">
      <div className="container h-full mx-auto flex items-center justify-between px-4 sm:px-0 overflow-auto">
        <Link to="/">
          <img src={logoLight} alt="bubaa.pl" />
        </Link>
        <EntryUserDialog
          initialOpen={openEntryUserDialog}
          handleClose={() => {
            setOpenEntryUserDialog(false);
          }}
        />
        <EmptyUserProfileDialog
          initialOpen={openEmptyUserProfileDialog}
          handleClose={() => {
            setOpenEmptyUserProfileDialog(false);
          }}
        />

        <Stack
          direction="row"
          sx={{ alignItems: "center" }}
          spacing={{ xs: 0, md: 1, lg: 2 }}
        >
          {!isMobile && (
            <Item
              Icon={MailOutlineIcon}
              name="Wiadomości"
              badgeCount={userCounters ? userCounters.messagesCount : 0}
              key={Math.random()}
              onClick={() => {
                if (!userData) {
                  setOpenEntryUserDialog(true);
                  return;
                }

                navigate("/messages");
              }}
            />
          )}
          <Item
            Icon={NotificationsNoneIcon}
            name="Powiadomienia"
            onClick={() => {
              if (!userData) {
                setOpenEntryUserDialog(true);
                return;
              }

              navigate("/notifications");
            }}
          />
          <Item
            Icon={FavoriteBorderIcon}
            name="Ulubione"
            badgeCount={userCounters ? userCounters.favoritesCount : 0}
            onClick={() => {
              if (!userData) {
                setOpenEntryUserDialog(true);
                return;
              }

              navigate("/account/favourite");
            }}
          />
          <Item
            Icon={ShoppingBagOutlinedIcon}
            name="Koszyk"
            badgeCount={basket?.itemsCount ?? 0}
            onClick={() => {
              navigate("/basket");
            }}
          />

          <Item
            avatarPath={userProfile ? userProfile.user.avatar : ""}
            userData={userData}
            onClick={() => {
              if (!userData) {
                setOpenEntryUserDialog(true);
                return;
              }

              navigate("/account/profile");
            }}
          />
          {isDesktop ? (
            <CustomButton
              leftIcon={!userData ? "" : "add_circle_outline"}
              text={!userData ? "Zaloguj się" : "Sprzedaj"}
              className="w-full"
              onClick={() => {
                const newUserData = getUserData();
                if (!newUserData) {
                  navigate("/login");
                  return;
                }
                if (!newUserData.isUserProfileComplete) {
                  setOpenEmptyUserProfileDialog(true);
                  return;
                }

                navigate("/offers/create");
              }}
            />
          ) : null}
        </Stack>
      </div>
    </nav>
  );
}

export default TopNav;
