import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Snackbar } from "@mui/material";
import useUserData from "../../hooks/useUserData";
import Typography from "../../components/atoms/Typography";
import { changeUserPassword } from "../../client/Requests";
import { inputPasswordRegex, inputRequired } from "../../utils/inputValidate";
import InputMUI from "../../components/atoms/Inputs/InputMUI";

function ChangePassword() {
  const { userData } = useUserData();
  const [message, setMessage] = useState<string | null>(null);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      agreeRegulations: false,
      agreeMarketing: false,
    },
  });

  const onSubmit = async (data: any) => {
    if (!userData) {
      return;
    }

    const response = await changeUserPassword(
      userData?.userToken,
      JSON.stringify({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      }),
    );

    if (response.status === 204) {
      setMessage("Hasło zmienione poprawnie");
    }
  };

  return (
    <div className="bg-white flex flex-col justify-center md:mb-8 mb-4 md:mt-8 mt-4 p-4">
      <Typography type="body1" text="Zmiana hasła" />
      <div className="grid grid-cols-2 md:grid-cols-2 gap-2 mt-3 mb-5">
        <form
          className="space-y-6 ng-untouched ng-pristine ng-valid"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <InputMUI
              control={control}
              name="oldPassword"
              placeholder="Stare hasło"
              type="password"
              rules={{
                required: inputRequired,
                pattern: inputPasswordRegex,
              }}
            />
          </div>
          <div>
            <InputMUI
              control={control}
              name="newPassword"
              placeholder="Nowe hasło"
              type="password"
              rules={{
                required: inputRequired,
                pattern: inputPasswordRegex,
              }}
            />
          </div>
          <button
            type="submit"
            className="`flex space-x-[7px] min-w-[122px] h-[42px] items-center justify-center rounded-lg bg-button-secondary transition ease-in-out duration-300 hover:bg-button-primary text-white font-semibold text-base py-2 px-4"
          >
            Zmień
          </button>
          <Snackbar
            open={message !== null && message.length > 0}
            onClose={() => setMessage(null)}
          >
            <Alert
              onClose={() => setMessage(null)}
              severity="success"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
