import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

interface IAskForOfferDialog {
  initialOpen: boolean;
  handleSend: any;
  handleClose: any;
  errorMessage: string | null;
}

function AskForOfferDialog({
  initialOpen,
  handleSend,
  handleClose,
  errorMessage,
}: IAskForOfferDialog) {
  const [open, setOpen] = useState(initialOpen);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setOpen(initialOpen);
  }, [initialOpen]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ minWidth: 400 }}>
        <DialogContentText id="alert-dialog-description">
          Zapytaj o przedmiot
          <form
            noValidate
            className="space-y-6 ng-untouched ng-pristine ng-valid"
          >
            <div>
              <textarea
                id="message"
                name="message"
                placeholder="O co chcesz zapytać?"
                className="w-full p-3 rounded dark:bg-gray-800"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </form>
          {errorMessage && (
            <span className="font-semibold text-[0.75rem] tracking-wider leading-[1.25rem] text-error-main">
              {errorMessage}
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Anuluj</Button>
        <Button
          onClick={() => {
            handleSend(message);
            setMessage("");
          }}
          variant="contained"
          autoFocus
        >
          Wyślij
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AskForOfferDialog;
