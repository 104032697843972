import { Box, Button, CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { BasketInterface } from "../../models/BasketInterface";
import { sendAskForShipping } from "../../client/Requests";
import { BasketContext } from "../../contexts/basketProvider/BasketProvider";
import { IBasketProvider } from "../../contexts/basketProvider/types";
import ModalForAskShipping from "../atoms/ModalForAskShipping";
import useUserData from "../../hooks/useUserData";

function AskForShippingButton() {
  const { basket, refetch } = useContext(BasketContext) as IBasketProvider;
  const { userData } = useUserData();
  const [askShippingMessageVisible, setAskShippingMessageVisible] =
    useState(false);

  const isStatePending = basket?.shippingStatus === "pending";

  if (!basket?.shippingStatus) {
    return null;
  }

  const handleAskForShipping = () => {
    // eslint-disable-next-line array-callback-return
    basket?.sellerItems.map((value) => {
      if (value.shippingStatus === "required") {
        sendAskForShipping(value.seller.userId, userData?.userToken)
          .then(() => {
            setAskShippingMessageVisible(true);
            refetch();
          })
          .catch(() => {});
      }
    });
  };

  return (
    <Box sx={{ width: 1 }}>
      <ModalForAskShipping
        isVisible={askShippingMessageVisible}
        onClickOut={() => setAskShippingMessageVisible(false)}
      />
      <Button
        size="large"
        variant="contained"
        disabled={isStatePending}
        sx={{
          width: 1,
          textTransform: "unset",
          cursor: isStatePending ? "not-allowed" : "pointer",
        }}
        onClick={handleAskForShipping}
      >
        Zapytaj o dostawę
      </Button>
    </Box>
  );
}

export default AskForShippingButton;
