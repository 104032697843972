import { Box, List, Typography } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  textRows: string[];
};

function OfferDetailsListElements({ title, textRows }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        width: 1,
        justifyContent: "space-between",
        alignItems: "center",
        py: 4,
      }}
    >
      <Typography sx={{ textTransform: "uppercase", color: "textLight" }}>
        {title}
      </Typography>
      <List>
        {textRows.map((textRow) => (
          <Typography
            key={textRow}
            variant="caption"
            sx={{ fontWeight: 500 }}
            component={Box}
          >
            {textRow}
          </Typography>
        ))}
      </List>
    </Box>
  );
}

export default OfferDetailsListElements;
