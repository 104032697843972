import { FormControlLabel, Radio, SxProps } from "@mui/material";

interface ISpippingFormLabel {
  label: string | JSX.Element;
  value: string;
  sx?: SxProps;
}

function SpippingFormLabel({ label, value, sx }: ISpippingFormLabel) {
  return (
    <FormControlLabel
      value={value}
      control={<Radio color="secondary" />}
      labelPlacement="start"
      sx={{
        ...sx,
        justifyContent: "space-between",
        alignItems: "center",
        width: 1,
        ml: 0,
        py: 1.5,
      }}
      label={label}
    />
  );
}

export default SpippingFormLabel;
