import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { useEffect } from "react";
import TopNav from "../navigation/TopNav";
import Footer from "../organisms/Footer";
import UserProvider from "../../contexts/UserProvider";

function Layout() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  return (
    <UserProvider>
      <TopNav />
      <Outlet />
      <Footer />
    </UserProvider>
  );
}

export default Layout;
