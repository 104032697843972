/* eslint-disable import/order */
/* eslint-disable import/no-cycle */
import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormContext } from "react-hook-form";
import { ProductCategoryInterface } from "../../../../models/ProductCategoryInterface";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

interface ICategoryOfferDialog {
  allCategories: ProductCategoryInterface[];
  handleCloseCategoryDialog: () => void;
  onSelectCategory: (category: ProductCategoryInterface) => void;
}

function CategoryOfferDialog({
  allCategories,
  handleCloseCategoryDialog,
  onSelectCategory,
}: ICategoryOfferDialog) {
  const [mainCategories, setMainCategories] = useState<
    ProductCategoryInterface[] | null
  >(null);
  const [currentCategory, setCurrentCategory] =
    useState<ProductCategoryInterface | null>(null);
  const [subcategory, setSubcategory] = useState<
    ProductCategoryInterface[] | null
  >(null);
  const { watch, setValue } = useFormContext();
  const categoryValue = watch("category");

  useEffect(() => {
    const filteredMainCategories = allCategories.filter(
      (category) => category.isMain === true,
    );
    setMainCategories(filteredMainCategories);
    if (categoryValue) {
      const earlierCategory = allCategories.filter(
        (category) => category.id === categoryValue.parentId,
      );
      setCurrentCategory(earlierCategory[0]);
    }
  }, []);

  useEffect(() => {
    if (currentCategory) {
      const currentSubCategories = allCategories.filter(
        (category) => category.parentId === currentCategory.id,
      );
      if (currentSubCategories.length) {
        setSubcategory(currentSubCategories);
      } else {
        onSelectCategory(currentCategory);
        setValue("category", currentCategory);
        handleCloseCategoryDialog();
      }
    }
  }, [currentCategory]);

  const reversCurrentCategory = () => {
    const earlierCategory = allCategories.filter(
      (category) => category.id === currentCategory?.parentId,
    );
    setCurrentCategory(earlierCategory[0]);
  };

  const closeDialog = () => {
    handleCloseCategoryDialog();
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <DialogTitle sx={{ fontSize: 16 }}>Wybierz kategorię</DialogTitle>
        <IconButton onClick={closeDialog} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Stack>
      <DialogContent sx={{ pt: 0, px: { xs: 1.5, md: 3 } }}>
        <Stack>
          {currentCategory && subcategory && (
            <Button
              sx={{ justifyContent: "start" }}
              startIcon={<ArrowBackIosIcon />}
              color="secondary"
              onClick={reversCurrentCategory}
            >
              Cofnij
            </Button>
          )}
          {currentCategory && subcategory
            ? subcategory.map((category) => {
                return (
                  <Button
                    sx={{ justifyContent: "space-between" }}
                    endIcon={<ArrowForwardIosIcon />}
                    color="secondary"
                    key={category.id}
                    onClick={() => setCurrentCategory(category)}
                  >
                    {category.name}
                  </Button>
                );
              })
            : mainCategories?.map((category) => {
                return (
                  <Button
                    sx={{ justifyContent: "space-between" }}
                    endIcon={<ArrowForwardIosIcon />}
                    color="secondary"
                    key={category.id}
                    onClick={() => setCurrentCategory(category)}
                  >
                    {category.name}
                  </Button>
                );
              })}
        </Stack>
      </DialogContent>
    </>
  );
}

export default CategoryOfferDialog;
