import { object, string, array, boolean } from "yup";
import {
  inputMaxLenght,
  inputMinLenght,
  inputRequired,
  patternBank,
  patternTel,
} from "../utils/inputValidate";

const accountFormSchema = (forbiddenWords: string[]) =>
  object({
    username: string().required(inputRequired.message),
    email: string().email().required(inputRequired.message),
    firstName: string()
      .required(inputRequired.message)
      .min(inputMinLenght.value, inputMinLenght.message)
      .max(inputMaxLenght.value, inputMaxLenght.message),
    lastName: string()
      .required(inputRequired.message)
      .min(inputMinLenght.value, inputMinLenght.message)
      .max(inputMaxLenght.value, inputMaxLenght.message),
    phone: string()
      .required(inputRequired.message)
      .matches(patternTel.value, { message: patternTel.message }),
    bankAccount: string()
      .required(inputRequired.message)
      .matches(patternBank.value, { message: patternBank.message })
      .matches(/^(?!00000000000000000000000000)\d{26}$/, {
        message: "Numer konta bankowego jest niepoprawny",
      })
      .matches(/^(?!11111111111111111111111111)\d{26}$/, {
        message: "Numer konta bankowego jest niepoprawny",
      })
      .matches(/^(?!22222222222222222222222222)\d{26}$/, {
        message: "Numer konta bankowego jest niepoprawny",
      })
      .matches(/^(?!33333333333333333333333333)\d{26}$/, {
        message: "Numer konta bankowego jest niepoprawny",
      })
      .matches(/^(?!44444444444444444444444444)\d{26}$/, {
        message: "Numer konta bankowego jest niepoprawny",
      })
      .matches(/^(?!55555555555555555555555555)\d{26}$/, {
        message: "Numer konta bankowego jest niepoprawny",
      })
      .matches(/^(?!66666666666666666666666666)\d{26}$/, {
        message: "Numer konta bankowego jest niepoprawny",
      })
      .matches(/^(?!77777777777777777777777777)\d{26}$/, {
        message: "Numer konta bankowego jest niepoprawny",
      })
      .matches(/^(?!88888888888888888888888888)\d{26}$/, {
        message: "Numer konta bankowego jest niepoprawny",
      })
      .matches(/^(?!99999999999999999999999999)\d{26}$/, {
        message: "Numer konta bankowego jest niepoprawny",
      }),
    street: string()
      .required(inputRequired.message)
      .min(inputMinLenght.value, inputMinLenght.message)
      .max(inputMaxLenght.value, inputMaxLenght.message),
    province: string().required(inputRequired.message),
    postCode: string()
      .required(inputRequired.message)
      .matches(/^\d{2}-\d{3}$/, "Niepoprawny kod pocztowy"),
    city: object().required(inputRequired.message),
    about: string().test({
      name: "forbidden-words",
      message: "Wprowadzono niedozwolone wyrazy",
      test: (value) => {
        if (value) {
          const textArray = value.split(/\s+/);
          const isForbiddenWords = textArray.some((word) =>
            forbiddenWords.some((forbidden: string) => forbidden === word),
          );
          return !isForbiddenWords;
        }
        return true;
      },
    }),
    haveChild: boolean(),
    children: array().when("haveChild", {
      is: true,
      then: (schema) =>
        schema.of(
          object().shape({
            name: string().nullable(),
            isComing: boolean(),
            birthDate: string().when("isComing", {
              is: (isComing: boolean) => !isComing,
              then: (schemaBirthDate) =>
                schemaBirthDate.required("Pole wymagane"),
            }),
            gender: string(),
          }),
        ),
    }),
  });

export default accountFormSchema;
