import { Box, SxProps, Theme, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import EntryUserDialog from "../molecules/dialog/EntryUserDialog";
import useUserData from "../../hooks/useUserData";

const styles: SxProps<Theme> = (theme: Theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "6px",
  padding: { xs: 2, md: 4 },
  mb: 5.5,
  width: 1,
});

function ParentAssistantOffersPlaceholder() {
  const navigate = useNavigate();
  const { userData } = useUserData();

  const [openEntryUserDialog, setOpenEntryUserDialog] =
    useState<boolean>(false);

  const handleGoToProfile = () => {
    if (userData) {
      navigate("/account/profile");
    } else {
      setOpenEntryUserDialog(true);
    }
  };

  return (
    <Box>
      <EntryUserDialog
        initialOpen={openEntryUserDialog}
        handleClose={() => {
          setOpenEntryUserDialog(false);
        }}
      />
      <Typography variant="h4" sx={{ fontWeight: 700, mb: 3 }}>
        Propozycje dla Twojego dziecka
      </Typography>
      <Box sx={styles}>
        <Typography sx={{ fontWeight: 500 }}>
          Tutaj wyświetlimy rzeczy dostosowane do wieku i płci Twojego dziecka
        </Typography>
        <Box
          size="small"
          component={Button}
          onClick={handleGoToProfile}
          sx={{ width: "max-content" }}
        >
          <Typography
            sx={{ p: { xs: 1, md: 2 }, fontWeight: 600 }}
            color="primary.main"
          >
            Przejdź do PROFILU i uzupełnij dane
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ParentAssistantOffersPlaceholder;
