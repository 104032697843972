import React from "react";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { ParentAssistantOffersInterface } from "../../models/ParentAssistantOffersInterface";
import OffersCarousel from "../organisms/OffersCarousel";

type Props = {
  parentAssistantOffers: ParentAssistantOffersInterface[];
};

function ParentAssistantShortOffer({ parentAssistantOffers }: Props) {
  const getShowMoreLink = (
    parentAssistantOffer: ParentAssistantOffersInterface,
  ) => `/propozycje/${parentAssistantOffer.assistantCode}`;

  return (
    <>
      {parentAssistantOffers.map((parentAssistantOffer) => (
        <Stack key={parentAssistantOffer.assistantCode}>
          <OffersCarousel
            key={parentAssistantOffer.assistantCode}
            title={parentAssistantOffer.childOffersTitle}
            offers={parentAssistantOffer.offers.items}
            showMoreLink={getShowMoreLink(parentAssistantOffer)}
          />
          <Button
            component={Link}
            to={`/propozycje/${parentAssistantOffer.assistantCode}`}
            sx={{
              margin: "auto",
              textTransform: "unset",
              my: { xs: 1, md: 2 },
            }}
            color="secondary"
            size="large"
            endIcon={
              <ArrowForwardIosIcon
                sx={(theme) => ({ color: theme.palette.secondary.light })}
              />
            }
          >
            Pokaż więcej
          </Button>
        </Stack>
      ))}
    </>
  );
}

export default ParentAssistantShortOffer;
