import { useQuery } from "@tanstack/react-query";
import { getOwnedSoldOffersList } from "../../../client/Requests";
import useUserData from "../../useUserData";

export const useOwnedSoldOffersList = (page: number = 1) => {
  const { userData } = useUserData();
  const userToken = userData?.userToken;

  const { data, isLoading } = useQuery<any>({
    queryKey: ["owned-sold-offers-list", page],
    queryFn: () =>
      userToken
        ? getOwnedSoldOffersList(userToken, page - 1).then((res) => res.data)
        : null,
    enabled: !!userToken,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  return {
    offers: data,
    isLoading,
  };
};
