import { MouseEvent, useRef, useState } from "react";

export const useScrollOnDrag = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current) {
      return;
    }

    event.preventDefault();

    setIsMouseDown(true);
    setStartX(event.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    if (!isMouseDown || !containerRef.current) {
      return;
    }

    const x = event.pageX - containerRef.current.offsetLeft;

    const distance = x - startX;
    if (Math.abs(distance) > 3) {
      setIsDragging(true);
    }
    containerRef.current.scrollLeft = scrollLeft - distance;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsMouseDown(false);
  };

  return {
    containerRef,
    styles: {
      overflowY: "auto",
      whiteSpace: "nowrap",
      cursor: isDragging ? "grabbing" : "grab",
    },
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isDragging,
  };
};
