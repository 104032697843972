interface IconInterface {
  icon: string;
  filled?: boolean;
  className?: string;
}

function Icon({ icon, filled, className }: IconInterface) {
  return (
    <div className={`flex rounded-full hover:bg-gray-100 ${className}`}>
      <span className={filled ? "material-icons" : "material-icons-outlined"}>
        {icon}
      </span>
    </div>
  );
}

export default Icon;
