interface IconInterface {
  icon: string;
  filled?: boolean;
  active?: boolean;
  size?: "small" | "medium" | "big";
  className?: string;
  type?: "button" | "submit" | "reset";
}

function IconButton({
  icon,
  filled,
  active,
  size,
  className,
  type = "button",
}: IconInterface) {
  const actived = active ? "text-text-primary" : "text-light-action-hover";
  let iconSize;

  switch (size) {
    case "big":
      iconSize = "text-[24px]";
      break;
    case "medium":
      iconSize = "text-[18px]";
      break;
    case "small":
      iconSize = "text-[14px]";
      break;
    default:
      iconSize = "text-[18px]";
      break;
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`flex items-center ${iconSize} ${actived} ${className}`}
    >
      <span
        className={`${
          filled ? "material-icons" : "material-icons-outlined"
        } ${iconSize} rounded-full hover:bg-gray-100 cursor-pointer`}
      >
        {icon}
      </span>
    </button>
  );
}

export default IconButton;
