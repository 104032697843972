import { useContext, useEffect, useState } from "react";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";

import { IOfferContext } from "../../contexts/offerProvider/types";
import PriceInput from "../inputs/PriceInput";

function PriceFrom() {
  const { handleChangeMinPrice, filters } = useContext(
    OfferContext,
  ) as IOfferContext;
  const [amount, setAmout] = useState<string>(
    filters.minPrice?.toString() ?? "",
  );

  useEffect(() => {
    if (amount && !filters.minPrice) {
      setAmout("");
    }
  }, [filters.minPrice]);

  const handleChangePrice = (_name: string, value: string) => {
    if (value !== amount) {
      handleChangeMinPrice(value);
      setAmout(value);
    }
  };

  return (
    <PriceInput
      label="Cena od"
      relatedValue={filters.minPrice?.toString() ?? ""}
      onChange={handleChangePrice}
      changeOnBlur
    />
  );
}

export default PriceFrom;
