import { twMerge } from "tailwind-merge";

interface ButtonOutlinedInterface {
  text: string;
  buttonType?: "submit" | "reset" | "button";
  className?: string;
  onClick?: () => void;
  active?: boolean;
}

function MenuItem({
  buttonType,
  active = false,
  text,
  className,
  onClick,
}: ButtonOutlinedInterface) {
  const classes =
    twMerge(`w-[100%] text-left transition ease-in-out duration-300 py-3 px-4 hover:bg-light-action-hover text-text-primary ${
      active && "bg-light-primary-shades"
    } ${className ?? ""}
    `);

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={buttonType ?? "button"}
      className={classes}
      onClick={onClick}
    >
      <span>{text}</span>
    </button>
  );
}

export default MenuItem;
