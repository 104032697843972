import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { City, Province, getCustomHotProductsOffer } from "../client/Requests";
import { BasicOfferFiltersInterface } from "../models/OffersFiltersInterface";
import { builBasicOfferdApiUrlParams } from "../utils/paramUtils";
import { HotOfferList } from "../models/HotOfferInterface";

export const useHotOfferList = (
  filters: BasicOfferFiltersInterface,
  provinces: Province[],
  cities: City[],
) => {
  const { hotOfferId } = useParams();
  const isProvinceDataComplete = filters.province ? !!provinces.length : true;
  const isCityDataComplete = filters.city ? !!cities.length : true;
  const locationDataIsValid = isProvinceDataComplete && isCityDataComplete;

  const getApiParams = () => {
    return builBasicOfferdApiUrlParams(filters, provinces, cities);
  };

  const { data, isLoading } = useQuery<HotOfferList>({
    queryKey: ["on-time-offer", hotOfferId, `${JSON.stringify(filters)}`],
    queryFn: hotOfferId
      ? () => getCustomHotProductsOffer(hotOfferId, getApiParams())
      : undefined,
    refetchOnWindowFocus: false,
    enabled: locationDataIsValid && !!hotOfferId,
    retry: 3,
  });

  return {
    hotOfferList: data,
    isLoading,
  };
};
