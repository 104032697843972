import { useOwnedBoughtOffersList } from "./fetchHooks/transactions/useOwnedBoughtOffersList";
import { useOwnedBoughtOffersListPaid } from "./fetchHooks/transactions/useOwnedBoughtOffersListPaid";
import { useOwnedPublishedOffersList } from "./fetchHooks/transactions/useOwnedPublishedOffersList";
import { useOwnedPublishedOffersListInactive } from "./fetchHooks/transactions/useOwnedPublishedOffersListInactive";
import { useOwnedSoldOffersList } from "./fetchHooks/transactions/useOwnedSoldOffersList";

export const useTransactionsCounters = () => {
  const { offers: publishedOffers } = useOwnedPublishedOffersList();
  const { offers: ownedPublishedOffers } =
    useOwnedPublishedOffersListInactive();

  const { offers: ownedSoldOffers } = useOwnedSoldOffersList();

  const { offers: ownedBoughtPaidOffers } = useOwnedBoughtOffersListPaid();
  const { offers: ownedBoughtOffers } = useOwnedBoughtOffersList();

  const sellingCount =
    parseInt(publishedOffers?.count, 10) +
    parseInt(ownedPublishedOffers?.count, 10);

  const soldCount = parseInt(ownedSoldOffers?.count, 10);

  const buyingCount =
    parseInt(ownedBoughtPaidOffers?.count, 10) +
    parseInt(ownedBoughtOffers?.count, 10);

  return {
    sellingCount,
    soldCount,
    buyingCount,
  };
};
