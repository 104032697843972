import { Link } from "react-router-dom";
import { BasketItemInterface } from "../../models/BasketItemInterface";
import Typography from "./Typography";
import { getFormattedPrice } from "../../utils/PriceUtils";
import { BasketSellerInterface } from "../../models/BasketSellerInterface";

interface BasketItemViewInterface {
  item: BasketItemInterface;
  basketSeller: BasketSellerInterface;
  handleDeleteItem: any;
  sellerItemsCount: number;
}

function BasketItem({
  item,
  basketSeller,
  handleDeleteItem,
  sellerItemsCount,
}: BasketItemViewInterface) {
  return (
    <div className="w-full py-2">
      <div className="w-full flex space-x-2">
        <div className="w-28 h-28">
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={item.imageUrl}
            alt="Product photo"
            className="w-full h-full rounded-md"
          />
        </div>
        <div className="w-full flex justify-between">
          <div>
            <Typography type="body3" text={item.name} />
            <Typography
              type="profile"
              text={`Cena: ${getFormattedPrice(item.price)}`}
              className="font-bold"
            />
            <div className="flex space-x-2">
              <Typography type="captions" text="Dostawa" />
              {item.shipping?.methodLabel ? (
                <Typography
                  type="captions"
                  text={`(${item.shipping?.methodLabel})`}
                />
              ) : null}
              {item.shipping?.price ? (
                <Typography
                  type="captions"
                  text={`${getFormattedPrice(item.shipping?.price)}`}
                />
              ) : null}
              {basketSeller.shippingStatus === "ready" &&
              sellerItemsCount > 1 ? (
                <Typography
                  type="captions"
                  text="potwierdzona"
                  className="font-bold text-primary-main"
                />
              ) : null}
              {basketSeller.shippingStatus === "pending" && (
                <Typography
                  type="captions"
                  text="w trakcie potwierdzenia"
                  className="font-bold text-warning-main"
                />
              )}
              {basketSeller.shippingStatus === "required" && (
                <Typography
                  type="captions"
                  text="do potwierdzenia"
                  className="font-bold text-warning-main"
                />
              )}
              {basketSeller.shippingStatus === "canceled" && (
                <Typography
                  type="captions"
                  text="anulowano"
                  className="font-bold text-red-600"
                />
              )}
            </div>
            <Typography
              type="captions"
              text={`Prowizja: ${getFormattedPrice(item.commissionValue)}`}
            />
            {item.isAvailable ? (
              <Link to={`/oferta/${item.slug}`}>
                <Typography
                  type="body2"
                  text="Zobacz szczegóły"
                  className="underline mt-2 cursor-pointer text-primary-main"
                />
              </Link>
            ) : (
              <Typography
                type="captions"
                text="Niedostępny"
                className="text-red-600"
              />
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              handleDeleteItem(item.productOfferId);
            }}
          >
            <span className="material-icons-outlined">close</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default BasketItem;
