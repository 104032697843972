import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CreateOffer2 from "./CreateOffer";
import useUserData from "../../hooks/useUserData";
import {
  City,
  Province,
  getCategories,
  getOfferEdit,
} from "../../client/Requests";
import { ProductCategoryInterface } from "../../models/ProductCategoryInterface";
import { convertPriceToString } from "../../utils/PriceUtils";
import useFiltersLocations from "../../hooks/useFiltersLocations";

function EditOffer() {
  const { id: offerId } = useParams();
  const { userData } = useUserData();
  const [offer, setOffer] = useState<any>();
  const [initialProvince, setInitialProvince] = useState<Province>();
  const [initialCity, setInitialCity] = useState<City>();
  const { provinces, cities } = useFiltersLocations(initialProvince?.name);
  const [categories, setCategories] = useState<
    ProductCategoryInterface[] | null
  >(null);

  const initalCategory = useMemo(() => {
    if (categories?.length && !!offer?.categoryName) {
      return categories.find(
        (category) => category.name === offer.categoryName,
      );
    }
    return null;
  }, [categories, offer?.categoryName]);

  useEffect(() => {
    const provinceData = provinces.find(
      (province) => province.id === offer?.provinceId,
    );
    setInitialProvince(provinceData);
  }, [provinces, offer?.provinceId]);

  useEffect(() => {
    const cityData = cities.find((city) => city.id === offer?.cityId);
    setInitialCity(cityData);
  }, [cities]);

  useEffect(() => {
    if (offerId && userData?.userToken) {
      getOfferEdit(userData?.userToken, offerId).then((value) =>
        setOffer(value),
      );
    }
    getCategories().then((value: ProductCategoryInterface[]) =>
      setCategories(value),
    );
  }, [userData]);

  if (!offer || !initialProvince || !initialCity || !initalCategory) {
    return null;
  }

  return (
    <CreateOffer2
      initialData={{
        name: offer.name,
        description: offer.description,
        price: convertPriceToString(offer.price) ?? "",
        province: initialProvince,
        city: initialCity,
        shipping: offer.shipping.methodName,
        shippingCustomName: offer.shipping.shippingName,
        shippingCustomPrice: convertPriceToString(offer.shipping.price) ?? "",
        inpostMethod: offer.shipping.choiceName,
        personalAvailable: offer.shipping.personalAvailable,
        category: initalCategory,
        attributesDefaultValuesIds: offer.attributes.map(
          (attribute: any) => attribute.valueId,
        ),
        offerImages: offer.images,
        offerId: offer.id,
      }}
      slug={offer?.slug}
    />
  );
}

export default EditOffer;
