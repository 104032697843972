import { Typography } from "@mui/material";
import DefaultLayout from "../components/layout/DefaultLayout";

function Notifications() {
  return (
    <DefaultLayout>
      <Typography variant="h3" component="h1" sx={{ fontWeight: 700 }}>
        Powiadomienia
      </Typography>
      <Typography sx={{ alignSelf: "center" }}>Brak powiadomień</Typography>
    </DefaultLayout>
  );
}

export default Notifications;
