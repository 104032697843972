import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CustomTextField } from "../../atoms/Inputs/InputMUI";
import { errorAdornment } from "./const";

function AboutData() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const { control } = useFormContext();

  return (
    <>
      <Typography
        variant="h5"
        component="h4"
        sx={{ fontWeight: 700, lineHeight: 1.4, mb: 3 }}
      >
        O mnie
        <Typography component="span" sx={{ ml: 1 }}>
          (opcjonalnie)
        </Typography>
      </Typography>
      <Box sx={{ width: 1, mb: 3 }}>
        <Controller
          control={control}
          name="about"
          render={({
            field: { ref, value, onChange },
            fieldState: { invalid, error },
          }) => (
            <CustomTextField
              sx={{ width: 1 }}
              placeholder="{opis o mnie}"
              variant="standard"
              inputRef={ref}
              value={value}
              onChange={onChange}
              error={invalid}
              rows={4}
              multiline
              helperText={error?.message}
              InputProps={invalid ? { endAdornment: errorAdornment } : {}}
            />
          )}
        />
      </Box>
    </>
  );
}

export default AboutData;
