import { Stack } from "@mui/system";
import { Box, Button, Divider, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { IOfferContext } from "../../contexts/offerProvider/types";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";
import FilterAttributes from "../offerCatalog/filterAttributes/FilterAttributes";
import FilterProvince from "../offerCatalog/filterLocation/FilterProvince";
import FilterCity from "../offerCatalog/filterLocation/FilterCity";
import FilterSort from "../offerCatalog/filterSort/FilterSort";
import PriceFrom from "./PriceFrom";
import PriceTo from "./PriceTo";
import Chips from "./Chips";
import { getFiltersCount } from "./utils";
import ClearAllFilters from "./ClearAllFilters";

function CatalogFilters() {
  const { filters, productTemplateAttributes } = useContext(
    OfferContext,
  ) as IOfferContext;
  const initialOpenState = Boolean(
    filters.attributes.length ||
      filters.province ||
      filters.city ||
      filters.minPrice ||
      filters.maxPrice,
  );

  const [openFilters, setOpenFilters] = useState<Boolean>(initialOpenState);
  const filtersCount = getFiltersCount(filters);

  useEffect(() => {
    if (!openFilters) {
      const shouldOpenFilters = Boolean(
        filters.attributes.length ||
          filters.province ||
          filters.city ||
          filters.minPrice ||
          filters.maxPrice,
      );
      if (shouldOpenFilters) {
        setOpenFilters(true);
      }
    }
  }, [filters]);

  return (
    <Box mb={2}>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "baseline",
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="outlined"
          sx={{ mb: 2 }}
          onClick={() => {
            setOpenFilters(!openFilters);
          }}
        >
          Filtry{filtersCount && ` (${filtersCount})`}
        </Button>
        <FilterSort />
      </Stack>
      <Divider sx={{ mb: 3 }} />

      {openFilters && (
        <>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FilterProvince />
            </Grid>
            {filters.province && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FilterCity />
              </Grid>
            )}
            <Grid item xs={12} sm={3} md={2}>
              <PriceFrom />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <PriceTo />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            {productTemplateAttributes?.map((productTemplateAttribute) => (
              <Grid
                item
                key={productTemplateAttribute.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <FilterAttributes
                  productTemplateAttribute={productTemplateAttribute}
                />
              </Grid>
            ))}
          </Grid>
          <Box display="flex" justifyContent="space-between" alignItems="start">
            <Chips />
            <ClearAllFilters />
          </Box>
        </>
      )}
    </Box>
  );
}

export default CatalogFilters;
