import { createContext, useEffect, useMemo, useState } from "react";
import useUserData from "../../hooks/useUserData";
import { IBasketProvider } from "./types";
import { sendGetBasketRequest } from "../../client/Requests";
import { BasketInterface } from "../../models/BasketInterface";
import { InpostDetailsInterface } from "../../models/InpostDetailsInterface";

export const BasketContext = createContext<IBasketProvider | null>(null);

function BasketProvider({ children }: React.PropsWithChildren<{}>) {
  const { userData } = useUserData();
  const [basket, setBasket] = useState<BasketInterface | null>(null);
  const [parcelLocerPoint, setParcelLocerPoint] =
    useState<InpostDetailsInterface | null>(null);

  const handleFetchBasket = async () => {
    if (userData) {
      try {
        const basketData = await sendGetBasketRequest(userData?.userToken);
        setBasket(basketData);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    handleFetchBasket();
  }, [userData?.userToken]);

  const handleRefeth = () => {
    handleFetchBasket();
  };

  const handleChangeParcelLocker = (
    newParcelLocerPoint: InpostDetailsInterface,
  ) => {
    setParcelLocerPoint(newParcelLocerPoint);
  };

  const basketProviderValue = useMemo(
    () => ({
      handleChangeParcelLocker,
      parcelLocerPoint,
      basket,
      refetch: handleRefeth,
    }),
    [basket, parcelLocerPoint],
  );

  return (
    <BasketContext.Provider value={basketProviderValue}>
      {children}
    </BasketContext.Provider>
  );
}

export default BasketProvider;
