/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Typography,
  CircularProgress,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import DefaultLayout from "../components/layout/DefaultLayout";
import SectionLayoutContent from "../components/layout/SectionLayoutContent";
import { requestNewPasswordSchema } from "../schemas/requestNewPasswordSchema";
import InputMUI from "../components/atoms/Inputs/InputMUI";
import { fetchRequestResetPassword } from "../client/Requests";
import PageTitle from "../components/atoms/PageTitle";
import SectionLayout from "../components/layout/SectionLayout";

function ForgotPassword() {
  const [openAlert, setOpenAlert] = useState(false);

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: (email: string) => fetchRequestResetPassword(email),
  });

  const { handleSubmit, control } = useForm<any>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(requestNewPasswordSchema()),
  });

  useEffect(() => {
    if (isSuccess || isError) {
      setOpenAlert(true);
    }
  }, [isSuccess, isError]);

  const onSubmit = (data: { email: string }) => {
    mutate(data.email);
  };

  return (
    <SectionLayout
      defaultLayoutProps={{ width: 500 }}
      sectionTitle="Resetuj hasło"
    >
      <SectionLayoutContent styles={{ p: 3 }}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <>
            <InputMUI
              control={control}
              name="email"
              placeholder="E-mail"
              type="email"
            />

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              sx={{ width: 1, mt: 4, mb: 2, borderRadius: 5 }}
              {...(isLoading && {
                endIcon: (
                  <CircularProgress color="inherit" size={24} sx={{ ml: 2 }} />
                ),
              })}
            >
              Zapomniałem hasła
            </Button>
            <Collapse in={openAlert}>
              {isSuccess && (
                <Alert
                  severity="success"
                  onClose={() => {
                    setOpenAlert(false);
                  }}
                >
                  Na podany adres e-mail wysłaliśmy link. Proszę kliknąć w link,
                  a następnie podać nowe hasło.
                </Alert>
              )}
              {isError && (
                <Alert
                  severity="error"
                  onClose={() => {
                    setOpenAlert(false);
                  }}
                >
                  Coś poszło nie tak. Spróbuj ponownie później.
                </Alert>
              )}
            </Collapse>
          </>
          <Box
            sx={{
              display: "flex",
              width: 1,
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Link to="/login">
              <Typography
                variant="body1"
                color="primary.main"
                sx={{ fontWeight: 600 }}
              >
                Wróc do logowania
              </Typography>
            </Link>
          </Box>
        </Box>
      </SectionLayoutContent>
    </SectionLayout>
  );
}

export default ForgotPassword;
