/* eslint-disable import/order */
import { Controller, useFormContext } from "react-hook-form";
import {
  Chip,
  FormControl,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import SpippingFormLabel from "./SpippingFormLabel";
import { ShippingMethodInterface } from "../../../../models/ShippingMethodInterface";
import { getFormattedPrice } from "../../../../utils/PriceUtils";

interface Props {
  inpostMethod: ShippingMethodInterface;
}

function ShippingInpostMethod({ inpostMethod }: Props) {
  const { control } = useFormContext();

  const choices = inpostMethod?.choices;

  if (!choices) {
    return null;
  }

  return (
    <Stack sx={{ flex: "1" }}>
      <Controller
        control={control}
        name="inpostMethod"
        render={({ field: { name, onChange, value } }) => (
          <FormControl>
            <RadioGroup name={name} onChange={onChange} value={value}>
              {choices.map((choice) => (
                <SpippingFormLabel
                  key={choice.name}
                  value={choice.name}
                  sx={{ px: 3 }}
                  label={
                    <Stack sx={{ alignItems: "flex-start" }}>
                      <Typography fontWeight={600}>
                        {`${choice?.second_label} (${choice?.label})`}
                      </Typography>
                      <Typography component="span" variant="caption">
                        {choice?.details}
                      </Typography>
                      <Chip
                        label={getFormattedPrice(choice?.price)}
                        size="small"
                        sx={{ fontWeight: 600, fontSize: 12, mt: 1 }}
                      />
                    </Stack>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
    </Stack>
  );
}

export default ShippingInpostMethod;
