/* eslint-disable no-restricted-syntax */
import { ProductTemplateAttributeInterface } from "../models/ProductTemplateAttributeInterface";
import {
  AttributeMutable,
  MultipleChoiceAttributesFields,
  SingleChoiceAttributes,
} from "../pages/offers/types";
import { swapToPennies } from "./PriceUtils";

const getSingleAttributesMutable = (
  attributesSingle: SingleChoiceAttributes,
  productAttributes: ProductTemplateAttributeInterface[],
): AttributeMutable => {
  let attributesSingleDataMutable: AttributeMutable = {};

  Object.keys(attributesSingle).forEach((attributeName) => {
    const productAttributeData = productAttributes.find(
      (productAttribute) => productAttribute.name === attributeName,
    );

    const selectedAttribute = attributesSingle[attributeName];

    const attributeId = productAttributeData?.id;
    if (attributeId && selectedAttribute) {
      attributesSingleDataMutable = {
        ...attributesSingleDataMutable,
        [attributeId]: [selectedAttribute.id],
      };
    }
  });

  return attributesSingleDataMutable;
};

const getMultipleAttributesMutable = (
  attributesMultiple: MultipleChoiceAttributesFields,
  productAttributes: ProductTemplateAttributeInterface[],
): AttributeMutable => {
  let attributesMultipleDataMutable: AttributeMutable = {};

  Object.keys(attributesMultiple).forEach((attributeName) => {
    const productAttributeData = productAttributes.find(
      (productAttribute) => productAttribute.name === attributeName,
    );

    const attributeId = productAttributeData?.id;

    const selectedAttributesIds = attributesMultiple[attributeName]?.map(
      (selectedAttribute) => selectedAttribute.id,
    );

    if (attributeId && selectedAttributesIds.length) {
      attributesMultipleDataMutable = {
        ...attributesMultipleDataMutable,
        [attributeId]: selectedAttributesIds,
      };
    }
  });

  return attributesMultipleDataMutable;
};

export const getOfferDataAttributes = (
  data: any,
  productAttributes: ProductTemplateAttributeInterface[] | null,
) => {
  if (!productAttributes) {
    return undefined;
  }

  const { attributesSingle, attributesMultiple } = data;
  let attributes: AttributeMutable = {};

  if (attributesSingle) {
    const singleAttributesMutable = getSingleAttributesMutable(
      attributesSingle,
      productAttributes,
    );

    attributes = { ...attributes, ...singleAttributesMutable };
  }

  if (attributesMultiple) {
    const multipleAttributesMutable = getMultipleAttributesMutable(
      attributesMultiple,
      productAttributes,
    );

    attributes = { ...attributes, ...multipleAttributesMutable };
  }

  if (Object.keys(attributes).length) {
    return attributes;
  }

  return undefined;
};

export const mutateOfferData = (
  data: any,
  productAttributes: ProductTemplateAttributeInterface[] | null,
  isEditAction?: boolean,
) => {
  return {
    ...(isEditAction && { productCategoryId: data.category.id }),
    name: data.name.trim(),
    description: data.description.trim(),
    price: swapToPennies(data.price.replace(/,/g, ".")),
    provinceId: data.province.id,
    cityId: data.city.id,
    attributes:
      data.attributesSingle || data.attributesMultiple
        ? getOfferDataAttributes(data, productAttributes)
        : undefined,
    shipping: {
      methodName: data.shipping,
      ...(data.shipping === "inpost" && {
        choiceName: data.inpostMethod,
      }),
      ...(data.shipping === "other" && {
        customName: data.customName,
      }),
      ...(data.shipping === "other" && {
        customPrice: swapToPennies(data.customPrice.replace(/,/g, ".")),
      }),
      personalAvailable: data.personalAvailable,
    },
  };
};
