import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Button, Typography as MUITypography } from "@mui/material";
import { sendLoginUserRequest } from "../client/Requests";
import useUserData from "../hooks/useUserData";
import { inputEmailRegex, inputRequired } from "../utils/inputValidate";
import InputMUI from "../components/atoms/Inputs/InputMUI";
import SocialButtons from "../components/organisms/SocialButtons";
import SectionLayoutContent from "../components/layout/SectionLayoutContent";
import SectionLayout from "../components/layout/SectionLayout";

function Login() {
  const { setUserData } = useUserData();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      agreeRegulations: false,
      agreeMarketing: false,
    },
  });
  const [searchParams] = useSearchParams();

  const onSubmit = async (data: any) => {
    await sendLoginUserRequest(JSON.stringify(data))
      .then((response) => {
        if (response.status === 200) {
          setUserData(response.data);
          navigate("/");
          navigate(0);
        } else {
          setError("Błędne dane lub użytkownik nie istnieje");
        }
      })
      .catch((errorResponse) => {
        const { response } = errorResponse;
        if (response.status === 400 && response.data.type === "InvalidData") {
          setError(response.data.message);
        } else {
          setError("Błędne dane lub użytkownik nie istnieje");
        }
      });
  };

  useEffect(() => {
    if (searchParams.get("registered") === "1") {
      setMessage(
        "Na Twojego maila wysłaliśmy link aktywacyjny. Dokończ rejestrację aktywując konto.",
      );
    }
  }, [searchParams]);

  return (
    <SectionLayout defaultLayoutProps={{ width: 500 }} sectionTitle="Logowanie">
      <SectionLayoutContent styles={{ p: 3 }}>
        <>
          <SocialButtons />
          <div>
            <div className="text-center my-10">lub</div>
            <form
              className="space-y-6 ng-untouched ng-pristine ng-valid"
              onSubmit={handleSubmit(onSubmit)}
            >
              {error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              {message && (
                <div
                  className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{message}</span>
                </div>
              )}
              <div>
                <InputMUI
                  control={control}
                  name="email"
                  placeholder="Email użytkownika"
                  rules={{
                    required: inputRequired,
                    pattern: inputEmailRegex,
                  }}
                />
              </div>
              <div>
                <InputMUI
                  control={control}
                  name="password"
                  placeholder="Hasło"
                  rules={{
                    required: inputRequired,
                  }}
                  type="password"
                />
              </div>
              <div>
                Nie masz konta?{" "}
                <Link to="/register">
                  {/* <Typography
                      type="text"
                      text="Zarejestruj się"
                      className="underline cursor-pointer text-primary-dark"
                    /> */}
                  <MUITypography
                    component="span"
                    variant="body1"
                    color="primary.main"
                    sx={{ fontWeight: 600 }}
                  >
                    Zarejestruj się
                  </MUITypography>
                </Link>
              </div>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                sx={{ width: 1, my: 4, borderRadius: 5 }}
              >
                Zaloguj
              </Button>
            </form>
            <Box
              sx={{
                display: "flex",
                width: 1,
                justifyContent: "center",
                mt: 3,
              }}
            >
              <Link to="/zapomnialem-hasla">
                <MUITypography
                  variant="body1"
                  color="primary.main"
                  sx={{ fontWeight: 600 }}
                >
                  Zapomniałem hasła
                </MUITypography>
              </Link>
            </Box>
          </div>
        </>
      </SectionLayoutContent>
    </SectionLayout>
  );
}

export default Login;
