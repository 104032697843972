import { Avatar } from "@mui/material";

function UserAvatar({
  username,
  avatarPath,
}: {
  username: string;
  avatarPath?: string | null;
}) {
  return (
    <Avatar
      sx={{ width: "64px", height: "64px", mr: 2.5 }}
      alt="avatar"
      src={
        avatarPath ||
        `https://ui-avatars.com/api/?background=B9BCC3&color=FFF&size=24&font-size=0.8&name=${username}`
      }
    />
  );
}

export default UserAvatar;
