import { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OffersCarousel from "../organisms/OffersCarousel";
import { getCustomHotProductsOffers } from "../../client/Requests";
import { HotOffer } from "../../models/HotOfferInterface";

function HotOfferCarousel() {
  const [hotOffers, setHotOffers] = useState<HotOffer[]>([]);
  const getShowMoreLink = (hotOffer: HotOffer) =>
    `/propozycje-na-czasie/${hotOffer.id}`;

  useEffect(() => {
    getCustomHotProductsOffers().then((data) => {
      setHotOffers(data.data);
    });
  }, []);

  if (!hotOffers.length) {
    return null;
  }

  return (
    <>
      {hotOffers.map((hotOffer) => (
        <Stack key={hotOffer.id}>
          <OffersCarousel
            avatar={hotOffer.avatarPath}
            title={hotOffer.name}
            offers={hotOffer.items}
            showMoreLink={getShowMoreLink(hotOffer)}
          />
          <Button
            component={Link}
            to={getShowMoreLink(hotOffer)}
            sx={{
              margin: "auto",
              textTransform: "unset",
              my: { xs: 1, md: 2 },
            }}
            color="secondary"
            size="large"
            endIcon={
              <ArrowForwardIosIcon
                sx={(theme) => ({ color: theme.palette.secondary.light })}
              />
            }
          >
            Pokaż więcej
          </Button>
        </Stack>
      ))}
    </>
  );
}

export default HotOfferCarousel;
