import { Box, Button, Link, Stack, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { BreadCrumbStepInterface } from "../../models/BreadCrumbStepInterface";

interface IBreadCrumbs {
  steps: BreadCrumbStepInterface[];
  onCategoryChange?: (arg0: number) => void;
}

function BreadCrumbs({ steps, onCategoryChange }: IBreadCrumbs) {
  const mainPageStep: BreadCrumbStepInterface = {
    url: "/",
    name: "Strona główna",
  };
  const stepWithMainPage = [mainPageStep, ...steps];

  const handleCategoryChange = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    step: BreadCrumbStepInterface,
  ) => {
    e.preventDefault();

    if (step.categoryId) {
      onCategoryChange?.(step?.categoryId);
    }
  };

  return (
    <Stack
      sx={{ alignItems: "center", my: 1, flexWrap: "wrap" }}
      direction="row"
    >
      {stepWithMainPage.map((step, index) => {
        const isLast = index === stepWithMainPage.length - 1;
        return (
          <Box
            key={step.name}
            component="span"
            sx={{ display: "flex", flexWrap: "nowrap", py: 0.5 }}
          >
            {step.categoryId ? (
              <Typography
                sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                onClick={(e) => handleCategoryChange(e, step)}
                color={isLast ? "secondary.main" : "secondary.light"}
              >
                {step.name}
              </Typography>
            ) : (
              <Link
                sx={{ whiteSpace: "nowrap" }}
                underline="none"
                href={step.url}
                color={isLast ? "secondary.main" : "secondary.light"}
              >
                {step.name}
              </Link>
            )}
            {!isLast ? (
              <ChevronRightIcon sx={{ mx: 1, color: "secondary.light" }} />
            ) : null}
          </Box>
        );
      })}
    </Stack>
  );
}

export default BreadCrumbs;
