import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useEffect, useState } from "react";

type EntryUserDialogProps = {
  initialOpen: boolean;
  handleYes: any;
  handleClose: any;
};

function DeleteUserDialog({
  initialOpen,
  handleYes,
  handleClose,
}: EntryUserDialogProps) {
  const [open, setOpen] = useState(initialOpen);

  useEffect(() => {
    setOpen(initialOpen);
  }, [initialOpen]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Czy na pewno chcesz usunąć konto?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Nie</Button>
        <Button onClick={handleYes} variant="contained" autoFocus>
          Tak
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteUserDialog;
