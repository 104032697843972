import { Avatar, Box, Button, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import Typography from "../../../atoms/Typography";
import MessagePanelItemOwner from "./MessagePanelItemOwner";
import MessagePanelItemUser from "./MessagePanelItemUser";
import { formatPriceNumber } from "../../../../utils/PriceUtils";
import { MessagesContext } from "../../../../contexts/MessagesProvider/MessagesProvider";
import { IMessageContext } from "../../../../contexts/MessagesProvider/types";
import { UserContext } from "../../../../contexts/UserProvider";
import { UserContextType } from "../../../../types/UserContextType";

function MessagePanelItem() {
  const { userProfile } = useContext(UserContext) as UserContextType;
  const {
    productOffer,
    priceProposal,
    messageBasketShipping,
    currentMessageThreadData,
  } = useContext(MessagesContext) as IMessageContext;
  const navigate = useNavigate();

  if (messageBasketShipping) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{
            p: 2,
            maxHeight: 170,
            overflow: "scroll",
          }}
        >
          {messageBasketShipping.items.map((item) => (
            <Stack key={item.id} direction="row">
              <Avatar
                alt="item"
                src={item?.imagePath ?? undefined}
                sx={{ width: 48, height: 48 }}
                variant="rounded"
              />
              <div className="ml-2">
                <Typography
                  type="body2-b"
                  text={item?.name ? item.name : ""}
                  className="text-primary"
                />
                <Link to={`/oferta/${item.slug}`}>
                  <Typography
                    type="body1"
                    text="Zobacz szczegóły"
                    className="text-secondary underline underline-offset-2"
                  />
                </Link>
              </div>
            </Stack>
          ))}
        </Stack>
        {messageBasketShipping.shippingStatus === "ready" &&
        messageBasketShipping.isSent &&
        !currentMessageThreadData?.isClosed ? (
          <Button
            size="large"
            variant="contained"
            sx={{ textTransform: "initial", m: 2 }}
            onClick={() => navigate("/basket")}
          >
            Przejdź do koszyka i dokończ zakupy
          </Button>
        ) : null}
      </Box>
    );
  }

  return (
    <div className="p-4 border-b">
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 justify-between">
        <div className="flex">
          <Avatar
            alt="item"
            src={productOffer?.imagePath ? productOffer.imagePath : undefined}
            sx={{ width: 48, height: 48 }}
            variant="rounded"
          />
          <div className="ml-2">
            <div className="ml-2">
              <Typography
                type="body2-b"
                text={productOffer?.name ? productOffer.name : ""}
                className="text-primary"
              />
              <a href={`/oferta/${productOffer?.slug}`} rel="noreferrer">
                <Typography
                  type="body1"
                  text="Zobacz szczegóły"
                  className="text-secondary underline underline-offset-2"
                />
              </a>
              {priceProposal && (
                <Typography
                  type="helper"
                  text={
                    productOffer?.price
                      ? `${formatPriceNumber(productOffer.price)} zł`
                      : ""
                  }
                  className="text-secondary mr-2 line-through"
                />
              )}
              <Typography
                type="helper"
                text={
                  productOffer?.price
                    ? `${formatPriceNumber(
                        priceProposal
                          ? priceProposal.price
                          : productOffer.price,
                      )} zł`
                    : ""
                }
                className="text-secondary"
              />
            </div>
          </div>
        </div>
        {userProfile?.user.id === priceProposal?.buyerId ? (
          <MessagePanelItemUser />
        ) : null}
      </div>
      {userProfile?.user.id !== priceProposal?.buyerId && priceProposal ? (
        <MessagePanelItemOwner />
      ) : null}
    </div>
  );
}

export default MessagePanelItem;
