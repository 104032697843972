import { CheckCircle, ThumbUp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { IUserProfile } from "../../types/UserContextType";
import UserAvatar from "./UserAvatar";

type Props = {
  userProfile: IUserProfile;
};

function UserProfileSection({ userProfile }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        p: { xs: 2, sm: 3 },
        width: 1,
        justifyContent: "center",
      }}
    >
      <UserAvatar />
      <Box sx={{ p: 1, pr: 0 }}>
        <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
          {userProfile.user.username ?? "Konto"}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mr: { xs: 1, md: 2 },
              mb: 0.5,
            }}
          >
            <CheckCircle sx={{ fontSize: "16px" }} />
            <Typography
              component="span"
              variant="body2"
              sx={{
                fontWeight: 600,
                ml: 1,
                textAlign: "center",
              }}
            >
              Sprzedane:&nbsp;
              <Typography
                component="span"
                variant="inherit"
                sx={{ color: "primary.main", fontWeight: 600 }}
              >
                {userProfile.user.soldProductOffersCount}
              </Typography>
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
            <ThumbUp sx={{ fontSize: "16px" }} />
            <Typography
              component="span"
              variant="body2"
              sx={{
                fontWeight: 600,
                ml: 1,
                textAlign: "center",
              }}
            >
              Polecenia:&nbsp;
              <Typography
                component="span"
                variant="inherit"
                sx={{ color: "primary.main", fontWeight: 600 }}
              >
                {userProfile.user.recommendedUsersCount}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UserProfileSection;
