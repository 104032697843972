import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

function Layout({ children }: React.PropsWithChildren<{}>) {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return isDesktop ? (
    <Paper sx={{ padding: 2 }}>{children}</Paper>
  ) : (
    <Box>{children}</Box>
  );
}

export default Layout;
