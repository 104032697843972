import { Snackbar, Alert, Avatar, Badge, Paper, Box } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import EditIcon from "@mui/icons-material/Edit";
import { editUserProfil } from "../../client/Requests";
import { UserContext } from "../../contexts/UserProvider";
import { UserContextType } from "../../types/UserContextType";
import useUserData from "../../hooks/useUserData";

function UserAvatar() {
  const { userData } = useUserData();
  const inputFile = useRef<HTMLInputElement>(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const { userProfile, updateUserAvatar } = useContext(
    UserContext,
  ) as UserContextType;

  if (!userProfile) {
    return null;
  }

  const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const target = event.currentTarget as HTMLInputElement;
    if (target.files && target.files[0].size > 2 * 1024 * 1024) {
      setOpenSnackBar(true);
    } else if (target.files) {
      const file = target.files[0];
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPEG",
        100,
        0,
        (avatar) => {
          const formData = new FormData();
          if (avatar instanceof Blob && userData) {
            formData.append("avatarFile[file]", avatar);
            editUserProfil(formData, userData.userToken);
            updateUserAvatar(avatar);
          }
        },
        "blob",
      );
    }
  };

  return (
    <Box sx={{ maxHeight: 100 }}>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="error">Maksymalny rozmiar zdięcia to 2MB</Alert>
      </Snackbar>
      <Badge
        sx={{ mr: 2.5 }}
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <>
            <input
              ref={inputFile}
              hidden
              type="file"
              onChange={handleChangeAvatar}
            />
            <Paper
              elevation={3}
              sx={{
                borderRadius: "50%",
                width: "36px",
                height: "36px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => inputFile.current?.click()}
            >
              <EditIcon sx={{ width: "20px", height: "20px" }} />
            </Paper>
          </>
        }
      >
        <Avatar
          sx={{ width: "64px", height: "64px" }}
          alt="avatar"
          src={userProfile.user.avatar}
        />
      </Badge>
    </Box>
  );
}

export default UserAvatar;
