import Typography from "../../atoms/Typography";
import IconButton from "../../atoms/CustomIcon/IconButton";

function GoBack({ handlerGoBack }: { handlerGoBack: () => void }) {
  return (
    <div className="border-b py-2 px-4">
      <button
        onClick={handlerGoBack}
        type="button"
        className="flex items-center space-x-1 py-1.5 px-3"
      >
        <IconButton icon="arrow_back_ios" active size="small" />
        <Typography type="buttonLarge" text="Powrót" />
      </button>
    </div>
  );
}

export default GoBack;
