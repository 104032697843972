import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type EntryUserDialogProps = {
  initialOpen: boolean;
  handleClose: any;
};

function EntryUserDialog({ initialOpen, handleClose }: EntryUserDialogProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(initialOpen);

  const handleYesClick = async () => {
    await setOpen(false);
    await handleClose();
    await navigate("/account/profile");
  };

  useEffect(() => {
    setOpen(initialOpen);
  }, [initialOpen]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Ta funkcja jest dostępna tylko dla użytkowników z uzupełnionym
          profilem.
        </DialogContentText>
        <DialogContentText sx={{ mt: 1 }} id="alert-dialog-description">
          Czy chcesz teraz uzupełnić profil?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Nie</Button>
        <Button onClick={handleYesClick} variant="contained" autoFocus>
          Tak
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EntryUserDialog;
