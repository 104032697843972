import React from "react";
import DefaultLayout from "../../components/layout/DefaultLayout";
import Typography from "../../components/atoms/Typography";

function News() {
  return (
    <DefaultLayout>
      <Typography type="h1" text="Aktualności" />
      <div className="news-item">
        <Typography
          type="h2"
          text="24.04.2023 - Wdrożenie przesyłek InPost i zmiana Regulaminu"
        />
        <div className="w-full md:flex justify-between h-auto">
          <div className="w-full">
            <div className="bg-white flex flex-col justify-center items-center">
              <div className="flex items-center p-6">
                Bubaa Dzień dobry, z przyjemnością informujemy, że w aplikacji
                Bubaa są już dostępne przesyłki za pośrednictwem Paczkomatów
                InPost! InPost Paczkomat 24/7 Co to oznacza w praktyce ?
                wystawiając przedmiot na sprzedaż, w polu dostawy masz do wybory
                InPost Paczkomat w trzech rozmiarach: mała (A), średnia (B) i
                duża (C) paczka; ceny przesyłek są preferencyjne i znacząco
                niższe niż te dostępne komercyjnie dla klientów InPostu; po
                zakupie przedmiotu z dostawą InPost, osoba kupująca
                automatycznie otrzyma maila z numerem do śledzenia przesyłki, a
                sprzedający maila z etykietą nadania w formacie .pdf gotową do
                wydruku i naklejenia na paczkę; jeśli masz już wystawione
                przedmioty na sprzedaż w aplikacji Bubaa i chcesz je oferować z
                dostawą InPost wystarczy, że edytujesz swoja ofertę i zmienisz
                formę dostawy na Paczkomat InPost (w tym celu należy wejść w
                KONTO `&gt;` TRANSAKCJE `&gt;` SPRZEDAŻ, a następnie wybrać
                sprzedawany przedmiot, kliknąć EDYTUJ i po zmianie dostawy na
                InPost kliknąć ZAKTUALIZUJ); jeżeli nie chcesz korzystać z
                przesyłek InPost, wystawiając przedmiot zawsze możesz wybrać
                opcję „Inna forma dostawy” i ręcznie wpisać cenę oraz operatora,
                z którego usług będziesz korzystać. W przypadku zakupu kilku
                przedmiotów od jednego użytkownika w ramach Koszyka, przed
                finalizacją transakcji wprowadziliśmy konieczność potwierdzenia
                przez sprzedającego aktualności oferty i wyboru formy przesyłki
                wszystkich przedmiotów w jednej paczce. Co więcej, już w maju
                uruchomimy platformę Bubaa w wersji przeglądarkowej (WWW),
                dzięki czemu będzie można z niej wygodnie korzystać także na
                urządzeniach stacjonarnych! Poinformujemy o tym w oddzielnej
                komunikacji. Nowa wersja aplikacji jest już dostępna w sklepie
                Google Play! Pobierz z Google Play Powyższe zmiany zostały
                również odzwierciedlone w Regulaminie aplikacji mobilnej Bubaa,
                którego aktualną wersję można znaleźć pod adresem
                https://bubaa.pl/page/regulamin-i-polityka-prywatnosci
                Dziękujemy za korzystanie z aplikacji i zachęcamy do
                przetestowania nowych rozwiązań.
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default News;
