/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Typography } from "@mui/material";
import React from "react";

interface MobileNavItemInterface {
  icon: string;
  badgeCount?: number;
  text: string;
  className?: string;
  onClick?: React.MouseEventHandler;
}

function MobileNavItem({
  icon,
  badgeCount = 0,
  text,
  className,
  onClick,
}: MobileNavItemInterface) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li
      className={`flex flex-col items-center relative sm:w-[100px] ${className}`}
      onClick={onClick}
    >
      <button
        type="button"
        className="p-2.5 flex rounded-full hover:bg-gray-100 cursor-pointer"
      >
        <span className="material-icons-outlined mb-5">{icon}</span>
        {badgeCount > 0 && (
          <div className="flex items-center justify-center bg-error-main rounded-full text-white text-center min-w-[8px] h-[8px] absolute top-2.5 right-4" />
        )}
      </button>
      <Typography sx={{ position: "absolute", bottom: 10 }} variant="caption">
        {text}
      </Typography>
    </li>
  );
}

export default MobileNavItem;
