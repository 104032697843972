import Typography from "./Typography";

export interface FooterNavItemInterface {
  text: string;
  href?: string;
}

function FooterNavItem({ text, href }: FooterNavItemInterface) {
  return (
    <li className="text-text-primary">
      <a href={href} className="cursor-pointer">
        <Typography type="menu" text={text} />
      </a>
    </li>
  );
}

export default FooterNavItem;
