import { createContext, useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { getCatalogOffers, getCategories } from "../../client/Requests";
import { ShortOffersInterface } from "../../models/ShortOffersInterface";
import { IOfferContext } from "./types";
import {
  buildApiUrlParams,
  buildFrontUrlParams,
  findCategoryBySlug,
  getExpandedCategories,
  matchCategory,
} from "../../utils/CatalogFilters";
import { ProductCategoryInterface } from "../../models/ProductCategoryInterface";
import useFilters from "../../hooks/useFilters";
import { isLoading } from "../../staticData/fetchingStatus";
import { getCategoryFamily } from "../../components/offerCatalog/utils";

export const OfferContext = createContext<IOfferContext | null>(null);

function OfferProvider({ children }: React.PropsWithChildren<{}>) {
  const navigate = useNavigate();
  const homeMatch = useMatch("/");
  const isHome = homeMatch?.pathname === "/";
  const { categorySlug } = useParams();
  const [currentOffer, setCurrentOffer] = useState<ShortOffersInterface[]>([]);
  const [count, setCount] = useState<number>(0);
  const [category, setCategory] = useState<ProductCategoryInterface | null>(
    null,
  );
  const [expandedCategories, setExpandedCategories] = useState<
    ProductCategoryInterface[]
  >([]);
  const {
    filters,
    handleChangePage,
    productTemplateAttributes,
    loadingProductTemplateStatus,
    handleChangeAttributes,
    areFiltersInitialized,
    handleChangeSort,
    handleChangeProvince,
    handleChangeCity,
    handleChangeMinPrice,
    handleChangeMaxPrice,
    handleClearAllFilters,
    provinces,
    cities,
  } = useFilters(category);
  const [isLoadingOffer, setIsLoadingOffer] = useState(true);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [categories, setCategories] = useState<ProductCategoryInterface[]>([]);

  const fetchCategoryOffer = async () => {
    try {
      setIsLoadingOffer(true);
      const apiParams = buildApiUrlParams(
        filters,
        category ?? null,
        provinces,
        cities,
      );
      const { data } = await getCatalogOffers(apiParams);
      setCurrentOffer(data?.items);
      setCount(data?.count);
      setIsLoadingOffer(false);
    } catch (error) {
      setIsLoadingOffer(false);
      console.error("ERROR:", error);
    }
  };

  const handleLoadCategories = async () => {
    try {
      const categoriesData = await getCategories();
      setCategories(categoriesData);
      setIsLoadingCategories(false);
    } catch (error) {
      setIsLoadingCategories(false);
    }
  };

  useEffect(() => {
    handleLoadCategories();
  }, []);

  useEffect(() => {
    if (areFiltersInitialized) {
      fetchCategoryOffer();
    }
  }, [category, filters]);

  useEffect(() => {
    if (!areFiltersInitialized && categorySlug) {
      return;
    }
    const urlParams = buildFrontUrlParams(filters);
    if (!isHome && category) {
      window.history.replaceState(
        window.history.state,
        "",
        `${window.location.origin}/kategoria/${category.slug}?${urlParams}`,
      );
    } else if (!categorySlug && !isHome) {
      navigate(`/najnowsze-oferty?${urlParams}`);
    } else if (isHome && category) {
      navigate(`/kategoria/${category.slug}?${urlParams}`);
    }
  }, [category, filters, areFiltersInitialized]);

  useEffect(() => {
    if (categorySlug && !category && categories && !expandedCategories.length) {
      const categoryBySlug = findCategoryBySlug(categories, categorySlug);
      const expandedCategoriesBySlug = categoryBySlug
        ? getExpandedCategories(categories, categoryBySlug)
        : [];
      setCategory(categoryBySlug);
      setExpandedCategories(expandedCategoriesBySlug);
    }
  }, [categories]);

  const handleChangeCategory = (selectedCategory: ProductCategoryInterface) => {
    if (matchCategory(selectedCategory, expandedCategories)) {
      setExpandedCategories((prevCategories) =>
        prevCategories.filter(
          (prevCategory) => prevCategory.id !== selectedCategory.id,
        ),
      );
      const isTopCategory = selectedCategory.parentId === 1;
      if (isTopCategory) {
        navigate("/najnowsze-oferty");
        setCategory(null); // show newest category when hide active one
      } else {
        setCategory(selectedCategory);
      }
    } else {
      setExpandedCategories((prev) => {
        const family = getCategoryFamily(
          prev,
          selectedCategory,
          selectedCategory ? [selectedCategory] : [],
        );
        if (family) {
          return family;
        }

        return [];
      });
      setCategory(selectedCategory);
    }
  };

  const handleChangeExapandedCategory = (categoryId: number) => {
    const selectedCategory = expandedCategories.find(
      (expandedCategory) => expandedCategory.id === categoryId,
    );

    if (selectedCategory) {
      handleChangeCategory(selectedCategory);
    }
  };

  const ProviderData = useMemo(
    () => ({
      currentOffer,
      offerCount: count,
      category,
      filters,
      handleChangeCategory,
      handleChangeExapandedCategory,
      handleChangePage,
      currentCategory: category,
      categories,
      expandedCategories,
      productTemplateAttributes,
      handleChangeAttributes,
      handleChangeSort,
      handleChangeProvince,
      handleChangeCity,
      handleChangeMinPrice,
      handleChangeMaxPrice,
      handleClearAllFilters,
      provinces,
      cities,
      isLoading:
        isLoadingOffer ||
        isLoadingCategories ||
        isLoading(loadingProductTemplateStatus),
    }),
    [
      currentOffer,
      category,
      expandedCategories,
      isLoadingOffer,
      loadingProductTemplateStatus,
      isLoadingCategories,
      productTemplateAttributes,
      filters,
      provinces,
      cities,
    ],
  );

  return (
    <OfferContext.Provider value={ProviderData}>
      {children}
    </OfferContext.Provider>
  );
}

export default OfferProvider;
