import {
  Autocomplete,
  TextField,
  FormControl,
  Box,
  Checkbox,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useMemo } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  ProductTemplateAttributeInterface,
  ProductTemplateAttributeValueInterface,
} from "../../models/ProductTemplateAttributeInterface";

type Props = {
  productAttribute: ProductTemplateAttributeInterface;
  category: string;
  defaultValuesIds?: number[];
};

function OfferAttributeMultipleField({
  productAttribute,
  category,
  defaultValuesIds,
}: Props) {
  const { control, setValue, watch } = useFormContext();
  const fieldName = `attributesMultiple.${productAttribute.name}`;
  const formFieldValue = watch(fieldName);

  const getAtributesValues = (selectedValues: string[]) => {
    if (selectedValues.length) {
      const selectedValuesWithIds = selectedValues.map((selectedValue) =>
        productAttribute.values.find(
          (attributeValue) => attributeValue.name === selectedValue,
        ),
      );
      return selectedValuesWithIds;
    }
    return selectedValues;
  };

  const defaultValue = useMemo(() => {
    const defaultAttributeValues: ProductTemplateAttributeValueInterface[] = [];
    defaultValuesIds?.forEach((defaultValueId) => {
      const productAttributeDefaultValue = productAttribute.values.find(
        (value) => value.id === defaultValueId,
      );

      if (productAttributeDefaultValue) {
        defaultAttributeValues.push(productAttributeDefaultValue);
      }
    });
    return defaultAttributeValues;
  }, []);

  useEffect(() => {
    if (formFieldValue) {
      setValue(fieldName, []);
    }
  }, [category]);

  return (
    <Box sx={{ width: 1, flex: 1, ml: { sm: 3 }, py: 1 }}>
      <Controller
        key={category}
        control={control}
        defaultValue={defaultValue}
        name={fieldName}
        render={({
          field: { onChange, value: fieldValues },
          fieldState: { error, invalid, isDirty },
        }) => (
          <FormControl error={invalid} variant="standard" sx={{ width: 1 }}>
            <Autocomplete
              multiple
              disablePortal
              options={productAttribute.values.map(
                (attribute) => attribute.name,
              )}
              disableCloseOnSelect
              defaultValue={[]}
              value={
                fieldValues?.map(
                  (fieldValue: { name: string; id: number }) =>
                    fieldValue?.name,
                ) ?? []
              }
              onChange={(_element, selectedValues) => {
                onChange(getAtributesValues(selectedValues));
              }}
              renderTags={(value: readonly string[]) =>
                value.map((option: string) => option).join(", ")
              }
              renderOption={(props, option, { selected }) => (
                <Box component="li" {...props}>
                  <Box
                    sx={{
                      width: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {option}
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                  </Box>
                </Box>
              )}
              noOptionsText="Nie znaleziono takiego atrybutu"
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="standard"
                    size="small"
                    error={!!error}
                    helperText={error?.message}
                    placeholder={`${formFieldValue?.length ? " " : ""}Wybierz ${
                      productAttribute.name
                    }`}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                );
              }}
            />
          </FormControl>
        )}
      />
    </Box>
  );
}

export default OfferAttributeMultipleField;
