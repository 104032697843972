import Alert from "./Alert";

type AlertType = "info" | "warning" | "error";

export interface AlertInterface {
  type?: AlertType;
  icon?: string;
  text?: string;
}

function ModalAlert({ type, icon, text }: AlertInterface) {
  if (!type || !text) {
    return null;
  }

  return (
    <div className="fixed bottom-4 left-4">
      <div className="bg-error-main rounded-lg text-white w-80">
        <Alert type={type} text={text} icon={icon} />
      </div>
    </div>
  );
}

export default ModalAlert;
