import sendRequest from "./ApiClient";
import { ProductCategoryInterface } from "../models/ProductCategoryInterface";
import { ShortNewestOffersInterface } from "../models/ShortNewestOffersInterface";
// eslint-disable-next-line import/no-cycle
// import {
//   AttributeRecord,
//   CreateProductOfferInterface,
// } from "@/pages/offers/create";
import { ProductTemplateInterface } from "../models/ProductTemplateInterface";
import { ShippingMethodInterface } from "../models/ShippingMethodInterface";
import { BasketInterface } from "../models/BasketInterface";
import sendFormRequest from "./ApiClientForm";

export interface Province {
  id: number;
  name: string;
}

export interface City {
  id: number;
  name: string;
}

export const getMainCategories = async (): Promise<
  ProductCategoryInterface[]
> => {
  const { data } = await sendRequest(
    "get",
    "v2/public-api/product-main-categories",
  );

  return data;
};

export const getCategories = async (): Promise<ProductCategoryInterface[]> => {
  const { data } = await sendRequest("get", "v2/public-api/product-categories");

  return data;
};

export const getShortNewestOffers = async (
  userToken?: string,
): Promise<ShortNewestOffersInterface[]> => {
  let headers = {};

  if (userToken) {
    headers = {
      "X-AUTH-TOKEN": userToken,
    };
  }

  const { data } = await sendRequest(
    "get",
    "v2/public-api/product-offer/short-newest?inpost=true",
    {},
    headers,
  );

  return data;
};

export const getProvinces = async (): Promise<Province[]> => {
  const { data } = await sendRequest("get", "v2/public-api/province");

  return data;
};

export const getCities = async (provinceId: number): Promise<City[]> => {
  const { data } = await sendRequest("get", `v2/public-api/city/${provinceId}`);

  return data;
};

export const getProductOfferTemplate = async (
  productCategoryId: number,
): Promise<ProductTemplateInterface> => {
  const { data } = await sendRequest(
    "get",
    `v2/public-api/product-template/${productCategoryId}`,
  );

  return data;
};

export const getShippingMethods = async (
  token?: string,
): Promise<ShippingMethodInterface[]> => {
  const { data } = await sendRequest(
    "get",
    `v2/user-api/shipping-methods`,
    undefined,
    {
      "X-AUTH-TOKEN": token,
    },
  );

  return data;
};

export const createProductOffer = async (
  categoryId: any,
  data: any,
  token?: string,
): Promise<any> => {
  return sendRequest(
    "post",
    `v2/user-api/product-category/${categoryId}/product-offer`,
    data,
    {
      "X-AUTH-TOKEN": token,
    },
  );
};

export const getFavoriteOffers = async (userToken: string): Promise<any> => {
  return sendRequest(
    "get",
    `v2/user-api/product-offer/favorites?limit=50&page=0&inpost=true`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const addOfferToFavorites = async (
  userToken: string,
  offerId: number,
): Promise<any> => {
  return sendRequest(
    "post",
    `api/product-offer-inpost/favorite/${offerId}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const removeOfferFromFavorites = async (
  userToken: string,
  offerId: number,
): Promise<any> => {
  return sendRequest(
    "delete",
    `api/product-offer-inpost/favorite/${offerId}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const getProductOffer = async (productOfferId: number): Promise<any> =>
  sendRequest("get", `v2/public-api/product-offer/${productOfferId}`);

export const getProductOfferBySlug = async (
  productOfferSlug: string,
  userToken?: string,
): Promise<any> => {
  let headers = {};

  if (userToken) {
    headers = {
      "X-AUTH-TOKEN": userToken,
    };
  }

  return sendRequest(
    "get",
    `v2/public-api/product-offer/slug/${productOfferSlug}`,
    {},
    headers,
  );
};

export const deleteProductOffer = async (
  productOfferId: number,
  userToken: string,
): Promise<any> => {
  const headers = {
    "X-AUTH-TOKEN": userToken,
  };

  return sendRequest(
    "delete",
    `api/product-offer/${productOfferId}`,
    {},
    headers,
  );
};

export const withdrawProductOffer = async (
  productOfferId: number,
  userToken: string,
): Promise<any> => {
  const headers = {
    "X-AUTH-TOKEN": userToken,
  };

  return sendRequest(
    "post",
    `api/product-offer/withdraw/${productOfferId}`,
    {},
    headers,
  );
};

export const getUserProfile = async (userId: string): Promise<any> => {
  const { data } = await sendRequest("get", `v2/public-api/user/${userId}`);

  return data;
};

export const getUserProfileBySlug = async (userSlug: string): Promise<any> => {
  const { data } = await sendRequest(
    "get",
    `v2/public-api/user-slug/${userSlug}`,
  );

  return data;
};

export const getStaticPage = async (staticPageName: string): Promise<any> =>
  sendRequest("get", `v2/public-api/page/${staticPageName}`);

export const getUserPublishedOffers = async (
  userId: string,
  page: number,
): Promise<any> => {
  const { data } = await sendRequest(
    "get",
    `v2/public-api/user-product-offers/${userId}?page=${page}`,
  );

  return data;
};
export const getUserPublishedOffersBySlug = async (
  userSlug: string,
  page: number,
): Promise<any> => {
  const { data } = await sendRequest(
    "get",
    `v2/public-api/user-product-offers-slug/${userSlug}?page=${page}`,
  );

  return data;
};

export const getCustomHotProductsOffers = async (): Promise<any> =>
  sendRequest("get", `v2/public-api/short-custom-hot-product-offers`);

export const getOwnedPublishedOffersList = async (
  userToken: string,
  page: number,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/product-offer/owned-published-offers-list?page=${page}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const getOwnedPublishedOffersListInactive = async (
  userToken: string,
  page: number,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/product-offer/owned-published-offers-list?type=inactive&page=${page}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const getOwnedSoldOffersList = async (
  userToken: string,
  page: number,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/product-offer/owned-sold-offers-list?page=${page}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const getOwnedBoughtOffersListPaid = async (
  userToken: string,
  page: number,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/product-offer/owned-bought-offers-list?type=paid&page=${page}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const getOwnedBoughtOffersList = async (
  userToken: string,
  page: number,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/product-offer/owned-bought-offers-list?page=${page}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const getCustomHotProductsOffer = async (
  hotOffersId: string,
  queryString: string,
): Promise<any> => {
  const { data } = await sendRequest(
    "get",
    `v2/public-api/custom-hot-product-offers-list/${hotOffersId}?${queryString}`,
  );

  return data;
};

export const getParentAssistantOffers = async (
  userToken: string,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/product-offer/parent-assistant-short-offers`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const getParentAssistantOffer = async (
  userToken: string,
  assistantCode: string,
  queryString: string,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/product-offer/parent-assistant-offers-list/${assistantCode}?${queryString}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const getAuthorizedUserPublishedOffers = async (
  userToken: string,
  userId: number,
  limit: number = 50,
): Promise<any> =>
  sendRequest(
    "get",
    `api/product-offer-inpost?limit=${limit}&owner=${userId}&page=0&statusName=published`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const getAuthorizedUserSoldOffers = async (
  userId: number,
  limit: number = 50,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/public-api/custom-product-offers?limit=${limit}&owner=${userId}&page=0&statusName=sold&inpost=true`,
  );

export const getAuthorizedUserWithdrawnOffers = async (
  userId: number,
  limit: number = 50,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/public-api/custom-product-offers?limit=${limit}&owner=${userId}&page=0&statusName=withdrawn&inpost=true`,
  );

export const getAuthorizedUserBoughtOffers = async (
  userId: number,
  limit: number = 50,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/public-api/custom-product-offers?limit=${limit}&buyer=${userId}&page=0&statusName=sold&inpost=true`,
  );

export const getAuthorizedUserReservedOffers = async (
  userId: number,
  limit: number = 50,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/public-api/custom-product-offers?limit=${limit}&buyer=${userId}&page=0&statusName=reserved&inpost=true`,
  );

export const searchPublishedOffers = async (
  searchPhrase: string,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/public-api/custom-product-offers?limit=30&query=${searchPhrase}&page=0&statusName=published`,
  );

export const getCatalogOffers = async (queryString: string): Promise<any> => {
  return sendRequest(
    "get",
    `v2/public-api/paginated-product-offers/published?${queryString}`,
  );
};

export const sendRegisterUserRequest = async (
  registerUserData: any,
): Promise<any> =>
  sendRequest("post", `v2/public-api/security/register`, registerUserData);

export const sendRegisterSocialUserRequest = async (
  registerUserData: any,
): Promise<any> =>
  sendRequest(
    "post",
    `v2/public-api/security/social-register`,
    registerUserData,
  );

export const changeUserPassword = async (
  userToken: string,
  changePasswordData: any,
): Promise<any> => {
  return sendRequest(
    "post",
    `v2/user-api/user/change-password`,
    changePasswordData,
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const deleteUser = async (userToken: string): Promise<any> => {
  return sendRequest(
    "post",
    `api/user/remove`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const sendSocialLoginUserRequest = async (
  socialUserData: any,
): Promise<any> =>
  sendRequest("post", `v2/public-api/security/social-login`, socialUserData);

export const sendSocialRegisterUserRequest = async (
  socialUserData: any,
): Promise<any> =>
  sendRequest("post", `v2/public-api/security/social-register`, socialUserData);

export const sendLoginUserRequest = async (loginUserData: any): Promise<any> =>
  sendRequest("post", `v2/public-api/security/login`, loginUserData);

export const sendGetBuyNowSummaryRequest = async (
  productOfferId: number,
  userToken: string,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/product-offer/${productOfferId}/buy-now-summary`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const sendAddToBasketRequest = async (
  productOfferId: number,
  userToken?: string,
): Promise<any> =>
  sendRequest(
    "post",
    `v2/user-api/basket/${productOfferId}/add`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const sendAskForShipping = async (
  sellerId: number,
  userToken?: string,
): Promise<any> =>
  sendRequest(
    "post",
    `v2/user-api/basket/${sellerId}/ask-for-shipping`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const sendAskForProductOffer = async (
  productOfferId: number,
  requestData: any,
  userToken?: string,
): Promise<any> =>
  sendRequest(
    "post",
    `v2/user-api/product-offer/${productOfferId}/ask-for-product`,
    requestData,
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const sendDeleteFromBasketRequest = async (
  productOfferId: number,
  userToken?: string,
): Promise<any> =>
  sendRequest(
    "delete",
    `v2/user-api/basket/${productOfferId}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const sendGetBasketRequest = async (
  userToken?: string,
): Promise<BasketInterface> => {
  const { data } = await sendRequest(
    "get",
    `v2/user-api/basket`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

  return data;
};

export const sendBuyBasketRequest = async (
  userToken?: string,
  parcelLockerCode?: string,
): Promise<any> => {
  const { data } = await sendRequest(
    "post",
    `v2/user-api/basket/buy`,
    parcelLockerCode
      ? {
          parcelLockerCode,
        }
      : {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

  return data;
};

export const sendRepublishOfferRequest = async (
  userToken?: string,
  offerId?: number,
): Promise<any> => {
  return sendRequest(
    "post",
    `/api/product-offer/republish/${offerId}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const sendBuyNowRequest = async (
  productOfferId: number,
  userToken: string,
  parcelLockerCode?: string,
): Promise<any> =>
  sendRequest(
    "post",
    `v2/user-api/product-offer/${productOfferId}/buy-now`,
    parcelLockerCode
      ? {
          parcelLockerCode,
        }
      : {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const sendGetForbiddenWordsList = async (
  userToken: string,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/forbidden-words`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );

export const sendGetMessageThreadList = async (
  userToken: string,
): Promise<any> =>
  sendRequest("get", `v2/user-api/messages`, {}, { "X-AUTH-TOKEN": userToken });

export const postAskForProductOffer = async (
  productOfferId: number,
  userToken: string,
): Promise<any> =>
  sendRequest(
    "post",
    `v2/user-api/product-offer/${productOfferId}/ask-for-product`,
    { content: "Czy produkt posiada jakieś uszkodzenia ?" },
    { "X-AUTH-TOKEN": userToken },
  );

export const getMessageThreadDetails = async (
  messageThreadId: number,
  userToken: string,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/messages/${messageThreadId}`,
    {},
    { "X-AUTH-TOKEN": userToken },
  );

export const getMessages = async (
  messageThreadId: number,
  userToken: string,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/messages/${messageThreadId}`,
    {},
    { "X-AUTH-TOKEN": userToken },
  );

export const getUserCounters = async (userToken: string): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/user/counters`,
    {},
    { "X-AUTH-TOKEN": userToken },
  );

export const AnswerForThread = async (
  content: string,
  messageThreadId: number,
  userToken: string,
): Promise<any> =>
  sendRequest(
    "post",
    `v2/user-api/messages/${messageThreadId}`,
    { content },
    { "X-AUTH-TOKEN": userToken },
  );

export const getUserProfilDetails = async (userToken: string): Promise<any> =>
  sendRequest("get", `/api/user-profile/`, {}, { "X-AUTH-TOKEN": userToken });

export const getNewUserProfilDetails = async (
  userToken: string,
): Promise<any> => {
  const { data } = await sendRequest(
    "get",
    `v2/user-api/user-profile`,
    {},
    { "X-AUTH-TOKEN": userToken },
  );

  return data;
};

export const getUserProfil = async (userToken: string): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/user-profile/edit`,
    {},
    { "X-AUTH-TOKEN": userToken },
  );

export const getOrderDetails = async (
  userToken: string,
  orderId: number,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/order/${orderId}`,
    {},
    { "X-AUTH-TOKEN": userToken },
  );

export const getProductOfferAddressSummary = async (
  userToken: string,
  productOfferId: number,
): Promise<any> =>
  sendRequest(
    "get",
    `v2/user-api/product-offer/${productOfferId}/address_summary`,
    {},
    { "X-AUTH-TOKEN": userToken },
  );

export const getAccountStatistics = async (userToken: string): Promise<any> =>
  sendRequest("get", `api/user/statistics`, {}, { "X-AUTH-TOKEN": userToken });

export const postUserProfilEdit = async (
  data: object,
  userToken: string,
): Promise<any> =>
  sendRequest(
    "put",
    `v2/user-api/user-profile`,
    { ...data },
    { "X-AUTH-TOKEN": userToken },
  );

export const editUserProfil = async (
  data: any,
  userToken: string,
): Promise<any> => {
  sendRequest(
    "post",
    `api/user-profile/update`,
    data,
    { "X-AUTH-TOKEN": userToken },
    "multipart/form-data",
  );
};

export const sendAddImages = async (
  productOfferId: number,
  files: File[],
  userToken?: string,
): Promise<any> => {
  const formData = new FormData();

  // eslint-disable-next-line array-callback-return
  files.map((value) => {
    formData.append("name", value.name);
    formData.append("contents", value);
  });

  return sendFormRequest(
    `v2/user-api/product-offer/${productOfferId}/image`,
    formData,
    { "X-AUTH-TOKEN": userToken },
  );
};

export const sendPriceProposal = async (
  productOfferId: number,
  data: any,
  userToken: string,
): Promise<any> => {
  sendRequest(
    "post",
    `v2/user-api/product-offer/${productOfferId}/price-proposal/create`,
    { ...data },
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const sendPriceProposalAccept = async (
  productOfferId: number,
  priceProposalId: number,
  userToken: string,
): Promise<any> => {
  sendRequest(
    "post",
    `v2/user-api/product-offer/${productOfferId}/price-proposal/${priceProposalId}/accept`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const sendPriceProposalReject = async (
  productOfferId: number,
  priceProposalId: number,
  userToken: string,
): Promise<any> => {
  sendRequest(
    "post",
    `v2/user-api/product-offer/${productOfferId}/price-proposal/${priceProposalId}/reject`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const getAvailableCharges = async (
  productOfferId: number,
  userToken: string,
): Promise<any> => {
  return sendRequest(
    "get",
    `v2/user-api/charge/product-offer/${productOfferId}/available-charges`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const buyCharges = async (
  productOfferId: number,
  charges: any,
  userToken: string,
): Promise<any> => {
  return sendRequest(
    "post",
    `v2/user-api/charge/product-offer/${productOfferId}/buy`,
    charges,
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const sendConfirmBasketShipping = async (
  basketShippingId: number,
  data: any,
  userToken: string,
): Promise<any> => {
  return sendRequest(
    "post",
    `v2/user-api/basket-shipping/${basketShippingId}/confirm`,
    { ...data },
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
};

export const getOfferEdit = async (
  userToken: string,
  productOfferId: string,
): Promise<any> => {
  const { data } = await sendRequest(
    "get",
    `v2/user-api/product-offer/${productOfferId}/edit`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
  return data;
};

export const offerEdit = async (
  userToken: string,
  dataForm: any,
  productOfferId: string,
): Promise<any> => {
  const { data } = await sendRequest(
    "put",
    `v2/user-api/product-offer/${productOfferId}`,
    dataForm,
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
  return data;
};

export const deleteImg = async (
  userToken: string,
  imageId: number,
): Promise<any> => {
  const { data } = await sendRequest(
    "post",
    `/api/product-offer/delete-images?ids=${imageId}`,
    {},
    {
      "X-AUTH-TOKEN": userToken,
    },
  );
  return data;
};

export const fetchRequestResetPassword = async (
  email: string,
): Promise<any> => {
  return sendRequest("post", "api/public/security/request-reset-password", {
    email,
  });
};

export const fetchResetPassword = async (
  first: string,
  second: string,
  resetRequestToken: string,
): Promise<any> => {
  return sendRequest("post", "api/public/security/reset-password", {
    plainPassword: {
      first,
      second,
    },
    resetRequestToken,
  });
};
