/* eslint-disable import/order */
import { useContext, useEffect, useMemo, useState } from "react";
import DefaultLayout from "../../components/layout/DefaultLayout";
import PageTitle from "../../components/atoms/PageTitle";
import BackButton from "../../components/molecules/BackButton";
import { FormProvider, useForm } from "react-hook-form";
import NameOffer from "../../components/organisms/createOffer/NameOffer";
import { Box, Button, Divider, useMediaQuery } from "@mui/material";
import DescribeOffer from "../../components/organisms/createOffer/DescribeOffer";
import PriceOffer from "../../components/organisms/createOffer/PriceOffer";
import LocalizationOffer from "../../components/organisms/createOffer/LocalizationOffer";
import ShippingOffer from "../../components/organisms/createOffer/shippingOffer/ShippingOffer";
import { yupResolver } from "@hookform/resolvers/yup";
import createOffertFormSchema from "../../schemas/createOffertFormSchema";
import CategoryOffer from "../../components/organisms/createOffer/categoryOffer/CategoryOffer";
import {
  City,
  Province,
  createProductOffer,
  getProductOfferTemplate,
  offerEdit,
  sendAddImages,
  sendRepublishOfferRequest,
} from "../../client/Requests";
import { ProductTemplateAttributeInterface } from "../../models/ProductTemplateAttributeInterface";
import { mutateOfferData } from "../../utils/createOfferUtils";
import ModalAlert, { AlertInterface } from "../../components/atoms/ModalAlert";
import useUserData from "../../hooks/useUserData";
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
import { getSpilttedAtrributesFieldsByType } from "./utils";
import { ProductCategoryInterface } from "../../models/ProductCategoryInterface";
import EditOfferUploader, {
  ImgData,
} from "../../components/organisms/EditOfferUploader";
import { UserContext } from "../../contexts/UserProvider";
import { UserContextType } from "../../types/UserContextType";

type CreateOfferProps = {
  initialData?: {
    offerId: number;
    name: string;
    description: string;
    price: string;
    province?: Province;
    city?: City;
    shipping: string;
    shippingCustomName: string;
    shippingCustomPrice: string;
    inpostMethod: string;
    personalAvailable: boolean;
    category: ProductCategoryInterface;
    attributesDefaultValuesIds?: number[];
    offerImages: ImgData[];
  };
  slug?: string;
};

function CreateOffer({ initialData, slug }: CreateOfferProps) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const { userData } = useUserData();
  const [alert, setAlert] = useState<AlertInterface>();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string | null>(
    null,
  );
  const [productAttributes, setProductAttributes] = useState<
    ProductTemplateAttributeInterface[] | null
  >(null);
  const [setedImgs, setSetedImgs] = useState<ImgData[]>(
    initialData?.offerImages ?? [],
  );
  let productOfferId: number = initialData?.offerId ?? 1;
  const navigate = useNavigate();
  const isEditAction = !!initialData?.offerId;
  const { userAddres } = useContext(UserContext) as UserContextType;

  const productAttributesFields = useMemo(
    () =>
      productAttributes?.length
        ? getSpilttedAtrributesFieldsByType(productAttributes)
        : null,
    [productAttributes],
  );

  const methods = useForm<any>({
    defaultValues: {
      name: initialData?.name ?? "",
      description: initialData?.description ?? "",
      price: initialData?.price ?? "",
      province: initialData?.province ?? userAddres?.userProvince ?? null,
      city: initialData?.city ?? userAddres.userCity ?? null,
      shipping: initialData?.shipping ?? "inpost",
      inpostMethod: initialData?.inpostMethod ?? "small",
      customName: initialData?.shippingCustomName ?? "",
      customPrice: initialData?.shippingCustomPrice ?? "",
      personalAvailable:
        initialData && "personalAvailable" in initialData
          ? initialData?.personalAvailable
          : false,
      category: initialData?.category || null,
    },
    resolver: yupResolver(createOffertFormSchema(productAttributesFields)),
    context: { productAttributes },
    mode: "onSubmit",
  });
  const { handleSubmit, watch, formState, setValue } = methods;

  useEffect(() => {
    if (userAddres.userProvince && !initialData?.province) {
      setValue("province", userAddres.userProvince);
    }

    if (userAddres.userCity && !initialData?.city) {
      setValue("city", userAddres.userCity);
    }
  }, [userAddres.userProvince, userAddres.userCity]);

  const handleUploadImages = () => {
    uploadedFiles.forEach(async (file, index) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "webp",
        100,
        0,
        (newFile) => {
          if (newFile instanceof File) {
            sendAddImages(productOfferId, [newFile], userData?.userToken)
              .then(() => {
                setAlert({
                  type: "info",
                  text: "Oferta została dodana",
                  icon: "info",
                });
                if (index === 0 && !isEditAction) {
                  sendRepublishOfferRequest(
                    userData?.userToken,
                    productOfferId,
                  );
                }
              })
              .catch(() => {
                setAlert({
                  type: "error",
                  text: "Błąd podczas dodawania zdjęć do oferty",
                  icon: "info",
                });
              });
          } else {
            setAlert({
              type: "error",
              text: "Błąd podczas dodawania zdjęć do oferty",
              icon: "info",
            });
          }
        },
        "file",
      );
    });
  };

  const onSubmit = async (data: any) => {
    const offerDataMutabled = mutateOfferData(
      data,
      productAttributes,
      isEditAction,
    );

    if (setedImgs?.length === 0 && uploadedFiles.length === 0) {
      setUploadErrorMessage("Musisz dodać przynajmniej jedno zdjęcie");
      return;
    }

    if (initialData?.offerId && userData?.userToken) {
      offerEdit(
        userData?.userToken,
        offerDataMutabled,
        initialData.offerId.toString(),
      )
        .then(() => {
          handleUploadImages();
        })
        .catch(() => {
          setAlert(
            <ModalAlert
              type="error"
              text="Błąd podczas edycji oferty"
              icon="info"
            />,
          );
        })
        .finally(() => {
          if (isEditAction && slug) {
            navigate(`/oferta/${slug}`);
          } else {
            navigate(`/promote/${productOfferId}`);
          }
        });
    } else {
      createProductOffer(
        data.category.id,
        offerDataMutabled,
        userData?.userToken,
      )
        .then((response) => {
          productOfferId =
            initialData?.offerId ?? parseInt(response.data.id, 10);
          setAlert({
            type: "info",
            text: "Oferta została dodana",
            icon: "info",
          });
          handleUploadImages();
        })
        .catch(() => {
          setAlert({
            type: "error",
            text: "Błąd podczas tworzenia oferty",
            icon: "info",
          });
        })
        .finally(() => {
          if (isEditAction && slug) {
            navigate(`/oferta/${slug}`);
          } else {
            navigate(`/promote/${productOfferId}`);
          }
        });
    }

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (Object.keys(formState.errors).length) {
      setAlert({
        type: "error",
        text: "Proszę sprawdzić wszystkie pola",
        icon: "info",
      });
      setTimeout(() => {
        setAlert(undefined);
      }, 3000);
    }
  }, [formState.errors]);

  const categoryValue = watch("category");

  useEffect(() => {
    if (categoryValue) {
      const fetchProductOfferTemplate = async () => {
        const productTemplate = await getProductOfferTemplate(categoryValue.id);
        if (productTemplate.attributes.length) {
          setProductAttributes(productTemplate.attributes);
        }
      };
      fetchProductOfferTemplate();
    }
  }, [categoryValue]);

  return (
    <DefaultLayout>
      <BackButton />
      <Box sx={{ ml: { xs: 2, lg: 0 }, mb: 2 }}>
        <PageTitle title="Nowa oferta" />
      </Box>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <EditOfferUploader
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            uploadErrorMessage={uploadErrorMessage}
            setUploadErrorMessage={setUploadErrorMessage}
            setedImg={setedImgs}
            setSetedImgs={setSetedImgs}
            isSubmitting={formState.isSubmitting}
          />
          <Box sx={{ my: 4 }}>
            <NameOffer />
            {!isMobile && <Divider />}
            <DescribeOffer />
            {!isMobile && <Divider />}
            <PriceOffer />
            {!isMobile && <Divider />}
            <LocalizationOffer />
            {!isMobile && <Divider />}
          </Box>
          <Box sx={{ mb: 4 }}>
            <ShippingOffer />
          </Box>
          <Box sx={{ mb: 4 }}>
            <CategoryOffer
              productAttributes={productAttributes}
              initialValues={{
                category: categoryValue,
                attributesDefaultValuesIds:
                  initialData?.attributesDefaultValuesIds,
              }}
            />
          </Box>
          <Box
            sx={{
              width: 1,
              mt: 3,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              sx={{ width: "160px", mr: { xs: 2, md: 0 } }}
              color="secondary"
              variant="contained"
              type="submit"
            >
              {isEditAction ? "Zaktualizuj" : "Dodaj"}
            </Button>
          </Box>
        </Box>
      </FormProvider>
      <ModalAlert type={alert?.type} text={alert?.text} icon={alert?.icon} />
    </DefaultLayout>
  );
}

export default CreateOffer;
