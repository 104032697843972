import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function BackButton() {
  const navigate = useNavigate();
  return (
    <Box sx={{ width: 100 }}>
      <Button
        size="small"
        variant="text"
        color="secondary"
        sx={{ fontSize: 16 }}
        startIcon={<ArrowBackIosIcon />}
        onClick={() => {
          navigate(-1);
        }}
        fullWidth
      >
        Powrót
      </Button>
    </Box>
  );
}

export default BackButton;
