import { useQuery } from "@tanstack/react-query";
import { getUserProfile } from "../../client/Requests";
import { UserProfileInterface } from "../../models/UserProfileInterface";

export const useUserProfile = (userProfileId?: string) => {
  const { data, isLoading, isError } = useQuery<UserProfileInterface | null>({
    queryKey: ["user-profile", userProfileId || ""],
    queryFn: () => (userProfileId ? getUserProfile(userProfileId) : null),
    refetchOnWindowFocus: false,
    enabled: !!userProfileId,
    retry: 3,
  });

  return {
    userProfile: data,
    isLoading,
    isError,
  };
};
