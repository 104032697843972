import Typography from "../atoms/Typography";
import FooterNavSection from "../molecules/FooterNavSection";
import logoLight from "../../images/logo_light.svg";
import ueBanner from "../../images/ue_banner.svg";
import googlePlay from "../../images/google_play.svg";

function Footer() {
  return (
    <footer className="sticky w-full border-t border-gray-300 h-72 mt-28 py-14">
      <div className="container mx-auto pb-16 px-4 md:px-0">
        <div className="w-full grid grid-cols-1 md:grid-cols-4 md:gap-8">
          <FooterNavSection
            title="bubaa"
            navItems={[
              {
                text: "O nas",
                href: "/page/o-nas",
              },
              // {
              //   text: "Funkcje aplikacji",
              // },
              {
                text: "Cennik",
                href: "/page/cennik",
              },
              {
                text: "Regulamin i polityka prywatności",
                href: "/page/regulamin-i-polityka-prywatnosci",
              },
            ]}
          />
          <FooterNavSection
            title="Centrum pomocy"
            navItems={[
              // {
              //   text: "Aktualności",
              //   href: "/news",
              // },
              {
                text: "Kontakt",
                href: "/page/kontakt",
              },
              // {
              //   text: "Regulamin promocji Wysyłka InPost za 5zł",
              //   href: "/page/regulamin-promocji-wysylka-inpost-za-5-zl",
              // },
            ]}
          />
          <FooterNavSection
            title="Social media"
            navItems={[
              {
                text: "Facebook",
                href: "https://www.facebook.com/bubaa.aplikacja",
              },
              {
                text: "Instagram",
                href: "https://www.instagram.com/bubaa.kids/",
              },
              {
                text: "Linkedin",
                href: "https://www.linkedin.com/company/bubaa/",
              },
            ]}
          />
          <div className="mt-6 md:mt-0">
            <a href="https://play.google.com/store/apps/details?id=pl.bubaa&hl=pl&gl=US">
              <img src={googlePlay} alt="google play" />
            </a>
            <Typography
              type="captions"
              text="Darmowa aplikacja na Twój telefon"
            />
          </div>
        </div>
        <div className="w-full mt-14">
          <img src={logoLight} alt="Bubaa.pl" />
          <Typography type="captions" text="Copyright © Bubaa.pl 2023" />
        </div>
        <div className="w-full my-12">
          <img src={ueBanner} alt="UE banner" />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
