import { Box, Typography } from "@mui/material";

type Props = {
  title: string;
  price: string;
  bold?: boolean;
};

function SummaryRecord({ title, price, bold }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          textTransform: "uppercase",
          fontWeight: bold ? 700 : 600,
          color: bold ? "auto" : "textLight",
        }}
      >
        {title}
      </Typography>
      <Typography sx={{ fontWeight: bold ? 700 : 500 }}>{price}</Typography>
    </Box>
  );
}

export default SummaryRecord;
