import { Button, Typography } from "@mui/material";
import { useContext } from "react";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../contexts/offerProvider/types";

function ClearAllFilters() {
  const { handleClearAllFilters, filters } = useContext(
    OfferContext,
  ) as IOfferContext;

  const filtersExists = Boolean(
    filters.attributes.length ||
      filters.province ||
      filters.city ||
      filters.minPrice ||
      filters.maxPrice,
  );

  return (
    <Button
      sx={{ minWidth: 130 }}
      onClick={() => handleClearAllFilters()}
      disabled={!filtersExists}
    >
      Wyczyść filtry
    </Button>
  );
}

export default ClearAllFilters;
