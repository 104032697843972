import React from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "../components/layout/DefaultLayout";
import Typography from "../components/atoms/Typography";
import CustomButton from "../components/atoms/Buttons/CustomButton";

function ActivationThankYouPage() {
  const navigate = useNavigate();

  return (
    <DefaultLayout>
      <div className="bg-white flex flex-col justify-center items-center py-10">
        <Typography type="h1" text="Dziękujemy za aktywację konta" />
        <div className="my-10 flex flex-col justify-center items-center">
          <Typography
            type="h3"
            text="Twoje konto w aplikacji Bubaa zostało właśnie aktywowane. "
          />
          <Typography type="h3" text="Zapraszamy do korzystania z aplikacji." />
          <CustomButton
            text="Zaloguj się"
            className="mt-10"
            onClick={() => {
              navigate("/login");
            }}
          />
        </div>
      </div>
    </DefaultLayout>
  );
}

export default ActivationThankYouPage;
