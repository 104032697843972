/* eslint-disable import/order */
import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  Input,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PriceField from "./PriceField";
import { errorAdornment, priceAdornment } from "../profile/const";

function PriceOffer() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const { control } = useFormContext();
  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={isMobile ? 0 : 3}
      sx={{
        p: { xs: 2, md: 3 },
        mb: { xs: 2, md: 0 },
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <Typography
        sx={{
          flex: "1",
          fontSize: { xs: 12, md: 16 },
          fontWeight: { xs: 500, md: 400 },
        }}
      >
        Cena
      </Typography>
      <Stack sx={{ flex: "1" }}>
        <Controller
          control={control}
          name="price"
          render={({
            field: { onChange, value, ref },
            fieldState: { error, invalid },
          }) => (
            <FormControl error={invalid} variant="standard" sx={{ width: 1 }}>
              <Input
                value={value}
                onChange={onChange}
                ref={ref}
                id="price"
                placeholder="np. 50"
                inputComponent={PriceField as any}
                endAdornment={
                  <>
                    {invalid && errorAdornment}
                    {priceAdornment}
                  </>
                }
              />
              {error?.message ? (
                <FormHelperText sx={{ ml: 0 }}>{error.message}</FormHelperText>
              ) : null}
            </FormControl>
          )}
        />
      </Stack>
    </Stack>
  );
}

export default PriceOffer;
