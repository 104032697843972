/* eslint-disable import/order */
import { Controller, useFormContext } from "react-hook-form";
import { CustomTextField } from "../../atoms/Inputs/InputMUI";
import {
  Box,
  FormHelperText,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function DescribeOffer() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const { control, watch } = useFormContext();
  const descriptionValue = watch("description");
  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={isMobile ? 0 : 3}
      sx={{
        p: { xs: 2, md: 3 },
        mb: { xs: 2, md: 0 },
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <Typography
        sx={{
          flex: "1",
          fontSize: { xs: 12, md: 16 },
          fontWeight: { xs: 500, md: 400 },
        }}
      >
        Opisz ofertę
      </Typography>
      <Stack sx={{ flex: "1" }}>
        <Controller
          control={control}
          name="description"
          render={({
            field: { onChange, value, ref },
            fieldState: { error, invalid },
          }) => (
            <CustomTextField
              variant="standard"
              onChange={onChange}
              value={value}
              ref={ref}
              error={invalid}
              multiline
              rows={4}
              helperText={
                <Stack
                  component="span"
                  direction="row"
                  sx={{ justifyContent: "space-between" }}
                >
                  <FormHelperText component="span">
                    {error?.message}
                  </FormHelperText>
                  <Typography
                    variant="caption"
                    component="span"
                    sx={{
                      alignSelf: "flex-end",
                    }}
                  >
                    {descriptionValue?.length ?? 0}/2000
                  </Typography>
                </Stack>
              }
              placeholder="np. Śpioszki dla dziecka lub wózek"
              InputProps={
                invalid
                  ? {
                      endAdornment: (
                        <InputAdornment position="start">
                          <ErrorOutlineIcon
                            sx={{ fontSize: "1rem" }}
                            color="error"
                          />
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
          )}
        />
        <Box sx={{ mt: 1 }}>
          <Typography variant="caption">
            {(descriptionValue?.length ?? 0) >= 50 ? (
              <CheckCircleIcon
                color="success"
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "text-top",
                  mr: 1,
                }}
              />
            ) : (
              <RadioButtonUncheckedIcon
                sx={{
                  fontSize: "1rem",
                  verticalAlign: "text-top",
                  mr: 1,
                }}
              />
            )}
            Opis musi zawierać min. 50 znaków
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}

export default DescribeOffer;
