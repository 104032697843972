import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProductOffer {
  id: number | null;
  imagePath: string | null;
  isFavorite: boolean | null;
  isPromoted: boolean | null;
  name: string | null;
  price: number | null;
  slug: string | null;
}

export interface Message {
  id: number;
  content: string;
  sendDate: string;
  avatarPath: string;
  isSent: boolean;
}

export interface MessageThread {
  id: number;
  title: string;
  content: string;
  sendDate: string;
  avatarPath: string;
  isClosed: boolean;
}

export interface PriceProposal {
  buyerId: number;
  id: number;
  price: number;
}

export interface Items {
  commissionValue: number;
  id: number;
  imagePath: string;
  name: string;
  price: number;
  shippingStatus: string;
  title: string;
}

export interface MessageBasketShipping {
  shippingStatus: string;
  id: number;
  isSent: boolean;
  items: Items[];
}

export interface MessageThreadDetails {
  messageBasketShipping: MessageBasketShipping | null;
  messageThread: MessageThread | null;
  messages: Message | null;
  priceProposal: PriceProposal | null;
  productOffer: ProductOffer | null;
}

interface AddMessageThreadDetails extends PayloadAction<MessageThreadDetails> {}

const initialState: MessageThreadDetails = {
  messageBasketShipping: null,
  messageThread: null,
  messages: null,
  priceProposal: null,
  productOffer: null,
};

const messageThreadDetailsSlice = createSlice({
  name: "messageThreadDetails",
  initialState,
  reducers: {
    addmessageThreadDetails: (state, action: AddMessageThreadDetails) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { addmessageThreadDetails } = messageThreadDetailsSlice.actions;

export default messageThreadDetailsSlice.reducer;
