import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Typography as MuiTypography } from "@mui/material";
import Typography from "../atoms/Typography";
import { BasketSellerInterface } from "../../models/BasketSellerInterface";
import BasketItem from "../atoms/BasketItem";
import { getFormattedPrice } from "../../utils/PriceUtils";

interface BasketItemList {
  basketSeller: BasketSellerInterface;
  handleDeleteItem: any;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
function BasketItemList({ basketSeller, handleDeleteItem }: BasketItemList) {
  const navigate = useNavigate();
  return (
    <div className="w-full py-2">
      <div className="flex space-x-2">
        <Typography type="body1" text="Sprzedający:" />
        <Typography
          type="body1"
          text={basketSeller.seller.username}
          className="text-primary-main"
        />
      </div>
      {basketSeller.items.map((item) => (
        <BasketItem
          item={item}
          basketSeller={basketSeller}
          handleDeleteItem={handleDeleteItem}
          sellerItemsCount={basketSeller.items.length}
          key={Math.random()}
        />
      ))}
      <div className="text-center mt-6">
        {basketSeller.shippingStatus === "required" && (
          <div className="w-full bg-warning-light p-4 text-text-secondary rounded-lg flex space-x-2 items-center justify-center my-4">
            <span className="material-icons-outlined text-warning-main font-bold">
              warning_amber
            </span>
            <Typography
              type="body2"
              text="Zakup kilku przedmiotów wymaga potwierdzenia dostawy"
            />
          </div>
        )}
        {basketSeller.shippingStatus === "ready" &&
        basketSeller.items?.length > 1 ? (
          <div className="w-full bg-warning-light p-4 text-text-secondary rounded-lg flex space-x-2 items-center justify-center my-4">
            <span className="material-icons-outlined text-warning-main font-bold">
              warning_amber
            </span>
            <Typography
              type="body2"
              text={`Dostawa potwierdzona przez sprzedającego: ${
                basketSeller.basketShipping?.shippingName
              } (${getFormattedPrice(
                basketSeller?.basketShipping?.shippingPrice ?? 0,
              )})`}
            />
          </div>
        ) : null}
        {basketSeller.shippingStatus === "ready" &&
          basketSeller.basketShipping?.isUnavailableSomeProduct && (
            <div className="w-full bg-warning-light p-4 text-text-secondary rounded-lg flex space-x-2 items-center justify-center my-4">
              <span className="material-icons-outlined text-warning-main font-bold">
                warning_amber
              </span>
              <Typography
                type="body2"
                text="Przepraszamy, ale co najmniej jedna oferta jest już nieaktualna. Skontaktuj się ze sprzedającym i usuń ją z koszyka, aby kontynuować zakupy"
              />
            </div>
          )}
        {basketSeller.shippingStatus === "ready" &&
          basketSeller.basketShipping?.isUnavailableAllProducts && (
            <div className="w-full bg-warning-light p-4 text-text-secondary rounded-lg flex space-x-2 items-center justify-center my-4">
              <span className="material-icons-outlined text-warning-main font-bold">
                warning_amber
              </span>
              <Typography
                type="body2"
                text="Przepraszamy, ale wszystkie oferty od tego sprzedającego są już nieaktualne. Usuń je z koszyka, aby kontynuować zakupy"
              />
            </div>
          )}
      </div>
      <Box sx={{ width: 1, display: "flex", justifyContent: "center" }}>
        <Button
          size="large"
          variant="outlined"
          sx={{
            minHeight: 40,
            maxWidth: 400,
            width: 1,
            borderColor: "primary.main",
            mt: 2,
            borderRadius: 5,
            fontWeight: 500,
          }}
          color="inherit"
          onClick={() =>
            navigate(`/user-profile/${basketSeller.seller.userId}`)
          }
        >
          <MuiTypography component="span" variant="body2">
            Dobierz przedmioty od sprzedającego&nbsp;
            <MuiTypography
              component="span"
              variant="body2"
              sx={{ color: "primary.main" }}
            >
              {basketSeller.seller.username}
            </MuiTypography>
          </MuiTypography>
        </Button>
      </Box>
    </div>
  );
}

export default BasketItemList;
