export interface IChild {
  birthData: string | null;
  gender: string;
  isComing: boolean;
  name: string | null;
  id: number;
}

export interface ICity {
  id: number;
  name: string;
}

export interface IEditProfile {
  about: string | null;
  agreeMarketing: boolean;
  bankAccount: number;
  children: IChild[];
  city: ICity;
  email: string;
  expectingChild: boolean;
  expectingGender: boolean;
  firstName: string;
  gender: string;
  haveChild: boolean;
  lastName: string;
  phone: string;
  postCode: string;
  province: number;
  street: string;
  username: string;
}

export const handleDataMutable = (data: IEditProfile) => {
  const {
    username,
    email,
    haveChild,
    children,
    expectingChild,
    expectingGender,
    ...newData
  } = data;
  let dataMutable;
  if (haveChild && expectingChild) {
    dataMutable = {
      children: [
        {
          gender: expectingGender,
          isComing: true,
        },
        ...children,
      ],
      ...newData,
    };
  } else if (!haveChild && expectingChild) {
    dataMutable = {
      children: [
        {
          gender: expectingGender,
          isComing: true,
        },
      ],
      ...newData,
    };
  } else if (haveChild && !expectingChild) {
    dataMutable = {
      children,
      ...newData,
    };
  }
  if (dataMutable) {
    dataMutable = {
      ...dataMutable,
      province: Number(dataMutable.province),
      city: dataMutable.city.id,
    };
  } else {
    dataMutable = {
      ...newData,
      province: Number(newData.province),
      city: newData.city.id,
    };
  }

  return dataMutable;
};
