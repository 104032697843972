/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Box, Stack } from "@mui/material";
import PageTitle from "../atoms/PageTitle";
import DefaultLayout, { DefaultLayoutInterface } from "./DefaultLayout";
import SectionLayoutContent from "./SectionLayoutContent";
import Breadcrumbs, { BreadcrumbsProps } from "../breadcrumbs/Breadcrumbs";
import BackButton from "../molecules/BackButton";
import { BreadcrumbsData } from "../breadcrumbs/types";

type Props = {
  children: React.ReactElement;
  sectionTitle?: string;
  defaultLayoutProps?: DefaultLayoutInterface;
  breadcrumbs?: BreadcrumbsData;
  withNavigationBack?: boolean;
};

function SectionLayout({
  children,
  sectionTitle,
  defaultLayoutProps,
  breadcrumbs,
  withNavigationBack = false,
}: Props) {
  return (
    <DefaultLayout {...defaultLayoutProps}>
      <Stack spacing={1} sx={{ p: { xs: 2, md: 0 } }}>
        {breadcrumbs ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
        {withNavigationBack ? <BackButton /> : null}
        {sectionTitle ? <PageTitle title={sectionTitle} /> : null}
      </Stack>
      {children}
    </DefaultLayout>
  );
}

export default SectionLayout;
