import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  decodeParams,
  getInitialFilters,
  getInitialParams,
} from "../utils/catalogFilters/params";
import { BasicOfferFiltersInterface } from "../models/OffersFiltersInterface";
import { getOfferBasicFilters } from "../utils/basicOfferFilters";
import { isNotEmptyObject } from "../utils/catalogFilters/objectUtils";
import { isBasicFilters } from "../types/typeGuards";

const defaultBasicOfferFilters = {
  currentPage: 1,
  pageSize: 28,
  sort: "od-najnowszych",
};

export const useOfferFilters = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const initialFilters = useMemo(() => {
    const decodedParams = decodeParams(searchParams);
    const initialParams = isNotEmptyObject(decodedParams)
      ? getInitialParams(decodedParams)
      : {};
    const initialParamFilters = isNotEmptyObject(initialParams)
      ? getInitialFilters(initialParams, [])
      : null;

    if (isBasicFilters(initialParamFilters)) {
      return { ...defaultBasicOfferFilters, ...initialParamFilters };
    }

    return defaultBasicOfferFilters;
  }, []);

  const [filters, setFilters] =
    useState<BasicOfferFiltersInterface>(initialFilters);

  const resetCurrentPage = () => {
    setFilters((prev) => ({
      ...prev,
      currentPage: initialFilters.currentPage,
    }));
  };

  const changeFilters = (name: string, value?: number | string) => {
    setFilters((prev) => ({ ...prev, [name]: value }));

    if (name !== "currentPage") {
      resetCurrentPage();
    }
  };

  const handleClearAllFilters = () => {
    setFilters((prev) => ({
      ...prev,
      province: undefined,
      city: undefined,
      maxPrice: undefined,
      minPrice: undefined,
    }));
  };

  useEffect(() => {
    if (isBasicFilters(filters)) {
      const urlParams = getOfferBasicFilters(filters);

      if (urlParams) {
        navigate(`${window.location.pathname}?${urlParams}`);
      }
    }
  }, [filters]);

  useEffect(() => {
    if (!filters.province) {
      changeFilters("city", "");
    }
  }, [filters.province]);

  return {
    filters,
    changeFilters,
    handleClearAllFilters,
  };
};
