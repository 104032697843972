import { Grid, SxProps, Theme, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CustomTextField } from "../../atoms/Inputs/InputMUI";
import { bankNumberAdornment, errorAdornment, numberAdornment } from "./const";

const numberInputStyles: SxProps<Theme> = {
  /* Chrome, Safari, Edge, Opera */
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },

  /* Firefox */
  "input[type=number]": {
    "-moz-appearance": "textfield",
  },
};

function AccountData() {
  const { control } = useFormContext();

  return (
    <>
      <Typography
        variant="h5"
        component="h4"
        sx={{ fontWeight: 700, lineHeight: 1.4 }}
      >
        Dane konta
      </Typography>
      <Typography sx={{ mb: 3 }}>
        Niezbędne, żeby kupujący mógł Ci przelać pieniądze za zakupione od
        Ciebie przedmioty.
      </Typography>
      <Grid container spacing={{ xs: 2, md: 3 }} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="username"
            render={({ field: { ref, value } }) => (
              <CustomTextField
                sx={{ width: 1 }}
                label="Login"
                variant="standard"
                inputRef={ref}
                value={value}
                disabled
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="email"
            render={({ field: { ref, value } }) => (
              <CustomTextField
                sx={{ width: 1 }}
                label="E-mail"
                variant="standard"
                inputRef={ref}
                value={value}
                disabled
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { ref, value, onChange },
              fieldState: { invalid, error },
            }) => (
              <CustomTextField
                sx={{ width: 1 }}
                label="Imię"
                placeholder="Wpisz swoje imię"
                variant="standard"
                inputRef={ref}
                value={value}
                onChange={onChange}
                error={invalid}
                helperText={error?.message}
                InputProps={invalid ? { endAdornment: errorAdornment } : {}}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="lastName"
            render={({
              field: { ref, value, onChange },
              fieldState: { invalid, error },
            }) => (
              <CustomTextField
                sx={{ width: 1 }}
                label="Nazwisko"
                placeholder="Wpisz swoje nazwisko"
                variant="standard"
                inputRef={ref}
                value={value}
                onChange={onChange}
                error={invalid}
                helperText={error?.message}
                InputProps={invalid ? { endAdornment: errorAdornment } : {}}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="phone"
            render={({
              field: { ref, value, onChange },
              fieldState: { invalid, error },
            }) => (
              <CustomTextField
                sx={{ width: 1, ...numberInputStyles }}
                label="Numer telefonu"
                variant="standard"
                type="number"
                inputRef={ref}
                value={value}
                onChange={onChange}
                error={invalid}
                helperText={error?.message}
                InputProps={
                  invalid
                    ? {
                        endAdornment: errorAdornment,
                        startAdornment: numberAdornment,
                      }
                    : {
                        startAdornment: numberAdornment,
                      }
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="bankAccount"
            render={({
              field: { ref, value, onChange },
              fieldState: { invalid, error },
            }) => (
              <CustomTextField
                sx={{ width: 1, ...numberInputStyles }}
                label="Numer konta bankowego"
                placeholder="Wpisz numer konta bankowego"
                variant="standard"
                inputRef={ref}
                type="number"
                value={value}
                onChange={onChange}
                error={invalid}
                helperText={error?.message}
                InputProps={
                  invalid
                    ? {
                        endAdornment: errorAdornment,
                        startAdornment: bankNumberAdornment,
                      }
                    : {
                        startAdornment: bankNumberAdornment,
                      }
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AccountData;
