import GoogleLogin from "react-google-login";
import { Box, Button } from "@mui/material";
import googleIcon from "../../../images/logo_google_g_icon.svg";

interface IGoogleButton {
  handleSocialLogin: any;
}

function GoogleButton({ handleSocialLogin }: IGoogleButton) {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const successResponse = (response: any) => {
    handleSocialLogin(response.profileObj.email);
  };

  if (!googleClientId) {
    return null;
  }

  return (
    <GoogleLogin
      clientId={googleClientId}
      redirectUri={window.location.href}
      render={(renderProps) => {
        return (
          <Button
            variant="outlined"
            color="inherit"
            sx={{ width: 1, mb: 2, p: 1 }}
            onClick={renderProps.onClick}
          >
            <Box
              component="img"
              sx={{
                height: 24,
                width: 24,
                mr: 2,
              }}
              alt="google icon"
              src={googleIcon}
            />
            Kontynuuj przez Google
          </Button>
        );
      }}
      buttonText="Google"
      onSuccess={successResponse}
      cookiePolicy="single_host_origin"
    />
  );
}

export default GoogleButton;
