/* eslint-disable import/order */
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import inpostImage from "../../../../images/inpost.svg";
import SpippingFormLabel from "./SpippingFormLabel";
import ShippingInpostMethod from "./ShippingInpostMethod";
import ShippingAnotherMethod from "./ShippingAnotherMethod";
import { useQuery } from "@tanstack/react-query";
import { getShippingMethods } from "../../../../client/Requests";
import useUserData from "../../../../hooks/useUserData";
import { ShippingMethodInterface } from "../../../../models/ShippingMethodInterface";

function ShippingOffer() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const { control, watch, setValue } = useFormContext();
  const shippingValue = watch("shipping");
  const { userData } = useUserData();
  const userToken = userData?.userToken;

  const { data: shippingMethods } = useQuery<ShippingMethodInterface[] | null>({
    queryKey: ["shipping-methods"],
    queryFn: () => (userToken ? getShippingMethods(userToken) : null),
    enabled: !!userToken,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const inpostMethod = shippingMethods
    ? shippingMethods.find((shippingMethod) => shippingMethod.name === "inpost")
    : null;

  const isShippingPersonalOnly = shippingValue === "personalOnly";

  useEffect(() => {
    if (isShippingPersonalOnly) {
      setValue("personalAvailable", false);
    }
  }, [isShippingPersonalOnly]);

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={isMobile ? 0 : 3}
      sx={{
        p: { xs: 2, md: 3 },
        mb: { xs: 2, md: 0 },
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <Typography
        sx={{
          flex: "1",
          fontSize: { xs: 14, md: 16 },
          fontWeight: { xs: 500, md: 400 },
        }}
      >
        Dostawa
      </Typography>
      <Stack sx={{ flex: "1", mb: { xs: 2, md: 0 } }}>
        <Controller
          control={control}
          name="shipping"
          render={({ field: { name, onChange, value } }) => (
            <FormControl>
              <RadioGroup name={name} onChange={onChange} value={value}>
                <SpippingFormLabel
                  value="inpost"
                  label={
                    <>
                      <img src={inpostImage} alt="inpost" width={192} />
                      <Typography component="span" variant="caption">
                        Cena specjalna dla portalu Bubaa. Po sprzedaży
                        automatycznie otrzymasz etykietę.
                      </Typography>
                    </>
                  }
                />
                {shippingValue === "inpost" && inpostMethod && (
                  <ShippingInpostMethod inpostMethod={inpostMethod} />
                )}
                <Divider />
                <SpippingFormLabel
                  value="other"
                  label={
                    <Typography fontWeight={600}>Inna forma dostawy</Typography>
                  }
                />
                {shippingValue === "other" && <ShippingAnotherMethod />}
                <Divider />
                <SpippingFormLabel
                  value="personalOnly"
                  label={
                    <Typography fontWeight={600}>
                      Tylko odbiór osobisty
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          )}
        />
      </Stack>
    </Stack>
  );
}

export default ShippingOffer;
