import { ProductCategoryInterface } from "../../models/ProductCategoryInterface";
import defaultOffersCategory from "../../staticData/DefaultOffersCategory";

export const getCategoryFamily = (
  expandedCategories: ProductCategoryInterface[],
  category: ProductCategoryInterface | null,
  family: ProductCategoryInterface[],
): ProductCategoryInterface[] | null => {
  if (!category) {
    return null;
  }
  const lastFamilyCategory = family.slice(-1)[0];
  const nextParent = expandedCategories.find(
    (expandedCategory) => expandedCategory.id === lastFamilyCategory?.parentId,
  );

  if (nextParent?.id === 1 || !nextParent?.id) {
    return family;
  }

  return getCategoryFamily(expandedCategories, category, [
    ...family,
    nextParent,
  ]);
};

export const createBreadcrumbs = (
  categories: ProductCategoryInterface[] | null,
) => {
  if (!categories) {
    return [
      {
        url: defaultOffersCategory.url,
        name: defaultOffersCategory.name,
      },
    ];
  }

  return categories.map((category) => ({
    name: category.name,
    categoryId: category.id,
  }));
};
