import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import PageTitle from "../../components/atoms/PageTitle";
import BackButton from "../../components/molecules/BackButton";
import DefaultLayout from "../../components/layout/DefaultLayout";

const FAQ_DATA = [
  {
    question: `Czy korzystanie z aplikacji jest darmowe?`,
    answer: `Tak. Zarówno za pobranie aplikacji,
    założenie konta jak i przeglądanie ofert oraz ogłoszeń nie pobieramy
    żadnych opłat. Nie pobieramy również prowizji za wystawienie i
    sprzedaż przedmiotów.`,
  },
  {
    question: `Czy za wystawienie i sprzedaż przedmiotów pobierana jest prowizja?`,
    answer: `Nie. Sprzedający nie
    ponosi żadnych kosztów związanych z wystawieniem i sprzedażą
    przedmiotu. Pobieramy jedynie niewielką prowizję (zgodną z
    Cennikiem) od kupującego, dzięki czemu jesteśmy w stanie rozwijać
    funkcjonalności naszej aplikacji.`,
  },
  {
    question: `Czy publikowanie ogłoszeń jest płatne?`,
    answer: `Tak. Za zamieszczenie ogłoszenia w aplikacji pobieramy
    prowizję zgodną z Cennikiem. Jako administrator aplikacji możemy
    wprowadzać czasowe promocje, kiedy nie będziemy pobierać takiej
    opłaty.`,
  },
  {
    question: `Jakie dane należy uzupełnić, aby sprzedawać i kupować oraz wystawiać ogłoszenia w aplikacji?`,
    answer: `W zakładce „Konto” w sekcji
    „Profil” należy uzupełnić imię, nazwisko, adres zamieszkania, numer
    telefonu i numer konta bankowego. Pamiętaj, aby po uzupełnieniu tych
    danych kliknąć przycisk „Zapisz”. `,
  },
  {
    question: `W jaki sposób mogę wystawić przedmiot?`,
    answer: `Na głównym ekranie aplikacji, na dolnym pasku, wybierz
    zakładkę „Sprzedaj” oznaczoną ikoną ‘+’. Następnie po uzupełnieniu
    wszystkich niezbędnych informacji oraz zdjęć dotyczących oferty,
    kliknij przycisk „Dodaj”.`,
  },
  {
    question: `W jaki sposób mogę opublikować ogłoszenie?`,
    answer: `Na głównym ekranie aplikacji, na dolnym pasku, wybierz
    zakładkę „Ogłoszenie”. Następnie w prawym dolnym rogu kliknij ikonę
    ‘+’. Po uzupełnieniu wszystkich niezbędnych informacji oraz zdjęć
    dotyczących ogłoszenia, kliknij przycisk „Dodaj”.`,
  },
  {
    question: `Gdzie znajdę wystawione przedmioty lub opublikowane ogłoszenia ?`,
    answer: `W zakładce „Konto” w sekcji „Transakcje” znajdują się wszystkie wystawione
    przez Ciebie przedmioty i ogłoszenia.`,
  },
  {
    question: `W jaki sposób mogę kupić przedmiot ? `,
    answer: `Znajdź interesujący Ciebie przedmiot (korzystając z
      zakładki „Nowości” lub zakładki „Kup”), kliknij w niego i po
      zapoznaniu się z ofertą wybierz przycisk „Kup teraz”. Po opłaceniu
      prowizji, otrzymasz dane sprzedającego niezbędne do dokończenia
      transakcji.`,
  },
  {
    question: `Gdzie znajdę kupione przedmioty?`,
    answer: `W zakładce „Konto” w
    sekcji „Transakcje” znajdują się wszystkie kupione przez Ciebie
    przedmioty. `,
  },
  {
    question: `Gdzie sprawdzę historię moich transakcji ?`,
    answer: `Wszystkie
    informacje dotyczące sprzedanych i kupionych przedmiotów, a także
    wystawionych ogłoszeń znajdziesz w zakładce „Konto” w sekcji
    „Transakcje”. `,
  },
  {
    question: `Gdzie znajdę przedmioty i ogłoszenia dodane do ulubionych za pomocą ikony serduszka?`,
    answer: `Wszystkie produkty i
    ogłoszenia dodane do ulubionych znajdziesz w zakładce „Konto” w
    sekcji „Ulubione”. `,
  },
  {
    question: `Na czym polega opcja promowania przedmiotu?`,
    answer: `Promowanie przedmiotu polega na lepszym pozycjonowaniu Twojej oferty
    i zwiększeniu jej widoczności, dzięki czemu zdecydowanie więcej
    użytkowników aplikacji będzie miało okazję ją obejrzeć. Promowanie
    przedmiotu jest płatne, zgodnie z Cennikiem. Udostępniamy dwie
    możliwości promowania swojej oferty: na górze listy przedmiotów w
    zakładce „Nowości” lub na górze listy przedmiotów w zakładce „Kup” w
    ramach danej kategorii w której przedmiot został wystawiony. Z opcji
    można skorzystać od razu w trakcie wystawiania nowej oferty. Można
    również promować przedmioty, które zostały wcześniej wystawione. W
    tym celu w zakładce „Konto” w sekcji „Transakcje” znajdź przedmioty,
    które aktualnie sprzedajesz. Wybierz ten, który chcesz promować, a
    następnie kliknij przycisk „Opcje promowania”. Wybierz interesujący
    Cię pakiet i dokonaj płatności.`,
  },
  {
    question: `Czy jako kupujący mogę zaproponować inną cenę sprzedającemu?`,
    answer: `Tak. Wchodząc w szczegóły
    danego przedmiotu, obok serduszka i koperty, znajduje się ikona
    „Zaproponuj cenę”. Po jej wybraniu będziesz mógł wpisać swoją
    propozycję ceny, która trafi do sprzedającego i pojawi się w
    zakładce „Wiadomości” jako konwersacja. Sprzedający będzie miał
    możliwość akceptacji bądź odrzucenia propozycji, o czym również
    zostaniesz powiadomiony w zakładce „Wiadomości”.`,
  },
  {
    question: `Gdzie znajdę dane kupującego niezbędne do wysyłki przedmiotu?`,
    answer: `W zakładce „Konto”
    w sekcji „Transakcje” znajdź sprzedaż i ustaw filtr na „Sprzedane”.
    Wybierz ten przedmiot, którego dotyczy wysyłka. Po jego wybraniu,
    nad opisem przedmiotu, będzie przycisk „dane do wysyłki”.`,
  },
  {
    question: `Gdzie znajdę dane sprzedającego niezbędne do rozliczenia transakcji?`,
    answer: `W
    zakładce „Konto” w sekcji „Transakcje” znajdź przedmioty, które
    kupiłeś. Wybierz ten, którego dotyczy rozliczenie. Po jego wybraniu,
    nad opisem przedmiotu, będzie przycisk „dane do przelewu”. W
    przypadku jakiś wątpliwości zawsze możesz skontaktować się ze
    sprzedającym wybierając ikonę koperty „Zadaj pytanie”.`,
  },
  {
    question: `W jaki sposób mogę opłacić prowizję za zakup / promowanie / wystawienie ogłoszenia?`,
    answer: `Prowizja za odpłatne funkcjonalności aplikacji opłacana
    jest w formie płatności elektronicznych za pośrednictwem serwisu
    Przelewy24.pl.`,
  },
  {
    question: `Czy w aplikacji mogę dokonać zapłaty za zakupiony przedmiot?`,
    answer: `Nie. Za pomocą aplikacji udostępniamy jedynie
    odpowiednie narzędzia techniczne do zawarcia umowy sprzedaży między
    sprzedającym i kupującym, jednak sami nie jesteśmy w takim przypadku
    stroną zawartej umowy. Oznacza to, że prawa i obowiązki z niej
    wynikające (przede wszystkim dostarczenie produktu i dokonanie
    zapłaty) ciążą zgodnie z Regulaminem na usługobiorcach będących
    stroną takiej umowy i dokonują się poza aplikacją.`,
  },
  {
    question: `Czy mogę wystawić ocenę sprzedawcy po dokonanej transakcji?`,
    answer: `Tak. Po
    rozliczeniu się ze sprzedającym i otrzymaniu przedmiotu, wejdź w
    zakładkę „Konto” w sekcję „Transakcje” i znajdź kupione przedmioty.
    Następnie wybierz ten którego dotyczy oceniana transakcja i kliknij
    przycisk „Wystaw ocenę”.`,
  },
  {
    question: `Czy mogę sprawdzić statystyki dotyczące mojego konta?`,
    answer: `Tak. W zakładce „Konto” obok ikony polecenia
    znajdziesz przycisk „więcej”. W tym miejscu zamieściliśmy podstawowe
    statystyki dotyczące sprzedaży, Twoich zakupów, wystawionych
    ogłoszeń, a także informację jak długo korzystasz z naszej
    aplikacji.`,
  },
  {
    question: `Czy mogę prowadzić sprzedaż w aplikacji jako firma?`,
    answer: `Nie. Aplikacja jest przeznaczona tylko i wyłącznie dla osób
    prywatnych. Zgodnie z Regulaminem, sprzedawcy mogą zawierać umowy
    sprzedaży wyłączenie jako osoby prywatne nieprowadzące w tym
    zakresie działalności gospodarczej – w konsekwencji kupujący nie
    posiada statusu konsumenta w zakresie umów sprzedaży.`,
  },
  {
    question: `Jak mogę skontaktować się ze sprzedającym?`,
    answer: `Wchodząc w szczegóły danego
    przedmiotu, pod ceną znajduje się ikona koperty „Zadaj pytanie”. Po
    jej wybraniu będziesz mógł skontaktować się ze sprzedającym.
    Konwersacja zostanie przeniesiona do zakładki „Wiadomości”, gdzie
    będziesz mógł kontynuować rozmowę. Pamiętaj, że zgodnie z
    Regulaminem zabronione jest wysyłanie w ramach wiadomości m.in.
    danych kontaktowych umożliwiających kontakt ze sprzedawcą poza
    aplikacją.`,
  },
  {
    question: `Gdzie mogę edytować dane i opis wystawionego przedmiotu ?`,
    answer: `W zakładce „Konto” w sekcji „Transakcje” znajdź
    przedmioty, które aktualnie sprzedajesz. Wybierz ten, który chcesz
    edytować, a następnie kliknij przycisk „Edytuj”. Po modyfikacji
    oferty nie zapomnij wybrać przycisku „Zapisz”.`,
  },
  {
    question: `Gdzie mogę edytować dane i opis wystawionego ogłoszenia?`,
    answer: `W zakładce „Konto” w
    sekcji „Transakcje” znajdź ogłoszenia, które masz aktualnie
    opublikowane. Wybierz to, które chcesz edytować, a następnie kliknij
    przycisk „Edytuj”. Po modyfikacji oferty nie zapomnij wybrać
    przycisku „Zapisz”.`,
  },
  {
    question: `Czy mogę czasowo wycofać przedmiot ze sprzedaży?`,
    answer: `Tak. W zakładce „Konto” w sekcji „Transakcje” znajdź
    przedmioty, które aktualnie sprzedajesz. Wybierz ten, który chcesz
    wycofać, a następnie kliknij przycisk „Wycofaj ze sprzedaży”.
    Przedmiot ten znajdziesz w sekcji „Sprzedaż” pod filtrem „Wycofane”.
    W tym miejscu możesz go przywrócić ponownie do sprzedaży.`,
  },
  {
    question: `Jak mogę usunąć wystawiony przedmiot ?`,
    answer: `W zakładce „Konto” w sekcji
    „Transakcje” znajdź przedmioty, które aktualnie sprzedajesz. Wybierz
    ten, który chcesz usunąć, a następnie kliknij przycisk „Usuń”. W
    przeciwieństwie do wybrania opcji „Wycofaj ze sprzedaży”, nie
    będziesz miał już możliwości przywrócenia tej oferty do sprzedaży.`,
  },
  {
    question: `Jak mogę usunąć wystawione ogłoszenie?`,
    answer: `W zakładce „Konto” w
    sekcji „Transakcje” znajdź ogłoszenia, które masz aktualnie
    opublikowane. Wybierz to, które chcesz usunąć, a następnie kliknij
    przycisk „Usuń”.`,
  },
  {
    question: `Jak mogę skontaktować się z administratorem aplikacji?`,
    answer: `Najszybszą metodą jest skorzystanie z formularza
    kontaktowego, który znajduje się w zakładce „Konto” w sekcji
    „Profil” pod informacjami z danymi osobowymi. W formularzu należy
    wybrać jeden z pięciu tematów, którego dotyczy wiadomość: problem z
    aplikacją, reklamacja, pomysł, naruszenie regulaminu i inne. Po
    wysłaniu wiadomości, otrzymasz potwierdzenie jej nadania w formie
    email`,
  },
  {
    question: `Jak mogę zgłosić reklamację?`,
    answer: `Najszybszą metodą jest
    skorzystanie z formularza kontaktowego, który znajduje się w
    zakładce „Konto” w sekcji „Profil” pod informacjami z danymi
    osobowymi. W formularzu należy wybrać temat „Zgłoś reklamację” i
    uzupełnić opis sprawy. Po wysłaniu wiadomości, otrzymasz
    potwierdzenie jej nadania w formie email.`,
  },
  {
    question: `Jak mogę zgłosić problem z działaniem aplikacji ?`,
    answer: `Najszybszą metodą jest skorzystanie
    z formularza kontaktowego, który znajduje się w zakładce „Konto” w
    sekcji „Profil” pod informacjami z danymi osobowymi. W formularzu
    należy wybrać temat „Zgłoś problem z aplikacją” i uzupełnić opis
    sprawy. Po wysłaniu wiadomości, otrzymasz potwierdzenie jej nadania
    w formie email.`,
  },
  {
    question: `Jak mogę zgłosić naruszenie Regulaminu?`,
    answer: `Najszybszą metodą jest skorzystanie z formularza kontaktowego, który
    znajduje się w zakładce „Konto” w sekcji „Profil” pod informacjami z
    danymi osobowymi. W formularzu należy wybrać temat „Zgłoś naruszenie
    regulaminu” i uzupełnić opis sprawy. Po wysłaniu wiadomości,
    otrzymasz potwierdzenie jej nadania w formie email.`,
  },
  {
    question: `Gdzie mogę zapoznać się z Regulaminem i Polityką prywatności?`,
    answer: `Zarówno
    Regulamin jak i załącznik do niego w postaci Polityki prywatności
    zamieściliśmy w zakładce „Konto” w sekcji „Profil” pod informacjami
    z danymi osobowymi.`,
  },
  {
    question: `Gdzie mogę zapoznać się z Cennikiem?`,
    answer: `Informacje dotyczące Cennika zamieściliśmy w zakładce „Konto” w
    sekcji „Profil” pod informacjami z danymi osobowymi.`,
  },
  {
    question: `Gdzie mogę zmienić hasło do aplikacji?`,
    answer: `Opcja zmiany hasła znajduje się w
    zakładce „Konto” w sekcji „Profil” pod informacjami z danymi
    osobowymi. Pamiętaj, że hasło musi zawierać minimum 6 znaków,
    minimum 1 dużą literę oraz znak specjalny. To wszystko dla Twojego
    bezpieczeństwa.`,
  },
  {
    question: `Gdzie mogę włączyć lub wyłączyć zgodę na marketing (w tym Newsletter)?`,
    answer: `Zarządzanie zgodą na marketing
    możliwe jest w zakładce „Konto” w sekcji „Profil” pod informacjami z
    danymi osobowymi. Pamiętaj, że po kliknięciu checkboxa „Zgadzam się
    na marketing” należy jeszcze wybrać przycisk „Zapisz”.`,
  },
  {
    question: `Gdzie mogę wylogować się z aplikacji?`,
    answer: `Opcja wylogowania się z aplikacji
    znajduje się w zakładce „Konto” w sekcji „Profil” pod informacjami z
    danymi osobowymi. 36 Czy mogę usunąć swoje konto w aplikacji ? Tak.
    Opcja usunięcia konta znajduje się w zakładce „Konto” w sekcji
    „Profil” pod informacjami z danymi osobowymi.`,
  },
];

function FAQ() {
  const [expanded, setExpanded] = useState<number | null>(null);
  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
    };

  return (
    <DefaultLayout>
      <Box sx={{ maxWidth: 800, m: "auto" }}>
        <BackButton />
        <PageTitle title="FAQ" />
        <Box sx={{ mt: 3 }}>
          {FAQ_DATA.map((faqSection, index) => (
            <Accordion
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={(theme) => ({
                    fontWeight: 500,
                    color:
                      expanded === index
                        ? theme.palette.primary.main
                        : theme.palette.secondary.main,
                  })}
                >
                  {index + 1}.{` `}
                  {faqSection.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faqSection.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </DefaultLayout>
  );
}

export default FAQ;
