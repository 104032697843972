import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUserData from "../hooks/useUserData";

function UserLoggedProtectedRoute({ children }: React.PropsWithChildren<{}>) {
  const { userData } = useUserData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) {
      navigate("/register");
    }
  }, [userData]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default UserLoggedProtectedRoute;
