/* eslint-disable react/no-this-in-sfc */
import { useEffect, useState } from "react";
import {
  Alert,
  CircularProgress,
  Snackbar,
  Stack,
  AlertColor,
  Box,
  Button,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getCities,
  getUserProfil,
  postUserProfilEdit,
  sendGetForbiddenWordsList,
} from "../../client/Requests";
import useUserData from "../../hooks/useUserData";
import AddressData from "../../components/organisms/profile/AddressData";
import AccountData from "../../components/organisms/profile/AccountData";
import AboutData from "../../components/organisms/profile/AboutData";
import MarketingConsent from "../../components/organisms/profile/MarketingConsent";
import PersonalizationData from "../../components/organisms/profile/PersonalizationData";
import accountFormSchema from "../../schemas/accountFormSchema";
import { accountFormDefaultValues } from "../../components/organisms/profile/const";
import {
  IChild,
  ICity,
  IEditProfile,
  handleDataMutable,
} from "../../components/organisms/profile/utils";

function Profile() {
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarColor, setSnackbarColor] = useState<AlertColor>("success");
  const [forbiddenWords, setForbiddenWords] = useState<string[]>([]);

  const { userData, addUserProfile } = useUserData();
  const methods = useForm<any>({
    defaultValues: accountFormDefaultValues,
    resolver: yupResolver(accountFormSchema(forbiddenWords)),
    mode: "onSubmit",
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const loadForbiddenWords = async () => {
    if (userData) {
      const getForbiddenWordsList = await sendGetForbiddenWordsList(
        userData.userToken,
      );
      setForbiddenWords(getForbiddenWordsList.data);
    }
  };

  const onSubmit = async (data: IEditProfile) => {
    const dataMutable = handleDataMutable(data);

    if (userData) {
      try {
        await postUserProfilEdit(dataMutable, userData.userToken).then(
          (response) => {
            if (response.status === 200) {
              setSnackbarMessage(
                "Twój profil został zaktualizowany pomyślnie. Dziękujemy",
              );
              setSnackbarColor("success");
              addUserProfile();
            }
          },
        );
      } catch (error) {
        setSnackbarMessage("Coś poszło nie tak. Spróbuj ponownie później.");
        setSnackbarColor("error");
      }
    }
  };

  useEffect(() => {
    if (userData) {
      loadForbiddenWords();
      const fetchData = async () => {
        const body = await getUserProfil(userData.userToken);
        const { data } = body;
        const isComingChild = data.children.find(
          (child: IChild) => child.isComing === true,
        );
        if (isComingChild) {
          setValue("expectingChild", true);
          setValue("expectingGender", isComingChild.gender);
        }
        const children = data.children.filter(
          (child: IChild) => child.isComing === false,
        );
        setValue(
          "children",
          children.length
            ? children
            : [
                {
                  name: "",
                  birthDate: "",
                  gender: "female",
                  isComing: false,
                },
              ],
        );
        setValue("username", data.username);
        setValue("email", data.email);
        setValue("bankAccount", data.bankAccount ? data.bankAccount : "");
        setValue("agreeMarketing", data.agreeMarketing);
        setValue("province", data.province ? Number(data.province) : 0);
        setValue("postCode", data.postCode ? data.postCode : "");
        setValue("street", data.street ? data.street : "");
        setValue("about", data.about ? data.about : "");
        setValue("firstName", data.firstName ? data.firstName : "");
        setValue("lastName", data.lastName ? data.lastName : "");
        setValue("phone", data.phone ? data.phone : "");
        setLoading(false);
        if (data.province !== null) {
          getCities(Number(data.province)).then((cityValue: ICity[]) => {
            if (cityValue === null) {
              setValue("city", null);
            } else {
              const currentCity = cityValue.find(
                (cityData: { id: number }) => cityData.id === data.city,
              );
              setValue("city", currentCity);
            }
          });
        }
      };
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setSnackbarMessage("Sprawdź wszystkie pola");
      setSnackbarColor("error");
    }
  }, [errors]);

  if (loading) {
    return (
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress color="primary" />
      </Stack>
    );
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ backgroundColor: "#fff", mt: { md: 4, xs: 2 }, p: 2 }}>
          <AccountData />
        </Stack>
        <Stack sx={{ backgroundColor: "#fff", mt: { md: 4, xs: 2 }, p: 2 }}>
          <AddressData />
        </Stack>
        <Stack sx={{ backgroundColor: "#fff", mt: { md: 4, xs: 2 }, p: 2 }}>
          <AboutData />
        </Stack>
        <Stack sx={{ backgroundColor: "#fff", mt: { md: 4, xs: 2 }, p: 2 }}>
          <PersonalizationData />
        </Stack>
        <Stack sx={{ backgroundColor: "#fff", mt: { md: 4, xs: 2 }, p: 2 }}>
          <MarketingConsent />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ minWidth: 122, mr: 1 }}
            size="large"
          >
            Zapisz
          </Button>
        </Stack>
        <Snackbar
          open={snackbarMessage !== null && snackbarMessage.length > 0}
          onClose={() => setSnackbarMessage(null)}
        >
          <Alert
            onClose={() => setSnackbarMessage(null)}
            severity={snackbarColor}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </FormProvider>
  );
}

export default Profile;
