interface CustomButtonInterface {
  leftIcon?: string;
  rightIcon?: string;
  text: string;
  buttonType?: "submit" | "reset" | "button";
  className?: string;
  onClick?: any;
  disabled?: boolean;
  mainColor?: boolean;
}

function CustomButton({
  buttonType,
  leftIcon,
  rightIcon,
  text,
  className,
  onClick,
  disabled,
  mainColor,
}: CustomButtonInterface) {
  const bg = mainColor
    ? "bg-primary-main"
    : "bg-button-secondary hover:bg-button-primary";

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={buttonType ?? "button"}
      className={`flex space-x-[7px] min-w-[122px] h-[42px] items-center justify-center rounded-lg transition ease-in-out duration-300 ${bg} text-white font-semibold text-base py-2 px-4 ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {leftIcon && <span className="material-icons-outlined">{leftIcon}</span>}

      <span>{text}</span>

      {rightIcon && (
        <span className="material-icons-outlined">{rightIcon}</span>
      )}
    </button>
  );
}

export default CustomButton;
