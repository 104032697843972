/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { sendConfirmBasketShipping } from "../../../../client/Requests";
import useUserData from "../../../../hooks/useUserData";
import OwnerMessage from "./OwnerMessage";
import UserMessage from "./UserMessage";
import { stringToNumber, swapToPennies } from "../../../../utils/PriceUtils";
import { MessagesContext } from "../../../../contexts/MessagesProvider/MessagesProvider";
import { IMessageContext } from "../../../../contexts/MessagesProvider/types";
import ShippingV2 from "../../shipping/ShippingV2";

function Messages() {
  const afterMessageRef = useRef<HTMLDivElement>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarColor, setSnackbarColor] = useState<AlertColor>("success");
  const { userData } = useUserData();
  const { messages, messageBasketShipping, refreshMessagesForThread } =
    useContext(MessagesContext) as IMessageContext;

  const methods = useForm({
    defaultValues: {
      shipping: "inpost",
      shippingInpost: "small",
      customName: "",
      customPrice: "",
    },
    mode: "onSubmit",
  });

  const { handleSubmit, setError, formState } = methods;

  useEffect(() => {
    if (afterMessageRef) {
      if (afterMessageRef.current) {
        afterMessageRef.current?.scrollIntoView({
          block: "end",
        });
      }
    }
  }, [messages, afterMessageRef]);

  const onSubmit = async (data: any) => {
    if (userData && messageBasketShipping) {
      try {
        const { shipping, shippingInpost, customName, customPrice } = data;
        const methodNameSetter = (shippingName: string) => {
          if (
            shippingName === "personalOnly" ||
            shippingName === "unavailableAllProducts" ||
            shippingName === "unavailableSomeProduct"
          ) {
            return null;
          }
          return shippingName;
        };
        const newData = {
          methodName: methodNameSetter(shipping),
          choiceName: shipping === "inpost" ? shippingInpost : null,
          customName:
            customName !== "" && shipping === "other" ? customName : null,
          customPrice:
            customName !== "" && shipping === "other"
              ? swapToPennies(stringToNumber(customPrice))
              : null,
          onlyPersonal: shipping === "personalOnly",
          unavailableSomeProduct: shipping === "unavailableSomeProduct",
          unavailableAllProducts: shipping === "unavailableAllProducts",
        };
        await sendConfirmBasketShipping(
          messageBasketShipping.id,
          newData,
          userData.userToken,
        );
        setSnackbarMessage("Odpowiedź została wysłana pomyślnie");
        setSnackbarColor("success");
        await refreshMessagesForThread();
      } catch (error: any) {
        setSnackbarMessage("Coś poszło nie tak. Spróbuj ponownie później.");
        setSnackbarColor("error");
        setError("shipping", {
          type: "api",
          message: error.response.data
            ? error.response.data
            : "Błąd podczas wysyłania odpowiedzi",
        });
      }
    }
  };

  if (!messages) {
    return (
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  const formIsDisabled =
    messageBasketShipping?.shippingStatus === "ready" ||
    messageBasketShipping?.shippingStatus === "canceled" ||
    formState.isSubmitting;

  return (
    <Box sx={{ overflow: "scroll" }}>
      {messageBasketShipping && !messageBasketShipping.isSent ? (
        <FormProvider {...methods}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack sx={{ p: 2, borderBottomWidth: "1px" }}>
              <Typography variant="caption" sx={{ mb: 2 }}>
                Wybierz formę WSPÓLNEJ dostawy:
              </Typography>
              <ShippingV2
                shippingStatus={messageBasketShipping.shippingStatus}
                formIsDisabled={formIsDisabled}
              />
              <Stack direction="row" sx={{ justifyContent: "end", mt: 2 }}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="secondary"
                  disabled={formIsDisabled}
                >
                  Wyślij odpowiedź
                </Button>
              </Stack>
            </Stack>
          </Box>
        </FormProvider>
      ) : null}

      <Stack direction="column" sx={{ px: 2, pb: 2 }}>
        {messages &&
          messages.map((message: any) => {
            if (message.isSent) {
              return (
                <OwnerMessage message={message.content} key={message.id} />
              );
            }
            return (
              <UserMessage
                message={message.content}
                avatar={message.avatarPath}
                key={message.id}
              />
            );
          })}
      </Stack>
      <Box ref={afterMessageRef} />
      <Snackbar
        open={snackbarMessage !== null && snackbarMessage.length > 0}
        onClose={() => setSnackbarMessage(null)}
      >
        <Alert
          onClose={() => setSnackbarMessage(null)}
          severity={snackbarColor}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Messages;
