export const inputRequired = {
  value: true,
  message: "Pole nie może być puste",
};

export const inputCategoryRequired = {
  value: true,
  message: "Wybierz jedną z proponowanych kategorii",
};

export const inputUsernameMinLength = {
  value: 5,
  message: "Nazwa użytkownika musi mieć przynajmniej 5 znaków",
};

export const inputUsernameRegex = {
  value: /^[^A-Z@]\w+[^@\s]+$/,
  message:
    "Nazwa użytkownika nie może zawierać dużych liter i adresu mailowego",
};

export const inputEmailRegex = {
  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  message: "Błędny format adresu email",
};

export const inputPasswordRegex = {
  value: /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{6,}$/,
  message:
    "Hasło musi zawierać minimum 6 znaków, minimum jedną dużą literę, oraz znak specjalny",
};
export const inputMinLenght = {
  value: 3,
  message: "Minimum 3 znaki",
};

export const inputMessageMinLenght = {
  value: 3,
  message: "Wiadomość jest zbyt krótka",
};

export const inputTitleMinLenght = {
  value: 10,
  message: "Za mało znaków",
};

export const inputTitleMaxLenght = {
  value: 50,
  message: "Za dużo znaków",
};

export const inputDescMinLenght = {
  value: 50,
  message: "Za mało znaków",
};

export const inputDescMaxLenght = {
  value: 2000,
  message: "Za dużo znaków",
};

export const descMinLenght = {
  value: 55,
  message: "Opis jest zbyt krótki. Minimalna długość to 55 znaków",
};

export const inputMaxLenght = {
  value: 255,
  message: "Maksymalnie 255 znaków",
};

export const inputMessageMaxLenght = {
  value: 255,
  message: "Maksymalna długość wiadomości wynosi 255 znaków",
};

export const patternTel = {
  value: /^(\d{3}\s?\d{3}\s?\d{3}|\d{9})$/,
  message: "Numer telefonu powinien składać się z 9 cyfr",
};

export const patternPrice = {
  value:
    /^(?:[1-2]?[0-9]{1,4}|30,000|30000,00|30000,0}|30000)(?:[.,]\d{1,2})?$/,
  message: "Niepoprawny format ceny lub jest ona zbyt duża",
};

export const minPrice = {
  value: 1,
  message: "Proponowana cena powinna wynosić minim 1 zł",
};

export const patternBank = {
  value: /^[0-9]{26}$/,
  message: "Numer konta bankowego powinien składać się z 26 cyfr",
};

export const forbiddenWords = (forbiddenWordsList: string[]) => {
  // console.log(forbiddenWordsList);
  // const regex = `/^(?!.(zakazane-slowo)).$/^(\d{9}|\d{3} ?\d{3} ?\d{3}|\d ?\d ?\d ?\d ?\d ?\d ?\d ?\d ?\d)$/`;
  const regex = new RegExp(
    `^(?!.*(${forbiddenWordsList
      .map((word) => word.replace(/\*\*/g, "\\*\\*"))
      .join("|")
      .replace(/\s/g, "\\s")})).*$`,
  );
  return {
    value: regex,
    message: "Dane kontaktowe i promowanie konkurencji nie są dozwolone",
  };
};
