import { useContext, useMemo } from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../contexts/offerProvider/types";
import { matchCategory } from "../../utils/CatalogFilters";
import NestedCategories from "./NestedCategories";

function CategoriesMenu() {
  const { expandedCategories, categories, handleChangeCategory } = useContext(
    OfferContext,
  ) as IOfferContext;

  const parentsCategories = useMemo(
    () => categories.filter((category) => category.parentId === 1),
    [categories],
  );

  return (
    <List
      sx={{ width: "100%", maxWidth: 360 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {parentsCategories.map((parentCategory) => (
        <Box key={parentCategory.id}>
          <ListItemButton
            sx={{ py: 1 }}
            onClick={() => handleChangeCategory(parentCategory)}
          >
            <ListItemIcon>
              {parentCategory.imagePath && (
                <img
                  src={parentCategory.imagePath}
                  className="w-[32px] max-h-[32px] text-text-primary"
                  alt="icon"
                />
              )}
            </ListItemIcon>
            <ListItemText
              primary={`${parentCategory.name}`}
              primaryTypographyProps={{
                fontSize: 15,
                fontWeight: 700,
              }}
            />
            {matchCategory(parentCategory, expandedCategories) ? (
              <ExpandMore />
            ) : (
              <ArrowForwardIosIcon />
            )}
          </ListItemButton>
          <NestedCategories parentCategory={parentCategory} />
        </Box>
      ))}
    </List>
  );
}

export default CategoriesMenu;
