import { Link, Typography, Box, Button } from "@mui/material";
import { useState } from "react";
import useStorage from "../../hooks/useStorage";

const policyText = `Informujemy, iż w celu realizacji usług dostępnych na naszej stronie, optymalizacji jej treści oraz dostosowania strony do Państwa indywidualnych potrzeb korzystamy z informacji zapisanych za pomocą plików cookies na urządzeniach końcowych użytkowników. Pliki cookies można kontrolować za pomocą ustawień swojej przeglądarki internetowej. Dalsze korzystanie z naszej strony internetowej, bez zmiany ustawień przeglądarki internetowej oznacza, iż użytkownik akceptuje stosowanie plików cookies. Więcej informacji zawartych jest w`;

function CookiesPolicy() {
  const { getData, setData } = useStorage();

  const cookiesCreatedDate = getData("cookies-info")?.created;
  const [open, setOpen] = useState<boolean>(!cookiesCreatedDate);

  if (!open) {
    return null;
  }

  const handleCloseAlert = () => {
    const date = new Date();
    setData("cookies-info", { created: date.toUTCString() });
    setOpen(false);
  };
  const policyLink = (
    <Link
      target="_blank"
      sx={{ color: "inherit", fontWeight: 600, textDecoration: "underline" }}
      href="https://bubaa.pl/page/polityka-cookies"
    >
      polityce cookies
    </Link>
  );
  return (
    <Box
      component="p"
      sx={{
        opacity: 0.95,
        position: "fixed",
        zIndex: 400,
        bottom: { xs: 0, lg: "16px" },
        left: { xs: 0, lg: "50%" },
        transform: { xs: "default", lg: "translatex(-50%)" },
        width: { sx: 1, lg: 1000 },
        p: { xs: 2, lg: 2.5 },
        bgcolor: "secondary.main",
        textAlign: "center",
        color: "common.white",
      }}
    >
      <Box sx={{ display: "flex", alignContent: "start", mb: 1 }}>
        <Typography
          variant="caption"
          component="span"
          sx={{
            color: "inherit",
            lineHeight: { xs: 1.2, lg: 2 },
            fontWeight: 500,
          }}
        >
          {policyText} {policyLink}.
        </Typography>
      </Box>
      <Button variant="contained" onClick={handleCloseAlert}>
        Zgadzam się
      </Button>
    </Box>
  );
}

export default CookiesPolicy;
