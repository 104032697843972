import { Stack, Typography } from "@mui/material";

interface Props {
  emptyOfferText?: string;
}

function EmptyOffers({
  emptyOfferText = "Brak ofert dla podanych kryteriów",
}: Props) {
  return (
    <div className="my-10">
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        className="mt-5"
      >
        <Typography variant="h5">{emptyOfferText}</Typography>
      </Stack>
    </div>
  );
}

export default EmptyOffers;
