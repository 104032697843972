import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { OffersFiltersInterface } from "../models/OffersFiltersInterface";
import defaultOffersFilters from "../staticData/DefaultOffersFilters";
import {
  ProductTemplateAttributeInterface,
  ProductTemplateAttributeValueInterface,
} from "../models/ProductTemplateAttributeInterface";
import { getProductOfferTemplate } from "../client/Requests";
import { ProductCategoryInterface } from "../models/ProductCategoryInterface";
import {
  getAttributes,
  getInitialAttributesSelections,
} from "../utils/CatalogFilters";
import {
  decodeParams,
  getInitialFilters,
  getInitialParams,
} from "../utils/catalogFilters/params";
import { isNotEmptyObject } from "../utils/catalogFilters/objectUtils";
import { Status, isFailure, isSuccess } from "../staticData/fetchingStatus";
import { SortType } from "../components/offerCatalog/filterSort/sortTypes";
import useFiltersLocations from "./useFiltersLocations";

const useFilters = (category: ProductCategoryInterface | null) => {
  const [filters, setFilters] =
    useState<OffersFiltersInterface>(defaultOffersFilters);
  const { provinces, cities } = useFiltersLocations(filters?.province);
  const [productTemplateAttributes, setProductTemplateAttributes] = useState<
    ProductTemplateAttributeInterface[] | null
  >(null);
  const [loadingProductTemplateStatus, setLoadingProductTemplateStatus] =
    useState<Status>("IDLE");
  const [areFiltersInitialized, setAreFiltersInitialized] = useState(false);
  const [searchParams] = useSearchParams();
  const { categorySlug } = useParams();

  useEffect(() => {
    const attributesParams = searchParams.get("attributes");
    if (attributesParams && productTemplateAttributes) {
      const initiateAttrbiutes = getInitialAttributesSelections(
        productTemplateAttributes,
        attributesParams,
      );

      setFilters((prevFilters) => ({
        ...prevFilters,
        attributes: initiateAttrbiutes,
      }));
    }
  }, [productTemplateAttributes]);

  const handleFetchOfferTemplate = async (categoryId: number) => {
    try {
      setLoadingProductTemplateStatus("LOADING");
      const { attributes } = await getProductOfferTemplate(categoryId);
      setLoadingProductTemplateStatus("SUCCESS");
      setProductTemplateAttributes(attributes);
    } catch (error) {
      setLoadingProductTemplateStatus("FAILURE");
      setProductTemplateAttributes([]);
    }
  };

  useEffect(() => {
    if (
      !areFiltersInitialized &&
      (isSuccess(loadingProductTemplateStatus) ||
        isFailure(loadingProductTemplateStatus) ||
        !categorySlug)
    ) {
      const decodedParams = decodeParams(searchParams);
      const initialParams = isNotEmptyObject(decodedParams)
        ? getInitialParams(decodedParams)
        : {};
      const initialFilters = isNotEmptyObject(initialParams)
        ? getInitialFilters(initialParams, productTemplateAttributes ?? [])
        : {};
      setAreFiltersInitialized(true);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...initialFilters,
      }));
    }
  }, [loadingProductTemplateStatus]);

  useEffect(() => {
    if (category) {
      handleFetchOfferTemplate(category.id);
    } else {
      setProductTemplateAttributes(null);
      setFilters((prevFilters) => ({
        ...prevFilters,
        attributes: [],
      }));
    }
  }, [category]);

  const handleChangePage = (page: number) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      currentPage: page,
    }));
  };

  const resetCurrentPage = () =>
    handleChangePage(defaultOffersFilters.currentPage);

  const handleChangeAttributes = (
    attributes: ProductTemplateAttributeValueInterface[],
    attributeFamilyId: number,
  ) => {
    resetCurrentPage();
    setFilters((prevFilters) => ({
      ...prevFilters,
      attributes: getAttributes(
        prevFilters.attributes,
        attributes,
        attributeFamilyId,
      ),
    }));
  };

  const handleChangeSort = (sort: SortType) => {
    resetCurrentPage();
    setFilters((prevFilters) => ({
      ...prevFilters,
      sort,
    }));
  };

  const handleChangeProvince = (province: string | null) => {
    resetCurrentPage();
    setFilters((prevFilters) => ({
      ...prevFilters,
      city: !province ? undefined : prevFilters.city,
      province: province ?? undefined,
    }));
  };

  const handleChangeCity = (city: string | null) => {
    resetCurrentPage();
    setFilters((prevFilters) => ({
      ...prevFilters,
      city: city ?? undefined,
    }));
  };

  const handleChangeMinPrice = (minPrice: string | null) => {
    resetCurrentPage();
    setFilters((prevFilters) => ({
      ...prevFilters,
      minPrice: minPrice || undefined,
    }));
  };

  const handleChangeMaxPrice = (maxPrice: string | null) => {
    resetCurrentPage();
    setFilters((prevFilters) => ({
      ...prevFilters,
      maxPrice: maxPrice || undefined,
    }));
  };

  const handleClearAllFilters = () => {
    setFilters((prevFilters) => ({
      ...defaultOffersFilters,
      sort: prevFilters.sort,
    }));
  };

  return {
    filters,
    handleChangePage,
    productTemplateAttributes,
    loadingProductTemplateStatus,
    handleChangeAttributes,
    areFiltersInitialized,
    handleChangeSort,
    handleChangeProvince,
    handleChangeCity,
    handleChangeMinPrice,
    handleChangeMaxPrice,
    handleClearAllFilters,
    provinces,
    cities,
  };
};

export default useFilters;
