import { useQuery } from "@tanstack/react-query";
import { getUserPublishedOffers } from "../../client/Requests";
import { UserProfileOfferInterface } from "../../models/UserProfileOfferInterface";

export const useUserProfileOffer = (page: number, userProfileId?: string) => {
  const { data, isLoading, isError } =
    useQuery<UserProfileOfferInterface | null>({
      queryKey: ["user-profile-offer", userProfileId || "", page],
      queryFn: () =>
        userProfileId ? getUserPublishedOffers(userProfileId, page - 1) : null,
      refetchOnWindowFocus: false,
      enabled: !!userProfileId,
      retry: 3,
    });

  return {
    userProfileOffer: data,
    isLoading,
    isError,
  };
};
