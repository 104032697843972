import { useContext } from "react";
import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import OffersLoading from "../molecules/OffersLoading";
import EmptyOffers from "../molecules/EmptyOffers";
import OffersPagination from "../molecules/OffersPagination";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../contexts/offerProvider/types";
import defaultOffersCategory from "../../staticData/DefaultOffersCategory";
import CategoriesMenu from "../categoriesMenu/CategoriesMenu";
import CatalogBreadCrumbs from "./CatalogBreadCrumbs";
import CategoriesMenuMobile from "../categoriesMenu/CategoriesMenuMobile";
import OfferCard from "./OfferCard";
import CatalogFilters from "../catalogFilters/CatalogFilters";
import { getPagesCount } from "../../utils/paginationUtils";
import PageTitle from "../atoms/PageTitle";

function OfferCatalogList() {
  const {
    currentOffer,
    filters,
    isLoading,
    currentCategory,
    handleChangePage,
    offerCount,
  } = useContext(OfferContext) as IOfferContext;
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <>
      <CatalogBreadCrumbs />
      <PageTitle title={currentCategory?.name || defaultOffersCategory.name} />
      <Divider sx={{ my: 2 }} />
      <CatalogFilters />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} lg={4}>
          {isMobile ? <CategoriesMenuMobile /> : <CategoriesMenu />}
        </Grid>
        <Grid item xs={12} sm={7} lg={8}>
          {isLoading ? (
            <OffersLoading />
          ) : currentOffer.length === 0 ? (
            <EmptyOffers />
          ) : null}

          {!isLoading && (
            <Box sx={{ height: 1 }}>
              <Grid container spacing={2} alignItems="stretch">
                {currentOffer.map((offer) => (
                  <Grid key={offer.id} item xs={6} md={4} lg={3}>
                    <OfferCard offer={offer} />
                  </Grid>
                ))}
              </Grid>

              <Box sx={{ mt: 2.25 }}>
                {offerCount > 20 && (
                  <OffersPagination
                    currentPage={filters.currentPage}
                    pagesCount={getPagesCount(offerCount)}
                    handleClickPage={handleChangePage}
                  />
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default OfferCatalogList;
