import {
  Box,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ProductCategoryInterface } from "../../models/ProductCategoryInterface";

type Props = {
  onClose: () => void;
  parentCategory?: ProductCategoryInterface;
  onReturnToCategory?: () => void;
};

function Navigation({ onClose, parentCategory, onReturnToCategory }: Props) {
  return (
    <>
      <Box
        display="flex"
        justifyContent={parentCategory ? "space-between" : "flex-end"}
        alignItems="center"
        marginRight={1}
        minHeight={56}
      >
        {parentCategory && (
          <ListItemButton
            sx={{ py: 1.5 }}
            onClick={() => onReturnToCategory?.()}
          >
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${parentCategory.name}`}
              primaryTypographyProps={{
                fontSize: 15,
                fontWeight: 700,
              }}
            />
          </ListItemButton>
        )}
        <IconButton
          aria-label="close-menu"
          onClick={() => onClose()}
          sx={{ width: 45, height: 45 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
    </>
  );
}

export default Navigation;
