import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MenuItem from "../../atoms/MenuItem";
import useUserData from "../../../hooks/useUserData";
import LogoutUserDialog from "../dialog/LogoutUserDialog";
import DeleteUserDialog from "../dialog/DeleteUserDialog";
import { deleteUser } from "../../../client/Requests";

function AccountMenuList() {
  const { clearUserData } = useUserData();
  const navigate = useNavigate();
  const [openLogoutUserDialog, setOpenLogoutUserDialog] = useState(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const { userData } = useUserData();

  const logoutUser = async () => {
    await clearUserData();
    await navigate("/");
    await navigate(0);
  };

  const handleDeleteUser = async () => {
    if (!userData) {
      return;
    }

    await deleteUser(userData.userToken);
    await logoutUser();
  };

  return (
    <>
      <LogoutUserDialog
        initialOpen={openLogoutUserDialog}
        handleYes={() => {
          setOpenLogoutUserDialog(false);
          logoutUser();
        }}
        handleClose={() => {
          setOpenLogoutUserDialog(false);
        }}
      />
      <DeleteUserDialog
        initialOpen={openDeleteUserDialog}
        handleYes={() => {
          setOpenDeleteUserDialog(false);
          handleDeleteUser();
        }}
        handleClose={() => {
          setOpenDeleteUserDialog(false);
        }}
      />
      <ul>
        <li>
          <MenuItem
            text="Zmiana hasła"
            className="font-semibold"
            onClick={() => {
              navigate("/account/zmiana-hasla");
            }}
          />
        </li>
        <li>
          <MenuItem
            text="O nas"
            className="font-semibold"
            onClick={() => {
              navigate("/page/o-nas");
            }}
          />
        </li>
        <li>
          <MenuItem
            text="FAQ"
            className="font-semibold"
            onClick={() => {
              navigate("/faq");
            }}
          />
        </li>
        <li>
          <MenuItem
            text={`Regulamin \n i polityka prywatności`}
            className="font-semibold whitespace-pre-line"
            onClick={() => {
              navigate("/page/regulamin-i-polityka-prywatnosci");
            }}
          />
        </li>
        <li>
          <MenuItem
            text="Cennik"
            className="font-semibold"
            onClick={() => {
              navigate("/page/cennik");
            }}
          />
        </li>
        <li>
          <MenuItem
            text="Kontakt"
            className="font-semibold"
            onClick={() => {
              navigate("/page/kontakt");
            }}
          />
        </li>
        {/* <li> */}
        {/*  <MenuItem */}
        {/*    text="Regulamin promocji Wysyłka InPost za 5zł" */}
        {/*    className="font-semibold" */}
        {/*    onClick={() => { */}
        {/*      navigate("/page/regulamin-promocji-wysylka-inpost-za-5-zl"); */}
        {/*    }} */}
        {/*  /> */}
        {/* </li> */}
        <li>
          <MenuItem
            text="Wyloguj się"
            className="font-semibold"
            onClick={() => {
              setOpenLogoutUserDialog(true);
            }}
          />
        </li>
        <li>
          <MenuItem
            text="Usuń konto"
            className="font-semibold text-error-main"
            onClick={() => {
              setOpenDeleteUserDialog(true);
            }}
          />
        </li>
      </ul>
    </>
  );
}

export default AccountMenuList;
