import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { City, Province, getParentAssistantOffer } from "../client/Requests";
import { ParentAssistantOffersInterface } from "../models/ParentAssistantOffersInterface";
import useUserData from "./useUserData";
import { BasicOfferFiltersInterface } from "../models/OffersFiltersInterface";
import { builBasicOfferdApiUrlParams } from "../utils/paramUtils";

export const useParentAssistantOffer = (
  filters: BasicOfferFiltersInterface,
  provinces: Province[],
  cities: City[],
) => {
  const { assistantCode } = useParams();
  const { userData } = useUserData();
  const userToken = userData?.userToken;
  const isProvinceDataComplete = filters.province ? !!provinces.length : true;
  const isCityDataComplete = filters.city ? !!cities.length : true;
  const locationDataIsValid = isProvinceDataComplete && isCityDataComplete;

  const getApiParams = () => {
    return builBasicOfferdApiUrlParams(filters, provinces, cities);
  };

  const { data, isLoading } = useQuery<ParentAssistantOffersInterface>({
    queryKey: ["parent-assistant-short-offers", JSON.stringify(filters)],
    queryFn:
      userToken && assistantCode
        ? () =>
            getParentAssistantOffer(
              userToken,
              assistantCode,
              getApiParams(),
            ).then((res) => res.data)
        : undefined,
    refetchOnWindowFocus: false,
    enabled: !!userToken && !!assistantCode && locationDataIsValid,
    retry: 3,
  });

  return {
    parentAssistantOffer: data,
    isLoading,
  };
};
