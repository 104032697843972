import axios from "axios";

const sendFormRequest = async (
  endpoint: string,
  formData: FormData,
  requestHeaders?: any,
) => {
  const headers = {
    ...requestHeaders,
    "Content-Type": "multipart/form-data",
  };

  const requestConfig = {
    method: "POST",
    baseURL: process.env.REACT_APP_API_URL,
    url: endpoint,
    data: formData,
    headers,
  };

  return axios.request(requestConfig);
};

export default sendFormRequest;
