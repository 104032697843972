import { Badge, Box, Tabs } from "@mui/material";
import { Stack } from "@mui/system";
import { SyntheticEvent, useState } from "react";
import SellingTransactions from "../../components/userTransactions/SellingTransactions";
import SoldTransactions from "../../components/userTransactions/SoldTransactions";
import { MainTab } from "../../components/userTransactions/MainTabs";
import BuyingTransactions from "../../components/userTransactions/BuyingTransactions";
import { useTransactionsCounters } from "../../hooks/useTransactionsCounters";

function Transactions() {
  const [tabsValue, setTabsValue] = useState("selling");
  const { sellingCount, soldCount, buyingCount } = useTransactionsCounters();

  const handleChangeTab = (event: SyntheticEvent, newValue: string) => {
    setTabsValue(newValue);
  };

  return (
    <Stack>
      <Box
        sx={{
          my: { xs: 2, md: 4 },
          pt: 1,
          backgroundColor: "#fff",
        }}
      >
        <Tabs value={tabsValue} onChange={handleChangeTab} variant="fullWidth">
          <MainTab
            value="selling"
            label={
              <Badge
                sx={{ pr: 1.5 }}
                color="secondary"
                badgeContent={sellingCount}
                invisible={!sellingCount}
              >
                Wystawione
              </Badge>
            }
            sx={{ flexGrow: 1 }}
          />
          <MainTab
            value="sold"
            label={
              <Badge
                sx={{ pr: 1.5 }}
                color="secondary"
                badgeContent={soldCount}
                invisible={!sellingCount}
              >
                Sprzedane
              </Badge>
            }
            sx={{ flexGrow: 1 }}
          />
          <MainTab
            value="buying"
            label={
              <Badge
                sx={{ pr: 1.5 }}
                color="secondary"
                badgeContent={buyingCount}
                invisible={!sellingCount}
              >
                Kupione
              </Badge>
            }
            sx={{ flexGrow: 1 }}
          />
        </Tabs>
      </Box>
      {tabsValue === "selling" && <SellingTransactions />}
      {tabsValue === "sold" && <SoldTransactions />}
      {tabsValue === "buying" && <BuyingTransactions />}
    </Stack>
  );
}

export default Transactions;
