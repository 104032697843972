import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

interface ModalForInpostInterface {
  isVisible: boolean;
  onClickOut: any;
  onPointSelect: any;
}

function ModalForInpost({
  isVisible,
  onClickOut,
  onPointSelect,
}: ModalForInpostInterface) {
  useEffect(() => {
    document.addEventListener("onPointCallback", (event) =>
      // @ts-ignore
      onPointSelect(event.detail),
    );
  }, [onPointSelect]);

  return (
    <>
      <Helmet>
        <script
          src={`${process.env.REACT_APP_INPOST_GEOWIDGET}/inpost-geowidget.js`}
          defer
        />
        <link
          href={`${process.env.REACT_APP_INPOST_GEOWIDGET}/inpost-geowidget.css`}
          rel="stylesheet"
        />
      </Helmet>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={`${
          !isVisible && "hidden"
        } fixed z-50 inset-0 overflow-y-auto`}
        onClick={onClickOut}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="w-[900px] h-[800px] inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
            {/* @ts-ignore */}
            <inpost-geowidget
              onpoint="onPointCallback"
              token={process.env.REACT_APP_INPOST_GEOWIDGET_TOKEN}
              language="pl"
              config="parcelCollect"
            />
          </div>
        </div>
      </div>
    </>
  );
}

// @ts-ignore
export default ModalForInpost;
