// eslint-disable-next-line @typescript-eslint/naming-convention
type typographyType =
  | "h1"
  | "h2"
  | "h3"
  | "body1"
  | "body2"
  | "body3"
  | "captions"
  | "menu"
  | "menuMobile"
  | "badge"
  | "input"
  | "profile"
  | "avatar"
  | "helper"
  | "text"
  | "profilName"
  | "body2-b"
  | "buttonLarge"
  | "buttonMedium"
  | "buttonSmall"
  | "message";

interface TypographyInterface {
  type: typographyType;
  text: string | number;
  className?: string;
}

function Typography({ type, text, className }: TypographyInterface) {
  switch (type) {
    case "h1":
      return (
        <h1
          className={`font-bold text-2xl md:text-3xl tracking-wider ${className}`}
        >
          {text}
        </h1>
      );
    case "h2":
      return (
        <h2
          className={`font-bold text-xl md:text-2xl tracking-wider  ${className}`}
        >
          {text}
        </h2>
      );
    case "h3":
      return (
        <h3 className={`font-bold text-xl tracking-wide ${className}`}>
          {text}
        </h3>
      );
    case "body1":
      return (
        <h4 className={`font-normal text-base tracking-wide ${className}`}>
          {text}
        </h4>
      );
    case "body2":
      return <p className={`font-medium text-sm ${className}`}>{text}</p>;
    case "body2-b":
      return <p className={`font-bold text-sm ${className}`}>{text}</p>;
    case "body3":
      return (
        <h4
          className={`font-medium text-[16px] tracking-wider leading-[24px] md:text-[22px] md:tracking-wide md:leading-[35px] ${className}`}
        >
          {text}
        </h4>
      );
    case "captions":
      return (
        <p className={`font-normal text-xs tracking-wider ${className}`}>
          {text}
        </p>
      );
    case "menu":
      return (
        <span className={`font-normal text-base tracking-wide ${className}`}>
          {text}
        </span>
      );
    case "menuMobile":
      return (
        <span className={`font-normal text-x2s tracking-wider ${className}`}>
          {text}
        </span>
      );
    case "badge":
      return (
        <span
          className={`font-bold text-[0.75rem] tracking-wider leading-[1.188rem] ${className}`}
        >
          {text}
        </span>
      );
    case "input":
      return (
        <span
          className={`font-medium text-[0.75rem] tracking-wider leading-[0.75rem] ${className}`}
        >
          {text}
        </span>
      );
    case "profile":
      return (
        <span
          className={`font-bold text-[0.875rem] tracking-wider leading-[1.5rem] ${className}`}
        >
          {text}
        </span>
      );
    case "avatar":
      return (
        <span
          className={`font-normal text-[1.25rem] tracking-wider leading-[1.5rem] ${className}`}
        >
          {text}
        </span>
      );
    case "helper":
      return (
        <span
          className={`font-semibold text-[0.75rem] tracking-wider leading-[1.25rem] ${className}`}
        >
          {text}
        </span>
      );
    case "profilName":
      return (
        <p className={`font-semibold text-sm leading-[1.5rem] ${className}`}>
          {text}
        </p>
      );
    case "text":
      return (
        <span className={`text-base tracking-wide ${className}`}>{text}</span>
      );
    case "buttonLarge":
      return (
        <span
          className={`font-semibold tracking-widest leading-[1.625rem] ${className}`}
        >
          {text}
        </span>
      );
    case "buttonMedium":
      return (
        <p
          className={`font-semibold text-[0.875rem] leading-[1.313rem] tracking-[0.04em] ${className}`}
        >
          {text}
        </p>
      );
    case "message":
      return (
        <p
          className={`font-medium text-[0.9375rem] leading-[1.407rem] tracking-wide ${className}`}
        >
          {text}
        </p>
      );
    default:
      return <span>{text}</span>;
  }
}

export default Typography;
