import { object, ref, string } from "yup";
import { inputRequired } from "../utils/inputValidate";

export const resetPasswordSchema = () =>
  object({
    password1: string().required(inputRequired.message),
    password2: string().oneOf(
      [ref("password1")],
      "Oba hasła muszą być identyczne!",
    ),
  });
