import { Controller, useForm } from "react-hook-form";
import { useContext, useEffect, useRef } from "react";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { yupResolver } from "@hookform/resolvers/yup";
import { AnswerForThread } from "../../../../client/Requests";
import useUserData from "../../../../hooks/useUserData";
import { MessagesContext } from "../../../../contexts/MessagesProvider/MessagesProvider";
import { IMessageContext } from "../../../../contexts/MessagesProvider/types";
import messageFormSchema from "../../../../schemas/messageSendSchema";

interface IFormValues {
  message: string;
}

function MessagePanelInputSend({
  forbiddenWordsList,
  idMessageThread,
}: {
  forbiddenWordsList: any;
  idMessageThread: null | number;
}) {
  const { userData } = useUserData();
  const { addNewMessage } = useContext(MessagesContext) as IMessageContext;
  const { handleSubmit, control, setValue, setError } = useForm<any>({
    defaultValues: {
      message: "",
    },
    resolver: yupResolver(messageFormSchema(forbiddenWordsList)),
    mode: "onSubmit",
  });
  const onSubmit = async (data: IFormValues) => {
    if (userData && idMessageThread) {
      try {
        const answer = await AnswerForThread(
          data.message,
          idMessageThread,
          userData.userToken,
        );
        addNewMessage(answer.data);
      } catch (error: any) {
        setError("message", {
          type: "api",
          message: error?.response.data && "Błąd podczas wysyłania wiadomości",
        });
      }
      setValue("message", "");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ p: 2, borderTopWidth: "1px" }}
    >
      <Controller
        control={control}
        name="message"
        render={({
          field: { onChange, value },
          fieldState: { invalid, error },
        }) => (
          <TextField
            placeholder="Twoja wiadomość"
            onChange={onChange}
            value={value}
            error={invalid}
            helperText={error?.message}
            sx={{
              width: 1,
              borderRadius: 1,
              "& fieldset": {
                border: invalid ? "auto" : "none",
                backgroundColor: "#1A243814",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit" disabled={invalid}>
                    <ArrowForwardIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}

export default MessagePanelInputSend;
