export const swapFromPennies = (price: number): number => price / 100;

export const swapToPennies = (price: number): number => Math.round(price * 100);

export const getFormattedPrice = (price: number): string => {
  const fixedPrice = swapFromPennies(price);

  return `${fixedPrice.toFixed(2).replace(".", ",")}\xA0zł`;
};

export const stringToNumber = (price: string): number => {
  return Number(price.replace(",", "."));
};

export const formatPriceNumber = (num: number) => {
  const formattedNum = (num / 100).toFixed(2);
  return formattedNum;
};

export const convertPriceToString = (price?: number | null) => {
  if (!price) {
    return null;
  }
  return formatPriceNumber(price).toString().replace(".", ",");
};
