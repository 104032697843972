import { Box, Theme, Typography, alpha } from "@mui/material";
import { BasketInterface } from "../../models/BasketInterface";

type Props = {
  basket: BasketInterface | null;
};

function BasketSummaryDescription({ basket }: Props) {
  return (
    <Box sx={{ textAlign: "center", px: 1, mb: 8 }}>
      {basket && basket.shippingStatus === "ready" && (
        <Typography sx={{ color: "textLight" }} variant="caption">
          {basket?.inpostShippingCost
            ? "Po opłaceniu prowizji i kosztów dostawy InPost, otrzymasz niezbędne dane \n" +
              "do rozliczenia się ze Sprzedającym(i)."
            : "Po opłaceniu prowizji, otrzymasz niezbędne dane \n" +
              "do rozliczenia się ze Sprzedającym(i)."}
        </Typography>
      )}
    </Box>
  );
}

export default BasketSummaryDescription;
