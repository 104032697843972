import Typography from "./Typography";

type AlertType = "info" | "warning" | "error";

interface AlertInterface {
  type: AlertType;
  icon?: string;
  text: string;
}

function Alert({ type, icon, text }: AlertInterface) {
  let background = "";
  let iconColor = "";

  switch (type) {
    case "info":
      background = "bg-light-info-light";
      iconColor = "text-light-info-main";
      break;
    case "warning":
      background = "bg-yellow-100";
      iconColor = "text-warning-main";
      break;
    case "error":
      background = "bg-red-100";
      iconColor = "text-error-main";
      break;
    default:
      background = "";
      iconColor = "";
  }

  return (
    <div
      className={`w-full flex space-x-3 items-center rounded-md p-4 ${background}`}
    >
      {icon && (
        <span className={`material-icons-outlined ${iconColor}`}>{icon}</span>
      )}
      <Typography type="body2" text={text} className="text-text-secondary" />
    </div>
  );
}

export default Alert;
