import { Box, IconButton, SxProps, Theme, useMediaQuery } from "@mui/material";
import { ReactElement, createContext } from "react";
import { styled } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useScrollOnDrag } from "../../hooks/useScrollOnDrag";

const MyScrollingElement = styled(Box)(() => ({
  overflow: "auto",
  scrollbarWidth: "none", // Hide the scrollbar for firefox
  "&::-webkit-scrollbar": {
    display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
  },
  "&-ms-overflow-style:": {
    display: "none", // Hide the scrollbar for IE
  },
}));

const carouselStyles: SxProps<Theme> = {
  display: "flex",
  overflow: "scroll",

  scrollbarWidth: "none", // Hide the scrollbar for firefox
  "&::-webkit-scrollbar": {
    display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
  },
  "&-ms-overflow-style:": {
    display: "none", // Hide the scrollbar for IE
  },
};

export type CarouselContextProvider = {
  isDragging: boolean;
};

export const CarouselContext = createContext<CarouselContextProvider | null>(
  null,
);

interface Props {
  children: (ReactElement | null)[];
}

function Carousel({ children }: Props) {
  const {
    containerRef,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isDragging,
  } = useScrollOnDrag();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const handleScrollLeft = () => {
    if (containerRef?.current) {
      containerRef.current.scrollLeft -= 150;
    }
  };

  const handleScrollRight = () => {
    if (containerRef?.current) {
      containerRef.current.scrollLeft += 150;
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CarouselContext.Provider value={{ isDragging }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {isDesktop && (
          <IconButton aria-label="scroll-left" onClick={handleScrollLeft}>
            <ChevronLeftIcon sx={{ fontSize: 28 }} />
          </IconButton>
        )}
        <Box
          sx={carouselStyles}
          ref={containerRef}
          onMouseUp={handleMouseUp}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
        >
          {children}
        </Box>
        {isDesktop && (
          <IconButton aria-label="scroll-right" onClick={handleScrollRight}>
            <ChevronRightIcon sx={{ fontSize: 28 }} />
          </IconButton>
        )}
      </Box>
    </CarouselContext.Provider>
  );
}

export default Carousel;
