import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { getProductOfferAddressSummary } from "../../client/Requests";
import useUserData from "../../hooks/useUserData";
import { AddressSummaryInterface } from "../../models/AddressSummaryInterface";
import SectionLayoutContent from "../../components/layout/SectionLayoutContent";
import SectionLayout from "../../components/layout/SectionLayout";

function AddressSummary() {
  const { userData } = useUserData();
  const { id } = useParams();
  const offerId = id;
  const navigate = useNavigate();
  const [addressSummary, setAddressSummary] =
    useState<AddressSummaryInterface | null>(null);

  useEffect(() => {
    if (typeof offerId !== "string") {
      navigate("/");
      return;
    }

    if (userData) {
      getProductOfferAddressSummary(
        userData.userToken,
        parseInt(offerId, 10),
      ).then((response) => {
        setAddressSummary(response.data);
      });
    }
  }, [userData, offerId]);

  return (
    <SectionLayout
      defaultLayoutProps={{ width: 500 }}
      sectionTitle="Dane do wysyłki"
      breadcrumbs={[
        { text: "Strona główna", link: "/" },
        { text: "Oferta", link: `/oferta/${addressSummary?.slug}` },
        { text: "Dane do wysyłki" },
      ]}
    >
      <SectionLayoutContent styles={{ p: 3 }}>
        <Box>
          {addressSummary && !addressSummary.shipmentLabel && (
            <Stack>
              <Typography fontWeight={500}>
                {addressSummary.buyerFirstName}
                {` `}
                {addressSummary.buyerLastName}
              </Typography>
              <Typography fontWeight={500}>{addressSummary.address}</Typography>
            </Stack>
          )}
          {addressSummary?.shipmentLabel && (
            <Stack spacing={2}>
              <Typography fontWeight={700}>Etykieta wysyłkowa</Typography>
              <Typography>
                Aby nadać przesyłkę, wydrukuj załączoną do maila etykietę
                wysyłkową InPost, przyklej ją do paczki, włóż do najbliższego
                paczkomatu i gotowe!
              </Typography>
              <Typography color="error">
                Dopiero po otrzymaniu płatności od kupującego na swój rachunek
                bankowy podany w aplikacji, wyślij przedmiot za pomocą InPost.
              </Typography>
              <Button
                sx={{ fontWeight: 600 }}
                href={addressSummary?.shipmentLabel}
                target="_blank"
                download
                endIcon={<DownloadIcon />}
              >
                Pobierz
              </Button>
            </Stack>
          )}
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: 1, mt: 3 }}
            onClick={() => navigate(-1)}
          >
            Wróć
          </Button>
        </Box>
      </SectionLayoutContent>
    </SectionLayout>
  );
}

export default AddressSummary;
