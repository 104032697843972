import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getCategories, getMainCategories } from "../../client/Requests";
import { ProductCategoryInterface } from "../../models/ProductCategoryInterface";
import useUserData from "../../hooks/useUserData";
import EntryUserDialog from "./dialog/EntryUserDialog";
import EmptyUserProfileDialog from "./dialog/EmptyUserProfileDialog";

interface CategoryListInterface {
  visibleMobileCategories?: boolean;
}

function CategoryList({ visibleMobileCategories }: CategoryListInterface) {
  const { getUserData } = useUserData();
  const navigate = useNavigate();
  const [displayedCategories, setDisplayedCategories] = useState<
    ProductCategoryInterface[]
  >([]);
  const [categories, setCategories] = useState<ProductCategoryInterface[]>([]);
  const [openEntryUserDialog, setOpenEntryUserDialog] =
    useState<boolean>(false);
  const [openEmptyUserProfileDialog, setOpenEmptyUserProfileDialog] =
    useState<boolean>(false);

  useEffect(() => {
    getMainCategories().then((data) => {
      setDisplayedCategories(data);
    });
    getCategories().then((data) => setCategories(data));
  }, []);

  return (
    <Box sx={{ backgroundColor: "white", width: 1, height: 1 }}>
      <EntryUserDialog
        initialOpen={openEntryUserDialog}
        handleClose={() => {
          setOpenEntryUserDialog(false);
        }}
      />
      <EmptyUserProfileDialog
        initialOpen={openEmptyUserProfileDialog}
        handleClose={() => {
          setOpenEmptyUserProfileDialog(false);
        }}
      />
      <Box sx={{ width: 1, px: 3, pb: 2, pt: 3 }}>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          color="secondary"
          sx={{ width: 1 }}
          size="large"
          onClick={() => {
            const newUserData = getUserData();
            if (!newUserData) {
              setOpenEntryUserDialog(true);
              return;
            }
            if (!newUserData.isUserProfileComplete) {
              setOpenEmptyUserProfileDialog(true);
              return;
            }

            navigate("/offers/create");
          }}
        >
          Sprzedaj produkt
        </Button>
      </Box>
      <List
        sx={{ width: "100%", maxWidth: 360 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {displayedCategories.map((category) => {
          return (
            <Box key={category.id}>
              <ListItemButton
                sx={{ px: 2, py: 1 }}
                onClick={() => navigate(`/kategoria/${category.slug}`)}
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  color="primary.light"
                >
                  {category.imagePath && (
                    <Box
                      sx={{ color: "text.primary", width: 24, height: 24 }}
                      component="img"
                      src={category.imagePath}
                      alt="icon"
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={`${category.name}`}
                  primaryTypographyProps={{
                    fontSize: 15,
                  }}
                />
                <ArrowForwardIosIcon sx={{ ml: 1, opacity: 0.64 }} />
              </ListItemButton>
            </Box>
          );
        })}
      </List>
    </Box>
  );
}

export default CategoryList;
