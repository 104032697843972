/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Typography,
  CircularProgress,
  Stack,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DefaultLayout from "../components/layout/DefaultLayout";
import SectionLayoutContent from "../components/layout/SectionLayoutContent";
import InputMUI from "../components/atoms/Inputs/InputMUI";
import { fetchResetPassword } from "../client/Requests";
import { resetPasswordSchema } from "../schemas/resetPasswordSchema";
import PageTitle from "../components/atoms/PageTitle";
import SectionLayout from "../components/layout/SectionLayout";

interface FormTypes {
  password1: string;
  password2: string;
}

function ResetPassword() {
  const [openAlert, setOpenAlert] = useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/zapomnialem-hasla");
    }
  }, []);

  const handleClickShowPassword1 = () => setShowPassword1((show1) => !show1);
  const handleClickShowPassword2 = () => setShowPassword2((show2) => !show2);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: ({ password1, password2 }: FormTypes) =>
      fetchResetPassword(password1, password2, token as string),
  });

  const { handleSubmit, control } = useForm<any>({
    defaultValues: {
      password1: "",
      password2: "",
    },
    resolver: yupResolver(resetPasswordSchema()),
  });

  useEffect(() => {
    if (isSuccess || isError) {
      setOpenAlert(true);
    }
  }, [isSuccess, isError]);

  const onSubmit = (data: { password1: string; password2: string }) => {
    mutate({ password1: data.password1, password2: data.password2 });
  };

  return (
    <SectionLayout
      defaultLayoutProps={{ width: 500 }}
      sectionTitle="Resetuj hasło"
    >
      <SectionLayoutContent styles={{ p: 3 }}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <>
            <Stack spacing={2}>
              <InputMUI
                control={control}
                name="password1"
                placeholder="Hasło"
                type={showPassword1 ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <InputMUI
                control={control}
                name="password2"
                placeholder="Hasło"
                type={showPassword2 ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText>
                Hasło musi zawierać: minimum 6 znaków, dużą literę i znak
                specjalny
              </FormHelperText>
            </Stack>

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              sx={{ width: 1, mt: 4, mb: 3, borderRadius: 5 }}
              {...(isLoading && {
                endIcon: (
                  <CircularProgress color="inherit" size={24} sx={{ ml: 2 }} />
                ),
              })}
            >
              Zmień
            </Button>
            <Collapse in={openAlert}>
              {isSuccess && (
                <Alert
                  severity="success"
                  onClose={() => {
                    setOpenAlert(false);
                  }}
                >
                  Hasło zostało zmienione poprawnie!
                </Alert>
              )}
              {isError && (
                <Alert
                  severity="error"
                  onClose={() => {
                    setOpenAlert(false);
                  }}
                >
                  Coś poszło nie tak. Spróbuj ponownie później.
                </Alert>
              )}
            </Collapse>
            {isSuccess && (
              <Box
                sx={{
                  display: "flex",
                  width: 1,
                  justifyContent: "center",
                  mt: 3,
                }}
              >
                <Link to="/login">
                  <Typography
                    variant="body1"
                    color="primary.main"
                    sx={{ fontWeight: 600 }}
                  >
                    Zaloguj się
                  </Typography>
                </Link>
              </Box>
            )}
          </>
        </Box>
      </SectionLayoutContent>
    </SectionLayout>
  );
}

export default ResetPassword;
