import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete, Box, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  ProductTemplateAttributeInterface,
  ProductTemplateAttributeValueInterface,
} from "../../../models/ProductTemplateAttributeInterface";
import { OfferContext } from "../../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../../contexts/offerProvider/types";

interface Props {
  productTemplateAttribute: ProductTemplateAttributeInterface;
}

function FilterAttributesMultiple({ productTemplateAttribute }: Props) {
  const { handleChangeAttributes, filters } = useContext(
    OfferContext,
  ) as IOfferContext;

  const selectedAttributes = filters.attributes.filter(
    (FilterAttributeValue) =>
      FilterAttributeValue.attributeFamilyId === productTemplateAttribute.id,
  );

  const defaultValue = useMemo(
    () =>
      selectedAttributes.length
        ? selectedAttributes.map((selectedAttribute) => selectedAttribute.name)
        : [],
    [],
  );

  const [attributesValues, setAttributesValues] = useState(defaultValue);

  useEffect(() => {
    if (attributesValues.length !== selectedAttributes.length) {
      const selectedAttributesNames = selectedAttributes.map(
        (selectedAttribute) => selectedAttribute.name,
      );
      setAttributesValues(selectedAttributesNames);
    }
  }, [filters.attributes]);

  const handleAttributeChange = (
    attributeFamilyId: number,
    attributes: ProductTemplateAttributeValueInterface[] | null,
    values: string[],
  ) => {
    setAttributesValues(values);
    const familltyAttributes = attributes?.map((attribute) => ({
      ...attribute,
      attributeFamilyId,
      attributeFamilyName: productTemplateAttribute.name,
    }));

    handleChangeAttributes(
      familltyAttributes?.length ? familltyAttributes : [],
      attributeFamilyId,
    );
  };

  const getSelectedAttributes = (selectedValues: string[]) => {
    return productTemplateAttribute.values.filter((attribute) =>
      selectedValues.some((selectedValue) => selectedValue === attribute.name),
    );
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Autocomplete
      multiple
      disablePortal
      options={productTemplateAttribute.values.map(
        (attribute) => attribute.name,
      )}
      disableCloseOnSelect
      defaultValue={defaultValue}
      value={attributesValues}
      onChange={(e, values) => {
        handleAttributeChange(
          productTemplateAttribute.id,
          getSelectedAttributes(values),
          values,
        );
      }}
      noOptionsText="Nie znaleziono takiego atrybutu"
      renderTags={(value: readonly string[]) =>
        value.map((option: string) => option).join(", ")
      }
      renderOption={(props, option, { selected }) => (
        <Box component="li" {...props}>
          <Box
            sx={{
              width: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {option}
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          </Box>
        </Box>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            size="small"
            label={productTemplateAttribute.name}
            inputProps={{
              ...params.inputProps,
            }}
          />
        );
      }}
    />
  );
}

export default FilterAttributesMultiple;
