import { Typography } from "@mui/material";

interface IPageTitle {
  title: string;
}

function PageTitle({ title }: IPageTitle) {
  return (
    <Typography
      variant="h4"
      component="h1"
      sx={{
        pt: 2,
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
  );
}

export default PageTitle;
