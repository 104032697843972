import { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMatch } from "react-router-dom";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../contexts/offerProvider/types";
import CategoryList from "./CategoryList";
import { ProductCategoryInterface } from "../../models/ProductCategoryInterface";
import Navigation from "./Navigation";

interface Props {
  onClose?: () => void;
}

function CategoriesMenuMobile({ onClose }: Props) {
  const homeMatch = useMatch("/");
  const isHome = homeMatch?.pathname === "/";
  const { categories } = useContext(OfferContext) as IOfferContext;
  const [isOpen, setIsOpen] = useState(isHome ?? false);
  const [selectedCategory, setSelectedCategory] =
    useState<ProductCategoryInterface | null>(null);

  const parentsCategories = useMemo(
    () => categories.filter((c) => c.parentId === 1),
    [categories],
  );

  const handleReturnToParent = () => {
    setSelectedCategory(null);
  };

  const handleCloseMenu = () => {
    onClose?.();
    setSelectedCategory(null);
    setIsOpen(false);
  };

  if (isHome && !isOpen) {
    return null;
  }

  if (!isOpen) {
    return (
      <IconButton aria-label="open-menu" onClick={() => setIsOpen(true)}>
        <MenuOpenIcon />
      </IconButton>
    );
  }

  if (selectedCategory) {
    return (
      <Dialog open={isOpen} fullScreen>
        <CategoryList
          parentCategory={selectedCategory}
          onClose={() => handleCloseMenu()}
          onSelectParent={handleReturnToParent}
        />
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} fullScreen>
      <>
        <Navigation onClose={() => handleCloseMenu()} />
        <List
          sx={{ width: "100%", bgclor: "background.paper", py: 0 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {parentsCategories.map((parentCategory) => (
            <Box key={parentCategory.id}>
              <ListItemButton
                sx={{ py: 1.5 }}
                onClick={() => setSelectedCategory(parentCategory)}
              >
                <ListItemIcon>
                  {parentCategory.imagePath && (
                    <img
                      src={parentCategory.imagePath}
                      className="w-[32px] max-h-[32px] text-text-primary"
                      alt="icon"
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={`${parentCategory.name}`}
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                />
                <ArrowForwardIosIcon />
              </ListItemButton>
            </Box>
          ))}
        </List>
      </>
    </Dialog>
  );
}

export default CategoriesMenuMobile;
