import { Avatar } from "@mui/material";
import Typography from "../../../atoms/Typography";

function UserMessage({ message, avatar }: { message: string; avatar: string }) {
  return (
    <div className="flex mt-6 items-start">
      <Avatar alt="avatar" src={avatar} sx={{ mr: 1 }} />
      <div className="bg-light-messageBox-grey py-1 px-2 rounded-[14px] w-full">
        <Typography type="message" text={message} className="text-left" />
      </div>
    </div>
  );
}

export default UserMessage;
