import { array, object, string } from "yup";
import {
  inputDescMaxLenght,
  inputDescMinLenght,
  inputRequired,
  inputTitleMaxLenght,
  inputTitleMinLenght,
} from "../utils/inputValidate";
import { ProductAttributesFields } from "../pages/offers/types";

const isAttributeRequired = (
  productAttributesFields: ProductAttributesFields | null,
  attributeName: string,
) => {
  if (!productAttributesFields) {
    return false;
  }

  const fields = productAttributesFields?.multipleChoiceAttributesFields;

  if (fields) {
    const fieldsKeys = Object.keys(fields);
    return fieldsKeys.some((fieldKey) => fieldKey === attributeName);
  }

  return true;
};

const getAttributesSchema = (
  productAttributesFields: ProductAttributesFields | null,
) => {
  let attributes = {};

  if (isAttributeRequired(productAttributesFields, "Stan")) {
    attributes = {
      ...attributes,
      Stan: array().min(1, inputRequired.message),
    };
  }

  if (isAttributeRequired(productAttributesFields, "Rozmiar")) {
    attributes = {
      ...attributes,
      Rozmiar: array().min(1, inputRequired.message),
    };
  }

  return {
    attributesMultiple: object().shape({
      ...attributes,
    }),
  };
};

const createOffertFormSchema = (
  productAttributesFields: ProductAttributesFields | null,
) =>
  object({
    name: string()
      .required(inputRequired.message)
      .min(inputTitleMinLenght.value, inputTitleMinLenght.message)
      .max(inputTitleMaxLenght.value, inputTitleMaxLenght.message),
    description: string()
      .required(inputRequired.message)
      .min(inputDescMinLenght.value, inputDescMinLenght.message)
      .max(inputDescMaxLenght.value, inputDescMaxLenght.message),
    price: string().required(inputRequired.message),
    province: object().required(inputRequired.message),
    city: object().required(inputRequired.message),
    category: object().required(inputRequired.message),
    ...getAttributesSchema(productAttributesFields),
  });

export default createOffertFormSchema;
