import { Provider } from "react-redux";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Notifications from "../pages/Notifications";
import NotFound from "../pages/NotFound";
import Messages from "../pages/Messages";
import Basket from "../pages/Basket";
import AcountLayout from "../components/templates/AcountLayout";
import Profile from "../pages/account/Profile";
import Favourite from "../pages/account/Favourite";
import Stats from "../pages/account/Stats";
import Transactions from "../pages/account/Transactions";
import Category from "../pages/category/Category";
import Search from "../pages/Search";
import Offer from "../pages/offers/Offer";
import Promote from "../pages/offers/Promote";
import UserProfile from "../pages/UserProfile";
import StaticPage from "../pages/statics/StaticPage";
import News from "../pages/statics/News";
import FAQ from "../pages/statics/FAQ";
import Contact from "../pages/statics/Contact";
import Layout from "../components/templates/Layout";
import store from "../Redux/messagesStore/store";
import BuyNowSummary from "../pages/offers/BuyNowSummary";
import OffersCatalog from "../pages/offers/OffersCatalog";
import ChangePassword from "../pages/account/ChangePassword";
import ThankYouPage from "../pages/ThankYouPage";
import AddressSummary from "../pages/offers/AddressSummary";
import RegisterSocial from "../pages/RegisterSocial";
import MessagesProvider from "../contexts/MessagesProvider/MessagesProvider";
import ParentAssistantOffer from "../pages/parentAssistantOffer/ParentAssistantOffer";
import ActivationThankYouPage from "../pages/ActivationThankYouPage";
import CreateOffer from "../pages/offers/CreateOffer";
import HotOffer from "../pages/hotOffer/HotOffer";
import UserLoggedProtectedRoute from "./UserLoggedProtectedRoute";
import EditOffer from "../pages/offers/EditOffer";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import PaymentSummary from "../pages/offers/PaymentSummary";
import UserProfileSlug from "../pages/UserProfileSlug";

const router = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/zapomnialem-hasla",
        element: <ForgotPassword />,
      },
      {
        path: "/page/resetowanie-hasla",
        element: <ResetPassword />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/social-register/:email",
        element: <RegisterSocial />,
      },
      {
        path: "/notifications",
        element: <Notifications />,
      },
      {
        path: "/not-found",
        element: <NotFound />,
      },
      {
        path: "/messages",
        element: (
          <Provider store={store}>
            <MessagesProvider>
              <Messages />
            </MessagesProvider>
          </Provider>
        ),
      },
      {
        path: "/basket",
        element: <Basket />,
      },
      {
        path: "/account",
        element: <AcountLayout />,
        children: [
          {
            path: "profile",
            element: (
              <UserLoggedProtectedRoute>
                <Profile />
              </UserLoggedProtectedRoute>
            ),
          },
          {
            path: "favourite",
            element: (
              <UserLoggedProtectedRoute>
                <Favourite />
              </UserLoggedProtectedRoute>
            ),
          },
          {
            path: "stats",
            element: (
              <UserLoggedProtectedRoute>
                <Stats />
              </UserLoggedProtectedRoute>
            ),
          },
          {
            path: "transactions",
            element: (
              <UserLoggedProtectedRoute>
                <Transactions />
              </UserLoggedProtectedRoute>
            ),
          },
          {
            path: "zmiana-hasla",
            element: (
              <UserLoggedProtectedRoute>
                <ChangePassword />
              </UserLoggedProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/category/:id",
        element: <Category />,
      },
      {
        path: "/szukaj/:searchPhrase",
        element: <Search />,
      },
      {
        path: "/kup-teraz/:slug",
        element: <BuyNowSummary />,
      },
      {
        path: "/offers/create",
        element: <CreateOffer />,
      },
      {
        path: "/offers/edit/:id",
        element: <EditOffer />,
      },
      {
        path: "/offers/:id",
        element: <Offer />,
      },
      {
        path: "/oferta/:slug",
        element: <Offer />,
      },
      {
        path: "/promote/:id",
        element: <Promote />,
      },
      {
        path: "/adres-dostawy/:id",
        element: <AddressSummary />,
      },
      {
        path: "/dane-do-przelewu/:id",
        element: <PaymentSummary />,
      },
      {
        path: "/user-profile/:id",
        element: <UserProfile />,
      },
      {
        path: "/u/:userSlug",
        element: <UserProfileSlug />,
      },
      {
        path: "/api/public/static-page/:name",
        element: <StaticPage />,
      },
      {
        path: "/page/:name",
        element: <StaticPage />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/payment/thank-you-page",
        element: <ThankYouPage />,
      },
      {
        path: "/activation/thank-you-page",
        element: <ActivationThankYouPage />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/kategoria/:categorySlug/",
        element: <OffersCatalog />,
      },
      {
        path: "/najnowsze-oferty/",
        element: <OffersCatalog />,
      },
      {
        path: "/propozycje/:assistantCode",
        element: <ParentAssistantOffer />,
      },
      {
        path: "/propozycje-na-czasie/:hotOfferId",
        element: <HotOffer />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default router;
