import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { getShortNewestOffers } from "../../client/Requests";
import { ShortNewestOffersInterface } from "../../models/ShortNewestOffersInterface";
import useUserData from "../../hooks/useUserData";
import OfferCard from "../offerCatalog/OfferCard";

function Offers() {
  const [offers, setOffers] = useState<ShortNewestOffersInterface[]>([]);
  const navigate = useNavigate();
  const { userData } = useUserData();

  useEffect(() => {
    getShortNewestOffers(userData?.userToken).then((data) => setOffers(data));
  }, [userData]);

  return (
    <Box>
      <Typography variant="h4" sx={{ fontWeight: 700, mb: { xs: 1, md: 1.5 } }}>
        Oferty
      </Typography>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={{ xs: 1, sm: 1.5 }}
        sx={{ mb: 5 }}
      >
        {offers.map((offer) => (
          <Grid key={offer.id} item xs={6} sm={4} md={3}>
            <OfferCard offer={offer} />
          </Grid>
        ))}
      </Grid>
      <button
        // eslint-disable-next-line react/button-has-type
        type="button"
        className="bg-grey-100 text-text-primary flex space-x-[7px] min-w-[122px] h-[42px] items-center justify-center rounded-lg transition ease-in-out duration-300 font-semibold text-base py-2 px-4 mx-auto"
        onClick={() => {
          navigate("/najnowsze-oferty");
        }}
      >
        <span>Pokaż więcej</span>
      </button>
    </Box>
  );
}

export default Offers;
