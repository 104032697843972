import { Outlet } from "react-router-dom";
import { Typography } from "@mui/material";
import AccountInfo from "../molecules/AccountInfo";
import AccountMenuList from "../molecules/menuList/AccountMenuList";

function AcountLayout() {
  return (
    <div className="max-md:flex-col-reverse container md:mt-8 mt-0 md:border-t-0 border-t	 md:px-0 flex">
      <div className="w-full max-w-[310px]">
        <Typography variant="h5" sx={{ p: 2, fontWeight: "700" }}>
          Ustawienia
        </Typography>
        <AccountMenuList />
      </div>
      <div className="flex flex-col w-full">
        <AccountInfo />
        <Outlet />
      </div>
    </div>
  );
}

export default AcountLayout;
