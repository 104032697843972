/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import {
  ProductTemplateAttributeInterface,
  ProductTemplateAttributeValueInterface,
} from "../../../models/ProductTemplateAttributeInterface";

interface Props {
  attribute: ProductTemplateAttributeInterface;
  attributeOption: Pick<ProductTemplateAttributeValueInterface, "id" | "name">;
  renderProps: React.HTMLAttributes<HTMLLIElement>;
  selectedAttributes?: ProductTemplateAttributeValueInterface[];
}

function FilterAttributeOption({
  attribute,
  attributeOption,
  renderProps,
  selectedAttributes,
}: Props) {
  if (attribute.type === "checkbox" && selectedAttributes) {
    const isChecked = selectedAttributes.some(
      (selectedAttribute) => selectedAttribute.id === attributeOption.id,
    );

    return (
      <Box component="li" {...renderProps}>
        <Box width={1} display="flex" justifyContent="space-between">
          <Typography component="span">{attributeOption.name}</Typography>
          <Checkbox checked={isChecked} />
        </Box>
      </Box>
    );
  }

  return <MenuItem {...renderProps}>{attributeOption.name}</MenuItem>;
}

export default FilterAttributeOption;
