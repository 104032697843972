import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { BasketInterface } from "../../models/BasketInterface";
import SummaryRecord from "./SummaryRecord";
import { getFormattedPrice } from "../../utils/PriceUtils";
import BasketSummaryDescription from "./BasketSummaryDescription";
import AskForShippingButton from "./AskForShippingButton";
import PayForBasketButton from "./PayForBasketButton";

type Props = {
  basket: BasketInterface | null;
};

function BasketSummary({ basket }: Props) {
  return (
    <Card sx={{ pt: 2, px: 3, pb: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: 700, mb: 4 }}>
        Podsumowanie
      </Typography>
      <Stack sx={{ my: 2 }} spacing={2}>
        <SummaryRecord
          title="Wartość koszyka"
          price={getFormattedPrice(basket?.itemsValue ?? 0)}
        />
        {basket?.inpostShippingCost && (
          <SummaryRecord
            title="Dostawa (InPost Paczkomat)"
            price={getFormattedPrice(basket?.inpostShippingCost ?? 0)}
          />
        )}
        {basket?.otherShippingCost && (
          <SummaryRecord
            title="Dostawa (Inna forma)"
            price={getFormattedPrice(basket?.otherShippingCost ?? 0)}
          />
        )}
        <SummaryRecord
          title="Prowizja za zakup"
          price={getFormattedPrice(basket?.commissionValue ?? 0)}
        />
      </Stack>
      <Divider />
      <Box sx={{ my: 2 }}>
        <SummaryRecord
          title="Razem"
          price={getFormattedPrice(
            (basket?.itemsValue ?? 0) +
              (basket?.commissionValue ?? 0) +
              (basket?.inpostShippingCost ?? 0) +
              (basket?.otherShippingCost ?? 0),
          )}
          bold
        />
      </Box>
      <BasketSummaryDescription basket={basket} />

      {basket &&
        (basket.shippingStatus === "required" ||
          basket.shippingStatus === "pending") && <AskForShippingButton />}

      {basket &&
        basket.sellerItems.length > 0 &&
        basket.shippingStatus === "ready" && <PayForBasketButton />}
    </Card>
  );
}

export default BasketSummary;
