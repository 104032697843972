import { useContext, useState } from "react";
import { Box, Button } from "@mui/material";
import { BasketContext } from "../../contexts/basketProvider/BasketProvider";
import { IBasketProvider } from "../../contexts/basketProvider/types";
import ModalForAskShipping from "../atoms/ModalForAskShipping";
import { sendBuyBasketRequest } from "../../client/Requests";
import useUserData from "../../hooks/useUserData";

function PayForBasketButton() {
  const { basket, parcelLocerPoint } = useContext(
    BasketContext,
  ) as IBasketProvider;
  const [askShippingMessageVisible, setAskShippingMessageVisible] =
    useState(false);
  const { userData } = useUserData();

  const handleBuyBasket = () => {
    sendBuyBasketRequest(userData?.userToken, parcelLocerPoint?.name)
      .then((r) => {
        window.location.assign(r.payUrl);
      })
      .catch(() => {});
  };

  const isBuyAvailable = () => {
    if (!basket) {
      return false;
    }
    if (!basket.isBuyAvailable) {
      return false;
    }

    if (basket.requireParcelLocker) {
      return !!parcelLocerPoint?.name;
    }

    return true;
  };

  return (
    <Box sx={{ width: 1 }}>
      <ModalForAskShipping
        isVisible={askShippingMessageVisible}
        onClickOut={() => setAskShippingMessageVisible(false)}
      />
      <Button
        size="large"
        variant="contained"
        color="secondary"
        sx={{
          width: 1,
          textTransform: "unset",
          cursor: isBuyAvailable() ? "pointer" : "not-allowed",
        }}
        onClick={handleBuyBasket}
        disabled={!isBuyAvailable()}
      >
        {basket?.inpostShippingCost
          ? `Opłać prowizję i koszt dostawy InPost`
          : "Opłać prowizję"}
      </Button>
    </Box>
  );
}

export default PayForBasketButton;
