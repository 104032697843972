import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import { useEffect } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CustomTextField } from "../../atoms/Inputs/InputMUI";
import { errorAdornment } from "./const";

function PersonalizationData() {
  const { control, watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "children",
  });
  const expectingChildField = watch("expectingChild");
  const haveChildField = watch("haveChild");
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  useEffect(() => {
    // @ts-ignore
    if (fields.length && fields[0].birthDate) {
      setValue("haveChild", true);
    }
  }, []);
  return (
    <>
      <Typography
        variant="h5"
        component="h4"
        sx={{ fontWeight: 700, lineHeight: 1.4, mb: 3 }}
      >
        Personalizacja
        <Typography component="span" sx={{ ml: 1 }}>
          (opcjonalnie)
        </Typography>
      </Typography>
      <Typography sx={{ mb: 3 }}>
        Informacje te pozwolą nam na lepsze dopasowanie wyświetlanych ofert do
        wieku i płci Twoich dzieci.
      </Typography>
      <Stack spacing={2} mb={3}>
        <FormGroup sx={{ pl: "11px" }}>
          <FormControlLabel
            control={
              <Controller
                control={control}
                name="expectingChild"
                render={({ field: { ref, value, onChange } }) => (
                  <Checkbox
                    inputRef={ref}
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            }
            label={
              <Typography fontWeight={600}>
                Spodziewam się dziecka/ci
              </Typography>
            }
          />
          {expectingChildField && (
            <Box sx={{ pl: 3 }}>
              <FormLabel
                id="radio-button-gender"
                sx={{
                  height: 48,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Wybierz płeć
              </FormLabel>
              <Controller
                control={control}
                name="expectingGender"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup
                    aria-labelledby="radio-buttons-gender"
                    value={value}
                    onChange={onChange}
                    sx={{ pl: "11px", mb: 3 }}
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Dziewczynka/i"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Chłopiec/cy"
                    />
                    <FormControlLabel
                      value="mixed"
                      control={<Radio />}
                      label="Dziewczynka/i i Chłopiec/cy"
                    />
                    <FormControlLabel
                      value="unknown"
                      control={<Radio />}
                      label="Jeszcze nie wiem"
                    />
                  </RadioGroup>
                )}
              />
            </Box>
          )}
          <FormControlLabel
            control={
              <Controller
                control={control}
                name="haveChild"
                render={({ field: { ref, value, onChange } }) => (
                  <Checkbox
                    inputRef={ref}
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            }
            label={<Typography fontWeight={600}>Mam już dziecko/ci</Typography>}
          />
          {haveChildField &&
            fields.map((item, index) => (
              <Box key={item.id}>
                <Box sx={{ pl: 3 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormLabel
                      id="radio-button-gender"
                      sx={{
                        height: 48,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Wybierz płeć
                    </FormLabel>
                    <IconButton
                      type="button"
                      onClick={() => remove(index)}
                      aria-label="delete"
                      disabled={!(fields.length > 1)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Stack>
                  <Controller
                    control={control}
                    name={`children.${index}.gender`}
                    render={({ field: { value, onChange } }) => (
                      <RadioGroup
                        aria-labelledby="radio-buttons-gender"
                        value={value || "female"}
                        onChange={onChange}
                        sx={{
                          pl: "11px",
                          mb: 3,
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                        }}
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Dziewczynka"
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Chłopiec"
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  spacing={3}
                  sx={{ py: 2, px: 3 }}
                >
                  <Controller
                    control={control}
                    name={`children.${index}.name`}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { invalid, error },
                    }) => (
                      <CustomTextField
                        sx={{ maxWidth: 605 }}
                        label="Imię (opcjonalne)"
                        variant="standard"
                        inputRef={ref}
                        value={value || ""}
                        onChange={onChange}
                        error={invalid}
                        helperText={error?.message}
                        InputProps={
                          invalid ? { endAdornment: errorAdornment } : {}
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`children.${index}.birthDate`}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { invalid, error },
                    }) => (
                      <CustomTextField
                        sx={{ maxWidth: { xs: "auto", md: 180 } }}
                        label="Data urodzenia"
                        variant="standard"
                        type="date"
                        inputRef={ref}
                        value={value || ""}
                        onChange={onChange}
                        error={invalid}
                        helperText={error?.message}
                        InputLabelProps={{ shrink: true }}
                        InputProps={
                          invalid ? { endAdornment: errorAdornment } : {}
                        }
                      />
                    )}
                  />
                </Stack>
              </Box>
            ))}
        </FormGroup>
      </Stack>
      {haveChildField && (
        <Tooltip
          title={fields.length >= 15 ? "Osiągnięto limit dzieci" : null}
          placement="bottom"
        >
          <Box component="span" sx={{ maxWidth: 200, width: 1 }}>
            <Button
              startIcon={<AddCircleIcon />}
              color="secondary"
              type="button"
              onClick={() =>
                append({
                  name: "",
                  birthDate: "",
                  gender: "female",
                  isComing: false,
                })
              }
              sx={{ textTransform: "none" }}
              disabled={fields.length >= 15}
            >
              Dodaj kolejne dziecko
            </Button>
          </Box>
        </Tooltip>
      )}
    </>
  );
}

export default PersonalizationData;
