import { useEffect, useState } from "react";
import { IUserProfile } from "../types/UserContextType";

interface UserData {
  userId: number;
  username: string;
  userToken: string;
  isUserProfileComplete: boolean;
}

export default function useUserData() {
  const getUserData = () => {
    if (typeof window === "undefined") {
      return null;
    }

    const userDataString: string | null =
      window.localStorage.getItem("userData");

    return userDataString && JSON.parse(userDataString);
  };

  const [userData, setUserData] = useState<UserData | null>(getUserData());

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const saveUserData = (userData: UserData) => {
    window.localStorage.setItem("userData", JSON.stringify(userData));
    setUserData(userData);
  };

  const clearUserData = () => {
    window.localStorage.removeItem("userData");
    setUserData(null);
  };

  const refreshUserData = () => {
    saveUserData(getUserData());
  };

  const addUserProfile = () => {
    if (!userData) {
      return;
    }
    // @ts-ignore
    const newUserData: UserData = { ...userData, isUserProfileComplete: true };

    setUserData(newUserData);

    saveUserData(newUserData);
  };

  const updateUserData = (userProfile: IUserProfile) => {
    const isUserProfileComplete = !!userProfile?.profile?.phone;
    if (!userData) {
      return;
    }

    if (isUserProfileComplete && !userData?.isUserProfileComplete) {
      const newUserData: UserData = {
        ...userData,
        isUserProfileComplete: true,
      };

      setUserData(newUserData);

      saveUserData(newUserData);
    }
  };

  useEffect(() => {
    refreshUserData();
  }, []);

  return {
    setUserData: saveUserData,
    refreshUserData,
    getUserData,
    clearUserData,
    addUserProfile,
    userData,
    updateUserData,
  };
}
