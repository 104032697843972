import Icon from "./Icon";
import Typography from "./Typography";

interface OfferProfileButtonInterface {
  icon: string;
  text: string;
  onClick?: any;
}

function OfferProfileButton({
  icon,
  text,
  onClick,
}: OfferProfileButtonInterface) {
  return (
    <div
      className="flex flex-col items-center cursor-pointer"
      onClick={onClick}
    >
      <Icon icon={icon} className="text-text-secondary p-1.5" />
      <Typography
        type="menuMobile"
        text={text}
        className="text-text-primary/70"
      />
    </div>
  );
}

export default OfferProfileButton;
