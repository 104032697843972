import Typography from "../../../atoms/Typography";

function OwnerMessage({ message }: { message: string }) {
  return (
    <div className="bg-light-messageBox-pink py-1 px-2 rounded-[14px] ml-12 mt-6">
      <Typography type="message" text={message} className="text-right" />
    </div>
  );
}

export default OwnerMessage;
