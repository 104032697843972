export default function useStorage() {
  const setData = (key: string, data: unknown) => {
    window.localStorage.setItem(key, JSON.stringify(data));
  };

  const getData = (key: string) => {
    const storageData = window.localStorage.getItem(key);

    return (storageData && JSON.parse(storageData)) || "";
  };

  const removeData = (key: string) => {
    window.localStorage.removeItem(key);
  };

  return {
    setData,
    getData,
    removeData,
  };
}
