/* eslint-disable import/order */
import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Stack,
  useMediaQuery,
} from "@mui/material";
import PriceField from "../PriceField";
import { errorAdornment, priceAdornment } from "../../profile/const";
import { CustomTextField } from "../../../atoms/Inputs/InputMUI";

function ShippingAnotherMethod() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const { control } = useFormContext();
  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      sx={{ mx: 4, my: 1.5 }}
      spacing={4}
    >
      <Controller
        control={control}
        name="customName"
        render={({
          field: { onChange, value, ref },
          fieldState: { error, invalid },
        }) => (
          <CustomTextField
            variant="standard"
            label="Nazwa dostawy"
            onChange={onChange}
            value={value}
            ref={ref}
            error={invalid}
            helperText={error?.message}
            placeholder="np. kurier, Poczta Polska..."
            sx={{ width: 1 }}
          />
        )}
      />
      <Controller
        control={control}
        name="customPrice"
        render={({
          field: { onChange, value, ref },
          fieldState: { error, invalid },
        }) => (
          <FormControl
            error={invalid}
            variant="standard"
            sx={{ width: 1, maxWidth: { xs: "none", md: 140 } }}
          >
            <InputLabel>Cena</InputLabel>
            <Input
              value={value}
              onChange={onChange}
              ref={ref}
              placeholder="np. 50"
              inputComponent={PriceField as any}
              endAdornment={
                <>
                  {invalid && errorAdornment}
                  {priceAdornment}
                </>
              }
            />
            {error?.message ? (
              <FormHelperText sx={{ ml: 0 }}>{error.message}</FormHelperText>
            ) : null}
          </FormControl>
        )}
      />
    </Stack>
  );
}

export default ShippingAnotherMethod;
