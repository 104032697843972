import { Stack, SxProps, Theme, useTheme } from "@mui/material";
import { AvailableChargeInterface } from "../../models/AvailableChargeInterface";
import PromoteListItemPrice from "./PromoteListItemPrice";
import PromotePicker from "./PromotePicker";
import { PromoteType } from "./types";

type Props = {
  charge: AvailableChargeInterface;
  variant?: PromoteType;
  isSelected: boolean;
  onSelect: (id: number | null) => void;
};

function PromoteListItem({
  charge,
  variant = "newType",
  isSelected,
  onSelect,
}: Props) {
  const theme = useTheme();
  let styles: SxProps<Theme> = {
    width: 1,
    border: "1px solid",
    padding: 2,
    borderColor: theme.palette.divider,
    borderRadius: 1,
    backgroundColor: theme.palette.common.white,
  };

  if (variant === "newType" && isSelected) {
    styles = {
      ...styles,
      borderColor: theme.palette.common.black,
      backgroundColor: theme.palette.action.hover,
    };
  }

  if (variant === "buyType" && isSelected) {
    styles = {
      ...styles,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.primaryShades[16],
    };
  }

  return (
    <Stack
      sx={styles}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <PromoteListItemPrice charge={charge} />
      <PromotePicker
        variant={variant}
        isSelected={isSelected}
        onSelect={onSelect}
        charge={charge}
      />
    </Stack>
  );
}

export default PromoteListItem;
