import React from "react";
import { Box, Grid } from "@mui/material";
import { TransactionsOffersInterface } from "../../models/ShortOffersInterface";
import EmptyOffers from "../molecules/EmptyOffers";
import OfferCard from "../offerCatalog/OfferCard";

type Props = {
  offers?: TransactionsOffersInterface[] | null;
  count: number;
  disableAddToFavorites?: boolean;
  emptyOfferText?: string;
};

function UserOfferList({
  offers,
  count,
  disableAddToFavorites,
  emptyOfferText,
}: Props) {
  return (
    <Box sx={{ my: 4 }}>
      {count === 0 && <EmptyOffers emptyOfferText={emptyOfferText} />}
      {count > 0 && offers && (
        <Grid container spacing={2}>
          {offers.map((offer) => (
            <Grid key={offer.id} item xs={6} sm={6} md={4} lg={3}>
              <OfferCard
                offer={offer}
                disableAddToFavorites={disableAddToFavorites}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default UserOfferList;
