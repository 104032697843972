import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";

interface Props {
  images?: string[];
  selectedImageIndex: number | null;
  onClose: () => void;
}

function ImageGallery({ images, selectedImageIndex, onClose }: Props) {
  if (!selectedImageIndex) {
    return null;
  }

  const closeImageViewer = () => {
    onClose();
  };

  return (
    <Box sx={{ zIndex: 100 }}>
      {selectedImageIndex && images ? (
        <ImageViewer
          src={images}
          currentIndex={selectedImageIndex - 1}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside
        />
      ) : null}
    </Box>
  );
}

export default ImageGallery;
