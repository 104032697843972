/* eslint-disable react/destructuring-assignment */
import { InputAdornment, TextField, styled } from "@mui/material";
import { useController } from "react-hook-form";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PriceField from "../../organisms/createOffer/PriceField";

export const CustomTextField = styled(TextField)({
  "& label": {
    fontWeight: "500",
  },
  "& .MuiInputLabel-root": {
    fontWeight: "500",
  },
  "& .MuiInputBase-input": {
    fontWeight: "500",
  },
});

function InputMUI({ label, placeholder, disabled, ...props }: any) {
  const { field, fieldState } = useController(props);
  return (
    <CustomTextField
      sx={{ width: 1 }}
      error={!!fieldState.error?.message}
      label={label}
      helperText={fieldState.error?.message}
      variant="standard"
      placeholder={placeholder}
      disabled={disabled}
      {...field}
      {...props}
      InputProps={
        fieldState.error?.message
          ? {
              endAdornment: (
                <InputAdornment position="start">
                  <ErrorOutlineIcon sx={{ fontSize: "1rem" }} color="error" />
                </InputAdornment>
              ),
            }
          : props.InputProps
      }
    />
  );
}

export default InputMUI;
