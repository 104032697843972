import { useContext, useEffect, useState } from "react";
import { Box, Button, Typography as MuiTypography, Stack } from "@mui/material";
import Typography from "../components/atoms/Typography";
import useUserData from "../hooks/useUserData";
import { sendDeleteFromBasketRequest } from "../client/Requests";
import BasketItemList from "../components/molecules/BasketItemList";
import ModalForInpost from "../components/atoms/ModalForInpost";
import DefaultLayout from "../components/layout/DefaultLayout";
import { BasketContext } from "../contexts/basketProvider/BasketProvider";
import { IBasketProvider } from "../contexts/basketProvider/types";
import { InpostDetailsInterface } from "../models/InpostDetailsInterface";
import BasketSummary from "../components/basket/BasketSummary";
import PageTitle from "../components/atoms/PageTitle";

function Basket() {
  const { userData } = useUserData();
  const [inpostModalVisible, setInpostModalVisible] = useState(false);
  const { basket, refetch, handleChangeParcelLocker, parcelLocerPoint } =
    useContext(BasketContext) as IBasketProvider;

  useEffect(() => {
    refetch();
  }, []);

  const handleDeleteItem = (offerId: number) => {
    sendDeleteFromBasketRequest(offerId, userData?.userToken)
      .then(() => refetch())
      .catch(() => {});
  };

  const handleClickOutInpost = () => {
    setInpostModalVisible(false);
  };

  const handleShowInpostModal = () => {
    setInpostModalVisible(true);
  };

  const handlePointSelect = (details: InpostDetailsInterface) => {
    setInpostModalVisible(false);
    if (details?.name) {
      handleChangeParcelLocker(details);
    }
  };

  return (
    <DefaultLayout>
      <ModalForInpost
        isVisible={inpostModalVisible}
        onClickOut={handleClickOutInpost}
        onPointSelect={handlePointSelect}
      />
      <Box sx={{ ml: { xs: 2, lg: 0 } }}>
        <PageTitle title="Koszyk" />
      </Box>
      <div className="w-full mt-8 md:grid md:grid-cols-6 md:gap-4 h-auto">
        <div className="w-full md:col-span-4 bg-white md:rounded-lg p-4">
          <MuiTypography variant="h5" sx={{ fontWeight: 700 }}>
            Twój koszyk
          </MuiTypography>
          {basket &&
            basket.sellerItems.map((value) => (
              <BasketItemList
                basketSeller={value}
                handleDeleteItem={handleDeleteItem}
                key={Math.random()}
              />
            ))}
          {parcelLocerPoint ? (
            <Box sx={{ textAlign: "center", position: "relative", mt: 2 }}>
              <MuiTypography>Miejsce dostawy</MuiTypography>
              <Button
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={handleShowInpostModal}
              >
                Zmień
              </Button>
              <Stack
                spacing={0.5}
                sx={{ mt: 0.5, mb: 1.5, alignContent: "center" }}
              >
                <MuiTypography variant="body2" sx={{ fontWeight: 500 }}>
                  Paczkomat {parcelLocerPoint.name}
                </MuiTypography>
                <MuiTypography variant="subtitle2">
                  {parcelLocerPoint.address.line1},{" "}
                  {parcelLocerPoint.address.line2}
                </MuiTypography>
                <MuiTypography
                  variant="subtitle2"
                  sx={{ color: "text.disabled" }}
                >
                  Opis: {parcelLocerPoint.location_description}
                </MuiTypography>
                <MuiTypography
                  variant="subtitle2"
                  sx={{ color: "text.disabled" }}
                >
                  Godziny otwarcia: {parcelLocerPoint.opening_hours}
                </MuiTypography>
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              {basket?.requireParcelLocker && !parcelLocerPoint && (
                <Button
                  variant="contained"
                  sx={{
                    my: 1.5,
                    textTransform: "unset",
                    width: 1,
                    maxWidth: 400,
                    mt: 4,
                  }}
                  onClick={handleShowInpostModal}
                >
                  Wybierz paczkomat
                </Button>
              )}
            </Box>
          )}
        </div>

        <div className="w-full md:col-span-2">
          <div className="md:sticky md:top-5 w-full flex-col justify-between">
            <BasketSummary basket={basket} />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Basket;
