import {
  ProductTemplateAttributeInterface,
  ProductTemplateAttributeValueInterface,
} from "../../models/ProductTemplateAttributeInterface";
import { getUniqueValues } from "../arrayUtils";

export const createAttributesUrlParams = (
  urlParams: URLSearchParams,
  attributesValues: ProductTemplateAttributeValueInterface[],
) => {
  const attrbiutesFamilyIds = attributesValues.map(
    (attributeValue) => attributeValue.attributeFamilyId,
  );

  const attrbiutesUniqueIds = getUniqueValues(attrbiutesFamilyIds);

  attrbiutesUniqueIds.forEach((attrbiuteUniqueId) => {
    const attributesValuesFromFamily = attributesValues.filter(
      (attributeValue) =>
        attributeValue.attributeFamilyId === attrbiuteUniqueId,
    );

    const attributesValuesNames = attributesValuesFromFamily.map(
      (attributeValue) => attributeValue.name,
    );

    urlParams.append(
      `${attributesValuesFromFamily[0].attributeFamilyName}`,
      attributesValuesNames.toString(),
    );
  });

  return urlParams;
};

export const decodeParams = (searchParams: URLSearchParams) => {
  let decodedParams = {};

  if (!searchParams.toString()) {
    return decodedParams;
  }

  searchParams.forEach((value, param) => {
    decodedParams = {
      ...decodedParams,
      [param]: value.split(","),
    };
  });

  return decodedParams;
};

export const getInitialParams = (decodedParams: {
  [key: string]: string[];
}) => {
  const decodedParamsMutations = { ...decodedParams };
  let basicFiltersParams = {};

  if ("strona" in decodedParams) {
    const currentPage = decodedParams["strona"];
    delete decodedParamsMutations["strona"];
    basicFiltersParams = {
      ...basicFiltersParams,
      currentPage: +currentPage[0],
    };
  }

  if ("wojewodztwo" in decodedParams) {
    const province = decodedParams["wojewodztwo"];
    delete decodedParamsMutations["wojewodztwo"];
    basicFiltersParams = {
      ...basicFiltersParams,
      province: province[0],
    };
  }

  if ("miasto" in decodedParams) {
    const city = decodedParams["miasto"];
    delete decodedParamsMutations["miasto"];
    basicFiltersParams = {
      ...basicFiltersParams,
      city: city[0],
    };
  }

  if ("cenaOd" in decodedParams) {
    const minPrice = decodedParams["cenaOd"];
    delete decodedParamsMutations["cenaOd"];
    basicFiltersParams = {
      ...basicFiltersParams,
      minPrice: minPrice.toString(),
    };
  }

  if ("cenaDo" in decodedParams) {
    const maxPrice = decodedParams["cenaDo"];
    delete decodedParamsMutations["cenaDo"];
    basicFiltersParams = {
      ...basicFiltersParams,
      maxPrice: maxPrice.toString(),
    };
  }

  if ("sortuj" in decodedParams) {
    const sort = decodedParams["sortuj"];
    delete decodedParamsMutations["sortuj"];
    basicFiltersParams = {
      ...basicFiltersParams,
      sort: sort[0],
    };
  }

  if ("szukaj" in decodedParams) {
    const searchPhrase = decodedParams["szukaj"];
    delete decodedParamsMutations["szukaj"];
    basicFiltersParams = {
      ...basicFiltersParams,
      searchPhrase: searchPhrase[0],
    };
  }

  return {
    ...basicFiltersParams,
    ...(Object.keys(decodedParamsMutations).length && {
      attributes: decodedParamsMutations,
    }),
  };
};

const getInitialAttributesValues = (
  templateAttribute: ProductTemplateAttributeInterface,
  attributesUrlParams: object,
) => {
  const attributeKeys: string[] = Object.keys(attributesUrlParams);
  const templateAttributeValues = templateAttribute.values;

  let values: ProductTemplateAttributeValueInterface[] = [];
  attributeKeys.forEach((attributeKey, id) => {
    const urlParamValues: string[] = Object.values(attributesUrlParams)[id];
    const urlParamName: string = Object.keys(attributesUrlParams)[id];

    urlParamValues.forEach((urlParamValue) => {
      const convertedParamName = urlParamValue.replaceAll("+", " ");
      const templateValues = templateAttributeValues.filter(
        (templateAttributeValue) => {
          return templateAttributeValue.name === convertedParamName;
        },
      );

      const valuesExtended = templateValues.map((templateValue) => ({
        ...templateValue,
        attributeFamilyName: urlParamName,
        attributeFamilyId: templateAttribute.id,
      }));

      values = [...values, ...valuesExtended];
    });
  });

  return values;
};

const getInitialAttributes = (
  attributesUrlParams: object,
  productTemplateAttributes: ProductTemplateAttributeInterface[],
) => {
  const attributesParamsKeys = Object.keys(attributesUrlParams);
  let initialAttributes: ProductTemplateAttributeValueInterface[] = [];

  attributesParamsKeys.forEach((attributeParam) => {
    const templateAttribute = productTemplateAttributes.find(
      (productTemplateAttribute) => {
        return productTemplateAttribute.name === attributeParam;
      },
    );

    if (templateAttribute) {
      const attrValues = getInitialAttributesValues(
        templateAttribute,
        attributesUrlParams,
      );
      initialAttributes = [...initialAttributes, ...attrValues];
    }
  });

  return initialAttributes;
};

export const getInitialFilters = (
  initialParams: object,
  productTemplateAttributes: ProductTemplateAttributeInterface[],
) => {
  let initialFilters = {};
  if ("currentPage" in initialParams) {
    if (initialParams.currentPage) {
      initialFilters = {
        ...initialFilters,
        currentPage: initialParams.currentPage,
      };
    }
  }

  if ("province" in initialParams) {
    if (initialParams.province) {
      const initialProvince = initialParams.province as string;
      initialFilters = {
        ...initialFilters,
        province: initialProvince.replaceAll("+", " "),
      };
    }
  }

  if ("city" in initialParams) {
    if (initialParams.city) {
      const initialCity = initialParams.city as string;
      initialFilters = {
        ...initialFilters,
        city: initialCity.replaceAll("+", " "),
      };
    }
  }

  if ("minPrice" in initialParams) {
    if (initialParams.minPrice) {
      initialFilters = {
        ...initialFilters,
        minPrice: initialParams.minPrice,
      };
    }
  }

  if ("maxPrice" in initialParams) {
    if (initialParams.maxPrice) {
      initialFilters = {
        ...initialFilters,
        maxPrice: initialParams.maxPrice,
      };
    }
  }

  if ("sort" in initialParams) {
    if (initialParams.sort) {
      initialFilters = {
        ...initialFilters,
        sort: `${initialParams.sort}`,
      };
    }
  }

  if ("searchPhrase" in initialParams) {
    if (initialParams.searchPhrase) {
      initialFilters = {
        ...initialFilters,
        searchPhrase: `${initialParams.searchPhrase}`,
      };
    }
  }

  if ("attributes" in initialParams) {
    if (initialParams.attributes) {
      initialFilters = {
        ...initialFilters,
        attributes: getInitialAttributes(
          initialParams.attributes,
          productTemplateAttributes,
        ),
      };
    }
  }

  return initialFilters;
};
