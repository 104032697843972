import { Autocomplete, FormControl, MenuItem, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { OfferContext } from "../../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../../contexts/offerProvider/types";

function FilterCity() {
  const { filters, cities, handleChangeCity } = useContext(
    OfferContext,
  ) as IOfferContext;

  const defaultCity = cities.find((city) => city.name === filters.city)?.name;

  const [cityValue, setCityValue] = useState(defaultCity ?? null);

  const handleCityChange = (event: any, city: string | null) => {
    handleChangeCity(city);
  };

  useEffect(() => {
    if (!filters.province || !filters.city) {
      handleChangeCity(null);
      setCityValue(null);
    }
    if (filters.province && filters.city) {
      setCityValue(filters.city);
    }
  }, [filters.province, filters.city]);

  return (
    <FormControl variant="standard" sx={{ width: 1 }}>
      <Autocomplete
        isOptionEqualToValue={() => {
          return true;
        }}
        disablePortal
        options={cities.map((city) => city.name)}
        getOptionLabel={(option) => option}
        onChange={(e, value) => handleCityChange(e, value)}
        noOptionsText="Nie znaleziono takiego miasta"
        value={cityValue}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            size="small"
            label="Wybierz miasto"
            disabled={!filters.province}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem key={option} {...props}>
            {option}
          </MenuItem>
        )}
      />
    </FormControl>
  );
}

export default FilterCity;
