import ReactFacebookLogin from "react-facebook-login";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import "./facebookButton.css";

interface IFacebookButton {
  handleSocialLogin: any;
}

function FacebookButton({ handleSocialLogin }: IFacebookButton) {
  const responseFacebook = (response: any) => {
    handleSocialLogin(response.email);
  };

  return (
    <ReactFacebookLogin
      appId="344203980684920"
      fields="name,email,picture"
      callback={responseFacebook}
      textButton="Kontynuuj przez Facebook"
      isMobile={false}
      cssClass="facebookButton basis-2/4 block mb-2 flex space-x-[7px] w-[100%] items-center justify-center transition ease-in-out duration-300 font-semibold text-sm h-[42px] text-buttonLarge py-2 px-4 border-text-secondary"
      icon={
        <FacebookRoundedIcon
          sx={{
            color: "#1877F2",
            fontSize: 28,
            mr: 2,
          }}
        />
      }
    />
  );
}

export default FacebookButton;
