import { BasketInterface } from "../../models/BasketInterface";
import { ProductTemplateAttributeInterface } from "../../models/ProductTemplateAttributeInterface";

export const getIsElementInBasket = (
  basket: BasketInterface | null,
  offerId?: number,
) => {
  if (!basket || !offerId) {
    return false;
  }

  return basket.sellerItems.some((sellerItem) =>
    sellerItem.items.some((item) => item.productOfferId === offerId),
  );
};

export const getSpilttedAtrributesFieldsByType = (
  productAttributes: ProductTemplateAttributeInterface[],
) => {
  const attributesWithoutValues = productAttributes.map((productAttribute) => ({
    id: productAttribute.id,
    name: productAttribute.name,
    type: productAttribute.type,
  }));

  const singleChoiceAttributes = attributesWithoutValues.filter(
    (productAttribute) => productAttribute.type === "radio",
  );

  const mutlipleChoiceAttributes = attributesWithoutValues.filter(
    (productAttribute) => productAttribute.type === "radio",
  );

  let singleChoiceAttributesFields = {};
  let multipleChoiceAttributesFields = {};

  singleChoiceAttributes.forEach((attribute) => {
    singleChoiceAttributesFields = {
      ...singleChoiceAttributesFields,
      [attribute.name]: null,
    };
  });

  mutlipleChoiceAttributes.forEach((attribute) => {
    multipleChoiceAttributesFields = {
      ...multipleChoiceAttributesFields,
      [attribute.name]: [],
    };
  });

  return { singleChoiceAttributesFields, multipleChoiceAttributesFields };
};
