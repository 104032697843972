import { Box } from "@mui/material";
import { useOwnedPublishedOffersListInactive } from "../../hooks/fetchHooks/transactions/useOwnedPublishedOffersListInactive";
import { useHandlePagination } from "../../hooks/useHandlePagination";
import UserOfferList from "../userOfferList/UserOfferList";
import OffersContentLoader from "./OffersContentLoader";
import OffersPagination from "../molecules/OffersPagination";
import { getPagesCount } from "../../utils/paginationUtils";

function WithdrawnTransactions() {
  const { page, handleChangePage } = useHandlePagination();
  const { offers, isLoading } = useOwnedPublishedOffersListInactive(page);

  if (isLoading) {
    return <OffersContentLoader />;
  }

  return (
    <>
      <UserOfferList
        offers={offers.items}
        count={offers.count}
        disableAddToFavorites
      />
      <Box sx={{ mt: 2.25 }}>
        {offers.count > 20 && (
          <OffersPagination
            currentPage={page}
            pagesCount={getPagesCount(offers?.count)}
            handleClickPage={handleChangePage}
          />
        )}
      </Box>
    </>
  );
}

export default WithdrawnTransactions;
