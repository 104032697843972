import { useContext, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import {
  ProductTemplateAttributeInterface,
  ProductTemplateAttributeValueInterface,
} from "../../../models/ProductTemplateAttributeInterface";
import { OfferContext } from "../../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../../contexts/offerProvider/types";
import FilterAttributeOption from "./FilterAttributeOption";

export const defaultOption = {
  id: 0,
  attributeFamilyId: 0,
  name: "",
  attributeFamilyName: "",
};

interface Props {
  productTemplateAttribute: ProductTemplateAttributeInterface;
}
function FilterAttributesSingle({ productTemplateAttribute }: Props) {
  const { handleChangeAttributes, filters } = useContext(
    OfferContext,
  ) as IOfferContext;
  const defaultSelectedAttributesValue =
    filters.attributes.find(
      (FilterAttributeValue) =>
        FilterAttributeValue.attributeFamilyId === productTemplateAttribute.id,
    ) || defaultOption;

  const [attributeValue, setAttributeValue] =
    useState<ProductTemplateAttributeValueInterface | null>(
      defaultSelectedAttributesValue,
    );

  const handleAttributeChange = (
    attributeFamilyId: number,
    attribute: ProductTemplateAttributeValueInterface | null,
  ) => {
    if (attribute) {
      setAttributeValue({
        ...attribute,
        attributeFamilyId,
        attributeFamilyName: productTemplateAttribute.name,
      });
    } else {
      setAttributeValue(null);
    }
    handleChangeAttributes(
      attribute
        ? [
            {
              ...attribute,
              attributeFamilyId,
              attributeFamilyName: productTemplateAttribute.name,
            },
          ]
        : [],
      attributeFamilyId,
    );
  };

  useEffect(() => {
    if (attributeValue && attributeValue.name) {
      const valueIsStillSelected = filters.attributes.some(
        (attribute) =>
          attribute.attributeFamilyId === attributeValue.attributeFamilyId,
      );

      if (!valueIsStillSelected) {
        setAttributeValue(null);
        handleChangeAttributes([], attributeValue.attributeFamilyId);
      }
    }
  }, [filters.attributes]);

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      disablePortal
      options={[...productTemplateAttribute.values, defaultOption]}
      getOptionLabel={(option) => option.name}
      value={attributeValue}
      onChange={(e, value) =>
        handleAttributeChange(productTemplateAttribute.id, value)
      }
      noOptionsText="Nie znaleziono takiego atrybutu"
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            size="small"
            label={productTemplateAttribute.name}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
          />
        );
      }}
      renderOption={(props, option) => (
        <FilterAttributeOption
          key={option.id}
          renderProps={props}
          attribute={productTemplateAttribute}
          attributeOption={option}
        />
      )}
    />
  );
}

export default FilterAttributesSingle;
