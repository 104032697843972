import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserData {
  userId?: number | null;
  userToken?: string | null;
  username?: string | null;
}

interface UserDataState extends UserData {}

interface AddUserDataAction extends PayloadAction<UserData> {}

const initialState: UserDataState = {
  userId: null,
  userToken: null,
  username: null,
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    addUserData: (state, action: AddUserDataAction) => {
      return { ...state, ...action.payload };
    },
    clearUserData: () => {
      return { ...initialState };
    },
  },
});

export const { addUserData, clearUserData } = userDataSlice.actions;

export default userDataSlice.reducer;
