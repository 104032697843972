import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getFavoriteOffers } from "../../client/Requests";
import { ShortOffersInterface } from "../../models/ShortOffersInterface";
import useUserData from "../../hooks/useUserData";
import OffersLoading from "../../components/molecules/OffersLoading";
import SectionLayoutContent from "../../components/layout/SectionLayoutContent";
import UserOfferList from "../../components/userOfferList/UserOfferList";

function Favourite() {
  const { userData } = useUserData();
  const userToken = userData?.userToken;

  const { data: offers, isLoading } = useQuery<ShortOffersInterface[] | null>({
    queryKey: ["user-favourites-offer"],
    queryFn: () =>
      userToken ? getFavoriteOffers(userToken).then((res) => res.data) : null,
    enabled: !!userToken,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  if (isLoading) {
    return (
      <SectionLayoutContent>
        <OffersLoading />
      </SectionLayoutContent>
    );
  }

  return (
    <SectionLayoutContent>
      <>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, lineHeight: 1.4, mb: 1 }}
        >
          Ulubione
        </Typography>
        <UserOfferList
          offers={offers}
          count={offers?.length ?? 0}
          emptyOfferText="Nie masz jeszcze ulubionych produktów!"
        />
      </>
    </SectionLayoutContent>
  );
}

export default Favourite;
