import { object, string } from "yup";
import {
  inputMaxLenght,
  inputMinLenght,
  inputRequired,
} from "../utils/inputValidate";

const messageFormSchema = (forbiddenWords: string[]) =>
  object({
    message: string()
      .required(inputRequired.message)
      .min(inputMinLenght.value, inputMinLenght.message)
      .max(inputMaxLenght.value, inputMaxLenght.message)
      .test({
        name: "forbidden-words",
        message: "Wprowadzono niedozwolone wyrazy",
        test: (value) => {
          if (value) {
            const textArray = value.split(/\s+/);
            const isForbiddenWords = textArray.some((word) =>
              forbiddenWords.some((forbidden: string) => forbidden === word),
            );
            return !isForbiddenWords;
          }
          return true;
        },
      }),
  });

export default messageFormSchema;
