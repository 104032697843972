import { Box, Grid } from "@mui/material";
import OfferCard from "../offerCatalog/OfferCard";
import { ShortOffersInterface } from "../../models/ShortOffersInterface";

type Props = {
  shortOffer: ShortOffersInterface[];
};

function OfferList({ shortOffer }: Props) {
  return (
    <Box sx={{ height: 1, mt: 5 }}>
      <Grid container spacing={2} alignItems="stretch">
        {shortOffer.map((offer) => (
          <Grid key={offer.id} item xs={6} sm={4} md={3}>
            <OfferCard offer={offer} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default OfferList;
