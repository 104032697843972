import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { CheckCircle, ThumbUp } from "@mui/icons-material";
import {
  Box,
  Paper,
  Typography as MuiTypography,
  Typography,
} from "@mui/material";
import DefaultLayout from "../components/layout/DefaultLayout";
import UserAvatar from "../components/atoms/UserAvatar";
import UserOfferList from "../components/userOfferList/UserOfferList";
import { useUserProfileBySlug } from "../hooks/fetchHooks/useUserProfileBySlug";
import { useHandlePagination } from "../hooks/useHandlePagination";
import OffersLoading from "../components/molecules/OffersLoading";
import OffersPagination from "../components/molecules/OffersPagination";
import { getPagesCount } from "../utils/paginationUtils";
import { useUserProfileOfferBySlug } from "../hooks/fetchHooks/useUserProfileOfferBySlug";

function UserProfile() {
  const navigate = useNavigate();
  const { userSlug } = useParams();
  const { page, handleChangePage } = useHandlePagination();

  const {
    userProfile,
    isLoading: userProfileIsLoading,
    isError: userProfileIsError,
  } = useUserProfileBySlug(userSlug);

  const {
    userProfileOffer,
    isLoading: userProfileOfferIsLoading,
    isError: userProfileOfferIsError,
  } = useUserProfileOfferBySlug(page, userSlug);

  useEffect(() => {
    if (userProfileIsError || userProfileOfferIsError) {
      navigate("/not-found");
    }
  }, [userProfile, userProfileIsError, userProfileOfferIsError]);

  if (userProfileIsLoading || !userProfile) {
    return <OffersLoading />;
  }

  if (!userProfile) {
    return null;
  }

  return (
    <DefaultLayout>
      <Paper
        elevation={0}
        sx={{ display: "flex", flexDirection: "column", width: 1 }}
      >
        <Box
          sx={{
            display: "flex",
            p: { xs: 2, sm: 3 },
            width: 1,
            justifyContent: "center",
          }}
        >
          <UserAvatar
            avatarPath={userProfile?.avatarPath}
            username={userProfile ? userProfile.username : ""}
          />
          <Box sx={{ p: 1, pr: 0 }}>
            <MuiTypography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
              {userProfile?.username ?? "Konto"}
            </MuiTypography>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: { xs: 1, md: 2 },
                  mb: 0.5,
                }}
              >
                <CheckCircle sx={{ fontSize: "16px" }} />
                <MuiTypography
                  component="span"
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    ml: 1,
                    textAlign: "center",
                  }}
                >
                  Sprzedane:&nbsp;
                  <MuiTypography
                    component="span"
                    variant="inherit"
                    sx={{ color: "primary.main", fontWeight: 600 }}
                  >
                    {userProfile ? userProfile.soldCount.toString() : ""}
                  </MuiTypography>
                </MuiTypography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                <ThumbUp sx={{ fontSize: "16px" }} />
                <MuiTypography
                  component="span"
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    ml: 1,
                    textAlign: "center",
                  }}
                >
                  Polecenia:&nbsp;
                  <MuiTypography
                    component="span"
                    variant="inherit"
                    sx={{ color: "primary.main", fontWeight: 600 }}
                  >
                    {userProfile ? userProfile.rateCount.toString() : ""}
                  </MuiTypography>
                </MuiTypography>
              </Box>
            </Box>
          </Box>
        </Box>
        {userProfile?.about && (
          <Box
            sx={{
              display: "flex",
              width: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                maxWidth: { xs: 400, md: 550 },
                textAlign: "center",
                px: { xs: 2, md: 2 },
                pb: { xs: 2, md: 3 },
              }}
            >
              {userProfile.about}
            </Typography>
          </Box>
        )}
      </Paper>

      <MuiTypography
        variant="h4"
        sx={{ fontWeight: 700, px: { xs: 3, lg: 0 }, mt: 4, mb: 3 }}
      >
        Oferty
      </MuiTypography>
      <Box
        sx={{
          backgroundColor: "#fff",
          flexGrow: 1,
          overflow: "hidden",
          p: 2,
        }}
      >
        {userProfileOfferIsLoading ? (
          <OffersLoading />
        ) : userProfileOffer ? (
          <>
            <UserOfferList
              offers={userProfileOffer.items}
              count={userProfileOffer.count}
            />
            <Box sx={{ mt: 2.25 }}>
              {userProfileOffer.count > 20 && (
                <OffersPagination
                  currentPage={page}
                  pagesCount={getPagesCount(userProfileOffer?.count)}
                  handleClickPage={handleChangePage}
                />
              )}
            </Box>
          </>
        ) : null}
      </Box>
    </DefaultLayout>
  );
}

export default UserProfile;
