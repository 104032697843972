import {
  Alert,
  Button,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { RootState } from "../../../../Redux/messagesStore/store";
import {
  sendPriceProposalAccept,
  sendPriceProposalReject,
} from "../../../../client/Requests";

function MessagePanelItemUser() {
  const userData = useSelector((state: RootState) => state.userData);
  const [open, setOpen] = useState(false);
  const [apiError, setApiError] = useState("");
  const messageThreadDetails = useSelector(
    (state: RootState) => state.messageThreadDetails,
  );
  const { productOffer, priceProposal } = messageThreadDetails;
  const [showPriceProposalActions, setShowPriceProposalActions] =
    useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const onSubmit = async (feedback: boolean) => {
    if (userData.userToken && productOffer?.id && priceProposal?.id) {
      try {
        if (feedback) {
          await sendPriceProposalAccept(
            productOffer.id,
            priceProposal.id,
            userData.userToken,
          );
          await setShowPriceProposalActions(false);
          await setIsSuccess(true);
        } else {
          await sendPriceProposalReject(
            productOffer.id,
            priceProposal.id,
            userData.userToken,
          );
          await setShowPriceProposalActions(false);
          await setIsSuccess(true);
        }
      } catch (error: any) {
        const message = error.response.data
          ? error.response.data
          : "Błąd podczas wysyłania odpowiedzi.";
        setApiError(message);
        setOpen(true);
      }
    }
  };

  return (
    <div>
      {showPriceProposalActions && (
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{
            mt: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1">
            Czy akceptujesz zaproponowaną cenę?
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{ width: 1, maxWidth: 400, justifyContent: "flex-end" }}
          >
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => onSubmit(true)}
              sx={{ width: { xs: 1, md: "auto" } }}
            >
              Tak
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => onSubmit(false)}
              sx={{ width: { xs: 1, md: "auto" } }}
            >
              Nie
            </Button>
          </Stack>
        </Stack>
      )}

      <Snackbar open={open} onClose={() => setOpen(false)}>
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {apiError}
        </Alert>
      </Snackbar>
      <Snackbar open={isSuccess} onClose={() => setIsSuccess(false)}>
        <Alert
          onClose={() => setIsSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Twoja odpowiedź na propozycję ceny została wysłana
        </Alert>
      </Snackbar>
    </div>
  );
}

export default MessagePanelItemUser;
