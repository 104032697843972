import React from "react";
import Typography from "./Typography";

interface ModalForAskShippingInterface {
  isVisible: boolean;
  onClickOut?: any;
}

function ModalForAskShipping({
  isVisible,
  onClickOut,
}: ModalForAskShippingInterface) {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={`${
          !isVisible && "hidden"
        } fixed z-50 inset-0 overflow-y-auto`}
        onClick={onClickOut}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="w-[600px] h-[200px] inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
            <div className="flex items-center justify-center w-full h-full text-center text-text-primary/80 p-4">
              <Typography
                type="message"
                text="Sprzedający właśnie otrzymał wiadomosć z prośbą o wycenę wspólnej dostawy zamówionych przez Ciebie przedmiotów. O jego decyzji poinformujemy Ciebie w wiadomościach"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// @ts-ignore
export default ModalForAskShipping;
