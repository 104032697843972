/* eslint-disable @typescript-eslint/no-loop-func */
import offersSortFieldMap from "../staticData/OffersSortFieldMap";
import { stringToNumber, swapToPennies } from "./PriceUtils";
import { OffersFiltersInterface } from "../models/OffersFiltersInterface";
import { ProductCategoryInterface } from "../models/ProductCategoryInterface";
import offersSortDirectionMap from "../staticData/OffersSortDirectionMap";
import { City, Province } from "../client/Requests";
import {
  ProductTemplateAttributeInterface,
  ProductTemplateAttributeValueInterface,
} from "../models/ProductTemplateAttributeInterface";
import { createAttributesUrlParams } from "./catalogFilters/params";

export const appendAttributesParams = (
  params: URLSearchParams,
  attributes: ProductTemplateAttributeValueInterface[],
) => {
  const attributesFamilies = attributes.map(
    ({ attributeFamilyId }) => attributeFamilyId,
  );
  const uniqueFamiliesIds: number[] = [];

  attributesFamilies.forEach((element) => {
    if (!uniqueFamiliesIds.includes(element)) {
      uniqueFamiliesIds.push(element);
    }
  });

  uniqueFamiliesIds.forEach((familyId) => {
    const familyAttributesIds = attributes
      .filter((attribute) => attribute.attributeFamilyId === familyId)
      ?.map((familyAttribute) => familyAttribute.id);

    if (familyAttributesIds.length) {
      params.append(`${familyId}`, `${familyAttributesIds.toString()}`);
    }
  });
};

export const buildApiUrlParams = (
  offersFilters: OffersFiltersInterface | null,
  categoryToDisplay: ProductCategoryInterface | null,
  provinces: Province[],
  cities: City[],
): string => {
  if (offersFilters === null) {
    return "";
  }

  const urlParams = new URLSearchParams();

  const newSortField = offersSortFieldMap.get(offersFilters.sort);
  const newSortDirection = offersSortDirectionMap.get(offersFilters.sort);

  urlParams.append("page", (offersFilters.currentPage - 1).toString());

  if (offersFilters?.province) {
    const provinceId = provinces.find(
      (province) => province.name === offersFilters?.province,
    )?.id;
    if (provinceId) {
      urlParams.append("province", provinceId.toString());
    }
  }

  if (categoryToDisplay?.id) {
    urlParams.append("category", categoryToDisplay.id.toString());
  }

  if (offersFilters?.city) {
    const cityId = cities.find((city) => city.name === offersFilters?.city)?.id;
    if (cityId) {
      urlParams.append("city", cityId.toString());
    }
  }

  if (offersFilters?.minPrice) {
    urlParams.append(
      "minPrice",
      swapToPennies(stringToNumber(offersFilters.minPrice)).toString(),
    );
  }

  if (offersFilters?.maxPrice) {
    urlParams.append(
      "maxPrice",
      swapToPennies(stringToNumber(offersFilters.maxPrice)).toString(),
    );
  }

  if (offersFilters?.sort && newSortField && newSortDirection) {
    urlParams.append("order", newSortField);
    urlParams.append("desc", newSortDirection);
  }

  if (offersFilters?.searchPhrase) {
    urlParams.append("query", offersFilters.searchPhrase);
  }

  appendAttributesParams(urlParams, offersFilters.attributes);

  return urlParams.toString();
};

export const buildFrontUrlParams = (
  offersFilters: OffersFiltersInterface | null,
): string => {
  if (offersFilters === null) {
    return "";
  }

  const urlParams = new URLSearchParams();

  if (offersFilters.currentPage > 1) {
    urlParams.append("strona", offersFilters.currentPage.toString());
  }

  if (offersFilters?.province) {
    urlParams.append("wojewodztwo", offersFilters.province);
  }

  if (offersFilters?.city) {
    urlParams.append("miasto", offersFilters.city);
  }

  if (offersFilters?.minPrice) {
    urlParams.append("cenaOd", offersFilters.minPrice.toString());
  }

  if (offersFilters?.maxPrice) {
    urlParams.append("cenaDo", offersFilters.maxPrice.toString());
  }

  if (offersFilters?.sort) {
    urlParams.append("sortuj", offersFilters.sort);
  }

  if (offersFilters?.searchPhrase) {
    urlParams.append("szukaj", offersFilters.searchPhrase);
  }

  const urlParamsWithAttributes = createAttributesUrlParams(
    urlParams,
    offersFilters.attributes,
  );

  return urlParamsWithAttributes.toString();
};

export const matchCategory = (
  parentCategory: ProductCategoryInterface,
  categories: ProductCategoryInterface[],
) => {
  return categories.some(
    (expandedCategory) => expandedCategory.id === parentCategory.id,
  );
};

export const findParentCategory = (
  categories: ProductCategoryInterface[],
  childCategory: ProductCategoryInterface,
) => {
  return categories.find((category) => category.id === childCategory.parentId);
};

export const findCategoryBySlug = (
  categories: ProductCategoryInterface[],
  categorySlug: string,
) => {
  const categoryBySlug = categories.find(
    (category) => category.slug === categorySlug,
  );

  return categoryBySlug ?? null;
};

export const getExpandedCategories = (
  categories: ProductCategoryInterface[],
  lastChildCategory: ProductCategoryInterface,
) => {
  let expandedCategories = [lastChildCategory];
  let i = 0;
  const maxLayers = 10;

  do {
    const lastExpandedCategory =
      expandedCategories[expandedCategories.length - 1];
    const parentCategory = findParentCategory(categories, lastExpandedCategory);

    if (parentCategory) {
      expandedCategories = [...expandedCategories, parentCategory];
    }
    i += 1;
  } while (
    !expandedCategories.some(
      (expandedCategory) => expandedCategory.parentId === 1,
    ) &&
    i < maxLayers
  );

  return expandedCategories;
};

export const getCommonSearchParams = (
  searchParams: URLSearchParams,
  provinces: Province[],
  cities: City[],
) => {
  let newFilters = {};

  const page = searchParams.get("strona");
  if (page) {
    newFilters = {
      ...newFilters,
      currentPage: parseInt(page, 10),
    };
  }

  const provinceName = searchParams.get("wojewodztwo");
  if (provinceName) {
    newFilters = {
      ...newFilters,
      province: provinces.find((filterProvince) => {
        return filterProvince.name === provinceName;
      }),
    };
  }

  const cityName = searchParams.get("miasto");
  if (cityName) {
    newFilters = {
      ...newFilters,
      city: cities.find((filterCity) => {
        return filterCity.name === cityName;
      }),
    };
  }

  const minPrice = searchParams.get("cenaOd");
  if (minPrice) {
    newFilters = {
      ...newFilters,
      minPrice: `${minPrice}`,
    };
  }

  const maxPrice = searchParams.get("cenaDo");
  if (maxPrice) {
    newFilters = {
      ...newFilters,
      maxPrice: `${maxPrice}`,
    };
  }

  const sort = searchParams.get("sortuj");
  if (sort) {
    newFilters = {
      ...newFilters,
      sort,
    };
  }

  const searchPhrase = searchParams.get("szukaj");
  if (searchPhrase) {
    newFilters = {
      ...newFilters,
      searchPhrase,
    };
  }

  return newFilters;
};

export const getTemplateSearchParams = (
  attributes: ProductTemplateAttributeInterface[],
  searchParams: URLSearchParams,
) => {
  let templateSearchParams: any[] = [];

  searchParams.forEach((paramValue, paramName) => {
    const queryAttribute = attributes.find((attribute) => {
      return attribute.id === parseInt(paramName, 10);
    });

    if (queryAttribute) {
      const queryValue = queryAttribute.values.find((value) => {
        return value.id === parseInt(paramValue, 10);
      });

      if (queryValue) {
        templateSearchParams = [
          ...templateSearchParams,
          {
            attributeId: parseInt(paramName, 10),
            value: queryValue,
          },
        ];
      }
    }
  });

  return templateSearchParams;
};

export const getInitialAttributesSelections = (
  productTemplateAttributes: ProductTemplateAttributeInterface[],
  attributesValuesIdsParams: string,
) => {
  const attributesValuesIds = attributesValuesIdsParams.split(",");

  // ** get valid attributes values by url params */
  let attributesValues: ProductTemplateAttributeValueInterface[] = [];
  attributesValuesIds.forEach((attributesValueId) => {
    productTemplateAttributes.forEach((productAttributes) => {
      const validAttributesValue = productAttributes.values.find(
        (attributeValue) => attributeValue.id === +attributesValueId,
      );

      if (validAttributesValue) {
        attributesValues = [
          ...attributesValues,
          { ...validAttributesValue, attributeFamilyId: productAttributes.id },
        ];
      }
    });
  });

  return attributesValues;
};

export const removeAttributesFamily = (
  prevAttributes: ProductTemplateAttributeValueInterface[],
  attributeFamilyId: number,
) => {
  return prevAttributes.filter(
    (prevAttribute) => prevAttribute.attributeFamilyId !== attributeFamilyId,
  );
};

export const getAttributes = (
  prevAttributes: ProductTemplateAttributeValueInterface[],
  newAttributes: ProductTemplateAttributeValueInterface[],
  attributeFamilyId: number,
) => {
  const prevAttributesCleared = removeAttributesFamily(
    prevAttributes,
    attributeFamilyId,
  );

  return [...prevAttributesCleared, ...newAttributes];
};
