import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";

type Props = {
  label: string;
  name: string;
  initialValue?: number | string | null;
  onSelectChange: (name: string, value: string) => void;
  options: { value: string; name: string }[];
};

function SelectInput({
  label,
  name,
  options,
  initialValue,
  onSelectChange,
}: Props) {
  const [selectedValue, setSelectedValue] = useState<string>(
    initialValue?.toString() ?? "",
  );

  const onChangeSelect = (event: any) => {
    const newValue = event.target.value;

    setSelectedValue(newValue);

    onSelectChange(name, newValue);
  };

  return (
    <FormControl variant="standard" sx={{ mb: 3, maxWidth: 220, width: 1 }}>
      <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={selectedValue}
        onChange={onChangeSelect}
        label={label}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            sx={{ height: "48px" }}
            value={option.value}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectInput;
