import { PAGE_SIZE } from "../components/molecules/OffersPagination";

export const getPagesCount = (count: number) => {
  if (count) {
    const extraPage = count % PAGE_SIZE > 0 ? 1 : 0;

    return Math.floor(count / PAGE_SIZE) + extraPage;
  }

  return 0;
};
