import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import OfferDetailsInformation from "../../components/atoms/OfferDetailsInformation";
import CustomButton from "../../components/atoms/Buttons/CustomButton";
import {
  getProductOfferBySlug,
  sendBuyNowRequest,
  sendGetBuyNowSummaryRequest,
} from "../../client/Requests";
import { getFormattedPrice } from "../../utils/PriceUtils";
import useUserData from "../../hooks/useUserData";
import { BuyNowSummaryInterface } from "../../models/BuyNowSummaryInterface";
import ModalForInpost from "../../components/atoms/ModalForInpost";
import Typography from "../../components/atoms/Typography";
import useLastOrder from "../../hooks/useLastOrder";
import BreadCrumbs from "../../components/molecules/BreadCrumbs";

function BuyNowSummary() {
  const { userData } = useUserData();
  const { slug } = useParams();
  const [buyNowSummary, setBuyNowSummary] = useState<BuyNowSummaryInterface>();
  const offerSlug = slug;
  const navigate = useNavigate();
  const [offerId, setOfferId] = useState<number>(0);
  const [inpostModalVisible, setInpostModalVisible] = useState(false);
  const [selectedInpostName, setSelectedInpostName] = useState<string>();
  const { saveLastOrder } = useLastOrder();

  useEffect(() => {
    if (typeof offerSlug !== "string") {
      navigate("/");
      return;
    }

    const fetchOffer = async () =>
      getProductOfferBySlug(offerSlug, userData?.userToken);

    fetchOffer()
      .then((value) => {
        setOfferId(value.data.id);
      })
      .catch(() => {
        navigate("/not-found");
      });

    if (offerId && userData) {
      const getBuyNowSummary = async () =>
        sendGetBuyNowSummaryRequest(offerId, userData?.userToken);

      getBuyNowSummary()
        .then((value) => setBuyNowSummary(value.data))
        .catch(() => {
          navigate("/not-found");
        });
    }
  }, [offerId]);

  const handleClickOutInpost = () => {
    setInpostModalVisible(false);
  };

  const handlePointSelect = (selectedName: string) => {
    setInpostModalVisible(false);
    setSelectedInpostName(selectedName);
    console.log(selectedInpostName);
  };

  const handleShowInpostModal = () => {
    setInpostModalVisible(true);
  };

  const getShippingName = () => {
    if (buyNowSummary?.shipping.methodName === "inpost") {
      return `Dostawa (${buyNowSummary?.shipping.methodLabel})`;
    }
    if (buyNowSummary?.shipping.methodName === "other") {
      return `Dostawa (${buyNowSummary?.shipping.shippingName})`;
    }
    return "";
  };

  const handleBuyNow = async () => {
    if (!userData) {
      await navigate("/login");
      return;
    }

    if (
      offerId &&
      (selectedInpostName || buyNowSummary?.shipping.methodName !== "inpost")
    ) {
      const response = await sendBuyNowRequest(
        offerId,
        userData?.userToken,
        selectedInpostName,
      );
      if (response.status === 201) {
        saveLastOrder({
          id: response.data.orderId,
        });
        window.location.href = response.data.payUrl;
      } else {
        console.log(response);
      }
    }
  };

  return (
    <DefaultLayout>
      <ModalForInpost
        isVisible={inpostModalVisible}
        onClickOut={handleClickOutInpost}
        onPointSelect={handlePointSelect}
      />
      <BreadCrumbs
        steps={[
          {
            url: `/oferta/${slug}`,
            name: buyNowSummary ? buyNowSummary.name : "",
          },
          {
            url: `/kup-teraz/${slug}`,
            name: "Kup teraz",
          },
        ]}
      />
      <div className="w-full md:flex justify-center h-auto">
        <div className="md:w-[793px]">
          <div className="bg-white md:p-6 md:rounded-md ml-auto">
            <div className="w-full py-2">
              <div className="w-full flex space-x-2">
                <div className="w-28 h-28">
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  <img
                    src={buyNowSummary?.imagePath}
                    alt="Product photo"
                    className="w-full h-full rounded-md"
                  />
                </div>
                <div className="w-full flex justify-between">
                  <div>
                    <Typography
                      type="body1"
                      text={`Sprzedający: ${
                        buyNowSummary ? buyNowSummary.seller.name : ""
                      }`}
                    />
                    <Typography
                      type="profile"
                      text={buyNowSummary ? buyNowSummary.name : ""}
                      className="font-bold"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mt-3 px-4 md:px-0">
              <Typography type="h3" text="Cena" className="font-bold" />
              <OfferDetailsInformation
                title="Przedmiot"
                description={getFormattedPrice(buyNowSummary?.price ?? 0)}
                bottomBorder
                key={Math.random()}
              />
              {buyNowSummary?.shipping.methodName !== "personalOnly" && (
                <OfferDetailsInformation
                  title={getShippingName()}
                  description={getFormattedPrice(
                    buyNowSummary?.shipping?.price ?? 0,
                  )}
                  bottomBorder
                  key={Math.random()}
                />
              )}
              <OfferDetailsInformation
                title="Prowizja"
                description={getFormattedPrice(
                  buyNowSummary?.commissionPrice ?? 0,
                )}
                bottomBorder
                key={Math.random()}
              />
            </div>
            <div className="space-y-2 mt-28">
              {buyNowSummary?.shipping.methodName === "inpost" && (
                <button
                  type="button"
                  className="w-full p-2 border border-text-primary/30 text-text-primary mb-4 text-center rounded-lg"
                  onClick={handleShowInpostModal}
                >
                  <Typography
                    type="body1"
                    text={selectedInpostName || "Wybierz paczkomat"}
                  />
                </button>
              )}
            </div>
            <div className="mb-4 text-center text-text-primary/50">
              <OfferDetailsInformation
                title="Do zapłaty"
                description={getFormattedPrice(buyNowSummary?.totalPrice ?? 0)}
                bottomBorder
                key={Math.random()}
              />
              <Typography
                type="captions"
                text={
                  buyNowSummary?.shipping.methodName === "inpost"
                    ? "Po opłaceniu prowizji i kosztów dostawy InPost otrzymasz niezbędne dane do rozliczenia się ze sprzedającym"
                    : "Po opłaceniu prowizji, otrzymasz niezbędne dane \n" +
                      "do rozliczenia się ze Sprzedającym"
                }
              />
            </div>
            <div className="space-y-2 mt-28">
              <CustomButton
                text="Zapłać"
                className={
                  buyNowSummary?.shipping.methodName === "inpost" &&
                  !selectedInpostName
                    ? "w-full bg-gray-500"
                    : "w-full"
                }
                onClick={handleBuyNow}
                disabled={
                  buyNowSummary?.shipping.methodName === "inpost" &&
                  !selectedInpostName
                }
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default BuyNowSummary;
