import { City, Province } from "../client/Requests";
import { BasicOfferFiltersInterface } from "../models/OffersFiltersInterface";
import offersSortDirectionMap from "../staticData/OffersSortDirectionMap";
import offersSortFieldMap from "../staticData/OffersSortFieldMap";
import { stringToNumber, swapToPennies } from "./PriceUtils";

export const builBasicOfferdApiUrlParams = (
  offersFilters: BasicOfferFiltersInterface,
  provinces: Province[],
  cities: City[],
): string => {
  if (offersFilters === null) {
    return "";
  }

  const urlParams = new URLSearchParams();

  const newSortField = offersSortFieldMap.get(offersFilters.sort);
  const newSortDirection = offersSortDirectionMap.get(offersFilters.sort);

  urlParams.append("page", (offersFilters.currentPage - 1).toString());

  if (offersFilters?.province) {
    const provinceId = provinces.find(
      (province) => province.name === offersFilters?.province,
    )?.id;
    if (provinceId) {
      urlParams.append("province", provinceId.toString());
    }
  }

  if (offersFilters?.city) {
    const cityId = cities.find((city) => city.name === offersFilters?.city)?.id;
    if (cityId) {
      urlParams.append("city", cityId.toString());
    }
  }

  if (offersFilters?.minPrice) {
    urlParams.append(
      "minPrice",
      swapToPennies(stringToNumber(offersFilters.minPrice)).toString(),
    );
  }

  if (offersFilters?.maxPrice) {
    urlParams.append(
      "maxPrice",
      swapToPennies(stringToNumber(offersFilters.maxPrice)).toString(),
    );
  }

  if (offersFilters?.sort && newSortField && newSortDirection) {
    urlParams.append("order", newSortField);
    urlParams.append("desc", newSortDirection);
  }

  return urlParams.toString();
};
