import { Box, CircularProgress, Container, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import BreadCrumbs from "../../components/molecules/BreadCrumbs";
import Filters from "../../components/offerFilters/Filters";
import { useOfferFilters } from "../../hooks/useOfferFilters";
import useFiltersLocations from "../../hooks/useFiltersLocations";
import OfferList from "../../components/offerList/OfferList";
import OffersPagination from "../../components/molecules/OffersPagination";
import { useHotOfferList } from "../../hooks/useHotOfferList";
import { getPagesCount } from "../../utils/paginationUtils";
import PageTitle from "../../components/atoms/PageTitle";

function OnTimeOffer() {
  const { filters, changeFilters, handleClearAllFilters } = useOfferFilters();
  const { provinces, cities } = useFiltersLocations(filters?.province);
  const { hotOfferList, isLoading } = useHotOfferList(
    filters,
    provinces,
    cities,
  );

  const [offerTitle, setOfferTitle] = useState<string>("");

  useEffect(() => {
    setOfferTitle(hotOfferList?.name ?? "");
  }, [hotOfferList?.name]);

  const handleChangePage = (page: number) => {
    changeFilters("currentPage", page);
  };

  return (
    <Container maxWidth="lg" sx={{ p: 2 }}>
      <BreadCrumbs steps={[{ name: offerTitle }]} />
      <PageTitle title={offerTitle} />
      <Divider sx={{ my: 2 }} />
      <Filters
        filters={filters}
        provinces={provinces}
        cities={cities}
        changeFilters={changeFilters}
        handleClearAllFilters={handleClearAllFilters}
      />
      {isLoading || !hotOfferList ? (
        <Box
          sx={{
            width: 1,
            p: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <OfferList shortOffer={hotOfferList.items} />
          <Box sx={{ mt: 2.25 }}>
            {hotOfferList.count > 20 && (
              <OffersPagination
                currentPage={filters.currentPage || 1}
                pagesCount={getPagesCount(hotOfferList?.count)}
                handleClickPage={handleChangePage}
              />
            )}
          </Box>
        </>
      )}
    </Container>
  );
}

export default OnTimeOffer;
