import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

interface Props {
  children: JSX.Element;
  styles?: SxProps<Theme>;
}

function SectionLayoutContent({ children, styles }: Props) {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        p: 2,
        my: { xs: 1, md: 2 },
        ...styles,
      }}
    >
      {children}
    </Box>
  );
}

export default SectionLayoutContent;
