import { Autocomplete, FormControl, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";

type Props = {
  label: string;
  name: string;
  value: string;
  options: string[];
  onChange: (name: string, value: string) => void;
};

function AutocompleteInput({ label, name, value, options, onChange }: Props) {
  const onChangeValue = (_event: any, newValue: string | null) => {
    onChange(name, newValue ?? "");
  };

  return (
    <FormControl variant="standard" sx={{ width: 1 }}>
      <Autocomplete
        disablePortal
        value={value ?? null}
        options={[...options, ""]}
        getOptionLabel={(option) => option}
        onChange={(e, newValue) => onChangeValue(e, newValue)}
        noOptionsText="Nie znaleziono takiego województwa"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            size="small"
            label={label}
          />
        )}
        renderOption={(props, option) => {
          return option ? (
            <MenuItem key={option} {...props}>
              {option}
            </MenuItem>
          ) : null;
        }}
      />
    </FormControl>
  );
}

export default AutocompleteInput;
