import { useEffect, useState } from "react";

interface LastOrder {
  id: number;
}

export default function useLastOrder() {
  const [lastOrder, setLastOrder] = useState<LastOrder | null>(null);
  const loadLastOrder = () => {
    if (typeof window === "undefined") {
      return null;
    }

    const lastOrderDataString: string | null =
      window.localStorage.getItem("lastOrder");

    return lastOrderDataString && JSON.parse(lastOrderDataString);
  };

  const saveLastOrder = (lastOrderData: LastOrder) => {
    window.localStorage.setItem("lastOrder", JSON.stringify(lastOrderData));
    setLastOrder(lastOrderData);
  };

  const clearLastOrder = () => {
    window.localStorage.removeItem("lastOrder");
    setLastOrder(null);
  };

  useEffect(() => {
    saveLastOrder(loadLastOrder());
  }, []);

  return {
    lastOrder,
    saveLastOrder,
    clearLastOrder,
  };
}
