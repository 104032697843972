import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useContext } from "react";
import { ProductCategoryInterface } from "../../models/ProductCategoryInterface";
import { OfferContext } from "../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../contexts/offerProvider/types";
import { matchCategory } from "../../utils/CatalogFilters";

type Props = {
  parentCategory: ProductCategoryInterface;
};

function NestedCategories({ parentCategory }: Props) {
  const { expandedCategories, categories, handleChangeCategory } = useContext(
    OfferContext,
  ) as IOfferContext;

  const childrens = categories.filter(
    (category) => category.parentId === parentCategory.id,
  );

  if (!childrens.length) {
    return null;
  }

  return (
    <Collapse
      in={matchCategory(parentCategory, expandedCategories)}
      timeout="auto"
      unmountOnExit
    >
      <List component="div" disablePadding sx={{ pl: 3, py: 1 }}>
        {childrens.map((childCategory) => (
          <Box key={childCategory.id}>
            <ListItemButton
              sx={{ pl: 1, py: 0.5 }}
              onClick={() => handleChangeCategory(childCategory)}
            >
              <ListItemText primary={childCategory.name} />
            </ListItemButton>
            <NestedCategories parentCategory={childCategory} />
          </Box>
        ))}
      </List>
    </Collapse>
  );
}

export default NestedCategories;
