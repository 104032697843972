import { OffersFiltersInterface } from "../../models/OffersFiltersInterface";

export const getFiltersCount = (filters: OffersFiltersInterface) => {
  let count = 0;

  if (filters.city) {
    count += 1;
  }

  if (filters.province) {
    count += 1;
  }

  if (filters.minPrice) {
    count += 1;
  }

  if (filters.maxPrice) {
    count += 1;
  }

  if (filters.attributes.length) {
    count += filters.attributes.length;
  }

  if (count > 0) {
    return count;
  }

  return null;
};
