import { Paper, useMediaQuery, useTheme } from "@mui/material";
import { AvailableChargeInterface } from "../../models/AvailableChargeInterface";
import { getChargesTotalPrice } from "./utils";
import PromoteActionsButtons from "./PromoteActionsButtons";

type Props = {
  selectedCharges: number[];
  handleBuyCharges: () => void;
  charges: AvailableChargeInterface[];
  isEdit: boolean;
};

function PromoteActions({
  selectedCharges,
  handleBuyCharges,
  charges,
  isEdit,
}: Props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const chargesAreSelected = !!selectedCharges.length;
  const totalPrice = chargesAreSelected
    ? getChargesTotalPrice(selectedCharges, charges)
    : 0;

  if (!isDesktop) {
    return (
      <Paper
        sx={{
          p: 2,
          position: "fixed",
          bottom: 0,
          left: 0,
          width: 1,
          zIndex: 100,
          boxShadow: theme.shadows[10],
        }}
      >
        <PromoteActionsButtons
          totalPrice={totalPrice}
          chargesAreSelected={chargesAreSelected}
          handleBuyCharges={handleBuyCharges}
          isEdit={isEdit}
        />
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 2, position: "sticky", top: 10 }}>
      <PromoteActionsButtons
        totalPrice={totalPrice}
        chargesAreSelected={chargesAreSelected}
        handleBuyCharges={handleBuyCharges}
        isEdit={isEdit}
      />
    </Paper>
  );
}

export default PromoteActions;
