import { Stack, Theme, Typography } from "@mui/material";
import { AvailableChargeInterface } from "../../models/AvailableChargeInterface";
import { getFormattedPrice } from "../../utils/PriceUtils";

type Props = {
  charge: AvailableChargeInterface;
};

function PromoteListItemPrice({ charge }: Props) {
  return (
    <Stack direction="row" flexWrap="wrap">
      <Typography fontWeight={700} variant="h6">
        {getFormattedPrice(charge.price)}
      </Typography>
      {!!charge.oldPrice && (
        <>
          &nbsp;
          <Typography variant="h6" sx={{ textDecoration: "line-through" }}>
            {getFormattedPrice(charge.oldPrice)}
          </Typography>
        </>
      )}
      <Typography fontWeight={400} variant="h6">
        &nbsp;/&nbsp;{charge.workingDays}&nbsp;dni
      </Typography>
      &nbsp;
    </Stack>
  );
}

export default PromoteListItemPrice;
