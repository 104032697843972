import { Autocomplete, FormControl, MenuItem, TextField } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { OfferContext } from "../../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../../contexts/offerProvider/types";

function FilterProvince() {
  const { provinces, filters, handleChangeProvince } = useContext(
    OfferContext,
  ) as IOfferContext;

  const defaultProvince = provinces.find(
    (province) => province.name === filters.province,
  )?.name;

  const [provinceValue, setProvinceValue] = useState(defaultProvince ?? null);

  const onProvinceChange = (event: any, province: string | null) => {
    handleChangeProvince(province);
    setProvinceValue(province);
  };

  useEffect(() => {
    if (!filters.province) {
      setProvinceValue(null);
    }
    if (filters.province && !provinceValue) {
      setProvinceValue(filters.province);
    }
  }, [filters.province]);

  return (
    <FormControl variant="standard" sx={{ width: 1 }}>
      <Autocomplete
        isOptionEqualToValue={() => {
          return true;
        }}
        disablePortal
        value={provinceValue}
        options={provinces.map((province) => province.name) ?? []}
        getOptionLabel={(option) => option}
        onChange={(e, value) => onProvinceChange(e, value)}
        noOptionsText="Nie znaleziono takiego województwa"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            size="small"
            label="Wybierz województwo"
          />
        )}
        renderOption={(props, option) => (
          <MenuItem key={option} {...props}>
            {option}
          </MenuItem>
        )}
      />
    </FormControl>
  );
}

export default FilterProvince;
