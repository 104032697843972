import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import GoogleButton from "../molecules/socialLogin/GoogleButton";
import FacebookButton from "../molecules/socialLogin/FacebookButton";
import { sendSocialLoginUserRequest } from "../../client/Requests";
import useUserData from "../../hooks/useUserData";

function SocialButtons() {
  const [socialLoginsEnabled] = useState<Boolean>(true);
  const navigate = useNavigate();
  const { getUserData, setUserData } = useUserData();

  const handleSocialLogin = (email: string) => {
    const currentUserData = getUserData();
    if (currentUserData) {
      navigate("/");
    }

    sendSocialLoginUserRequest({
      email,
    })
      .then((loginResponse) => {
        if (loginResponse.status === 200) {
          const loginData = loginResponse.data;
          setUserData({
            userId: loginData.userId,
            username: loginData.username,
            userToken: loginData.userToken,
            isUserProfileComplete: false,
          });
          navigate("/");
          navigate(0);
        } else if (loginResponse.status === 404) {
          navigate(`/social-register/${email}`);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          navigate(`/social-register/${email}`);
        }
      });
  };

  if (!socialLoginsEnabled) {
    return null;
  }

  if (navigator.userAgent.indexOf("cordova") !== -1) {
    return (
      <div>
        Logowanie społecznościowe jest zablokowane przez aplikację. Aby się
        zalogować społecznościowo, otwórz stronę w zewnętrznej przeglądarce
      </div>
    );
  }

  return (
    <Stack spacing={2}>
      <FacebookButton handleSocialLogin={handleSocialLogin} />
      <GoogleButton handleSocialLogin={handleSocialLogin} />
    </Stack>
  );
}

export default SocialButtons;
