/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Divider,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ProductTemplateAttributeInterface } from "../../models/ProductTemplateAttributeInterface";
import OfferAttributeField from "./OfferAttributeField";
import OfferAttributeMultipleField from "./OfferAttributeMultipleField";

interface OfferAttributesProps {
  productAttributes: ProductTemplateAttributeInterface[] | null;
  category: string;
  defaultValuesIds?: number[];
}

const desktopStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const mobileStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

function OfferAttributes({
  productAttributes,
  category,
  defaultValuesIds,
}: OfferAttributesProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  return (
    <Stack
      sx={{ width: 1 }}
      spacing={2}
      divider={<Divider orientation="horizontal" flexItem />}
    >
      {productAttributes?.map((productAttribute) => {
        const isMultipleAttribute = productAttribute.type === "checkbox";

        return (
          <Box
            sx={isDesktop ? desktopStyles : mobileStyles}
            key={productAttribute.id}
          >
            <Typography sx={{ flex: 1 }}>{productAttribute.name}</Typography>
            <OfferAttributeMultipleField
              key={`${productAttribute.id}-${category}`}
              productAttribute={productAttribute}
              category={category}
              defaultValuesIds={defaultValuesIds}
            />
          </Box>
        );
      })}
    </Stack>
  );
}

export default OfferAttributes;
