import { Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import PageTitle from "../atoms/PageTitle";

function Hero() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );

  if (isMobile) {
    return null;
  }

  return (
    <Stack sx={{ mb: 4 }} spacing={1}>
      <PageTitle title="Daj rzeczom dziecka drugie życie i bądź eko!" />
      <Typography variant="h5">
        Kupuj do 70% taniej i oszczędzaj | Sprzedaj to czego nie używa już Twoje
        dziecko i zarabiaj
      </Typography>
    </Stack>
  );
}

export default Hero;
