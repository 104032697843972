import React from "react";
import { Box, Typography } from "@mui/material";
import DefaultLayout from "../../components/layout/DefaultLayout";
import PageTitle from "../../components/atoms/PageTitle";
import BackButton from "../../components/molecules/BackButton";

function Contact() {
  return (
    <DefaultLayout>
      <Box sx={{ m: "auto" }}>
        <BackButton />
        <Box sx={{ width: 1, display: "flex", justifyContent: "center" }}>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              p: 2,
              fontWeight: 700,
            }}
          >
            Kontakt
          </Typography>
        </Box>
        <Box
          sx={{
            my: { xs: 2, md: 4 },
            pt: 1,
            backgroundColor: "#fff",
            p: 2,
            maxWidth: 800,
          }}
        >
          <Typography>
            Jeżeli potrzebujesz się z nami skontaktować, skorzystaj z formularza
            dostępnego w aplikacji w zakładce KONTO w sekcji PROFIL. Możesz też
            napisać maila: kontakt@bubaa.pl
          </Typography>
        </Box>
      </Box>
    </DefaultLayout>
  );
}

export default Contact;
