import { InputAdornment } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const errorAdornment = (
  <InputAdornment position="end">
    <ErrorOutlineIcon sx={{ fontSize: "1rem" }} color="error" />
  </InputAdornment>
);

export const numberAdornment = (
  <InputAdornment position="start">+48</InputAdornment>
);

export const bankNumberAdornment = (
  <InputAdornment position="start">PL</InputAdornment>
);

export const priceAdornment = (
  <InputAdornment position="end">PLN</InputAdornment>
);

export const accountFormDefaultValues = {
  username: "",
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  street: "",
  province: 0,
  postCode: "",
  city: null,
  bankAccount: "",
  about: "",
  agreeMarketing: true,
  expectingChild: false,
  haveChild: false,
  gender: "female",
  expectingGender: "female",
  children: [{ name: "", birthDate: "", gender: "female", isComing: false }],
};
