import { useEffect, useState } from "react";
import useFiltersLocations from "./useFiltersLocations";
import { City, Province } from "../client/Requests";
import { NewUserDataInterface } from "../models/NewUserDataInterface";

export const useUserAddress = (newUserData?: NewUserDataInterface | null) => {
  const [initialProvince, setInitialProvince] = useState<Province>();
  const [initialCity, setInitialCity] = useState<City>();
  const { provinces, cities } = useFiltersLocations(initialProvince?.name);

  useEffect(() => {
    const provinceData = provinces.find(
      (province) => province.id === newUserData?.province,
    );
    setInitialProvince(provinceData);
  }, [provinces, newUserData?.province]);

  useEffect(() => {
    const cityData = cities.find((city) => city.id === newUserData?.city);
    setInitialCity(cityData);
  }, [cities]);

  return {
    userCity: initialCity,
    userProvince: initialProvince,
  };
};
