import React from "react";
import { FormControl } from "@mui/material";
import { ProductTemplateAttributeInterface } from "../../../models/ProductTemplateAttributeInterface";
import FilterAttributesMultiple from "./FilterAttributesMultiple";
import FilterAttributesSingle from "./FilterAttributesSingle";

interface Props {
  productTemplateAttribute: ProductTemplateAttributeInterface;
}

function FilterAttributes({ productTemplateAttribute }: Props) {
  const isMultipleAttribute = productTemplateAttribute.type === "checkbox";

  return (
    <FormControl sx={{ width: 1 }}>
      {isMultipleAttribute ? (
        <FilterAttributesMultiple
          productTemplateAttribute={productTemplateAttribute}
        />
      ) : (
        <FilterAttributesSingle
          productTemplateAttribute={productTemplateAttribute}
        />
      )}
    </FormControl>
  );
}

export default FilterAttributes;
