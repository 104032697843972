import { useState } from "react";
import Typography from "../atoms/Typography";
import FooterNavItem, { FooterNavItemInterface } from "../atoms/FooterNavItem";

interface FooterNavSectionInterface {
  title: string;
  navItems: FooterNavItemInterface[];
}

function FooterNavSection({ title, navItems }: FooterNavSectionInterface) {
  const [visible, setVisible] = useState(false);

  const changeVisibleHandler = () => {
    setVisible(!visible);
  };

  return (
    <div className="border-b border-gray-300 py-3 md:border-none">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="flex justify-between items-center"
        onClick={changeVisibleHandler}
      >
        <Typography
          type="menu"
          text={title.toUpperCase()}
          className="text-text-primary/50 text-uppercase"
        />
        <span className="material-icons-outlined md:hidden">
          {visible ? "expand_less" : "expand_more"}
        </span>
      </div>
      <ul className={`${visible ? "block" : "hidden"} space-y-6 mt-6 md:block`}>
        {navItems.map((navItem) => (
          <FooterNavItem
            text={navItem.text}
            href={navItem.href}
            key={Math.random()}
          />
        ))}
      </ul>
    </div>
  );
}

export default FooterNavSection;
