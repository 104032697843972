import { useState, useEffect, useContext } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import DefaultLayout from "../components/layout/DefaultLayout";
import MessagePanel from "../components/organisms/MessagePanel";
import emptyMessages from "../images/empty_messages.svg";
import { MessagesContext } from "../contexts/MessagesProvider/MessagesProvider";
import { IMessageContext } from "../contexts/MessagesProvider/types";

function Messages() {
  const [loading, setLoading] = useState(true);
  const { messageThreadList } = useContext(MessagesContext) as IMessageContext;
  useEffect(() => {
    if (messageThreadList) {
      setLoading(false);
    }
  }, [messageThreadList]);

  if (loading) {
    return (
      <DefaultLayout>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              backgroundColor: "#fff",
              borderTop: "1px solid #E0E0E0",
              borderBottom: "1px solid #E0E0E0",
            },
          })}
        >
          <Typography variant="h2" sx={{ pb: 2, pt: { xs: 2, md: 0 } }}>
            Wiadomości
          </Typography>
        </Box>
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: 1,
            height: { xs: 410, md: 660 },
            backgroundColor: "#fff",
          }}
        >
          <CircularProgress />
        </Stack>
      </DefaultLayout>
    );
  }

  if (messageThreadList) {
    return (
      <DefaultLayout>
        <Stack
          sx={{
            flexDirection: "row",
            backgroundColor: "#fff",
            overflow: "hidden",
            height: { xs: 1, md: "80vh" },
            border: "1px solid #E0E0E0",
            borderRadius: { xs: 0, md: 1 },
          }}
        >
          <MessagePanel />
        </Stack>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            backgroundColor: "#fff",
            borderTop: "1px solid #E0E0E0",
            borderBottom: "1px solid #E0E0E0",
          },
        })}
      >
        <Typography variant="h2" sx={{ pb: 2, pt: { xs: 2, md: 0 } }}>
          Wiadomości
        </Typography>
      </Box>
      <Stack
        sx={{
          height: { xs: 410, md: 660 },
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Box
          component="img"
          alt="no messages"
          src={emptyMessages}
          width={360}
          height={162}
          className="mb-4"
        />
        <Typography sx={{ fontWeight: 700 }}>Brak wiadomości</Typography>
      </Stack>
    </DefaultLayout>
  );
}

export default Messages;
