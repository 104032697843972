import { Chip, Stack } from "@mui/material";
import { BasicOfferFiltersInterface } from "../../models/OffersFiltersInterface";

type Props = {
  filters: BasicOfferFiltersInterface;
  onChange: (name: string, value?: string) => void;
};

function FiltersChips({ filters, onChange }: Props) {
  return (
    <Stack direction="row" sx={{ mb: 2, flexWrap: "wrap" }}>
      {filters.province && (
        <Chip
          sx={{ mb: 1, ml: 1 }}
          label={filters.province}
          variant="outlined"
          onDelete={() => onChange("province", undefined)}
        />
      )}
      {filters.city && (
        <Chip
          sx={{ mb: 1, ml: 1 }}
          label={filters.city}
          variant="outlined"
          onDelete={() => onChange("city", undefined)}
        />
      )}
      {!!filters.minPrice && (
        <Chip
          sx={{ mb: 1, ml: 1 }}
          label={`od ${filters.minPrice} zł`}
          variant="outlined"
          onDelete={() => onChange("minPrice", undefined)}
        />
      )}
      {filters.maxPrice && (
        <Chip
          sx={{ mb: 1, ml: 1 }}
          label={`do ${filters.maxPrice} zł`}
          variant="outlined"
          onDelete={() => onChange("maxPrice", undefined)}
        />
      )}
    </Stack>
  );
}

export default FiltersChips;
