import { useContext } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { OfferContext } from "../../../contexts/offerProvider/OfferProvider";
import { IOfferContext } from "../../../contexts/offerProvider/types";
import { sortOptions } from "./sortTypes";

function FilterSort() {
  const { filters, handleChangeSort } = useContext(
    OfferContext,
  ) as IOfferContext;

  const onChangeSort = (event: any) => {
    const sort = event.target.value;

    handleChangeSort(sort);
  };

  return (
    <FormControl variant="standard" sx={{ mb: 3, maxWidth: 220, width: 1 }}>
      <InputLabel id="demo-simple-select-standard-label">Sortuj</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={filters.sort}
        onChange={onChangeSort}
        label="Age"
      >
        {sortOptions.map(({ value, name }) => (
          <MenuItem key={value} sx={{ height: "48px" }} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default FilterSort;
