import { TypeText, createTheme } from "@mui/material/styles";

interface IPrimaryShades {
  16: "#BF9AA329";
}

declare module "@mui/material/styles" {
  interface Theme {
    primaryShades: IPrimaryShades;
  }

  interface ThemeOptions {
    primaryShades: IPrimaryShades;
  }

  interface PaletteOptions {
    textLight: string;
  }
}

// theme.typography.h3 = {
//   fontSize: '1.2rem',
//   '@media (min-width:600px)': {
//     fontSize: '1.5rem',
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '2.4rem',
//   },
// };

const theme = createTheme({
  typography: {
    button: {
      fontWeight: 600,
      textTransform: "none",
    },
    fontFamily: "Quicksand, sans-serif",
    // h1: {
    //   fontSize: "2.5rem",
    //   fontWeight: "700",
    //   color: "#1A2438",
    // },
    // h2: {
    //   fontSize: "1.625rem",
    //   fontWeight: "700",
    //   color: "#1A2438",
    // },

    h3: {
      color: "#1A2438",
      fontSize: "1.625rem", // 26px
      "@media (min-width:600px)": {
        fontSize: "2.5rem", // 40px
      },
    },
    h4: {
      color: "#1A2438",
      fontSize: "1.375rem", // 22px
      "@media (min-width:600px)": {
        fontSize: "1.625rem", // 26px
      },
    },
    h5: {
      color: "#1A2438",
      fontSize: "1rem", // 16px
      "@media (min-width:600px)": {
        fontSize: "1.375rem", // 22px
      },
    },
    // h5: {
    // fontSize: "1.375rem",
    // fontWeight: "700",
    // color: "#1A2438",
    // },

    body1: {
      fontSize: "1rem",
      color: "#1A2438",
    },
    // body2: {
    //   fontSize: "0.875rem",
    //   fontWeight: "500",
    //   color: "#1A2438",
    // },
    // caption: {
    //   fontSize: "0.75rem",
    //   fontWeight: "400",
    //   color: "#1A2438",
    // },
    caption: {
      color: "#1A2438",
    },
  },
  palette: {
    primary: {
      light: "#FEF7FB",
      main: "#BF9AA3",
      dark: "#8C777C",
      contrastText: "#fff",
    },
    secondary: {
      main: "#243351",
    },
    error: {
      main: "#FF6262",
      contrastText: "#fff",
    },
    warning: {
      light: "#FFF6E8",
      main: "#CB9970",
      contrastText: "#fff",
    },
    info: {
      light: "#E7F0FF",
      main: "#4B8AE8",
    },
    success: {
      main: "#82B785",
    },
    text: {
      primary: "#1A2438",
      secondary: "#243351",
    },
    textLight: "rgba(26, 36, 56, 0.45)",
  },
  primaryShades: {
    16: "#BF9AA329",
  },
});

export default theme;
