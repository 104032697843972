import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Typography from "../../components/atoms/Typography";
import { buyCharges, getAvailableCharges } from "../../client/Requests";
import useUserData from "../../hooks/useUserData";
import { AvailableChargeInterface } from "../../models/AvailableChargeInterface";
import Layout from "../../components/promote/Layout";
import Header from "../../components/promote/Header";
import PromotePageLayout from "../../components/promote/PromotePageLayout";
import PromoteListItem from "../../components/promote/PromoteListItem";
import useLastOrder from "../../hooks/useLastOrder";
import PromoteActions from "../../components/promote/PromoteActions";
import { PromoteLocationState } from "../../components/promote/types";

function Promote() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state }: PromoteLocationState = useLocation();
  const isEdit = !!state?.isEdit;
  const offerId = id;
  const { userData } = useUserData();
  const { saveLastOrder } = useLastOrder();
  const [availableNewsCharges, setAvailableNewsCharges] = useState<
    AvailableChargeInterface[]
  >([]);
  const [availableBuyCharges, setAvailableBuyCharges] = useState<
    AvailableChargeInterface[]
  >([]);
  const [selectedBuyCharges, setSelectedBuyCharges] = useState<number | null>(
    null,
  );
  const [selectedNewCharges, setSelectedNewCharges] = useState<number | null>(
    null,
  );

  const getChargesValues = (charges: (number | null)[]) =>
    charges.filter((charge) => Boolean(charge)) as number[];

  const handleBuyCharges = () => {
    if (typeof offerId !== "string") {
      navigate("/");
    }

    if (!userData || offerId === undefined) {
      return;
    }

    buyCharges(
      parseInt(offerId, 10),
      {
        charges: getChargesValues([selectedBuyCharges, selectedNewCharges]),
      },
      userData?.userToken,
    ).then((response) => {
      if (response.status === 201) {
        saveLastOrder({
          id: response.data.orderId,
        });
        window.location.href = response.data.payUrl;
      } else {
        console.log(response);
      }
    });
  };

  useEffect(() => {
    if (typeof offerId !== "string") {
      navigate("/");
    }

    if (userData) {
      if (availableNewsCharges.length === 0 && offerId !== undefined) {
        getAvailableCharges(parseInt(offerId, 10), userData?.userToken).then(
          (response) => {
            setAvailableNewsCharges(response.data[0].charges);
            setAvailableBuyCharges(response.data[1].charges);
          },
        );
      }
    }
  }, [userData, offerId, availableNewsCharges]);

  const handlePickNewPromote = (pickedChargeId: number | null) => {
    if (selectedNewCharges === pickedChargeId) {
      setSelectedNewCharges(null);
      return;
    }

    if (pickedChargeId) {
      setSelectedNewCharges(pickedChargeId);
    }
  };

  const handlePickBuyPromote = (pickedChargeId: number | null) => {
    if (selectedBuyCharges === pickedChargeId) {
      setSelectedBuyCharges(null);
      return;
    }

    if (pickedChargeId) {
      setSelectedBuyCharges(pickedChargeId);
    }
  };

  return (
    <PromotePageLayout maxSize="lg">
      <>
        <Box sx={{ my: 3 }}>
          <Typography type="h2" text="Lista pakietów" />
        </Box>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2.5}>
          <Box sx={{ width: 1 }}>
            <Layout>
              <Box sx={{ mb: 3 }}>
                <Header text="Promowanie na górze listy w zakładce nowości" />
                <Stack spacing={2}>
                  {availableNewsCharges.map((charge) => (
                    <PromoteListItem
                      key={`${charge.id}`}
                      charge={charge}
                      onSelect={handlePickNewPromote}
                      isSelected={selectedNewCharges === charge.id}
                    />
                  ))}
                </Stack>
              </Box>

              <Header text="Promowanie na górze listy w zakładce Kup" />
              <Stack spacing={2}>
                {availableBuyCharges.map((charge) => (
                  <PromoteListItem
                    key={`${charge.id}`}
                    charge={charge}
                    onSelect={handlePickBuyPromote}
                    isSelected={selectedBuyCharges === charge.id}
                    variant="buyType"
                  />
                ))}
              </Stack>
            </Layout>
          </Box>
          <Box sx={{ width: { xs: 1, sm: 520 } }}>
            <PromoteActions
              selectedCharges={getChargesValues([
                selectedBuyCharges,
                selectedNewCharges,
              ])}
              handleBuyCharges={handleBuyCharges}
              charges={[...availableNewsCharges, ...availableBuyCharges]}
              isEdit={isEdit}
            />
          </Box>
        </Stack>
      </>
    </PromotePageLayout>
  );
}

export default Promote;
