import { Button } from "@mui/material";
import { PromoteType } from "./types";
import { AvailableChargeInterface } from "../../models/AvailableChargeInterface";

type Props = {
  isSelected: boolean;
  variant: PromoteType;
  onSelect: (id: number) => void;
  charge: AvailableChargeInterface;
};

function PromotePicker({ isSelected, variant, onSelect, charge }: Props) {
  const buttonVariant = isSelected ? "contained" : "outlined";
  const color = variant === "buyType" ? "primary" : "secondary";

  return (
    <Button
      variant={buttonVariant}
      color={color}
      onClick={() => onSelect(charge.id)}
      disableRipple
    >
      {isSelected ? "Wybrany" : "Wybieram"}
    </Button>
  );
}

export default PromotePicker;
