// ROZDZIELONE SHIPPING / JESLI SHIPPING W MESSAGE BEDZIE DZIAŁAĆ POPRAWNIE TO TRZEBA POŁĄCZYĆ SHIPIINGI W 1 KOMPONENT

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import InputMUI from "../../atoms/Inputs/InputMUI";
import {
  inputMaxLenght,
  inputMinLenght,
  inputRequired,
  minPrice,
  patternPrice,
} from "../../../utils/inputValidate";
import inpostImage from "../../../images/inpost.svg";
import { getShippingMethods } from "../../../client/Requests";
import useUserData from "../../../hooks/useUserData";
import { ShippingMethodInterface } from "../../../models/ShippingMethodInterface";
import { getFormattedPrice } from "../../../utils/PriceUtils";
import InpostMethodsV2 from "./InpostMethodsV2";
import PriceField from "../../organisms/createOffer/PriceField";
import { errorAdornment, priceAdornment } from "../../organisms/profile/const";

interface IShipping {
  shippingStatus: string | null;
  formIsDisabled: boolean;
}

function ShippingV2({ shippingStatus, formIsDisabled }: IShipping) {
  const { control, formState, watch, setValue } = useFormContext();
  const { errors } = formState;
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const disableInpostOptions = watch("shipping") !== "inpost";
  const openInputSection =
    watch("shipping") === "other" && !disableInpostOptions;
  const otherShippingRef = useRef<HTMLDivElement>(null);
  const { userData } = useUserData();
  const [inpostShipping, setInpostShipping] =
    useState<ShippingMethodInterface>();

  const StyledFormControlLabelSmall = styled(FormControlLabel)({
    "& .MuiFormControlLabel-label": {
      fontSize: "0.75rem",
      fontWeight: 400,
      maxWidth: "270px",
      width: "100%",
    },
  });

  const StyledFormControlLabel = styled(FormControlLabel)({
    "& .MuiFormControlLabel-label": {
      fontWeight: 600,
      fontSize: "1rem",
      maxWidth: "270px",
      width: "100%",
    },
    "&": {
      paddingTop: "20px",
      paddingBottom: "20px",
      borderTopWidth: "1px",
    },
  });

  const getChoicePrice = (name: string): string => {
    if (!inpostShipping?.choices) {
      return "";
    }

    const foundChoice = inpostShipping.choices.find((choice) => {
      return choice.name === name;
    });

    if (foundChoice) {
      return getFormattedPrice(foundChoice.price);
    }

    return "";
  };

  useEffect(() => {
    if (otherShippingRef && openInputSection) {
      if (otherShippingRef.current) {
        otherShippingRef.current?.scrollIntoView({
          block: "end",
        });
      }
    }
  }, [openInputSection]);

  useEffect(() => {
    if (userData) {
      getShippingMethods(userData?.userToken).then(
        (responseShippingMethods) => {
          setInpostShipping(responseShippingMethods[0]);
        },
      );
    }
  }, [userData]);

  return (
    <Controller
      control={control}
      name="shipping"
      render={({ field }: any) => (
        <FormControl
          error={errors.shipping !== undefined}
          variant="standard"
          color="secondary"
          disabled={formIsDisabled}
          sx={{
            ".MuiFormControlLabel-labelPlacementStart": {
              justifyContent: "flex-end",
            },
            width: 1,
          }}
        >
          <RadioGroup {...field}>
            <StyledFormControlLabelSmall
              value="inpost"
              control={<Radio sx={{ ml: 2, mb: 3 }} color="secondary" />}
              labelPlacement="start"
              sx={{ ml: 0, mb: "-15px" }}
              label={
                <>
                  <img src={inpostImage} alt="inpost" width={192} />
                  Cena specjalna dla portalu Bubaa. Po sprzedaży automatycznie
                  otrzymasz etykietę.
                </>
              }
            />
            {!disableInpostOptions && (
              <InpostMethodsV2
                disabled={formIsDisabled}
                getChoicePrice={getChoicePrice}
              />
            )}
            <StyledFormControlLabel
              value="other"
              control={<Radio sx={{ ml: 2 }} color="secondary" />}
              labelPlacement="start"
              label="Inna forma dostawy"
              sx={{ ml: 0, mt: openInputSection ? 2 : 0 }}
            />
            {openInputSection ? (
              <Stack
                ref={otherShippingRef}
                direction={isMobile ? "column" : "row"}
                spacing={2}
                mb={3}
                sx={{
                  justifyContent: "center",
                  alignItems: "flex-start",
                  ml: 4,
                }}
              >
                <Box sx={{ flexBasis: "60%" }}>
                  <InputMUI
                    control={control}
                    name="customName"
                    label="Nazwa dostawy"
                    placeholder="np. Kurier, Poczta Polska..."
                    color="secondary"
                    rules={{
                      required: inputRequired,
                      minLength: inputMinLenght,
                      maxLength: inputMaxLenght,
                    }}
                  />
                </Box>
                <Box sx={{ flexBasis: "40%" }}>
                  <Controller
                    control={control}
                    name="customPrice"
                    rules={{
                      required: inputRequired,
                      min: minPrice,
                      pattern: patternPrice,
                    }}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error, invalid },
                    }) => (
                      <FormControl
                        error={invalid}
                        variant="standard"
                        sx={{ width: 1, maxWidth: { xs: "none", md: 140 } }}
                      >
                        <InputLabel>Cena</InputLabel>
                        <Input
                          value={value}
                          onChange={onChange}
                          ref={ref}
                          placeholder="np. 50"
                          inputComponent={PriceField as any}
                          endAdornment={
                            <>
                              {invalid && errorAdornment}
                              {priceAdornment}
                            </>
                          }
                        />
                        {error?.message ? (
                          <FormHelperText sx={{ ml: 0 }}>
                            {error.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Box>
              </Stack>
            ) : null}
            <StyledFormControlLabel
              value="personalOnly"
              sx={{ ml: 0 }}
              control={<Radio sx={{ ml: 2 }} color="secondary" />}
              labelPlacement="start"
              label="Tylko odbiór osobisty"
            />

            <StyledFormControlLabel
              value="unavailableAllProducts"
              sx={{ ml: 0 }}
              control={<Radio sx={{ ml: 2 }} color="secondary" />}
              labelPlacement="start"
              label="Cała oferta jest już nieaktualna"
            />
            <StyledFormControlLabel
              value="unavailableSomeProduct"
              sx={{ ml: 0 }}
              control={<Radio sx={{ ml: 2 }} color="secondary" />}
              labelPlacement="start"
              label="Co najmniej jeden przedmiot jest już nieaktualny"
            />
          </RadioGroup>

          <FormHelperText>
            {errors.shipping?.message && errors.shipping.message.toString()}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default ShippingV2;
