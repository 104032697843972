import { useContext } from "react";
import Typography from "../../../atoms/Typography";
import UserMessagePreview from "./UserMessagePreview";
import { MessagesContext } from "../../../../contexts/MessagesProvider/MessagesProvider";
import { IMessageContext } from "../../../../contexts/MessagesProvider/types";

function UsersMessagesList({
  handleOpenMessages,
}: {
  handleOpenMessages?: () => void;
}) {
  const { messageThreadList, handleSetCurrentMessageThread } = useContext(
    MessagesContext,
  ) as IMessageContext;
  const reverseMessagesThread = messageThreadList
    ? [...messageThreadList].reverse()
    : null;

  const handlerMessageThread = (id: number) => {
    handleOpenMessages?.();
    handleSetCurrentMessageThread(id);
  };
  return (
    <>
      <Typography type="h3" text="Wiadomości" className="p-4 border-b" />
      {reverseMessagesThread &&
        reverseMessagesThread.map((messageThread) => (
          <UserMessagePreview
            messageThread={messageThread}
            handlerMessageThread={handlerMessageThread}
            key={messageThread.id}
          />
        ))}
    </>
  );
}

export default UsersMessagesList;
