import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import DefaultLayout from "../../components/layout/DefaultLayout";
import { StaticPageInterface } from "../../models/StaticPageInterface";
import { getStaticPage } from "../../client/Requests";
import BackButton from "../../components/molecules/BackButton";

function StaticPage() {
  const navigate = useNavigate();
  const { name } = useParams();
  const staticPageName = name;
  const [staticPage, setStaticPage] = useState<StaticPageInterface>();

  useEffect(() => {
    if (typeof staticPageName !== "string") {
      navigate("/");
      return;
    }

    const fetchStaticPage = async () => getStaticPage(staticPageName);

    fetchStaticPage()
      .then((value) => {
        setStaticPage(value.data);
      })
      .catch(() => {
        navigate("/not-found");
      });
  }, [navigate, staticPageName]);

  return (
    <DefaultLayout>
      <BackButton />
      <Typography
        variant="h4"
        component="h1"
        sx={{
          alignSelf: "center",
          p: 2,
          fontWeight: 700,
        }}
      >
        {staticPage ? staticPage.title : ""}
      </Typography>
      <div className="w-full md:flex justify-between h-auto">
        <div className="w-full">
          <div className="bg-white">
            <div className="flex items-center p-6">
              <div
                className="mx-4 text-xl"
                dangerouslySetInnerHTML={{
                  __html: staticPage ? staticPage.content : "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default StaticPage;
