import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DefaultLayout from "../components/layout/DefaultLayout";
import { ShortOffersInterface } from "../models/ShortOffersInterface";
import { searchPublishedOffers } from "../client/Requests";
import Typography from "../components/atoms/Typography";
import OfferPreview from "../components/atoms/OfferPreview";
import { getFormattedPrice } from "../utils/PriceUtils";
import BreadCrumbs from "../components/molecules/BreadCrumbs";
import SearchForm from "../components/molecules/SearchForm";
import EmptyOffers from "../components/molecules/EmptyOffers";

function Search() {
  const { searchPhrase } = useParams();
  const [offers, setOffers] = useState<ShortOffersInterface[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof searchPhrase !== "string") {
      navigate("/");
      return;
    }

    searchPublishedOffers(searchPhrase).then((data) => {
      setOffers(data.data);
    });
  }, [navigate, searchPhrase]);

  return (
    <DefaultLayout>
      <SearchForm />
      <BreadCrumbs
        steps={[
          {
            url: `/szukaj/${searchPhrase}`,
            name: "Wyniki Wyszukiwania",
          },
        ]}
      />
      <div className="my-8 flex flex-col space-y-2">
        <Typography type="h1" text={`Szukaj: ${searchPhrase}`} />
      </div>
      <div className="w-full md:flex justify-between h-auto">
        <div className="w-full">
          <div className="bg-white flex flex-col justify-center items-center">
            <div className="flex items-center py-6">
              {offers.length === 0 && <EmptyOffers />}
              <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mt-3 mb-5">
                {offers.map((offer) => (
                  <OfferPreview
                    offerId={offer.id}
                    isPromoted={Boolean(offer.isPromoted)}
                    text={offer.name}
                    slug={offer.slug}
                    price={getFormattedPrice(offer.price)}
                    imageUrl={offer.imagePath}
                    key={Math.random()}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Search;
